import { defineMessages } from 'react-intl';

export const days = defineMessages({
  mon: {
    id: 'common.dates.days.mon',
    description: 'Monday',
    defaultMessage: 'Monday'
  },
  tue: {
    id: 'common.dates.days.tue',
    description: 'Tuesday',
    defaultMessage: 'Tuesday'
  },
  wed: {
    id: 'common.dates.days.wed',
    description: 'Wednesday',
    defaultMessage: 'Wednesday'
  },
  thu: {
    id: 'common.dates.days.thu',
    description: 'Thursday',
    defaultMessage: 'Thursday'
  },
  fri: {
    id: 'common.dates.days.fri',
    description: 'Friday',
    defaultMessage: 'Friday'
  },
  sat: {
    id: 'common.dates.days.sat',
    description: 'Saturday',
    defaultMessage: 'Saturday'
  },
  sun: {
    id: 'common.dates.days.sun',
    description: 'Sunday',
    defaultMessage: 'Sunday'
  }
});

export const timeUnits = defineMessages({
  minutes: {
    id: 'common.dates.time_units.minute',
    description: 'Minutes',
    defaultMessage: 'minutes'
  },
  hours: {
    id: 'common.dates.time_units.hour',
    description: 'Hours',
    defaultMessage: 'hours'
  },
  days: {
    id: 'common.dates.time_units.day',
    description: 'Days',
    defaultMessage: 'Days'
  }
});

export const placeholders = defineMessages({
  from: {
    id: 'common.dates.placeholders.from',
    defaultMessage: 'From'
  },
  to: {
    id: 'common.dates.placeholders.to',
    defaultMessage: 'To'
  },
  startDate: {
    id: 'common.dates.placeholders.start_date',
    defaultMessage: 'Start Date'
  },
  endDate: {
    id: 'common.dates.placeholders.end_date',
    defaultMessage: 'End Date'
  }
});

export const unavailableDays = defineMessages({
  markAsUnavailable: {
    id: 'common.dates.unavailable_days',
    defaultMessage: 'Mark As Unavailable'
  }
});
