/*
 * AdditionalExtraTable Messages
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.components.ResourcesTable';

export default defineMessages({
  tourResources: {
    id: `${scope}.tour_resources`,
    defaultMessage: 'Tour Resources'
  },
  resourceTitle: {
    id: `${scope}.resource_title`,
    defaultMessage: 'Resource Title'
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type'
  },
  availability: {
    id: `${scope}.availability`,
    defaultMessage: 'Availability'
  },
  capacity: {
    id: `${scope}.capacity`,
    defaultMessage: 'Capacity'
  },
  coolDown: {
    id: `${scope}.cool_down`,
    defaultMessage: 'Cool Down'
  },
  shared: {
    id: `${scope}.shared`,
    defaultMessage: 'Shared'
  },
  private: {
    id: `${scope}.private`,
    defaultMessage: 'Private'
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'Number'
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email'
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note'
  },
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage'
  },
  labelRowsSelect: {
    id: `${scope}.labelRowsSelect`,
    defaultMessage: 'rows'
  },
  labelRowsPerPage: {
    id: `${scope}.labelRowsPerPage`,
    defaultMessage: 'Rows per page'
  },
  firstAriaLabel: {
    id: `${scope}.firstAriaLabel`,
    defaultMessage: 'First Page'
  },
  firstTooltip: {
    id: `${scope}.firstTooltip`,
    defaultMessage: 'First Page'
  },
  previousAriaLabel: {
    id: `${scope}.previousAriaLabel`,
    defaultMessage: 'Previous Page'
  },
  previousTooltip: {
    id: `${scope}.previousTooltip`,
    defaultMessage: 'Previous Page'
  },
  nextAriaLabel: {
    id: `${scope}.nextAriaLabel`,
    defaultMessage: 'Next Page'
  },
  nextTooltip: {
    id: `${scope}.nextTooltip`,
    defaultMessage: 'Next Page'
  },
  lastAriaLabel: {
    id: `${scope}.lastAriaLabel`,
    defaultMessage: 'Last Page'
  },
  lastTooltip: {
    id: `${scope}.lastTooltip`,
    defaultMessage: 'Last Page'
  },
  searchTooltip: {
    id: `${scope}.searchTooltip`,
    defaultMessage: 'Search'
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search'
  },
  emptyDataSourceMessage: {
    id: `${scope}.emptyDataSourceMessage`,
    defaultMessage: 'No records to display'
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes'
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No'
  },
  mins: {
    id: `${scope}.mins`,
    defaultMessage: 'mins'
  }
});
