import { defineMessages } from 'react-intl';

export const scope = 'app.containers.BookingWidget';

export default defineMessages({
  operationalHours: {
    id: `${scope}.operational_hours`,
    defaultMessage: 'Operational hours'
  },
  startingTimes: {
    id: `${scope}.starting_times`,
    defaultMessage: 'Time'
  },
  placesLeft: {
    id: `${scope}.places_left`,
    defaultMessage: ' places left'
  },
  selectDateFirst: {
    id: `${scope}.select_date_first`,
    defaultMessage: 'Please select a date first'
  },
  selectDate: {
    id: `${scope}.select_date`,
    defaultMessage: 'Select Date'
  }
});
