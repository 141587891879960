import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/cards';

export const assignCardToUser = ({ cardId, userId }) => {
  return axios
    .post(`${url}/assign-card-to-user`, { cardId, userId })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchUserProfileByCardId = cardId => {
  return axios
    .get(`${url}/profile/${cardId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
