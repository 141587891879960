import { Analytics } from '@analytics';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AddToursModal from '../AddToursModal';
import messages from './messages';

const useStyles = makeStyles({
  manageButton: {
    marginRight: 5
  }
});

const AgentProfileSummaryButton = ({ activeTours, profile, addToursToProfile, userId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleOpenDropDown = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.manageButton}
        style={{ backgroundColor: '#fff' }}
        color="#fff"
        variant="contained"
        onClick={handleOpenDropDown}>
        <MoreHorizIcon />
      </Button>
      <Menu
        style={{ marginTop: '50px' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropDown}>
        <MenuItem onClick={() => {
            setIsOpen(true);
            Analytics.track('open add tours to guide');
          }}>
          <FormattedMessage {...messages.addToursToProfile} />
        </MenuItem>
      </Menu>

      <AddToursModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeTours={activeTours}
        profile={profile}
        userId={userId}
        addToursToProfile={tourIds => addToursToProfile(profile.userId, tourIds)}
      />
    </div>
  );
};

export default AgentProfileSummaryButton;
