/*
 *
 * LanguageProvider reducer
 *
 */
import produce from 'immer';
import { DEFAULT_LOCALE } from '../../i18n';
import {
  CHANGE_BOOKING_WIDGET_LOCALE,
  CHANGE_LOCALE,
  CHANGE_SESSION_LOCALE,
  CHANGE_TIPPING_LOCALE
} from './constants';

const LOCALE_KEY = 'locale';
const LOCALE_BW_KEY = 'bw-locale';
const LOCALE_TIPPING_KEY = 'tipping-locale';

var localeFromLS = null;

try {
  localeFromLS = localStorage.getItem(LOCALE_KEY);
} catch (e) {
  console.log('Caught E, cookies blocked', e);
}

export const initialState = {
  locale: localeFromLS ?? DEFAULT_LOCALE
};

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_LOCALE:
        //side effects, but who cares untill they're sync lol
        try {
          draft.locale = action.locale;
          localStorage.setItem(LOCALE_KEY, action.locale);
        } catch (e) {
          console.log('exception in CHANGE_LOCALE', e);
        }
        break;
      case CHANGE_BOOKING_WIDGET_LOCALE:
        try {
          draft.locale = action.locale;
          localStorage.setItem(LOCALE_BW_KEY, action.locale);
        } catch (e) {
          console.log('exception in CHANGE_BOOKING_WIDGET_LOCALE', e);
        }
        break;
      case CHANGE_TIPPING_LOCALE:
        try {
          draft.locale = action.locale;
          localStorage.setItem(LOCALE_TIPPING_KEY, action.locale);
        } catch (e) {
          console.log('exception in CHANGE_TIPPING_LOCALE', e);
        }
        break;
      case CHANGE_SESSION_LOCALE:
        draft.locale = action.locale;
        break;
    }
  });

export default languageProviderReducer;
