import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
import MaterialTable from 'material-table';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints, UserRoles } from '../../../utils/constants';
import { formatMoneyAndCurrencySymbol } from '../../../utils/helpers';
import localizationMessages from '../../Resources/ResourcesTable/messages';
import {
  TransactionStatusBackgroundColor,
  TransactionStatusForeColor,
  TransactionStatusType
} from './constants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    width: '80%',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%'
    }
  },
  table: {
    width: '100%'
  },
  status: {
    padding: '4px 4px',
    fontSize: '11px',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '66px',
    borderRadius: '4px'
  },
  headerContainer: {
    padding: '20px',
    width: '100%'
  }
}));

const TransactionStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: TransactionStatusBackgroundColor[status],
        color: TransactionStatusForeColor[status]
      }}>
      {TransactionStatusType[status]}
    </div>
  );
};

const PaymentsTable = ({
  transactions,
  fetchTransactions,
  userRole,
  disableCompanyTransactionReporting
}) => {
  const intl = useIntl();
  const classes = useStyles();

  useEffect(() => {
    if (!transactions?.length) {
      fetchTransactions(null);
    }
  }, []);

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      sorting: false,
      render: rowData => <TransactionStatus status={rowData.status} classes={classes} />
    },
    {
      field: 'amount',
      title: intl.formatMessage(messages.revenue),
      render: rowData =>
        formatMoneyAndCurrencySymbol({ amount: rowData.amount, currency: rowData.currency })
    },
    {
      field: 'date',
      title: intl.formatMessage(messages.activityDate),
      render: rowData => format(new Date(rowData.date), 'dd-MM-yyyy')
    },
    {
      field: 'time',
      title: intl.formatMessage(messages.time),
      sorting: false,
      render: rowData =>
        new Date(rowData.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },
    {
      field: 'name',
      title: intl.formatMessage(messages.name),
      render: rowData => `${rowData.firstName} ${rowData.lastName}`,
      customFilterAndSearch: (term, rowData) => {
        return (
          `${rowData.firstName.toLowerCase()} ${rowData.lastName.toLowerCase()}`.indexOf(
            term.toLowerCase()
          ) != -1
        );
      },
      hidden: userRole !== UserRoles.admin || disableCompanyTransactionReporting
    },
    {
      field: 'userId',
      title: intl.formatMessage(messages.userId),
      hidden: userRole !== UserRoles.admin || disableCompanyTransactionReporting
    },
    {
      field: 'transactionId',
      title: intl.formatMessage(messages.reference)
    }
  ];

  const handleExportCsv = (_, data) => {
    const columnTitles = columns.map(columnDef => columnDef.title);

    const csvData = data.map(rowData =>
      columns.map(columnDef => {
        switch (columnDef.field) {
          case 'status':
            return intl.formatMessage(messages.success);
          case 'amount':
            return formatMoneyAndCurrencySymbol({
              amount: rowData.amount,
              currency: rowData.currency
            });
          case 'date':
            return format(new Date(rowData.date), 'dd-MM-yyyy');
          case 'time':
            return new Date(rowData.date).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            });
          case 'name':
            return `${rowData.firstName} ${rowData.lastName}`;
          default:
            return rowData[columnDef.field];
        }
      })
    );

    const builder = new CsvBuilder(`taptotip-payments.csv`)
      .setColumns(columnTitles)
      .addRows(csvData)
      .exportFile();

    return builder;
  };

  return (
    <div className={classes.container}>
      <MaterialTable
        style={{ width: '100%' }}
        columns={columns}
        data={transactions}
        title={intl.formatMessage(messages.payments)}
        components={{
          Container: props => <Paper className={classes.striped} {...props} />
        }}
        options={{
          exportButton: true,
          exportCsv: handleExportCsv,
          tableHeadStyle: {
            whiteSpace: 'nowrap'
          },
          headerStyle: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            top: '0'
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            width: 'fit-content'
          },
          pageSizeOptions: [5, 10, 20, 30],
          pageSize: 30,
          emptyRowsWhenPaging: false,
          maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
        }}
        localization={{
          pagination: {
            labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
            labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
            firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
            firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
            previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
            previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
            nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
            nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
            lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
            lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
          },
          toolbar: {
            searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
            searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage(localizationMessages.emptyDataSourceMessage)
          }
        }}
      />
    </div>
  );
};

export default PaymentsTable;
