import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { currencySign, formatMoney } from '../../../../utils/helpers';
import { useCurrency } from '../../../../utils/hooks';
import messages from '../messages';

const useStyles = makeStyles({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  tableWithPadding: {
    maxWidth: '900px',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingLeft: '30px',
        position: 'static'
      }
    }
  }
});

function SelectToursForPromotion({ tours, selectedToursIds, handleChange, setIsNextStepDisabled }) {
  const intl = useIntl();
  const classes = useStyles();
  const currency = useCurrency();
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    const formDataForTable = () => {
      if (tours?.length) {
        return tours.map(tour => ({
          tour: tour,
          tourId: tour._id,
          title: tour.displayName,
          minPrice: `${currencySign(currency)} ${formatMoney({
            amount: getMinimalPriceForTour(tour),
            currency
          })}`,
          tableData: {
            checked: selectedToursIds.includes(tour._id)
          }
        }));
      }
      return [];
    };
    setDataForTable([...formDataForTable()]);
  }, [tours]);

  function getMinimalPriceForTour(tour) {
    const minimalPrice = Math.min(
      ...tour.seasons.map(season =>
        Math.min(...season.pricing.participants.filter(p => p.isActive).map(p => p.price))
      )
    );
    return isFinite(minimalPrice) ? minimalPrice : 0;
  }

  const handleSelection = rows => {
    handleChange(rows.map(row => row.tourId));
    setIsNextStepDisabled(!rows.length);
  };

  const columns = [
    {
      title: intl.formatMessage(messages.selectAll),
      field: 'title',
      cellStyle: {
        fontWeight: 'bold'
      }
    },
    {
      title: intl.formatMessage(messages.priceFrom),
      field: 'minPrice',
      cellStyle: {
        fontWeight: 'bold',
        textAlign: 'right',
        padding: '0 43px 0 0'
      },
      headerStyle: {
        textAlign: 'right'
      }
    }
  ];

  return (
    <>
      <MaterialTable
        columns={columns}
        data={dataForTable}
        options={{
          tableLayout: 'fixed',
          paging: false,
          showTitle: false,
          search: false,
          toolbar: false,
          selection: true
        }}
        onSelectionChange={handleSelection}
        components={{
          // eslint-disable-next-line react/display-name
          Container: props => (
            <div className={`${classes.striped} ${classes.tableWithPadding}`} {...props} />
          )
        }}
      />
    </>
  );
}

export default SelectToursForPromotion;
