import { call, put, takeLatest } from '@redux-saga/core/effects';
import { resetPassword } from '../../api/resetPassword';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as types from './types';
import * as actions from './actions';

function* resetPasswordWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.sendingEmail));
    yield call(resetPassword, payload);
    yield put(setSnackbarStatus(snackbarStatus.willSendEmailIfExists));
    yield put(actions.resetPasswordSuccess());
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.details)));
  }
}

export default function* resetPasswordWatcher() {
  yield takeLatest(types.RESET_PASSWORD, resetPasswordWorker);
}
