import {
  SIGNIN,
  SIGNUP,
  SIGNOUT,
  SET_CURRENT_USER,
  INIT_USER_LOADING,
  GET_CURRENT_USER,
  SET_TARGET_LOCATION,
  INIT_USER_SIGNIN_FAILED,
  INIT_USER_SIGNUP_FAILED,
  SET_LANDING_URL
} from './actions';

export function signin(email, password, targetLocation, history) {
  return {
    type: SIGNIN,
    payload: { email, password },
    history,
    targetLocation
  };
}

export function setLandingUrl(url) {
  return {
    type: SET_LANDING_URL,
    payload: url
  };
}

export function signout() {
  return {
    type: SIGNOUT
  };
}

export function signup(
  signupDetails,
  history,
  targetLocation,
  signupWithSubscription = false,
  checkoutId
) {
  return {
    type: SIGNUP,
    payload: {
      targetLocation,
      ...signupDetails
    },
    history,
    signupWithSubscription,
    checkoutId
  };
}

export function getCurrentUser() {
  return {
    type: GET_CURRENT_USER
  };
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    payload: user
  };
}

export function initUserLoading() {
  return {
    type: INIT_USER_LOADING
  };
}

export function initUserSigninFailed(error) {
  return {
    type: INIT_USER_SIGNIN_FAILED,
    payload: error
  };
}

export function setTargetLocation(location) {
  return {
    type: SET_TARGET_LOCATION,
    payload: location
  };
}

export function initUserSignupFailed(error) {
  return {
    type: INIT_USER_SIGNUP_FAILED,
    payload: error
  };
}
