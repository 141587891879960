/*
 * ChannelManagerTable Messages
*/
import { defineMessages } from 'react-intl';

export const scope = 'app.components.ChannelManagerTable';

export default defineMessages({
  channelManager: {
    id: `${scope}.channel_manager`,
    defaultMessage: 'Channel Manager'
  },
  CMTours: {
    id: `${scope}.cm_tours`,
    defaultMessage: 'Channel Manager Tours'
  },
  activeChannels: {
    id: `${scope}.active_channels`,
    defaultMessage: 'Active Channels'
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete'
  }
});
