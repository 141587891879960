import { defineMessages } from 'react-intl';

const scope = `app.containers.TipDirectPage`;

export default defineMessages({
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile'
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Payments'
  },
  tipDirect: {
    id: `${scope}.tipDirect`,
    defaultMessage: 'Tip Direct'
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Users'
  },
  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: 'Transactions'
  },
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews'
  },
  admin: {
    id: `${scope}.admin`,
    defaultMessage: 'Admin'
  },
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  recommendToAFriend: {
    id: `${scope}.recommend_to_a_friend`,
    defaultMessage: 'Recommend To A Friend'
  }
});
