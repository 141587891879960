import { StyleBreakpoints } from '@constants';
import { Paper } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import localizationMessages from '../../Resources/ResourcesTable/messages';
import TotalStars from '../TotalStars';
import Filters from './Filters';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  striped: {
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      height: '100%',
      minHeight: '100%'
    },

    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    width: '80%',
    maxHeight: '90vh',
    flexWrap: 'wrap',
    flexDirection: 'column',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%',
      maxWidth: '100%',
      flexWrap: 'noWrap'
    }
  },
  tableWrapper: {
    flex: '3 2',

    '& > .MuiPaper-root': {
      borderRadius: '16px',
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'
    },

    '& > .MuiPaper-root > div': {
      borderRadius: '16px'
    },

    '& .MuiTableFooter-root > tr > td': {
      borderRadius: '16px'
    },

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%',
      maxWidth: '100%'
    }
  },
  reviewsRow: {
    borderRadius: '18px',
    backgroundColor: '#f9f9f9',
    padding: '12px',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '50px 20px',
    flex: '1 1 auto',
    marginBottom: '16px',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%',
      maxWidth: '100%',
    }
  },
  disclaimerContainer: {
    borderRadius: '18px',
    backgroundColor: '#f9f9f9',
    padding: '20px',
    height: '128px',
    maxWidth: '385px'
  },
  disclaimerWrapper: {
    maxWidth: '350px',
    padding: '18px 14px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#fff',
    display: 'flex'
  },
  disclaimerIcon: {
    width: '40px',
    minWidth: '40px',
    height: '40px',
    minHeight: '40px',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)',
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disclaimerText: {
    fontSize: '13px',
  },
  boldText: {
    fontWeight: '500'
  },
  infoIcon: {
    fill: '#6897ff',
    color: '#6897ff'
  }
}));

const ReviewsTable = ({
  agentsProfiles,
  fetchAgentsProfiles,
  reviewsStatistic,
  fetchReviewsStatistic,
  userRole
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [selectedTours, setSelectedTours] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: new Date().setDate(new Date().getDate() - 4),
    end: new Date().setDate(new Date().getDate() + 3)
  });

  const [filteredAgentsProfiles, setFilteredAgentProfiles] = useState([]);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    if (!agentsProfiles?.length) {
      fetchAgentsProfiles();
    }
  }, []);

  useEffect(() => {
    if (filteredAgentsProfiles) {
      const tempTours = {};

      filteredAgentsProfiles.forEach(agent => {
        agent.tours?.forEach(tour => {
          tempTours[tour._id] = tour;
        });
      });
      setTours(Object.values(tempTours));
    }
  }, [filteredAgentsProfiles]);

  useEffect(() => {
    if (agentsProfiles) {
      setFilteredAgentProfiles(agentsProfiles?.filter(p => p.status == 'active'));
    }
  }, [agentsProfiles]);

  useEffect(() => {
    fetchReviewsStatistic({
      dateRange,
      tourIds: selectedTours,
      guideIds: selectedProfiles,
      submitReviewOptionsClicked: {
        Google: true,
        TripAdvisor: true,
        Yelp: true
      }
    });
  }, [selectedTours, selectedProfiles, dateRange]);

  const columns = [
    {
      field: 'tourTitle',
      title: intl.formatMessage(messages.tour),
      cellStyle: {
        minWidth: '230px',
        maxWidth: '300px',
        wordBreak: 'normal'
      }
    },
    {
      field: 'averageRating',
      title: intl.formatMessage(messages.averageRating),
      headerStyle: {
        wordBreak: 'normal'
      }
    },
    {
      field: 'reviewsAmountByRating.5',
      title: 5
    },
    {
      field: 'reviewsAmountByRating.4',
      title: 4
    },
    {
      field: 'reviewsAmountByRating.3',
      title: 3
    },
    {
      field: 'reviewsAmountByRating.2',
      title: 2
    },
    {
      field: 'reviewsAmountByRating.1',
      title: 1
    },
    {
      field: 'totalReviewsAmount',
      title: intl.formatMessage(messages.total)
    }
  ];

  function getToolbarComponent() {
    return (
      <Filters
        tours={tours}
        profiles={filteredAgentsProfiles}
        selectedTours={selectedTours}
        setSelectedTours={setSelectedTours}
        selectedProfiles={selectedProfiles}
        setSelectedProfiles={setSelectedProfiles}
        dateRange={dateRange}
        setDateRange={setDateRange}
        userRole={userRole}
      />
    );
  }

  return (
    <>
      <div className={classes.container}>
        {getToolbarComponent()}
        <div className={classes.reviewsRow}>
          <div className={classes.tableWrapper}>
            <MaterialTable
              title={intl.formatMessage(messages.reviews)}
              columns={columns}
              data={reviewsStatistic}
              components={{
                // eslint-disable-next-line react/display-name
                Container: props => <Paper className={classes.striped} {...props} />,
                Toolbar: () => null
              }}
              options={{
                pageSize: 10,
                tableLayout: 'auto',
                emptyRowsWhenPaging: false,
                headerStyle: {
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  top: '0'
                },
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: 'fit-content'
                }
              }}
              localization={{
                pagination: {
                  labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
                  labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
                  firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
                  firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
                  previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
                  previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
                  nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
                  nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
                  lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
                  lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
                },
                toolbar: {
                  searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
                  searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
                },
                body: {
                  emptyDataSourceMessage: intl.formatMessage(
                    localizationMessages.emptyDataSourceMessage
                  )
                }
              }}
            />
          </div>
          <TotalStars reviews={reviewsStatistic} />
        </div>
        <div className={classes.disclaimerContainer}>
          <div className={classes.disclaimerWrapper}>
            <div className={classes.disclaimerIcon}><Info className={classes.infoIcon} /></div>
            <div className={classes.disclaimerText}>
              <span className={classes.boldText}>Note</span> - Figures represent the number of
              customers which have opened your review link only.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsTable;
