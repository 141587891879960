import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { compose, isNil } from 'ramda';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../../utils/constants';
import {
  constructDateStringWithTimezone,
  date2day,
  getCutoffTimeInMs,
  getFormattedOffsetTime
} from '../../../utils/helpers';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  coloredInput: {
    color: theme.palette.primary.main
  },
  freePlacesAmount: {
    display: 'inline-block',
    marginLeft: 'auto'
  },
  chooseTimeWrapper: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    minWidth: '200px'
  },
  nativeSelect: {
    display: 'block',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none'
    }
  },
  desktopSelect: {
    display: 'none',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'block'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '16px'
  }
}));

export const getSelectedDateInMs = d => {
  const date = new Date(d);
  date.setHours(0, 0, 0, 0);
  return date.getTime() + getFormattedOffsetTime(date.getTimezoneOffset());
};

export const getTimeslotInMs = (d, ts) => {
  const date = new Date(d);
  date.setHours(ts?.hours ?? 0, ts?.minutes ?? 0, 0, 0);
  return date.getTime() + getFormattedOffsetTime(date.getTimezoneOffset());
};

export const getTimeslotInMsConsideringTimezone = (d, ts, timezoneName) => {
  const date = new Date(d);
  date.setHours(ts?.hours ?? 0, ts?.minutes ?? 0, 0, 0);

  return Date.parse(constructDateStringWithTimezone(date, timezoneName));
};

export const isTimeslotDisabled = ({
  timeslot,
  season,
  selectedDate,
  bookedAmountPerDate,
  timezoneName,
  participantsAmount,
  shouldIgnoreCutoffTimes,
  canOverbook
}) => {
  if (!season) return true;

  const capacityForDay = season.pricing.capacity;
  const bookingDateInMs = getSelectedDateInMs(selectedDate);
  const bookingTimeInMs = getTimeslotInMs(selectedDate, timeslot);
  const cutoffTimeInMs = shouldIgnoreCutoffTimes ? 0 : getCutoffTimeInMs(season.cutoffTimes);
  const timeslotMsConsideringEventTimezone = getTimeslotInMsConsideringTimezone(
    selectedDate,
    timeslot,
    timezoneName
  );

  if (bookedAmountPerDate[bookingDateInMs]?.[bookingTimeInMs]?.count >= capacityForDay) {
    return true;
  }

  if (bookedAmountPerDate[bookingDateInMs]?.[bookingTimeInMs]?.isBlocked) {
    return true;
  }

  if (Date.now() >= timeslotMsConsideringEventTimezone - cutoffTimeInMs) {
    return true;
  }

  if (
    participantsAmount >
      getFreePlacesPerTimeslot({ timeslot, selectedDate, season, bookedAmountPerDate }) &&
    !canOverbook
  ) {
    return true;
  }

  return false;
};

function getFreePlacesPerTimeslot({ timeslot, selectedDate, season, bookedAmountPerDate }) {
  const capacityForDay = season.pricing.capacity;
  const takenPlaces =
    bookedAmountPerDate[getSelectedDateInMs(selectedDate)]?.[
      getTimeslotInMs(selectedDate, timeslot)
    ]?.count;

  if (takenPlaces) {
    return capacityForDay - takenPlaces > 0 ? capacityForDay - takenPlaces : 0;
  }

  return capacityForDay;
}

function parseTimeslot(timeslotStr) {
  const [hours, minutes] = timeslotStr.split(':').map(Number);
  return { hours, minutes };
}

function renderTimeslot(timeslot) {
  if (isNil(timeslot)) {
    return '';
  }

  let ts;

  if (typeof timeslot === 'string') {
    ts = parseTimeslot(timeslot);
  } else {
    ts = { ...timeslot };
  }

  const { hours, minutes } = ts;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      notifyParentOfPreSelect: false
    };
  }

  render() {
    if (isNil(this.props.season) || isNil(this.props.selectedDate)) {
      return <FormattedMessage {...messages.selectDateFirst} />;
    }
    const day = this.props.season.schedule[date2day(this.props.selectedDate)];

    if (this.props.season.tag === 'allDay' || this.props.season.tag === 'privateTour') {
      return <AllDayPicker operationalHours={day} />;
    }

    return (
      <IntervalDatePicker
        timeslots={day.enabled ? day.timeslots : []}
        selectedTime={this.props.selectedTime}
        handleTimeslotSelect={this.props.handleTimeslotChange}
        bookedAmountPerDate={this.props.bookedAmountPerDate}
        season={this.props.season}
        selectedDate={this.props.selectedDate}
        shouldShowFreePlaces={this.props.shouldShowFreePlaces}
        participantsAmount={this.props.participantsAmount}
        timezoneName={this.props.timezoneName}
        shouldSelectFirstAvailableTime={this.props.shouldSelectFirstAvailableTime || false}
        shouldIgnoreCutoffTimes={this.props.shouldIgnoreCutoffTimes}
        canOverbook={this.props.canOverbook}
        isDisabled={this.props.isDisabled ?? false}
      />
    );
  }
}

function AllDayPicker({ operationalHours }) {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.chooseTimeWrapper}>
      <FormControl style={{ width: '100%', marginTop: '16px' }}>
        <InputLabel className={classes.coloredInput} id="opertaional-hours-label" color="primary">
          {intl.formatMessage(messages.operationalHours)}
        </InputLabel>
        <Input
          labelId="opertaional-hours-label"
          id="opertaional-hours"
          value={`${renderTimeslot(operationalHours.start)}-${renderTimeslot(
            operationalHours.end
          )}`}
          disabled
          variant="outlined"
        />
      </FormControl>
    </div>
  );
}

function IntervalDatePicker({
  timeslots,
  selectedTime,
  handleTimeslotSelect,
  bookedAmountPerDate,
  season,
  selectedDate,
  participantsAmount,
  timezoneName,
  shouldShowFreePlaces,
  shouldSelectFirstAvailableTime,
  shouldIgnoreCutoffTimes,
  canOverbook,
  isDisabled
}) {
  const classes = useStyles();
  const intl = useIntl();
  if (timeslots.length <= 1) {
    //return null;
  }

  if (!selectedTime && shouldSelectFirstAvailableTime) {
    selectedTime = timeslots.find(t => {
      return !isTimeslotDisabled({
        timeslot: t,
        season,
        bookedAmountPerDate,
        selectedDate,
        timezoneName,
        participantsAmount,
        shouldIgnoreCutoffTimes,
        canOverbook
      });
    });

    if (selectedTime) {
      handleTimeslotSelect(selectedTime);
    }
  }

  function getOptions(t, i, isMobile) {
    const isDisabled = isTimeslotDisabled({
      timeslot: t,
      season,
      bookedAmountPerDate,
      selectedDate,
      timezoneName,
      participantsAmount,
      shouldIgnoreCutoffTimes,
      canOverbook
    });
    const shouldRenderFreePlacesInfo = shouldShowFreePlaces === 'true';
    const freePlacesPerTimeslot = getFreePlacesPerTimeslot({
      timeslot: t,
      selectedDate,
      season,
      bookedAmountPerDate
    });

    if (isMobile) {
      return (
        <option disabled={isDisabled} key={i} value={renderTimeslot(t)}>
          {renderTimeslot(t)}
          {shouldRenderFreePlacesInfo &&
            ` - ${freePlacesPerTimeslot} ${intl.formatMessage(messages.placesLeft)}`}
        </option>
      );
    }

    return (
      <MenuItem disabled={isDisabled} key={i} value={renderTimeslot(t)}>
        {renderTimeslot(t)}
        {shouldRenderFreePlacesInfo && (
          <span className={classes.freePlacesAmount}>
            {`${freePlacesPerTimeslot} ${intl.formatMessage(messages.placesLeft)}`}
          </span>
        )}
      </MenuItem>
    );
  }

  return (
    <div className={classes.chooseTimeWrapper}>
      <div className={classes.nativeSelect}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            className={classes.coloredInput}
            color="primary"
            id="selectedTime-outlined-label">
            {intl.formatMessage(messages.startingTimes)}
          </InputLabel>
          <Select
            disabled={isDisabled}
            native={true}
            inputProps={{
              name: 'startingTimes',
              id: 'outlined-age-native-simple'
            }}
            labelId="selectedTime-outlined-label"
            id="selectedTime-select-outlined"
            value={renderTimeslot(selectedTime)}
            onChange={compose(
              handleTimeslotSelect,
              parseTimeslot,
              e => e.target.value
            )}
            label={intl.formatMessage(messages.startingTimes)}>
            {!selectedTime && <option aria-label="None" value="" />}
            {timeslots.map((t, i) => getOptions(t, i, true))}
          </Select>
        </FormControl>
      </div>

      <div className={classes.desktopSelect}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="startinTimeSelect-label" className={classes.coloredInput} color="primary">
            {intl.formatMessage(messages.startingTimes)}
          </InputLabel>
          <Select
            disabled={isDisabled}
            label={intl.formatMessage(messages.startingTimes)}
            labelId="startinTimeSelect-label"
            id="startinTimeSelect"
            value={renderTimeslot(selectedTime)}
            renderValue={renderTimeslot}
            onChange={compose(
              handleTimeslotSelect,
              parseTimeslot,
              e => e.target.value
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}>
            {timeslots.map((t, i) => getOptions(t, i, false))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
