import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.createTourModal';

export default defineMessages({
  tourName: {
    id: `${scope}.tourName`,
    defaultMessage: 'Tour Name'
  },
  longDesc: {
    id: `${scope}.longDesc`,
    defaultMessage: 'Long Description'
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Title'
  },
  createTour: {
    id: `${scope}.createTour`,
    defaultMessage: 'Create Tour'
  },
  updateTour: {
    id: `${scope}.updateTour`,
    defaultMessage: 'Update Tour'
  },
  inputLanguage: {
    id: `${scope}.input_language`,
    defaultMessage: 'Input Language'
  },
  inputLanguageDesc: {
    id: `${scope}.input_language_desc`,
    defaultMessage: 'Select which language this tour has been created in'
  },
  googleReviewURL: {
    id: `${scope}.google_review_url`,
    defaultMessage: 'Google Review URL'
  },
  tripAdvisorReviewURL: {
    id: `${scope}.tripadvisor_review_url`,
    defaultMessage: 'TripAdvisor Review URL'
  },
  yelpReviewURL: {
    id: `${scope}.yelp_review_url`,
    defaultMessage: 'Yelp Review URL'
  },
  manageGuides: {
    id: `${scope}.manage_guides`,
    defaultMessage: 'Manage Guides'
  }
});
