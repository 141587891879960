import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.ManageGuidesModal';

export default defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Search for and add people to this tour'
  },
  updateTour: {
    id: `${scope}.update_tour`,
    defaultMessage: 'Update Tour'
  }
});
