import { Button, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import React, { useState } from 'react';

const useStyles = makeStyles({
  manageButton: {
    marginRight: 5
  }
});

const ButtonWithMenu = ({ menuBody }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleOpenDropDown = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classes.manageButton}
        style={{ backgroundColor: '#fff' }}
        color="inherit"
        variant="contained"
        onClick={handleOpenDropDown}>
        <MoreHorizIcon />
      </Button>
      <Menu
        style={{ marginTop: '50px' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropDown}>
        {menuBody.map((el, i) => (
          <div key={i}>{el}</div>
        ))}
      </Menu>
    </>
  );
};

export default ButtonWithMenu;
