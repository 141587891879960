import React, { useEffect, useState } from 'react';
import { InteractiveMap, Marker } from 'react-map-gl';
import Pin from './Pin';
const mapboxToken =
  'pk.eyJ1IjoidHJpcGFkbWl0IiwiYSI6ImNrMWh0ZmE3NTFldGwzbHBkNnBzcDM3YWEifQ.JkipbnZyNBwttrOUxsfTmg';

const MapContainer = ({
  long,
  lat,
  userZoom,
  width = '100%',
  height = '400px',
  description = 'Meeting Point',
  handlePointSelection
}) => {
  const [viewport, setViewport] = useState({
    width,
    height,
    latitude: lat,
    longitude: long,
    zoom: lat && long ? 15 : 1
  });

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: userZoom ? viewport.latitude : lat,
      longitude: userZoom ? viewport.longitude : long,
      zoom: userZoom ? userZoom : lat && long ? 15 : 1
    });
  }, [lat, long]);

  const handleClick = ({ lngLat: [long, lat] }) => handlePointSelection(long, lat, viewport.zoom);

  return (
    <InteractiveMap
      {...viewport}
      preserveDrawingBuffer
      onViewportChange={viewport => setViewport(viewport)}
      mapboxApiAccessToken={mapboxToken}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onClick={handleClick}
    >
      {lat && (
        <Marker latitude={lat} longitude={long} offsetLeft={-9} offsetTop={-24}>
          <Pin title={description} />
        </Marker>
      )}
    </InteractiveMap>
  );
};

export default MapContainer;
