import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/tour-order';

export const fetchToursAggregate = ({ companyId, page, perPage, language }) => {
  return axios
    .get(`${url}?companyId=${companyId}&language=${language}&page=${page}&perPage=${perPage}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
