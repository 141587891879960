import { CircularProgress, Paper, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AlertTitle, Alert as MuiAlert } from '@material-ui/lab';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { setSnackbarStatus } from './actions';
import { snackbarStatus } from './constants';
import messages from './messages';
import { StatusBarReducer, initialState } from './reducer';

export { snackbarStatus } from './constants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    padding: theme.spacing(1),
    fontWeight: '500',
    color: theme.palette.text.secondary
  },
  alert: {
    fontSize: '16px',
    padding: theme.spacing(1),
    fontWeight: '500'
  }
}));

export function extractStatusBarError(error) {
  let errorString = `${error.message} `;
  if (typeof error === 'string') {
    errorString = error;
  } else if (typeof error.details == 'string') {
    errorString = error.details;
  } else if (typeof error.details === 'object' && error.details !== null) {
    for (let key in error.details) errorString += key + ': ' + error.details[key] + ', ';
  }

  return errorString;
}

export default function StatusBar({ type, title, message, onClose }) {
  const classes = useStyles();

  switch (type) {
    case snackbarStatus.waiting_changes:
      return (
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Paper className={classes.container}>
            <CircularProgress /> &nbsp; <FormattedMessage {...messages.statusBarWaiting} />
          </Paper>
        </Snackbar>
      );
    case snackbarStatus.saving:
      return (
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Paper className={classes.container}>
            <CircularProgress /> &nbsp; <FormattedMessage {...messages.statusBarSaving} />
          </Paper>
        </Snackbar>
      );
    case snackbarStatus.error:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          className={classes.alert}>
          <MuiAlert variant="filled" elevation={6} severity="error">
            <AlertTitle>{title}</AlertTitle>
            {message}
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.success:
      return (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.statusBarSaved} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.successfulSalesClosed:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.successfulSalesClosed} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.successfulSalesOpened:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.successfulSalesOpened} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.successfulAssignedTipCard:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.successfulAssignedTipCard} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.inviteDeleted:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.inviteDeleted} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.generatingReview:
      return (
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Paper className={classes.container}>
            <CircularProgress /> &nbsp; <FormattedMessage {...messages.generatingReview} />
          </Paper>
        </Snackbar>
      );
    case snackbarStatus.successfulGeneratedReview:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.successfulGeneratedReview} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.sendingEmail:
      return (
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Paper className={classes.container}>
            <CircularProgress /> &nbsp; <FormattedMessage {...messages.sendingEmail} />
          </Paper>
        </Snackbar>
      );
    case snackbarStatus.successfulEmailSent:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.successfulEmailSent} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.willSendEmailIfExists:
      return (
        <Snackbar
          open={true}
          onClose={onClose}
          className={classes.alert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <MuiAlert variant="filled" elevation={6} severity="success" className={classes.alert}>
            <FormattedMessage {...messages.willSendEmailIfExists} />
          </MuiAlert>
        </Snackbar>
      );
    case snackbarStatus.redirectToStripe:
      return (
        <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Paper className={classes.container}>
            <CircularProgress /> &nbsp; <FormattedMessage {...messages.redirectToStripe} />
          </Paper>
        </Snackbar>
      );
    case snackbarStatus.hidden:
      return null;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

const InjectedStatusBar = props => {
  useInjectReducer({ key: 'statusBar', reducer: StatusBarReducer });

  return <StatusBar {...props} />;
};

function mapStateToProps(state) {
  return state.statusBar ?? initialState;
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => dispatch(setSnackbarStatus(snackbarStatus.hidden, '', ''))
  };
}

export const ReduxConnectedStatusBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectedStatusBar);
