import { Analytics } from '@analytics';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import * as R from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as yup from 'yup';
import TopNav from '../../components/Nav/TopNav';
import { TextIconBoxType } from '../../components/Onboarding/constants';
import PaymentProviderCards from '../../components/Onboarding/PayoutProviderCards';
import Profile from '../../components/Onboarding/Profile';
import StepButtons from '../../components/Onboarding/StepButtons';
import StepInformation from '../../components/Onboarding/StepInformation';
import TextIconInformationBox from '../../components/Onboarding/TextIconInformationBox';
import TippingInformation from '../../components/Onboarding/TippingInformation';
import { SessionStorageKeys, StyleBreakpoints } from '../../utils/constants';
import useActivePaymentPlatform from '../../utils/hooks/payment_platform';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as actions from '../TapToTipPage/actions';
import reducer from '../TapToTipPage/reducer';
import saga from '../TapToTipPage/saga';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    padding: '0 16px 16px 16px',
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    maxHeight: '700px',
    justifySelf: 'start',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000'
  },
  description: {
    fontSize: '12px',
    color: '#a6a6a6'
  },
  skipButton: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#afaeae',
    width: '100%',
    maxWidth: '350px'
  },
  nextButton: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#fff',
    width: '100%',
    margin: '8px 0',
    maxWidth: '350px'
  },
  infoBlock: {
    marginBottom: '12px',
    alignSelf: 'start'
  },
  step: {
    fontSize: '10px',
    fontWeight: '600',
    color: '#000',
    margin: '8px 0'
  },
  bottomButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  },
  infoComponent: {
    marginBottom: '24px',
    width: '100%',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginBottom: '10px'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 93px)',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      height: 'calc(100% - 77px)'
    }
  },
  form: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: '100%',
    width: '100%'
  },
  supplierMessage: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    padding: '18px 24px 13px 23px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    marginBottom: '10px'
  },
  supplierText: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000'
  }
}));

const buttonText = {
  0: {
    next: 'Next',
    skip: 'Skip setup now'
  },
  1: {
    next: 'Next',
    skip: 'Skip'
  },
  2: {
    next: 'Complete Setup',
    skip: 'Skip'
  }
};

const StepNames = {
  PROFILE: 'Profile',
  TIPPING: 'Tipping Information',
  PAYOUT: 'Payout'
};

const findFalseFieldIndex = onboarding => {
  return Object.keys(onboarding).findIndex(key => !onboarding[key]);
};

const findNextFalseIndex = (onboarding, falseIndex) => {
  return Object.keys(onboarding).findIndex((key, index) => index > falseIndex && !onboarding[key]);
};

const initialProfile = {
  firstName: '',
  lastName: '',
  description: '',
  nickname: '',
  payment: '',
  defaultPaymentAmount: 10,
  country: 'ie',
  currency: 'eur',
  businessStatus: 'individual',
  taxNumber: '',
  onboarding: {
    profile: false,
    tipping: false,
    payments: false
  }
};

const profileSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  defaultPaymentAmount: yup.number().required(),
  description: yup.string(),
  currency: yup.string().required(),
  country: yup.string().required()
});

const Onboarding = ({
  history,
  user,
  profile,
  updateProfile,
  fetchProfile,
  isLoading,
  isUpdatingProfile,
  connectStripeResponse,
  connectStripe,
  setStatusBar,
  uploadUserProfileImage
}) => {
  useInjectReducer({ key: 'taptotip', reducer });
  useInjectSaga({ key: 'taptotip', saga });

  const formRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  if (profile && !profile.currency) {
    profile.currency = 'eur';
  }
  const mergedProfile = { ...initialProfile, ...profile };
  const activePaymentPlatform = useActivePaymentPlatform(user.userId, connectStripeResponse);
  useEffect(() => {
    Analytics.track('viewed onboarding', null, true);
  }, []); // <-- empty dependency array

  const handleGoToProfile = (skipSteps = false) => {
    sessionStorage.setItem(SessionStorageKeys.SkipAllSteps, skipSteps);
    history.push('/onboarding/complete');
  };

  const manuallySubmitOnboardingForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (!profile) {
      fetchProfile(user.userId);
    }

    if (user?.profile?.stripeConnect?.stripeClientId) {
      connectStripe();
    }
  }, []);

  useEffect(() => {
    // if there is a payment enabled but we haven't saved it in the onboarding
    if (activePaymentPlatform && profile && !profile.onboarding?.payments) {
      updateProfile({
        userId: user.userId,
        profile: {
          ...mergedProfile,
          onboarding: {
            ...mergedProfile.onboarding,
            payments: true
          }
        }
      });
    }
  }, [activePaymentPlatform]);

  useEffect(() => {
    if (profile) {
      // find false step after the profile was changed
      const onboardingStep = findFalseFieldIndex(mergedProfile.onboarding);

      if (onboardingStep < 0) {
        // if there are no false onboarding steps, go to profile
        handleGoToProfile();
      } else if (activeStep > onboardingStep) {
        // if the active step is higher we don't want to move back
        return;
      } else {
        setActiveStep(onboardingStep);
      }
    }
  }, [profile]);

  const classes = useStyles();
  const intl = useIntl();
  const { next, skip } = buttonText[activeStep];
  const steps = [
    {
      infoComponent: <TextIconInformationBox card={TextIconBoxType.Welcome} />,
      title: intl.formatMessage(messages.profileTitle),
      name: StepNames.PROFILE,
      fieldName: 'profile',
      description: intl.formatMessage(messages.profileDescription),
      body: (errors, touched, values) => (
        <Profile
          profile={profile}
          errors={errors}
          touched={touched}
          values={values}
          uploadUserProfileImage={uploadUserProfileImage}
        />
      )
    },
    {
      infoComponent: <TextIconInformationBox card={TextIconBoxType.AdditionalInfo} />,
      title: intl.formatMessage(messages.tippingInfoTitle),
      name: StepNames.TIPPING,
      fieldName: 'tipping',
      description: intl.formatMessage(messages.tippingInfoDescription),
      body: (errors, touched, values) => (
        <TippingInformation profile={profile} errors={errors} touched={touched} values={values} />
      )
    }
  ];

  /*

    {
      infoComponent: <TextIconInformationBox card={TextIconBoxType.NearlyThere} />,
      title: intl.formatMessage(messages.payoutTitle),
      name: StepNames.PAYOUT,
      fieldName: 'payments',
      description: intl.formatMessage(messages.payoutDescription),
      supplierMessage: intl.formatMessage(messages.onlySelectPaymentMethod),
      body: (errors, touched, values) => (
        <PaymentProviderCards
          userId={user.userId}
          manuallySubmitOnboardingForm={manuallySubmitOnboardingForm}
          skipOnboarding={handleSkip}
          setStatusBar={setStatusBar}
        />
      )
    }*/

  const currentStep = steps[activeStep];

  const handleNext = values => {
    console.log('in handle next');
    Analytics.track(`${currentStep.name} Next Button Selected`, { page: 'Onboarding' });

    updateProfile({
      userId: user.userId,
      profile: {
        ...values,
        onboarding: {
          ...mergedProfile.onboarding,
          [currentStep.fieldName]: true
        }
      }
    });

    if (activeStep === steps.length - 1) {
      handleGoToProfile();
    } else {
      setActiveStep(prev => prev + 1);
    }
  };

  const handleSkip = () => {
    Analytics.track(`${currentStep.name} Skipped`, { page: 'Onboarding' });

    if (activeStep === steps.length - 1) {
      handleGoToProfile(true);
      return;
    }

    const nextOnboardingStep = findNextFalseIndex(mergedProfile.onboarding, activeStep);

    if (nextOnboardingStep > activeStep) {
      setActiveStep(nextOnboardingStep);
    } else {
      handleGoToProfile(true);
    }
  };

  const validateSchema = schema => values =>
    schema
      .validate(values, {
        abortEarly: false,
        strict: false
      })
      .then(() => ({}))
      .catch(({ inner }) => {
        return inner.reduce(
          (errorInfo, { path, message }) => ({
            ...errorInfo,
            [path]: (errorInfo[path] || []).concat(message)
          }),
          {}
        );
      });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!currentStep) {
    handleGoToProfile(true);
  }

  return (
    <>
      <TopNav />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div>
            <div className={classes.infoComponent}>{currentStep.infoComponent}</div>
            {currentStep.supplierMessage && (
              <div className={classes.supplierMessage}>
                <div className={classes.supplierText}>{currentStep.supplierMessage}</div>
              </div>
            )}
            <StepInformation title={currentStep.title} description={currentStep.description} />
          </div>
          <Formik
            innerRef={formRef}
            initialValues={mergedProfile}
            validateOnChange={false}
            validate={validateSchema(profileSchema)}
            validateOnBlur={false}
            onSubmit={handleNext}>
            {({ errors, touched, values }) => (
              <Form className={classes.form}>
                <div>{currentStep.body(errors, touched, values)}</div>
                <StepButtons
                  next={next}
                  skip={skip}
                  isUpdatingProfile={isUpdatingProfile}
                  handleSkip={handleSkip}
                  stepsLength={steps.length}
                  activeStep={activeStep}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.taptotip?.profile,
  isLoading: state.taptotip?.isLoading,
  isUpdatingProfile: state.taptotip?.isUpdatingProfile,
  connectStripeResponse: state.taptotip?.connectStripeResponse,
  user: state.user?.user
});

const mapDispatchToProps = dispatch => ({
  fetchProfile: userId => dispatch(actions.fetchProfile(userId)),
  updateProfile: R.compose(
    dispatch,
    actions.updateProfile
  ),
  connectStripe: () => dispatch(actions.connectStripe()),
  setStatusBar: R.compose(
    dispatch,
    setSnackbarStatus
  ),
  uploadUserProfileImage: image => dispatch(actions.uploadUserProfileImage(image))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding);
