import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.StarRatings';

export default defineMessages({
  starRatings: {
    id: `${scope}.starRatings`,
    defaultMessage: 'Star Ratings'
  },
  totalStars: {
    id: `${scope}.totalStars`,
    defaultMessage: 'Total Stars'
  }
});
