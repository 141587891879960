import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Counter from '../Counter';
import { currencySign, formatMoney, moneyFromIntegerToDecimal } from '../../../utils/helpers';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  itemWrapper: {
    display: 'flex',
    marginBottom: '30px',
    [theme.breakpoints.down('500')]: {
      flexWrap: 'wrap'
    }
  },
  extrasImageBlock: {
    maxWidth: '110px',
    flex: '0 1 100%',
    alignSelf: 'flex-start'
  },
  extrasImage: {
    width: '100%'
  },
  extrasInfoBlock: {
    margin: '0 20px',
    flex: '1 1 auto',
    [theme.breakpoints.down('500')]: {
      minWidth: '100%',
      order: 3,
      margin: '10px 0 0 0'
    }
  },
  extrasTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#000',
    margin: '5px 0'
  },
  extrasDesc: {
    fontSize: '12px',
    color: '#979797',
    letterSpacing: '-0.03px',
    margin: '0'
  },
  extrasPriceBlock: {
    marginLeft: 'auto',
    flex: '0 1 auto'
  },
  extrasPrice: {
    display: 'block',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#000',
    float: 'right',
    margin: '8px 0',
    whiteSpace: 'nowrap'
  },
  extrasCounter: {
    float: 'right'
  },
  counterBlock: {
    textAlign: 'right'
  },
  mandatoryExtra: {
    color: '#6997FF',
    fontWeight: 'bold',
    margin: '5px 0',
    fontSize: '12px'
  }
}));

export default function ExtraItem({
  participantsAmount,
  isMandatory,
  currency,
  title,
  description,
  image,
  price,
  id,
  count,
  handleExtraCountChange
}) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.itemWrapper}>
      <Paper className={classes.extrasImageBlock}>
        <img className={classes.extrasImage} src={image} alt={title} title={title} />
      </Paper>

      <div className={classes.extrasInfoBlock}>
        <h3 className={classes.extrasTitle}>{title}</h3>
        <p className={classes.extrasDesc}>{description}</p>
        {isMandatory && (
          <p className={classes.mandatoryExtra}>{intl.formatMessage(messages.mandatoryExtra)}</p>
        )}
      </div>

      <div className={classes.extrasPriceBlock}>
        <span className={classes.extrasPrice}>
          {currencySign(currency) + formatMoney({ amount: new Number(price), currency: currency })}
        </span>
        <div className={classes.counterBlock}>
          <Counter
            className={classes.extrasCounter}
            id={id}
            count={count}
            isMandatory={isMandatory}
            participantsAmount={participantsAmount}
            handleExtraCountChange={handleExtraCountChange}
          />
        </div>
      </div>
    </div>
  );
}
