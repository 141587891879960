const prefix = 'app/containers/StatusBar';

export const SET_SNACKBAR_STATUS = `${prefix}/editor/SET_SNACKBAR_STATUS`;
export const snackbarStatus = {
  hidden: 'hidden',
  error: 'error',
  waiting_changes: 'waiting_changes',
  saving: 'saving',
  success: 'success',
  inviteDeleted: 'inviteDeleted',
  successfulSalesOpened: 'successfulSalesOpened',
  successfulSalesClosed: 'successfulSalesClosed',
  successfulAssignedTipCard: 'successfulAssignedTipCard',
  generatingReview: 'generatingReview',
  successfulGeneratedReview: 'successfulGeneratedReview',
  sendingEmail: 'sendingEmail',
  successfulEmailSent: 'successfulEmailSent',
  willSendEmailIfExists: 'willSendEmailIfExists',
  redirectToStripe: 'redirectToStripe'
};
