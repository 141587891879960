import { defineMessages } from 'react-intl';

const scope = `app.containers.ForgotPassword`;

export default defineMessages({
  forgotPasswordTitle: {
    id: `${scope}.forgotPasswordTitle`,
    defaultMessage: 'Forgot Password'
  },
  forgotPasswordInfo: {
    id: `${scope}.forgotPasswordInfo`,
    defaultMessage:
      'Enter the email address you used when you joined and we’ll send you instructions to reset your password.'
  },
  forgotPasswordInfo2: {
    id: `${scope}.forgotPasswordInfo2`,
    defaultMessage:
      'For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.'
  },
  sendInstructions: {
    id: `${scope}.sendInstructions`,
    defaultMessage: 'Send Reset Instructions'
  },
  resetPasswordInfo: {
    id: `${scope}.resetPasswordInfo`,
    defaultMessage:
      'If an account exists for this email you will receive reset instructions shortly, please check your email.'
  },
  emailAddressLabel: {
    id: `${scope}.emailAddressLabel`,
    defaultMessage: 'Email Address'
  }
});
