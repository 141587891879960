/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const CHANGE_SESSION_LOCALE = 'app/LanguageToggle/CHANGE_SESSION_LOCALE';
export const CHANGE_BOOKING_WIDGET_LOCALE = 'app/LanguageToggle/CHANGE_BOOKING_WIDGET_LOCALE';
export const CHANGE_TIPPING_LOCALE = 'app/LanguageToggle/CHANGE_TIPPING_LOCALE';
