import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  title: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#000'
  },
  description: {
    fontSize: '12px',
    color: '#a6a6a6'
  },
  infoBlock: {
    marginBottom: '12px',
    alignSelf: 'start'
  }
});

const StepInformation = ({ title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoBlock}>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

export default StepInformation;
