import {
  INIT_USER_LOADING,
  INIT_USER_SIGNIN_FAILED,
  INIT_USER_SIGNUP_FAILED,
  SET_CURRENT_USER,
  SET_LANDING_URL,
  SET_TARGET_LOCATION
} from './actions';

const initialState = {
  user: {},
  isInitialUserFetched: false,
  signinInProgress: false,
  siginError: null,
  landingUrl: null,
  signupError: null
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANDING_URL:
      return { ...state, landingUrl: action.payload };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
        signinInProgress: false,
        signinError: null,
        isInitialUserFetched: true
      };
    case INIT_USER_LOADING:
      return {
        ...state,
        user: {},
        signinInProgress: true,
        signinError: false
      };
    case INIT_USER_SIGNIN_FAILED:
      return {
        ...state,
        user: {},
        signinError: action.payload,
        signinInProgress: false,
        isInitialUserFetched: true
      };
    case INIT_USER_SIGNUP_FAILED:
      return {
        ...state,
        user: {},
        signupError: action.payload
      };
    case SET_TARGET_LOCATION:
      return {
        ...state,
        targetLocation: action.payload
      };

    default:
      return state;
  }
}

export default userReducer;
