export const FETCH_PROMOTIONS = 'FETCH_PROMOTIONS';
export const CREATE_PROMOTION = 'CREATE_PROMOTION';
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION';
export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const FETCH_TOURS = 'FETCH_TOURS';
export const UPDATE_MODAL_DATA = 'UPDATE_MODAL_DATA';
export const FETCH_PROMOTION_TOURS = 'FETCH_PROMOTION_TOURS';
export const DELETE_TOUR_FROM_PROMOTION = 'DELETE_TOUR_FROM_PROMOTION';
export const FETCH_PROMOTION_BY_ID = 'FETCH_PROMOTION_BY_ID';
export const ACTION_ERROR = 'ACTION_ERROR';

export const FETCH_PROMOTIONS_SUCCESS = 'FETCH_PROMOTIONS_SUCCESS';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';
export const FETCH_PROMOTION_TOURS_SUCCESS = 'FETCH_PROMOTION_TOURS_SUCCESS';
export const DELETE_TOUR_FROM_PROMOTION_SUCCESS = 'DELETE_TOUR_FROM_PROMOTION_SUCCESS';
export const FETCH_PROMOTION_BY_ID_SUCCESS = 'FETCH_PROMOTION_BY_ID_SUCCESS';
