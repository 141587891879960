import React from 'react';
import PinSVG from '../../../assets/images/Pin.svg';

const pinStyle = {
  fill: '#d00',
  stroke: 'none',
  cursor: 'pointer'
};

//export const Pin = ({ title }) =>;

export default function Pin(props) {
  return (
    <div>
      {/* Logo is an actual React component */}
      <img src={PinSVG} style={pinStyle} />
    </div>
  );
}
