import React, { useEffect, useState } from 'react';
import { fetchProfilesByCompanyId } from '../../api/taptotip/profile';
import { useHistory, useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Lock as LockIcon } from '@material-ui/icons';
import { Button, Container, Grid, Paper, CircularProgress, Avatar } from '@material-ui/core';
import PoweredByImageSrc from '../../assets/images/PoweredBy.svg';

let dummyData = [
  {
    userId: '63f610ccd039c130465d1bdc',
    firstName: 'Andy',
    lastName: 'Kelly',
    email: 'andykelly@outlook.com',
    avatarUrl:
      'http://res.cloudinary.com/dhjpkbuiq/image/upload/c_fill,h_300,w_300/jnln7wbatlino8zpkkic'
  },
  {
    userId: '63f610ccd039c130465d1bdc',
    firstName: 'Jago',
    lastName: 'Gibbons',
    email: 'jago@outlook.com',
    avatarUrl: 'http://res.cloudinary.com/dhjpkbuiq/image/upload/c_fill,h_300,w_300/c4_du3dhk'
  },
  {
    userId: '63f610ccd039c130465d1bdc',
    firstName: 'Johnny',
    lastName: 'Null',
    email: 'jago@outlook.com',
    avatarUrl: null
  },
  {
    userId: '63f610ccd039c130465d1bdc',
    firstName: 'Barthelemeuw',
    lastName: 'Guide',
    email: 'jago@outlook.com',
    avatarUrl: 'http://res.cloudinary.com/dhjpkbuiq/image/upload/c_fill,h_300,w_300/08_budlal'
  },
  {
    userId: '63f610ccd039c130465d1bdc',
    firstName: 'Steve',
    lastName: 'Guide',
    email: 'jago@outlook.com',
    avatarUrl: 'http://res.cloudinary.com/dhjpkbuiq/image/upload/c_fill,h_300,w_300/57_ez6fjt'
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14)
  },
  avatarPaper: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: theme.spacing(14),
    margin: 'auto'
  },
  outerPaper: {
    padding: theme.spacing(2),
    backgroundColor: '#f9f9f9',
    textAlign: 'center'
  },
  innerPaper: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    textDecoration: 'none'
  },
  heading: {
    color: '#000000',
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '42px'
  },
  signOff: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: '400',
    margin: '42px',
    textAlign: 'center'
  },
  trustBuilder: {
    height: '44px',
    width: '166px',
    borderRadius: '9px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#323232',
    padding: '10px',
    margin: 'auto',
    marginBottom: '22px'
  },
  poweredByImageContainer: {
    width: '160px',
    margin: 'auto',
    marginTop: '22px'
  },
  poweredByImage: {
    width: '100%',
    margin: 'auto'
  }
}));

export default function GuidesList() {
  const { companyId } = useParams();
  const [guideData, setGuideData] = useState();

  const classes = useStyles();

  useEffect(() => {
    const fetchGuideData = async () => {
      const data = await fetchProfilesByCompanyId(companyId);

      setGuideData(
        data.sort(function(a, b) {
          if (a.avatarUrl === null) {
            return 1;
          }
          if (b.avatarUrl === null) {
            return -1;
          }
          return 0;
        })
      );
    };

    if (!guideData) {
      fetchGuideData().catch(console.error);
    }
  });
  function getMdGridColumns(guidesLength) {
    if (guidesLength >= 6) return 2;
    else if (guidesLength == 5) return 3;
    else if (guidesLength == 4) return 3;
    else if (guidesLength == 3) return 4;
    else if (guidesLength == 2) return 6;
    else return 12;
  }

  function renderGuide(guide, guidesLength) {
    let md = getMdGridColumns(guidesLength);
    return (
      <Grid item md={md} sm={6} xs={12}>
        <Link to={'/t/g/' + guide.userId} style={{ textDecoration: 'none' }}>
          <Paper elevation={0} className={classes.outerPaper}>
            <Paper elevation={3} className={classes.avatarPaper}>
              <Avatar
                alt={guide.firstName + ' ' + guide.lastName}
                src={guide.avatarUrl}
                className={classes.large}
              />
            </Paper>

            <Button variant="contained" color="secondary" className={classes.button}>
              Tip {guide.firstName || ''}
            </Button>
          </Paper>
        </Link>
      </Grid>
    );
  }

  if (!guideData) {
    return (
      <center>
        <CircularProgress />
      </center>
    );
  }

  return (
    <>
      <Container maxWidth="lg">
        <h2 className={classes.heading}>All Tips Are Greatly Appreciated!</h2>
        <Paper className={classes.trustBuilder}>
          <LockIcon /> safe & secure
        </Paper>
        <Grid container spacing={2}>
          {guideData.map(g => renderGuide(g, guideData.length))}
        </Grid>

        <h2 className={classes.signOff}>We hope you've enjoyed your experience!</h2>

        <div className={classes.poweredByImageContainer}>
          <img src={PoweredByImageSrc} className={classes.poweredByImage} />
        </div>
      </Container>
    </>
  );
}
