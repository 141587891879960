export const bwButtonTrans = {
  en: 'Book Now',
  de: 'Buchen Sie Jetzt',
  es: 'Reservar Ahora',
  fr: 'Reserve Maintenant',
  nl: 'Boek Nu',
  pl: 'Rezerwuj Teraz',
  pt: 'Reservar',
  sv: 'Boka Nu',
  ja: '今予約する',
  da: 'Book Nu',
  it: 'Prenota Ora',
  no: 'Bestill nå',
  fi: 'Varaa nyt',
  ru: 'Забронируйте сейчас'
};

export const fromOnlyTrans = {
  en: 'From Only',
  fr: 'Juste De',
  es: 'Desde',
  de: 'Von Nur',
  no: 'Fra Only',
  pl: 'Tylko Od',
  ru: 'Всего От',
  pt: 'De Apenas',
  nl: 'Alleen Van',
  sv: 'Från Only',
  ja: 'からのみ',
  da: 'Fra Only',
  it: 'Solamente',
  fi: 'Vain',
  ca: 'De Només'
};

export const perTrans = {
  en: 'per ',
  fr: 'par ',
  es: 'por ',
  de: 'pro ',
  no: 'per ',
  pl: 'na ',
  ru: 'на ',
  pt: 'por ',
  nl: 'per ',
  sv: 'per ',
  ja: '対して ',
  da: 'per ',
  it: 'A ',
  fi: 'per ',
  ca: 'per '
};

export const perPersonTrans = {
  en: 'per person',
  fr: 'par personne',
  es: 'por persona',
  de: 'pro person',
  no: 'per person',
  pl: 'na osobę',
  ru: 'на человека',
  pt: 'por pessoa',
  nl: 'per persoon',
  sv: 'per person',
  ja: '一人当たり',
  da: 'per person',
  it: 'a persona',
  fi: 'per henkilö',
  ca: 'per persona'
};
