import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccountCircle as AccountIcon } from '@material-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../messages';

const useStyles = makeStyles({
  profileImage: {
    width: '47px',
    height: '47px',
    marginTop: '4px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  guideInfoBlock: ({ isSelectedUser }) => ({
    display: 'grid',
    margin: '20px 0',
    gridTemplateColumns: '20% 80%',
    backgroundColor: isSelectedUser && '#f0f0f0'
  }),
  activeCards: ({ isActive }) => ({
    padding: '4px 12px',
    width: 'fit-content',
    borderRadius: '4px',
    backgroundColor: isActive ? '#d9ffd7' : '#dbdbdb',
    color: isActive ? '#12573e' : '#6a6b6b',
    fontWeight: 'bold',
    fontSize: '11px'
  }),

  profileName: {
    fontSize: '14px',
    color: '#000',
    fontWeight: 600
  },
  profileInfo: {
    fontSize: '12px',
    color: '#000',
    fontWeight: 500,

    '& > *': {
      margin: '4px 0'
    }
  }
});

const UserRole = {
  system_admin: 'Admin',
  tap_to_tip_agent: 'Guide'
};

const ProfileOverview = ({ user, selectedUser, handleSelectUser }) => {
  const { userId, firstName, lastName, email, avatarUrl, assignedCardsAmount, role } = user;
  const classes = useStyles({
    isSelectedUser: selectedUser && selectedUser === userId,
    isActive: assignedCardsAmount
  });
  const intl = useIntl();

  return (
    <Grid className={classes.guideInfoBlock} onClick={() => handleSelectUser(userId)}>
      <Grid className={classes.imageContainer}>
        {avatarUrl ? (
          <img src={avatarUrl} className={classes.profileImage} alt="Profile Avatar" />
        ) : (
          <AccountIcon className={classes.profileImage} />
        )}
      </Grid>
      <Grid className={classes.profileInfo}>
        <div className={classes.profileName}>
          {firstName || ''} {lastName || ''}
        </div>
        <div>{email}</div>
        <div>
          {intl.formatMessage(messages.type)}: {UserRole[role]}
        </div>
        <div className={classes.activeCards}>
          {intl.formatMessage(messages.activeCards)}: {assignedCardsAmount}
        </div>
      </Grid>
    </Grid>
  );
};

export default ProfileOverview;
