/*
 * AdditionalExtrasPurchase Item Messages
 *
 * This contains all the text for the AdditionalExtrasPurchase Item component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AdditionalExtrasPurchaseItem';

export default defineMessages({
  mandatoryExtra: {
    id: `${scope}.mandatory_extra`,
    defaultMessage: 'Mandatory Extra'
  }
});
