import React from 'react';
import { useIntl } from 'react-intl';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icons from '../Common/Icons/icons';
import ButtonWithMenu from '../../Common/ButtonWithMenu';
import messages from './messages';

const useStyles = makeStyles({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  channelList: {
    listStyle: 'none',
    margin: '0',
    padding: '0'
  },
  channelItem: {
    display: 'inline-block',
    width: '36px',
    height: 'auto',
    marginRight: '25px'
  },
  channelItemPending: {
    display: 'inline-block',
    width: '36px',
    height: '36px',
    marginRight: '25px',
    '&:after': {
      content: '""'
    }
  },
  channelIcon: {
    width: '100%'
  },
  spinner: {
    width: '36px !important',
    height: '36px !important',
    position: 'absolute'
  },
  checkbox: {
    marginLeft: '10px'
  }
});

const ChannelsSummary = ({
  channelsData,
  tourId,
  handleStatusChange,
  integratedTour,
  editIntegratedTour,
  deleteIntegratedTour,
  isSelected = true
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const shouldRenderMenuBtn = channelsData.length && editIntegratedTour && deleteIntegratedTour;
  const shouldRenderCheckboxes = handleStatusChange;

  const getImageByStatus = ({ integrationStatus, channelType }) => {
    if (integrationStatus == 'active') {
      return Icons[channelType];
    } else {
      return Icons[channelType + 'Grey'];
    }
  };

  const getMenuBody = () => [
    <MenuItem onClick={() => editIntegratedTour(integratedTour)}>
      {intl.formatMessage(messages.edit)}
    </MenuItem>,
    <MenuItem onClick={() => deleteIntegratedTour(integratedTour)}>
      {intl.formatMessage(messages.delete)}
    </MenuItem>
  ];

  return (
    <ul className={classes.channelList}>
      {channelsData.map(channel => (
        <li key={channel.channelId} className={classes.channelItem}>
          {channel.integrationStatus == 'pending_update' && (
            <CircularProgress
              classes={{
                root: classes.spinner
              }}
              thickness={3}
              // disableShrink
            />
          )}
          <img className={classes.channelIcon} src={getImageByStatus(channel)} />
          {shouldRenderCheckboxes && (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!isSelected}
                  inputProps={{
                    id: channel.channelId
                  }}
                  classes={{
                    root: classes.checkbox
                  }}
                  checked={channel.integrationStatus != 'not_active'}
                  onChange={e => handleStatusChange(tourId, channel.channelId, e.target.checked)}
                  name={channel.channelType}
                  color="primary"
                  size="small"
                />
              }
            />
          )}
        </li>
      ))}
      {shouldRenderMenuBtn && (
        <li className={classes.channelItem}>
          <ButtonWithMenu menuBody={getMenuBody()} />
        </li>
      )}
    </ul>
  );
};

export default ChannelsSummary;
