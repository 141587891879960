export const StatusType = {
  incomplete: 'Incomplete',
  active: 'Active'
};

export const StatusBackgroundColor = {
  incomplete: '#555555',
  active: '#d9ffd7'
};

export const StatusForeColor = {
  incomplete: '#eaeaea',
  active: '#12573e'
};

export const ReviewSite = {
  TripAdvisor: 'TripAdvisor',
  Google: 'Google',
  Yelp: 'Yelp',
  Email: 'Email'
};
