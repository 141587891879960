import { defineMessages } from 'react-intl';

export const scope = 'app.components.Promotions.PromotionsTable';

export default defineMessages({
  tableTitle: {
    id: `${scope}.table_title`,
    defaultMessage: 'Promotions'
  },

  promotionTitle: {
    id: `${scope}.promotionTitle`,
    defaultMessage: 'Promotion Title'
  },
  promoCode: {
    id: `${scope}.promoCode`,
    defaultMessage: 'Promo Code'
  },
  starts: {
    id: `${scope}.starts`,
    defaultMessage: 'Promotion Starts'
  },
  ends: {
    id: `${scope}.ends`,
    defaultMessage: 'Promotion Ends'
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Price'
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Discount'
  },
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  promotionPrice: {
    id: `${scope}.promotion_price`,
    defaultMessage: 'Promotion Price'
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage'
  },
  lastUpdated: {
    id: `${scope}.last_updated`,
    defaultMessage: 'Last updated'
  },
  createdAt: {
    id: `${scope}.created_at`,
    defaultMessage: 'Created'
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  statusDraft: {
    id: `${scope}.status_draft`,
    defaultMessage: 'Draft'
  },
  statusPublished: {
    id: `${scope}.status_published`,
    defaultMessage: 'Published'
  },
  labelRowsSelect: {
    id: `${scope}.labelRowsSelect`,
    defaultMessage: 'rows'
  },
  labelRowsPerPage: {
    id: `${scope}.labelRowsPerPage`,
    defaultMessage: 'Rows per page'
  },
  firstAriaLabel: {
    id: `${scope}.firstAriaLabel`,
    defaultMessage: 'First Page'
  },
  firstTooltip: {
    id: `${scope}.firstTooltip`,
    defaultMessage: 'First Page'
  },
  previousAriaLabel: {
    id: `${scope}.previousAriaLabel`,
    defaultMessage: 'Previous Page'
  },
  previousTooltip: {
    id: `${scope}.previousTooltip`,
    defaultMessage: 'Previous Page'
  },
  nextAriaLabel: {
    id: `${scope}.nextAriaLabel`,
    defaultMessage: 'Next Page'
  },
  nextTooltip: {
    id: `${scope}.nextTooltip`,
    defaultMessage: 'Next Page'
  },
  lastAriaLabel: {
    id: `${scope}.lastAriaLabel`,
    defaultMessage: 'Last Page'
  },
  lastTooltip: {
    id: `${scope}.lastTooltip`,
    defaultMessage: 'Last Page'
  },
  searchTooltip: {
    id: `${scope}.searchTooltip`,
    defaultMessage: 'Search'
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search'
  },
  noPromotionsRunning: {
    id: `${scope}.noPromotionsRunning`,
    defaultMessage: 'No Promotions Running'
  },
  addNewPromotion: {
    id: `${scope}.addNewPromotion`,
    defaultMessage: 'Add New Promotion'
  }
});
