import { Grid, Paper } from '@material-ui/core';
import { format } from 'date-fns';
import { compose } from 'ramda';
import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Map from '../../components/Tours/Map';
import { langCodeToName, strToAbsoluteDate } from '../../utils/helpers';
import { changeSessionLocale } from '../LanguageProvider/actions';
import messages from './messages';

//TODO: REEEEEEEEEEEEEEEEEEEEEEEE
var Cal = require('../../assets/images/cal.svg');
var WheelChairIcon = require('../../assets/images/disabled.svg');
var Duration = require('../../assets/images/duration.svg');
var FreeCancellation = require('../../assets/images/FreeCancellation.svg');
var Queue = require('../../assets/images/queue.svg');
var TourGuide = require('../../assets/images/TourGuide.svg');
var Pin = require('../../assets/images/pinalt.svg');

var { QRCodeSVG } = require('qrcode.react');

function renderTimeslot(timeslot) {
  const { hours, minutes } = timeslot;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

var textStyle = {
  marginRight: '14px',
  marginBottom: '14px',
  width: '100%',
  minWidth: '15ch',
  boxShadow: 'rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
  borderRadius: '4px',
  backgroundColor: 'white'
};

function stringAsList(str) {
  if (!str) {
    return null;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {str.split('\n').map((x, i) => (
        <span key={i}>{x}</span>
      ))}
    </div>
  );
}
class TicketPage extends React.Component {
  //Get tour ID from query params
  //Pass along price and details to stripe checkout

  constructor(props) {
    super(props);
    this.state = {
      season: null,
      enablePayButton: false
    };
  }

  componentDidMount() {
    fetch('/api/v1/booking/' + this.props.match.params.ticketId)
      .then(response => response.json())
      .then(data => {
        this.props.changeLanguage(data.data.language);
        this.setState({
          manifest: data.data,
          isLoaded: true
        });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  printDuration(duration) {
    const res = [];
    if (duration.days > 0) {
      res.push(
        `${duration.days} ${this.props.intl.formatMessage(
          duration.days > 1 ? messages.dayPlural : messages.daySingular
        )}`
      );
    }
    if (duration.hours > 0) {
      res.push(
        `${duration.hours} ${this.props.intl.formatMessage(
          duration.hours > 1 ? messages.hourPlural : messages.hourSingular
        )}`
      );
    }
    if (duration.minutes > 0) {
      res.push(
        `${duration.minutes} ${this.props.intl.formatMessage(
          duration.minutes > 1 ? messages.minutePlural : messages.minuteSingular
        )}`
      );
    }
    return res.join(', ');
  }

  printStartingTime(t) {
    if (!t) {
      return null;
    }
    var lhs = '';
    var rhs = '';
    if (t.hours.toString().length == 2) {
      lhs = t.hours;
    } else {
      lhs = '0' + t.hours;
    }
    if (t.minutes.toString().length == 2) {
      rhs = t.minutes;
    } else {
      rhs = '0' + t.minutes;
    }

    return lhs + ':' + rhs;
  }

  renderTourFeature(childrenText, title, icon, itemsPosition, time) {
    return (
      <div
        style={{
          display: 'flex',
          height: 'auto',
          alignItems: itemsPosition ?? 'center',
          margin: '20px 0 20px 0'
        }}>
        <img src={icon} style={{ width: '40px', height: '30px', marginRight: '10px' }} />
        <div style={{ flex: 3, fontWeight: '500', display: 'flex', flexDirection: 'column' }}>
          {title.length > 1 ? title + ' - ' : ''}
          {childrenText}
          {time}
        </div>
      </div>
    );
  }

  renderTourItem(childrenText, title) {
    return (
      <div style={{ display: 'flex', height: '60px' }}>
        <div style={{ flex: 3, fontWeight: '500', display: 'flex', alignItems: 'center' }}>
          <div>
            <div style={{ fontSize: '12px' }}>{title}</div>
            <div style={{ fontWeight: 'bold' }}>{childrenText}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    var tour = this.state.manifest.tourDetails;
    var t = this.state.manifest.bookingDate.date.timeslot;
    var date = strToAbsoluteDate(this.state.manifest.bookingDate.date.date);
    var printableDate = format(date, 'dd-MM-yyyy');
    var altPrintableDate = format(date, 'MMMM do, yyyy');
    var printableTime = this.printStartingTime(t);
    const travelerDetails = this.state.manifest?.bookingDetails?.travelerDetails;

    const extras = [
      ...this.state.manifest.priceBreakdown.elements.filter(x => x.type === 'additional_extra')
    ];
    const participants = [
      ...this.state.manifest.priceBreakdown.elements.filter(x => x.type === 'participant')
    ];

    const participantsElement = (
      <>
        <h3 style={{ marginBottom: 4, marginTop: 0 }}>
          {this.props.intl.formatMessage(messages.participants)}
        </h3>
        {participants.map(p => {
          return (
            <Fragment key={p.participantId}>
              <span>
                {p.title} x {p.count}
              </span>
            </Fragment>
          );
        })}
        {extras.length ? (
          <h3 style={{ marginBottom: 4 }}>
            {this.props.intl.formatMessage(messages.additionalExtras)}
          </h3>
        ) : null}
        {extras.map(p => {
          return (
            <Fragment key={p.id}>
              <span>
                {p.title} x {p.count}
              </span>
            </Fragment>
          );
        })}
      </>
    );

    return (
      <div style={{ margin: '40px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 3 }}>
            <h1>{tour.title}</h1>
            <p>{tour.description}</p>
            {this.state.manifest?.bookingDetails?.paymentDetails?.tag === 'onArrival' && (
              <b style={{ color: 'red' }}>
                {this.props.intl.formatMessage(messages.paymentOnArrival)}
              </b>
            )}
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {this.renderTourFeature(
                  printableDate,
                  this.props.intl.formatMessage(messages.date),
                  Cal,
                  null,
                  this.renderHours(this.state.manifest.bookingDate, printableTime)
                )}
              </Grid>
              <Grid item xs={4}>
                {this.renderTourFeature(
                  this.printDuration(this.state.manifest.tourDetails.duration),
                  this.props.intl.formatMessage(messages.duration),
                  Duration
                )}
              </Grid>
              <Grid item xs={4}>
                {this.renderTourFeature(
                  this.props.intl.formatMessage(
                    tour.services.wheelchairAccessible
                      ? messages.accessabilityYes
                      : messages.accessabilityNo
                  ),
                  this.props.intl.formatMessage(messages.accessability),
                  WheelChairIcon
                )}
              </Grid>
              <Grid item xs={4}>
                {this.renderTourFeature(participantsElement, '', Queue, 'flex-start')}
              </Grid>
              <Grid item xs={4}>
                {this.renderTourFeature(
                  this.state.manifest.tourDetails.availableLanguages
                    .map(code => langCodeToName(code))
                    .join(', '),
                  this.props.intl.formatMessage(messages.language),
                  TourGuide
                )}
              </Grid>
              <Grid item xs={4}>
                {this.renderTourFeature(
                  this.state.manifest.tourDetails.cancelationPolicy,
                  this.props.intl.formatMessage(messages.cancellationPolicy),
                  FreeCancellation
                )}
              </Grid>

              {(travelerDetails.firstName || travelerDetails.lastName) && (
                <Grid item xs={4}>
                  {this.renderTourItem(
                    (travelerDetails.firstName || '') + ' ' + (travelerDetails.lastName || ''),
                    this.props.intl.formatMessage(messages.bookedBy)
                  )}
                </Grid>
              )}

              <Grid item xs={6}>
                {this.renderTourItem(
                  this.state.manifest.bookingDetails.id.toUpperCase(),
                  this.props.intl.formatMessage(messages.bookingreference)
                )}
              </Grid>
            </Grid>
          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>
            <QRCodeSVG
              value={
                'https://thrive.tripadmit.com/checkin/' + this.state.manifest.bookingDetails.id
              }
            />
          </div>
        </div>
        {tour.meetingPoint.lat != 0 && tour.meetingPoint.long != 0 ? (
          <Paper
            elevation={5}
            style={{
              width: '190px',
              height: '130px',
              position: 'relative',
              top: '74px',
              left: '18px',
              zIndex: 1,
              padding: '18px',
              overflow: 'hidden',
              fontWeight: 'bold',
              display: tour.meetingPoint.meetingPointDescription.length > 70 ? 'none' : 'block'
            }}>
            {this.props.intl.formatMessage(messages.meetingPoint)}
            <div style={{ display: 'flex', marginTop: '12px' }}>
              <div style={{ flex: 1 }}>
                <img src={Pin} style={{ height: '22px' }} />
              </div>
              <div style={{ flex: 4, fontWeight: '500', fontSize: '11px' }}>
                {tour.meetingPoint.meetingPointDescription}
              </div>
            </div>
          </Paper>
        ) : null}
        {tour.meetingPoint.lat != 0 && tour.meetingPoint.long != 0 ? (
          <Paper elevation={5} style={{ overflow: 'hidden' }}>
            {tour.meetingPoint.meetingPointDescription}
          </Paper>
        ) : null}

        {tour.meetingPoint.lat != 0 && tour.meetingPoint.long != 0 ? (
          <Paper elevation={5} style={{ overflow: 'hidden' }}>
            <Map lat={tour.meetingPoint.lat || 0} long={tour.meetingPoint.long || 0} />
          </Paper>
        ) : null}

        <Grid container style={{ marginTop: '40px' }}>
          <Grid item xs={12}>
            <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px' }}>
              {this.props.intl.formatMessage(messages.meetingPoint)}
            </div>
            {tour.meetingPoint.meetingPointDescription}
          </Grid>
        </Grid>
        <br />
        <div style={{ height: '48px' }} />
        {this.state.manifest.bookingDetails.customQuestionsAnswers?.length > 0 && (
          <Grid>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                marginTop: '54px',
                marginBottom: '8px'
              }}>
              {this.props.intl.formatMessage(messages.supplierRequestedInfromation)}
            </div>
            {this.state.manifest.bookingDetails.customQuestionsAnswers
              .map(x => {
                return {
                  ...x,
                  title: this.state.manifest.tourDetails.customQuestions.find(
                    q => q.id === x.questionId
                  )?.title
                };
              })
              .filter(x => x.title !== null)
              .map(question => (
                <div key={question.questionId}>
                  <div style={{ color: '#666', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>
                    Q: {question.title}
                  </div>
                  <div>A: {question.answer}</div>
                </div>
              ))}
          </Grid>
        )}
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '22px',
            marginTop: '44px',
            marginBottom: '8px'
          }}>
          {tour.highlights || tour.inclusions || tour.exclusions || tour.importantInformation ? (
            <>{this.props.intl.formatMessage(messages.experienceDetails)}</>
          ) : null}
        </div>
        <Grid container style={{ marginTop: '40px' }} spacing={2}>
          {tour.highlights ? (
            <Grid item xs={4}>
              <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px' }}>
                {this.props.intl.formatMessage(messages.highlights)}
              </div>
              {stringAsList(tour.highlights)}
            </Grid>
          ) : null}
          {tour.inclusions ? (
            <Grid item xs={4}>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  marginBottom: '8px'
                }}>
                {this.props.intl.formatMessage(messages.includes)}
              </div>
              {stringAsList(tour.inclusions)}
            </Grid>
          ) : null}

          {tour.exclusions ? (
            <Grid item xs={4}>
              <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px' }}>
                {this.props.intl.formatMessage(messages.excludes)}
              </div>
              {stringAsList(tour.exclusions)}
            </Grid>
          ) : null}
        </Grid>
        <Grid container style={{ marginTop: '40px' }}>
          {tour.importantInformation ? (
            <Grid item xs={12}>
              <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px' }}>
                {this.props.intl.formatMessage(messages.importantInformation)}
              </div>
              {stringAsList(tour.importantInformation)}
            </Grid>
          ) : null}
        </Grid>
        <Grid container style={{ marginTop: '40px' }}>
          <Grid item xs={12}>
            <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '8px' }}>
              {this.props.intl.formatMessage(messages.needHelp)}
            </div>
            {this.props.intl.formatMessage(messages.helpInfo)}
            <br />
            {this.props.intl.formatMessage(messages.activityProviderName)}:{' '}
            <b>{this.state.manifest.supplier.supplierName}</b>
            <br />
            <br />
            <b>{this.props.intl.formatMessage(messages.activityProviderPhone)}:</b>
            {this.state.manifest.supplier.callCentreNumber} <br />
            <b>{this.props.intl.formatMessage(messages.activityProviderEmail)}:</b>
            {this.state.manifest.supplier.callCentreEmail}
          </Grid>
        </Grid>
      </div>
    );
  }

  renderHours(date, startingTime) {
    if (!date.operationalHours) {
      return (
        <span>
          <span>{startingTime}</span>
        </span>
      );
    }

    const range = date.operationalHours;

    return (
      <span>
        <span>
          {renderTimeslot(range.start)}-{renderTimeslot(range.end)}
        </span>
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: compose(
      dispatch,
      changeSessionLocale
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TicketPage));
