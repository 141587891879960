import { defineMessages } from 'react-intl';

export const scope = 'app.components.Promotions.ToursPromotionTable';

export default defineMessages({
  tableTitle: {
    id: `${scope}.table_title`,
    defaultMessage: 'Tours in promotion'
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'From'
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Discount'
  },
  promotionPrices: {
    id: `${scope}.promotion_prices`,
    defaultMessage: 'Promotion Prices'
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage'
  },
  noToursInPromotion: {
    id: `${scope}.n_tours_in_promotion`,
    defaultMessage: 'There are no tours in this promotion'
  }
});
