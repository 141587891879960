import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.ReviewsTable';

export default defineMessages({
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  tour: {
    id: `${scope}.tour`,
    defaultMessage: 'Tour'
  },
  averageRating: {
    id: `${scope}.averageRating`,
    defaultMessage: 'Average Rating'
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total'
  },
  dateRange: {
    id: `${scope}.dateRange`,
    defaultMessage: 'Date Range:'
  },
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews'
  },
  allTours: {
    id: `${scope}.allTours`,
    defaultMessage: 'All Tours'
  },
  allProfiles: {
    id: `${scope}.allProfiles`,
    defaultMessage: 'All Profiles'
  }
});
