import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/transactions';

export const fetchTransactions = ({ userId = null }) => {
  const params = new URLSearchParams({
    ...(userId && { userId })
  });

  return axios
    .get(`${url}?${params}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const initTipPayment = ({ amount, userId, currency, chargedFeesOutside }) => {
  return axios
    .post(`${url}/create-payment-intent`, { amount, userId, currency, chargedFeesOutside })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
