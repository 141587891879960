import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    tourTitle: {
        fontSize: '22px',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
    },
    description: {
        fontSize: '16px',
        fontFamily: theme.typography.fontFamily,
    }
}));

export default function ShortTourSummary({ title, shortDescription }) {

    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.tourTitle}>
                {title}
            </div>
            <div className={classes.description}>
                {shortDescription}
            </div>
        </Fragment>
    );
}