import { Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useLayoutEffect, useState } from 'react';
import LangPickerImage from '../../assets/images/booking_widget/LangPickerImage.png';
import SmallTriangleSvg from '../../assets/images/booking_widget/Small_Triangle.svg';
import Catalan from '../../assets/images/countryFlags/Catalan.svg';
import Danish from '../../assets/images/countryFlags/Danish.svg';
import Dutch from '../../assets/images/countryFlags/Dutch.svg';
import English from '../../assets/images/countryFlags/English.svg';
import Finnish from '../../assets/images/countryFlags/Finnish.svg';
import French from '../../assets/images/countryFlags/French.svg';
import German from '../../assets/images/countryFlags/German.svg';
import Italian from '../../assets/images/countryFlags/Italian.svg';
import Japanese from '../../assets/images/countryFlags/Japanese.svg';
import Norwegian from '../../assets/images/countryFlags/Norwegian.svg';
import Polish from '../../assets/images/countryFlags/Polish.svg';
import Portugeue from '../../assets/images/countryFlags/Portugal.svg';
import Russian from '../../assets/images/countryFlags/Russian.svg';
import Spanish from '../../assets/images/countryFlags/Spanish.svg';
import Swedish from '../../assets/images/countryFlags/Swedish.svg';
import { langCodeToName, supportedLanguages } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1),
    color: 'black'
  },
  button: {
    background: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '5px 0',
    '&:hover': {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    },
    '&:active': {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    }
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '20px 10px 4px 18px'
  },
  chip: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#000',
    cursor: 'pointer',
    marginBottom: `${theme.spacing(1)}px`,
    minWidth: '90px',
    flex: 1,
    textAlign: 'center'
  },
  chipActive: props => ({
    fontWeight: 'bold',
    fontSize: '15px',
    padding: '3px 19px 5px 25px',
    borderRadius: '3.8px',
    backgroundColor: `${props.color}`,
    color: '#ffffff',
    cursor: 'pointer',
    marginBottom: `${theme.spacing(1)}px`
  }),
  icon: props => ({
    width: `${props.fontSize == 'medium' ? '24px' : '16px'}`,
    height: `${props.fontSize == 'medium' ? '24px' : '16px'}`,
    marginRight: '14px',
    marginTop: '-2px'
  }),
  language: props => ({
    fontSize: `${props.fontSize == 'medium' ? ' 19px' : '13px'}`,
    fontWeight: 'bold',
    marginRight: '15px',
    fontFamily: 'Roboto, Montserrat',
    lineHeight: '24px',
    textAlign: 'left'
  }),
  triangle: props => ({
    fontSize: `${props.fontSize == 'medium' ? ' 16px' : '13px'}`,
    position: 'relative',
    top: '-1px'
  }),
  tooltipArrow: {
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '100%',
      left: '5px',
      zIndex: 1,
      backgroundImage: `url(${SmallTriangleSvg})`,
      backgroundSize: '10px 10px'
    }
  },
  flagIcon: {
    height: '32px',
    width: '32px'
  },
  popover: props => ({
    maxWidth: `${props.windowWidth < 600 ? 'calc(100% - 32px)' : '566px'}`,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
  })
}));

const icons = {
  ca: Catalan,
  da: Danish,
  en: English,
  nl: Dutch,
  fi: Finnish,
  de: German,
  ja: Japanese,
  no: Norwegian,
  pl: Polish,
  pt: Portugeue,
  fr: French,
  it: Italian,
  es: Spanish,
  sv: Swedish,
  ru: Russian
};

export default function LanguagePicker({
  selectedLanguage,
  setSelectedLanguage,
  icon = LangPickerImage,
  color = '#0a6dce',
  fontSize = 'medium',
  useFlagIcons = false
}) {
  const [windowWidth, setWindowWidth] = useState(0);
  const classes = useStyles({ color, windowWidth, fontSize });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLangSelect = langCode => {
    if (langCode !== selectedLanguage) {
      setSelectedLanguage(langCode);
    }
    handleClose();
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}>
        {useFlagIcons ? (
          <img src={`${icons[selectedLanguage]}`} className={classes.flagIcon} alt="Flag Icon" />
        ) : (
          <>
            {' '}
            <img src={icon} alt="" className={classes.icon} />{' '}
            <span className={classes.language}>{langCodeToName(selectedLanguage)}</span>{' '}
            <span className={`${classes.triangle} ${classes.tooltipArrow}`}>&#9662;</span>{' '}
          </>
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.popover
        }}>
        <div className={classes.chips}>
          {supportedLanguages.map(langCode => (
            <div
              key={langCode}
              tabIndex="0"
              role="link"
              className={langCode === selectedLanguage ? classes.chipActive : classes.chip}
              onKeyPress={() => handleLangSelect(langCode)}
              onClick={() => handleLangSelect(langCode)}>
              {langCodeToName(langCode)}
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
}
