import { defineMessages } from 'react-intl';

const scope = 'app/containers/StatusBar';

export default defineMessages({
  statusBarWaiting: {
    id: `${scope}.status_bar.waiting`,
    defaultMessage: 'Waiting for changes...'
  },
  statusBarSaving: {
    id: `${scope}.status_bar.saving`,
    defaultMessage: 'Saving...'
  },
  statusBarSaved: {
    id: `${scope}.status_bar.saved`,
    defaultMessage: 'Saved changes successfully'
  },
  successfulSalesClosed: {
    id: `${scope}.status_bar.successfulSalesClosed`,
    defaultMessage: 'Sales Closed - no future bookings can be made'
  },
  successfulSalesOpened: {
    id: `${scope}.status_bar.successfulSalesOpened`,
    defaultMessage: 'Sales Open - bookings can now be made'
  },
  successfulAssignedTipCard: {
    id: `${scope}.status_bar.successfulAssignedTipCard`,
    defaultMessage: 'Tip Card was successfully claimed'
  },
  inviteDeleted: {
    id: `${scope}.status_bar.inviteDeleted`,
    defaultMessage: 'Invite deleted successfully'
  },
  generatingReview: {
    id: `${scope}.status_bar.generatingReview`,
    defaultMessage: 'Generating Review...'
  },
  successfulGeneratedReview: {
    id: `${scope}.status_bar.generatingReview`,
    defaultMessage: 'Review was successfully generated'
  },
  sendingEmail: {
    id: `${scope}.status_bar.sendingEmail`,
    defaultMessage: 'Sending Email...'
  },
  successfulEmailSent: {
    id: `${scope}.status_bar.successfulEmailSent`,
    defaultMessage: 'Email sent successfully'
  },
  willSendEmailIfExists: {
    id: `${scope}.status_bar.willSendEmailIfExists`,
    defaultMessage: 'If account exists for this email we will send instructions'
  },
  redirectToStripe: {
    id: `${scope}.status_bar.redirectToStripe`,
    defaultMessage: 'Redirecting to Stripe...'
  }
});
