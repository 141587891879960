import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AssignCardToProfile';

export default defineMessages({
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type'
  },
  activeCards: {
    id: `${scope}.active_cards`,
    defaultMessage: 'Active Cards'
  },
  selectUser: {
    id: `${scope}.select_user`,
    defaultMessage: 'Select User'
  },
  selectUserInfo: {
    id: `${scope}.select_user_info`,
    defaultMessage:
      'Search or select a user you would like to assign the card to from the list below'
  },
  assignCardInfo: {
    id: `${scope}.assign_card_info`,
    defaultMessage:
      'It is only possible to assign cards to users who have accepted their inviation.'
  },
  searchLabel: {
    id: `${scope}.search_label`,
    defaultMessage: 'Search'
  },
  searchPlaceholder: {
    id: `${scope}.search_placeholder`,
    defaultMessage: 'Enter Name'
  },
  checkboxLabel: {
    id: `${scope}.checkbox_label`,
    defaultMessage: 'Only show users with no active cards'
  },
  emptyDataSourceMessage: {
    id: `${scope}.empty_data_source_message`,
    defaultMessage: 'No records to display'
  }
});
