import { AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StyleBreakpoints } from '../../../utils/constants';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';

const userNameOrMock = user =>
  `${pathOr('', ['profile', 'firstName'], user)} ${pathOr('', ['profile', 'lastName'], user)}`;

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
    marginBottom: '20px'
  },
  AppBar: {
    backgroundColor: '#ffffff',
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(236,236,236)'
  }
});

export function PrimarySearchAppBar({
  user,
  withFilters = false,
  setIsOpenFilters,
  sendInvitesToGuides = null
}) {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChanged);
  });

  const handleWindowWidthChanged = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.AppBar}>
        {windowWidth > parseInt(StyleBreakpoints.sm) ? (
          <DesktopNavBar
            username={userNameOrMock(user)}
            user={user}
            handleProfileMenuOpen={handleProfileMenuOpen}
          />
        ) : (
          <MobileNavBar
            username={userNameOrMock(user)}
            withFilters={withFilters}
            user={user}
            setIsOpenFilters={setIsOpenFilters}
            sendInvitesToGuides={sendInvitesToGuides}
          />
        )}
      </AppBar>
    </div>
  );
}

function mapStateToProps(state) {
  return { user: state.user.user };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimarySearchAppBar);
