import { Analytics } from '@analytics';
import { Box, Button, Container, CssBaseline, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Logo from '../../components/Nav/TopNav/Logo';
import ResetPasswordInfo, { ResetPasswordInfoStatus } from '../../components/ResetPasswordInfo';
import { Copyright } from '../../components/SignInForm';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: '18px'
  },
  header: {
    fontSize: '16px',
    color: '#000',
    fontWeight: 600
  },
  info: {
    fontSize: '12px'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  sendButton: {
    margin: theme.spacing(3, 0, 2)
  },
  infoContainer: {
    textAlign: 'left'
  },
  footer: {
    marginTop: 72
  }
}));

const ForgotPassword = ({ resetPassword, isResetEmailSent }) => {
  useInjectSaga({ key: 'forgotPassword', saga });
  useInjectReducer({ key: 'forgotPassword', reducer });

  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const prefilledEmail = queryParams.get('email');
  const classes = useStyles();
  const intl = useIntl();
  const [email, setEmail] = useState(prefilledEmail ?? '');

  const onSendInstructionClicked = () => {
    Analytics.track('Send reset instructions button clicked');
    resetPassword(email);
  };

  return (
    <div>
      <Helmet>
        <title>{intl.formatMessage(messages.forgotPasswordTitle)}</title>
        <meta name="description" content="TripAdmit | Forgot Password" />
      </Helmet>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.infoContainer}>
            <p className={classes.header}>{intl.formatMessage(messages.forgotPasswordTitle)}?</p>
            <p className={classes.info}>{intl.formatMessage(messages.forgotPasswordInfo)}</p>
            <p className={classes.info}>{intl.formatMessage(messages.forgotPasswordInfo2)}</p>
          </div>

          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={intl.formatMessage(messages.emailAddressLabel)}
                  value={email}
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSendInstructionClicked}
              className={classes.sendButton}>
              {intl.formatMessage(messages.sendInstructions)}
            </Button>
          </form>
          {isResetEmailSent && (
            <Grid item xs={12}>
              <ResetPasswordInfo
                text={intl.formatMessage(messages.resetPasswordInfo)}
                status={ResetPasswordInfoStatus.SUCCESS}
              />
            </Grid>
          )}
        </div>
      </Container>

      <Box className={classes.footer}>
        <Copyright />
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  isResetEmailSent: state.forgotPassword?.isResetEmailSent
});

const mapDispatchToProps = dispatch => ({
  resetPassword: email => dispatch(actions.resetPassword(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
