import { defineMessages } from "react-intl";

export const scope = `app.containers.BookingPage`;

export default defineMessages({
    startingTime: {
        id: `${scope}.starting_time`,
        defaultMessage: 'Starting Time'
    },
    operationalHours: {
        id: `${scope}.operational_hours`,
        defaultMessage: 'Operational Hours'
    },
    duration: {
        id: `${scope}.duration`,
        defaultMessage: 'Duration'
    }, 
});