import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connectStripe } from '../../../api/taptotip';
import BankTransferSmall from '../../../assets/images/onboarding/BankTransfer_SmallButton.svg';
import PayPalSmall from '../../../assets/images/onboarding/PayPal_SmallButton.svg';
import RevolutSmall from '../../../assets/images/onboarding/Revolut_SmallButton.svg';
import SafeSecureSmall from '../../../assets/images/onboarding/Safe&Secure_SmallButton.svg';
import StripeSmall from '../../../assets/images/onboarding/Stripe_SmallButton.svg';
import VenmoSmall from '../../../assets/images/onboarding/Venmo_SmallButton.svg';
import { snackbarStatus } from '../../../containers/StatusBar';
import { Analytics } from '../../../utils/analytics';
import { StyleBreakpoints } from '../../../utils/constants';
import { WireDialogContent } from '../../TapToTip/AddRemittanceDetails';
import RevolutWireDialogContent from '../../TapToTip/AddRevolutDetails';

const useStyles = makeStyles(theme => ({
  paymentCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '90%',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      maxWidth: '100%',
      margin: '0 auto'
    }
  },
  paymentCard: {
    margin: '8px',
    width: '45%',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      margin: '5px 0',
      width: '50%'
    }
  },
  safeSecurePaymentCard: {
    marginLeft: '11px',
    marginTop: '9px',
    marginRight: '5px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      margin: '6px 0 0 0',
      paddingLeft: '3px'
    }
  },
  paymentCardLeft: {
    marginLeft: 'auto'
  },
  paymentCardRight: {
    marginRight: 'auto'
  },
  paymentButton: {
    width: '170px',
    cursor: 'pointer'
  },
  safeSecureButton: {
    width: '170px'
  },
  dialogContainer: {
    width: '100%',
    maxWidth: '709px',
    margin: '0 auto'
  },
  dialogPaper: {
    maxWidth: '100vw',
    margin: '32px auto',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      margin: '0 auto',
      height: '100vh',
      maxHeight: 'unset'
    }
  },
  paymentGatewayRow: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  paymentGatewayCard: {
    borderRadius: '11.2px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    maxWidth: '385px'
  },
  paymentGatewayDetailsImage: {
    marginBottom: '20px'
  },
  paymentGatewayDetailsText: {
    margin: '0 30px 30px 30px'
  },
  paymentGatewayGetStartedRow: {
    display: 'flex',
    paddingRight: '20px',
    flexDirection: 'row-reverse',
    color: '#767777',
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '20px'
  }
}));

const PaymentModalType = {
  PayPal: 'pay_pal',
  Venmo: 'venmo',
  Revolut: 'revolut',
  BankTransfer: 'wire'
};

const PaymentProviderCards = ({
  userId,
  manuallySubmitOnboardingForm,
  skipOnboarding,
  setStatusBar
}) => {
  const classes = useStyles();
  const [selectedPayment, setSelectedPayment] = useState(null);

  const handleOpenPaymentModal = paymentType => {
    Analytics.track(`Onboarding - ${paymentType} Card Selected`);
    setSelectedPayment(paymentType);
  };

  const handleClosePaymentModal = () => {
    setSelectedPayment(null);
  };

  const handleStripeRedirect = async () => {
    Analytics.track('Onboarding - Stripe Card Selected');
    setStatusBar(snackbarStatus.redirectToStripe);

    const response = await connectStripe();
    skipOnboarding();
    window.open(response.redirectUrl, '_blank');
    setStatusBar(snackbarStatus.success);
  };

  const renderModal = () => {
    switch (selectedPayment) {
      case PaymentModalType.PayPal:
        return null;
      case PaymentModalType.Venmo:
        return null;
      case PaymentModalType.Revolut:
        return (
          <RevolutWireDialogContent
            userId={userId}
            isOpen={true}
            onClose={handleClosePaymentModal}
            onSubmit={manuallySubmitOnboardingForm}
          />
        );
      case PaymentModalType.BankTransfer:
        return (
          <WireDialogContent
            open={true}
            onClose={handleClosePaymentModal}
            userId={userId}
            onSubmit={manuallySubmitOnboardingForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.paymentCardContainer}>
      <div className={classes.paymentCard}>
        <div
          className={`${classes.paymentButton} ${classes.paymentCardLeft}`}
          onClick={handleStripeRedirect}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}>
          <img src={StripeSmall} alt="Stripe" />
        </div>
      </div>
      {/* Hide until we connect PayPal and Venmo */}
      {/* <div className={classes.paymentCard}>
        <div
          className={`${classes.paymentButton} ${classes.paymentCardRight}`}
          onClick={() => handleOpenPaymentModal(PaymentModalType.PayPal)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}>
          <img src={PayPalSmall} alt="PayPal" />
        </div>
      </div>
      <div className={classes.paymentCard}>
        <div
          className={`${classes.paymentButton} ${classes.paymentCardLeft}`}
          onClick={() => handleOpenPaymentModal(PaymentModalType.Venmo)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}>
          <img src={VenmoSmall} alt="Venmo" />
        </div>
      </div> */}
      <div className={classes.paymentCard}>
        <div
          className={`${classes.paymentButton} ${classes.paymentCardRight}`}
          onClick={() => handleOpenPaymentModal(PaymentModalType.Revolut)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}>
          <img src={RevolutSmall} alt="Revolut" />
        </div>
      </div>
      <div className={classes.paymentCard}>
        <div
          className={`${classes.paymentButton} ${classes.paymentCardLeft}`}
          onClick={() => handleOpenPaymentModal(PaymentModalType.BankTransfer)}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}>
          <img src={BankTransferSmall} alt="Bank Transfer" />
        </div>
      </div>
      <div className={`${classes.paymentCard} ${classes.safeSecurePaymentCard}`}>
        <div className={`${classes.safeSecureButton} ${classes.paymentCardRight}`}>
          <img src={SafeSecureSmall} alt="Safe and Secure Messaging" />
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default PaymentProviderCards;
