import { createReducer } from 'utils/helpers';
import { strToAbsoluteDate } from '../../../utils/helpers';
import Constants from '../constants';

export const schedulePresetTypes = {
  everyX: 'everyX',
  everyHour: 'everyHour',
  manual: 'manual'
};

export const scheduleAvailabilityTypes = {
  interval: 'interval',
  allDay: 'allDay'
};

function everyHourTimeslots({ start, end, minutes }) {
  let timeslotCount = end.hours - start.hours;
  if (end.minutes >= minutes) timeslotCount += 1;
  const timeslots = [];
  for (let i = 0; i < timeslotCount; i++)
    timeslots.push({
      hours: start.hours + i,
      minutes: Number(minutes)
    });

  return timeslots;
}
function everyXTimeslots({ start, end, minutes }) {
  const timeslots = [];
  let cHours = start.hours,
    cMinutes = start.minutes;

  while (cHours < end.hours || (cHours === end.hours && cMinutes <= end.minutes)) {
    timeslots.push({ hours: cHours, minutes: cMinutes });
    cMinutes += minutes;
    cHours += Math.floor(cMinutes / 60);
    cMinutes %= 60;
  }

  return timeslots;
}

function generateSchedule(timeslots) {
  //copy timeslots to copy arrays and be more secure
  return {
    mon: { enabled: true, timeslots: [...timeslots] },
    tue: { enabled: true, timeslots: [...timeslots] },
    wed: { enabled: true, timeslots: [...timeslots] },
    thu: { enabled: true, timeslots: [...timeslots] },
    fri: { enabled: true, timeslots: [...timeslots] },
    sat: { enabled: true, timeslots: [...timeslots] },
    sun: { enabled: true, timeslots: [...timeslots] }
  };
}

function calculateSchedule({ type, schedule }) {
  let timeslots = [];
  switch (type) {
    case schedulePresetTypes.everyHour:
      timeslots = everyHourTimeslots(schedule);
      break;
    case schedulePresetTypes.everyX:
      timeslots = everyXTimeslots(schedule);
      break;
  }

  return generateSchedule(timeslots);
}

const schedulePresets = {
  [scheduleAvailabilityTypes.interval]: generateSchedule([]),
  [scheduleAvailabilityTypes.allDay]: {
    mon: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } },
    tue: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } },
    wed: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } },
    thu: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } },
    fri: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } },
    sat: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } },
    sun: { enabled: true, start: { hours: 8, minutes: 0 }, end: { hours: 18, minutes: 0 } }
  }
};

const schedulePresetInitial = {
  type: schedulePresetTypes.everyHour,
  configs: {
    [schedulePresetTypes.everyX]: {
      start: {
        hours: 8,
        minutes: 0
      },
      end: {
        hours: 18,
        minutes: 0
      },
      minutes: 5
    },
    [schedulePresetTypes.everyHour]: {
      start: {
        hours: 8,
        minutes: 0
      },
      end: {
        hours: 18,
        minutes: 0
      },
      minutes: 5
    }
  }
};

const newSeason = {
  title: '',
  tag: scheduleAvailabilityTypes.interval,
  seasonAvailability: {
    start: new Date(),
    end: new Date()
  },
  exclusions: [],
  schedule: schedulePresets[scheduleAvailabilityTypes.interval]
};

function extractScheduleFromSeason(season) {
  return {
    id: season.id,
    title: season.title,
    tag: season.tag,
    seasonAvailability: {
      start: strToAbsoluteDate(season.start),
      end: strToAbsoluteDate(season.end)
    },
    cutoffTimes: season.cutoffTimes,
    exclusions: season.exclusions.map(ex => ({
      start: strToAbsoluteDate(ex.start),
      end: strToAbsoluteDate(ex.end)
    })),
    schedule: season.schedule,
    appliedSchedulePreset: season.appliedSchedulePreset || schedulePresetTypes.everyHour
  };
}

export const initialState = {
  step: 0,
  seasons: [],
  activeSeason: null,
  schedulePreset: schedulePresetInitial
};

const initFromTour = (state, tour) => ({
  ...initialState,
  seasons: tour.seasons.map(extractScheduleFromSeason)
});
const setStep = (state, step) => ({ ...state, step });
const setActiveSeason = (state, season) => ({
  ...state,
  step: 0,
  schedulePreset: {
    ...schedulePresetInitial,
    ...(season.appliedSchedulePreset && { type: season.appliedSchedulePreset })
  },
  activeSeason: season
});
const createNewSeason = (state, shouldDuplicateSeason = false) => {
  if (shouldDuplicateSeason) {
    const duplicatedSeason = { ...state.activeSeason };
    if (duplicatedSeason.id) {
      duplicatedSeason.title = duplicatedSeason.title + ' - copy';
    }

    delete duplicatedSeason.id;

    if (duplicatedSeason.schedule) {
      for (const day in duplicatedSeason.schedule) {
        delete duplicatedSeason.schedule[day].resources;
      }
    }
    return setActiveSeason(state, duplicatedSeason);
  } else {
    return setActiveSeason(state, newSeason);
  }
};
const updateActiveSeason = (state, season) => {
  let newSeason = { ...season };
  if (newSeason.tag !== state.activeSeason.tag) newSeason.schedule = schedulePresets[newSeason.tag];

  return { ...state, activeSeason: newSeason };
};

const setPresetState = (state, preset) => ({ ...state, schedulePreset: preset });

const generateScheduleFromPreset = (state, preset) => {
  return {
    ...state,
    activeSeason: {
      ...state.activeSeason,
      schedule: calculateSchedule(preset),
      appliedSchedulePreset: preset.type
    }
  };
};

const resetActiveSchedule = state => setActiveSeason(state, null);

export default createReducer(
  {
    [Constants.schedule.SET_TOUR_SEASONS]: initFromTour,
    [Constants.schedule.CREATE_NEW_SEASON]: createNewSeason,
    [Constants.schedule.SET_STEP]: setStep,
    [Constants.schedule.SET_ACTIVE_SEASON]: setActiveSeason,
    [Constants.schedule.APPLY_SCHEDULE_PRESET]: generateScheduleFromPreset,
    [Constants.schedule.UPDATE_ACTIVE_SEASON]: updateActiveSeason,
    [Constants.schedule.UPDATE_SCHEDULE_PRESET_STATE]: setPresetState,
    [Constants.schedule.RESET_ACTIVE_SCHEDULE]: resetActiveSchedule
  },
  initialState
);
