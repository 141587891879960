import messages from './messages';

export function printDuration(intl, duration){
    const res = [];
    if (duration.days > 0) {
      res.push(`${duration.days} ${intl.formatMessage(duration.days > 1 ? messages.dayPlural : messages.daySingular)}`);
    }
    if (duration.hours > 0) {
      res.push(`${duration.hours} ${intl.formatMessage(duration.hours > 1 ? messages.hourPlural : messages.hourSingular)}`)
    }
    if (duration.minutes > 0) {
      res.push(`${duration.minutes} ${intl.formatMessage(duration.minutes > 1 ? messages.minutePlural : messages.minuteSingular)}`)
    }
    const joined = res.join(', ');
    return joined === '' ? 'N/A' : joined;
}