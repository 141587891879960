import { Tooltip } from '@material-ui/core';
import React from 'react';

const TooltipWrapper = ({ shouldShowTooltip, title, children }) => {
  return shouldShowTooltip ? (
    <Tooltip placement="top" title={title}>
      {children}
    </Tooltip>
  ) : (
    <div>{children}</div>
  );
};

export default TooltipWrapper;
