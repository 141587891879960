export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';

export const UPLOAD_USER_PROFILE_IMAGE = 'UPLOAD_USER_PROFILE_IMAGE';
export const UPLOAD_USER_PROFILE_IMAGE_SUCCESS = 'UPLOAD_USER_PROFILE_IMAGE_SUCCESS';

export const CONNECT_STRIPE = 'CONNECT_STRIPE';
export const CONNECT_STRIPE_SUCCESS = 'CONNECT_STRIPE_SUCCESS';

export const FETCH_AGENTS_PROFILES = 'FETCH_AGENTS_PROFILES';
export const FETCH_AGENTS_PROFILES_SUCCESS = 'FETCH_AGENTS_PROFILES_SUCCESS';

export const SEND_GUIDE_INVITES = 'SEND_GUIDE_INVITES';
export const REVOKE_INVITE = 'REVOKE_INVITE';

export const FETCH_ACTIVE_TOURS = 'FETCH_ACTIVE_TOURS';
export const FETCH_ACTIVE_TOURS_SUCCESS = 'FETCH_ACTIVE_TOURS_SUCCESS';

export const ADD_TOURS_TO_PROFILE = 'ADD_TOURS_TO_PROFILE';

export const ADD_TTT_TOUR = 'ADD_TTT_TOUR';
export const EDIT_TTT_TOUR = 'EDIT_TTT_TOUR';
export const DELETE_TOUR = 'DELETE_TOUR';

export const FETCH_REVIEWS_STATISTIC = 'FETCH_REVIEWS_STATISTIC';
export const FETCH_REVIEWS_STATISTIC_SUCCESS = 'FETCH_REVIEWS_STATISTIC_SUCCESS';

export const ACTION_ERROR = 'ACTION_ERROR';

export const FETCH_TOURS = 'FETCH_TOURS';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';

export const GET_PAYPAL_LOGIN_URL = 'GET_PAYPAL_LOGIN_URL';
export const GET_PAYPAL_LOGIN_URL_SUCCESS = 'GET_PAYPAL_LOGIN_URL_SUCCESS';

export const PAYPAL_LOGIN_CALLBACK = 'PAYPAL_LOGIN_CALLBACK';
export const PAYPAL_LOGIN_CALLBACK_SUCCESS = 'PAYPAL_LOGIN_CALLBACK_SUCCESS';

export const MULTIPLE_ASSIGN = 'MULTIPLE_ASSIGN';
