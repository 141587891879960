import React, { useEffect, useState } from 'react';
import NavLink from '../NavLink';
var logoSrc = require('../../../../assets/images/TripAdmitLogo.png');
var tipDirectLogo = require('../../../../assets/images/TipDirectLogo.png');

export function Logo({ user }) {
  const [link, setLink] = useState();

  useEffect(() => {
    //Default link should link to TipDirect homepage
    let tempLink = '/TipDirect';

    if (!user?.companyDetails?.products) tempLink = '/manifest';

    if (user?.companyDetails?.products?.TicketingSystem) {
      tempLink = '/manifest';
    }

    setLink(tempLink);
  }, [user]);

  return (
    <NavLink link={link}>
      <img
        style={{ width: 'auto', height: process.env.REGION === 'EU-TS' ? '60px' : '45px' }}
        alt="Logo Img"
        src={process.env.REGION === 'EU-TS' ? logoSrc : tipDirectLogo}
      />
    </NavLink>
  );
}

export default Logo;
