import { defineMessages } from 'react-intl';

const scope = `app.containers.TipDirectPage.claimCard`;

export default defineMessages({
  claimCard: {
    id: `${scope}.claimCard`,
    defaultMessage: 'Claim card'
  },
  unclaimedCardHeader: {
    id: `${scope}.unclaimedCardHeader`,
    defaultMessage: 'New Card ID Detected'
  },
  claimCardMessage: {
    id: `${scope}.claimCardMessage`,
    defaultMessage: 'Would you like to claim this card to your Tip Direct account?'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  goBackToProfile: {
    id: `${scope}.go_to_tip_direct_page`,
    defaultMessage: 'Back to Profile'
  },
  cardAssignedText: {
    id: `${scope}.card_assigned_text`,
    defaultMessage: 'Card Assigned to Profile'
  },
  cardAssignedInfo: {
    id: `${scope}.card_assigned_info`,
    defaultMessage: 'You have successfully paired the card to the selected profile.'
  },
  claimForUserButton: {
    id: `${scope}.claim_for_user_button`,
    defaultMessage: 'Claim for Selected User'
  },
  assignToUser: {
    id: `${scope}.assign_to_user`,
    defaultMessage: 'Assign to User'
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: 'Or'
  }
});
