import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/profiles';

export const fetchProfilesByCompanyId = companyId => {
  return axios
    .get(`${url}/company/${companyId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchUserProfileById = userId => {
  return axios
    .get(`${url}/${userId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const uploadUserProfileImage = image => {
  const formData = new FormData();
  formData.append('image', image);
  return axios
    .post(`${url}/image/upload`, formData)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const updateProfile = (userId, profile) => {
  return axios
    .put(`${url}/${userId}`, profile)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const connectStripe = () => {
  return axios
    .post(`${url}/connect`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const connectApplePay = () => {
  return axios
    .post(`${url}/connect-apple-pay`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const openStripeBillingPortal = type => {
  return axios
    .post(`${url}/stripe/open${type === 'company' ? '-company' : ''}-billing-portal`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export const fetchAgentsProfiles = () => {
  return axios
    .get(url)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const addToursToProfile = (guideId, tourIds) => {
  return axios
    .post(`${url}/add-tours/${guideId}`, { tourIds })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const multipleAssign = (tourIds, guideIds) => {
  return axios
    .post(`${url}/multiple-assign`, { tourIds, guideIds })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchUsers = () => {
  return axios
    .get(`${url}/guides`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
