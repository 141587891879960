import axios from 'axios';
import {
  Analytics,
  analyticsSignUpHandler,
  filterOutInternalTrafficForAnalytics
} from '../utils/analytics';
import { ApiError } from './error';

export function currentUser() {
  return axios.get('/api/v1/user/me').then(user => {
    filterOutInternalTrafficForAnalytics(user.data.data.userIp);
    return user;
  });
}

export function signup(signupData) {
  return axios
    .post('/api/v1/user/signup', signupData)
    .then(res => res.data.data)
    .then(user => {
      analyticsSignUpHandler(user, signupData.interestedIn);
      return user;
    })
    .catch(ApiError.ofAndThrow);
}

export function signupWithSubscription(signupData) {
  return axios
    .put('/api/v1/user/signup', signupData)
    .then(res => res.data.data)
    .then(user => {
      analyticsSignUpHandler(user, signupData.interestedIn);
      return user;
    })
    .catch(ApiError.ofAndThrow);
}

export function signin(signinData) {
  return axios
    .post('/api/v1/user/signin', signinData)
    .then(user => {
      filterOutInternalTrafficForAnalytics(user.data.data.userIp);
      Analytics.logInMoEngageUser(user.data.data.userId);
      return user;
    })
    .catch(ApiError.ofAndThrow);
}
