import { createReducer } from '../../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  tours: [],
  page: 0,
  canLoadMore: false,
  isLoaded: false,
  saleOptions: null
};

const fetchToursAggregateInit = (state, { resetList }) => ({
  ...state,
  tours: resetList ? [] : [...state.tours],
  isLoaded: false
});

const fetchToursAggregateSuccess = (state, { canLoadMore, page, data }) => ({
  ...state,
  page,
  canLoadMore,
  tours: state.tours.concat(data),
  isLoaded: true
});

const fetchSaleOptionsSuccess = (state, saleOptions) => ({
  ...state,
  saleOptions
});

export default createReducer(
  {
    [actionTypes.FETCH_TOURS_AGGREGATE_INIT]: fetchToursAggregateInit,
    [actionTypes.FETCH_TOURS_AGGREGATE_SUCCESS]: fetchToursAggregateSuccess,
    [actionTypes.FETCH_SALE_OPTIONS_SUCCESS]: fetchSaleOptionsSuccess
  },
  initialState
);
