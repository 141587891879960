import { Analytics } from '@analytics';
import { Button, Paper, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterNone as CopyIcon, GetApp as DownloadIcon } from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ShippingBoxIcon from '../../../assets/images/tipping/ShippingBox.svg';
import { StyleBreakpoints } from '../../../utils/constants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '20px 20px 50px',
    gap: '40px 70px',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      justifyContent: 'flex-start',
      marginLeft: '28px',
      padding: '0 0 50px'
    }
  },
  sectionBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '390px',
    borderRadius: '16px',
    backgroundColor: '#f9f9f9',
    padding: '20px 16px',
    gap: '20px'
  },
  divider: {
    height: '40px'
  },
  firstColumnWrapper: {
    minWidth: '250px'
  },
  agentName: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  helperTextPaper: {
    borderRadius: '16px',
    padding: '5px 15px',
    display: 'flex'
  },
  helperTextTitle: {
    fontWeight: 'bold'
  },
  orderCardsIcon: {
    display: 'inline-block',
    margin: '0 15px 0 0',
    width: '50px'
  },
  orderCardsButton: {
    color: '#fff!important'
  },
  qrPaper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column',
    margin: ' 0',
    padding: '10px'
  },
  qrCode: {
    border: '3px solid black',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '10px'
  },
  linkText: {
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    textTransform: 'lowercase'
  },
  copyIcon: { cursor: 'pointer', '&:hover': { color: 'black' } }
}));

function TapToTipLinkInformation({ user }) {
  const intl = useIntl();
  const classes = useStyles();
  const canvasRef = useRef();

  const [showAlert, setShouldShowCopyAlert] = useState(false);
  const linkUrl =
    process.env.REGION == 'EU'
      ? `https://ticketing.tripadmit.com/t/g/${user.userId}`
      : process.env.REGION == 'QA'
      ? `https://qats.tripadmit.com/t/g/${user.userId}`
      : `https://us.tip.direct/t/g/${user.userId}`;

  const guideGallerylinkUrl =
    process.env.REGION == 'EU'
      ? `https://ticketing.tripadmit.com/t/all/g/${user.companyId}`
      : process.env.REGION == 'QA'
      ? `https://qats.tripadmit.com/t/all/g/${user.companyId}`
      : `https://us.tip.direct/t/all/g/${user.companyId}`;

  const orderCardLink = 'https://tripadmit.com/tripadmit-tap-to-tip-order-cards/';

  function downloadQRCodeAsPNG() {
    const canvas = canvasRef?.current.children[0];
    if (!canvas) {
      throw new Error('Canvas not found');
    }

    const imageData = canvas.toDataURL('image/png');
    downloadStringAsFile(imageData, 'TripAdmit-Tap-To-Tip-QR-Code.png');
    Analytics.track('download QR code clicked');
  }

  function downloadStringAsFile(data, filename) {
    let a = document.createElement('a');
    a.download = filename;
    a.href = data;
    a.click();
  }

  const imageSettings = {
    height: window.innerWidth <= StyleBreakpoints.sm ? 240 : 300,
    width: window.innerWidth <= StyleBreakpoints.sm ? 240 : 300,
    x: 0,
    y: 0
  };

  const size = window.innerWidth <= StyleBreakpoints.sm ? 240 : 300;

  return (
    <>
      <div className={classes.leftContainer}>
        <div className={classes.firstColumnWrapper}>
          <Paper className={classes.sectionBlock}>
            <Paper className={classes.agentName}>{`${user.profile.firstName} ${
              user.profile.lastName
            }`}</Paper>
            <Paper className={classes.helperTextPaper}>
              <p>
                <div className={classes.helperTextTitle}>
                  {intl.formatMessage(messages.personalCodeTitle)}
                </div>
                {intl.formatMessage(messages.personalCodeDesc)}
              </p>
            </Paper>

            <Paper className={classes.qrPaper}>
              <div className={classes.qrCode} ref={canvasRef}>
                <QRCodeCanvas value={linkUrl} size={size} imageSettings={imageSettings} />
              </div>
            </Paper>
            <Button variant="contained" color="primary" onClick={downloadQRCodeAsPNG}>
              {intl.formatMessage(messages.downloadImage)}
              <DownloadIcon />
            </Button>
          </Paper>

          <div className={classes.divider} />

          <Paper
            className={classes.sectionBlock}
            onClick={() => {
              navigator.clipboard.writeText(linkUrl);
              setShouldShowCopyAlert(true);
            }}>
            <Paper className={classes.helperTextPaper}>
              <p>
                <div className={classes.helperTextTitle}>
                  {intl.formatMessage(messages.personalLinkTitle)}
                </div>
                {intl.formatMessage(messages.personalLinkDesc)}
              </p>
            </Paper>
            <Button variant="contained" color="primary">
              <div className={classes.linkText}>{linkUrl}</div>
              <CopyIcon className={classes.copyIcon} />
            </Button>
          </Paper>

          <div className={classes.divider} />
          <Paper
            className={classes.sectionBlock}
            onClick={() => {
              navigator.clipboard.writeText(guideGallerylinkUrl);
              setShouldShowCopyAlert(true);
            }}>
            <Paper className={classes.helperTextPaper}>
              <p>
                <div className={classes.helperTextTitle}>Guide Gallery</div>
                Collect tips for all of the guides in your organisation with this link.
              </p>
            </Paper>
            <Button variant="contained" color="primary">
              <div className={classes.linkText}>{guideGallerylinkUrl}</div>
              <CopyIcon className={classes.copyIcon} />
            </Button>
          </Paper>
        </div>

        <Paper className={classes.sectionBlock}>
          <Paper className={classes.helperTextPaper}>
            <img src={ShippingBoxIcon} alt="ShippingBoxIcon" className={classes.orderCardsIcon} />
            <p>
              <span className={classes.helperTextTitle}>
                {intl.formatMessage(messages.orderCards)}
              </span>
              {' - '}
              {intl.formatMessage(messages.orderCardsDesc)}
            </p>
          </Paper>
          <Button
            className={classes.orderCardsButton}
            variant="contained"
            color="primary"
            target="_blank"
            href={orderCardLink}>
            {intl.formatMessage(messages.orderCards)}
          </Button>
        </Paper>
      </div>
      <Snackbar
        open={showAlert}
        autoHideDuration={10000}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="success"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.copiedToClipboard)}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default TapToTipLinkInformation;
