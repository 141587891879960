import { defineMessages } from 'react-intl';

const scope = `app.containers.BookingPage`;

export default defineMessages({
  tripTotal: {
    id: `${scope}.trip_total`,
    defaultMessage: 'Trip Total'
  },
  summaryOfCharges: {
    id: `${scope}.summary_of_charges`,
    defaultMessage: 'Summary Of Charges'
  },
  priceBreakdown: {
    id: `${scope}.price_breakdown`,
    defaultMessage: 'Price Breakdown'
  },
  participants: {
    id: `${scope}.participants`,
    defaultMessage: 'Participants'
  },
  participantsEach: {
    id: `${scope}.participants_each`,
    defaultMessage: 'each'
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Promotional Discount'
  },
  sale: {
    id: `${scope}.sale`,
    defaultMessage: 'Sale'
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code'
  },
  hasBeenManualAdjusted: {
    id: `${scope}.hasBeenManualAdjusted`,
    defaultMessage: 'Price has been manually adjusted'
  }
});
