import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import GreenTick from '../../assets/images/Green_Tick.svg';
import TopNav from '../../components/Nav/TopNav';
import AssignCardToProfile from '../../components/TapToTip/AssignCardToProfile';
import { Analytics } from '../../utils/analytics';
import { StyleBreakpoints } from '../../utils/constants';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const logoSrc = require('../../assets/images/TripAdmitLogo.png');

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '22px',
    justifyContent: 'center',
    width: '100%'
  },
  wrapper: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%'
    }
  },
  claimButton: {
    width: '354px',
    height: '39px',
    backgroundColor: '#6997ff',
    color: 'white',

    '&:hover': {
      backgroundColor: '#6982ff'
    }
  },
  cancelButton: {
    width: '354px',
    height: '39px'
  },
  unclaimedCardHeader: {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '8px',
    color: 'black'
  },
  claimCardMessage: {
    fontSize: '12px',
    color: '#a6a6a6',
    marginBottom: '14px'
  },
  logo: {
    width: '260px',
    marginBottom: '60px'
  },
  cardNumberBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '51px',
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    minWidth: '100%',
    fontWeight: 'bold',
    color: '#101a29'
  },
  goToTapToTipButton: {
    marginTop: '32px'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  dividerBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0'
  },
  divider: {
    width: '45%'
  },
  dividerText: {
    width: '10%',
    margin: '0 16px',
    whiteSpace: 'nowrap'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '30px'
  },
  claimForUserButton: {
    width: '354px',
    height: '39px',
    backgroundColor: '#6997ff',
    color: 'white',

    '&:hover': {
      backgroundColor: '#6982ff'
    },

    '&:disabled': {
      backgroundColor: '#dadbdb',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#ccc'
      }
    }
  },
  cardAssignedBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '367px',
    height: '112px',
    padding: '14px',
    backgroundColor: '#a6e5d3',
    color: '#22644d',
    borderRadius: '5px'
  },
  cardAssignedText: {
    margin: '8px 0',
    fontWeight: 600,
    fontSize: '14px'
  },
  cardAssignedInfo: {
    margin: 0,
    textAlign: 'center',
    fontWeight: 500,
    width: '192px',
    fontSize: '12px'
  }
}));

function TapToTipAssignCard({ assignCardToUser, isAssigned, users, fetchUsers }) {
  useInjectReducer({ key: 'tapToTipAssignCard', reducer });
  useInjectSaga({ key: 'tapToTipAssignCard', saga });

  const [isAssignToUserOpen, setIsAssignToUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const classes = useStyles();
  const intl = useIntl();
  const { cardId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!users?.length) {
      fetchUsers();
    }
  }, []);

  const handleAssignCard = userId => {
    Analytics.track('Claim Card Button Clicked');
    assignCardToUser(cardId, userId);
  };

  const handlePushToTapToTip = () => {
    history.push({
      pathname: `/tipdirect/`
    });
  };

  return (
    <div>
      <TopNav />
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {!isAssigned ? (
            <>
              <img className={classes.logo} alt="TripAdmit logo" src={logoSrc} />
              <div className={classes.unclaimedCardHeader}>
                {intl.formatMessage(messages.unclaimedCardHeader)}
              </div>
              <div className={classes.claimCardMessage}>
                {intl.formatMessage(messages.claimCardMessage)}
              </div>
              <div className={classes.cardNumberBox}>{cardId}</div>
            </>
          ) : (
            <div className={classes.cardAssignedBox}>
              <img src={GreenTick} alt="Check Icon" />
              <p className={classes.cardAssignedText}>
                {intl.formatMessage(messages.cardAssignedText)}
              </p>
              <p className={classes.cardAssignedInfo}>
                {intl.formatMessage(messages.cardAssignedInfo)}
              </p>
            </div>
          )}
          {!isAssigned && isAssignToUserOpen && (
            <AssignCardToProfile
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              users={users}
            />
          )}

          <div className={classes.buttonGroup}>
            {!isAssigned ? (
              <>
                <div className={classes.buttonContainer}>
                  {isAssignToUserOpen ? (
                    <Button
                      disabled={!selectedUser}
                      onClick={() => handleAssignCard(selectedUser)}
                      className={classes.claimForUserButton}>
                      {intl.formatMessage(messages.claimForUserButton)}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setIsAssignToUserOpen(true)}
                      className={`${classes.claimForUserButton} ${classes.goToTapToTipButton}`}>
                      {intl.formatMessage(messages.assignToUser)}
                    </Button>
                  )}
                </div>

                <div className={classes.dividerBlock}>
                  <Divider className={classes.divider} />
                  <Typography className={classes.dividerText}>
                    {intl.formatMessage(messages.or)}
                  </Typography>
                  <Divider className={classes.divider} />
                </div>
                <Button className={classes.claimButton} onClick={() => handleAssignCard()}>
                  {intl.formatMessage(messages.claimCard)}
                </Button>
                <Button className={classes.cancelButton} onClick={handlePushToTapToTip}>
                  {intl.formatMessage(messages.cancel)}
                </Button>
              </>
            ) : (
              <Button
                className={`${classes.claimButton} ${classes.goToTapToTipButton}`}
                onClick={handlePushToTapToTip}>
                {intl.formatMessage(messages.goBackToProfile)}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isAssigned: state.tapToTipAssignCard?.isAssigned,
  users: state.tapToTipAssignCard?.users
});

const mapDispatchToProps = dispatch => ({
  assignCardToUser: (cardId, userId) => dispatch(actions.assignCardToUser({ cardId, userId })),
  fetchUsers: () => dispatch(actions.fetchUsers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TapToTipAssignCard);
