import axios from 'axios';

export function fetchManifest(startDate, endDate, view) {
  return axios.get('/api/v1/manifest', { params: { startDate, endDate, view } });
}

export function fetchManifestMonth(startDate, endDate, view) {
  return axios.get('/api/v1/manifest/monthview', { params: { startDate, endDate, view } });
}

export function fetchManifestWeek(startDate, endDate, view) {
  return axios.get('/api/v1/manifest/weekview', { params: { startDate, endDate, view } });
}

export function changeTimeslotAvailability(payload) {
  return axios.post('/api/v1/manifest/change-timeslot-availability', payload);
}

export function changeBunchDatesAvailability(payload) {
  return axios.post('/api/v1/manifest/change-bunch-dates-availability', payload);
}
