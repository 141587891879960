import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.PaymentsTable';

export default defineMessages({
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  payment: {
    id: `${scope}.payment`,
    defaultMessage: 'Payment'
  },
  revenue: {
    id: `${scope}.revenue`,
    defaultMessage: 'Gross Revenue'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name'
  },
  userId: {
    id: `${scope}.userId`,
    defaultMessage: 'User ID'
  },
  reference: {
    id: `${scope}.reference`,
    defaultMessage: 'Reference'
  },
  activityDate: {
    id: `${scope}.activityDate`,
    defaultMessage: 'Activity Date'
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: 'Time'
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: 'Success'
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Payments'
  }
});
