/* eslint-disable no-console */
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  createPromotion,
  deletePromotion,
  deleteTourFromPromo,
  fetchAvailablePromotions,
  fetchAvailableTours,
  fetchPromotionById,
  fetchPromotionTours,
  updatePromotion
} from '../../api';
import { date2str } from '../../utils/helpers';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function toPromoDto(activePromo) {
  return {
    title: activePromo.title,
    discount: Number(activePromo.discount),
    promoEndDate: date2str(activePromo.promoEndDate),
    promoStartDate: date2str(activePromo.promoStartDate),
    travelByEndDate: date2str(activePromo.travelByEndDate),
    travelByStartDate: date2str(activePromo.travelByStartDate),
    tourIds: activePromo.tourIds,
    ...(activePromo.promoCode && { promoCode: activePromo.promoCode })
  };
}

function* fetchPromotionsWorker() {
  try {
    const promotionsResponse = yield call(fetchAvailablePromotions);
    yield put(actions.fetchPromotionsSuccess(promotionsResponse));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
    console.log(error);
  }
}

function* fetchToursWorker() {
  try {
    const toursResponse = yield call(fetchAvailableTours);
    yield put(actions.fetchToursSuccess(toursResponse.data.data.tours));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
    console.log(error);
  }
}

function* createPromoWorker({ payload }) {
  try {
    yield call(createPromotion, toPromoDto(payload));
    yield fetchPromotionsWorker();
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
    console.log(error);
  }
}

function* updatePromoWorker({ payload }) {
  try {
    yield call(updatePromotion, toPromoDto(payload), payload._id);
    yield fetchPromotionsWorker();
    yield fetchToursFromPromoWorker({ payload: payload._id });
    yield fetchPromotionByIdWorker({ payload: payload._id });
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
    console.log(error);
  }
}

function* deletePromoWorker({ payload }) {
  try {
    yield call(deletePromotion, payload._id);
    yield fetchPromotionsWorker();
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
    console.log(error);
  }
}

function* fetchToursFromPromoWorker({ payload }) {
  try {
    const toursFromPromotionResponse = yield call(fetchPromotionTours, payload);
    yield put(actions.fetchPromotionToursSuccess(toursFromPromotionResponse));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* deleteTourFromPromoWorker({ payload }) {
  try {
    yield call(deleteTourFromPromo, payload.promotionId, payload.tourId);
    yield fetchToursFromPromoWorker({ payload: payload.promotionId });
    yield fetchPromotionByIdWorker({ payload: payload.promotionId });
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* fetchPromotionByIdWorker({ payload }) {
  try {
    const promotion = yield call(fetchPromotionById, payload);
    yield put(actions.fetchPromotionByIdSuccess(promotion));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

export default function* promotionsWatcher() {
  yield takeLatest(types.FETCH_TOURS, fetchToursWorker);
  yield takeLatest(types.FETCH_PROMOTIONS, fetchPromotionsWorker);
  yield takeLatest(types.CREATE_PROMOTION, createPromoWorker);
  yield takeLatest(types.UPDATE_PROMOTION, updatePromoWorker);
  yield takeLatest(types.DELETE_PROMOTION, deletePromoWorker);
  yield takeLatest(types.FETCH_PROMOTION_TOURS, fetchToursFromPromoWorker);
  yield takeLatest(types.DELETE_TOUR_FROM_PROMOTION, deleteTourFromPromoWorker);
  yield takeLatest(types.FETCH_PROMOTION_BY_ID, fetchPromotionByIdWorker);
}
