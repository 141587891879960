import moengage from '@moengage/web-sdk';
import mixpanel from 'mixpanel-browser';
mixpanel.init('2c5f3028106e7b8710a4472a00693686');

//moengage.initialize({ app_id: 'HOPWKBBRL4KCO6QM5RMK64LT', debug_logs: 1, cluster: 'DC_2' });
//moengage.initialize({ app_id: 'HOPWKBBRL4KCO6QM5RMK64LT', cluster: 'DC_2' });
//moengage.setDebugLevel(2);
// Uncomment to have MixPanel track events logged to the console
// mixpanel.set_config({debug: true});

const regions = {
  US: 'US',
  QA: 'QA'
};

const regionRootMap = {
  [regions.US]: 'https://us.tip.direct/',
  [regions.QA]: 'https://qats.tripadmit.com/',
  default: 'https://ticketing.tripadmit.com/'
};

const ipsToIgnore = ['212.90.34.162', '127.0.0.1'];
const urlsToIgnore = ['qats.tripadmit.com', 'localhost'];

export function filterOutInternalTrafficForAnalytics(userIp) {
  if (
    ipsToIgnore.some(ip => userIp?.includes(ip)) ||
    urlsToIgnore.some(url => window.location.href.includes(url))
  ) {
    // mixpanel.register({ $ignore: true });
  }

  mixpanel.unregister('$ignore');
}

export function analyticsSignUpHandler(user, interestedIn) {
  filterOutInternalTrafficForAnalytics(user.userIp);

  Analytics.identify(user.email, user.userId);

  Analytics.addProfileInformation({
    name: `${user.profile.firstName} ${user.profile.lastName}`,
    ...user.profile,
    $email: user.email,
    companyId: user.companyId,
    role: user.role,
    accountType: user.accountType
  });

  /*
  Analytics.registerMoEngageUser({
    ...user,
    ...user.profile,
    email: user.email,
    companyId: user.companyId,
    role: user.role,
    interestedIn
  });

  Analytics.track('new signup', { interestedIn }, true);*/
}

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  register: properties => {
    if (env_check) mixpanel.register(properties);
  },
  track: (name, props, useMoEngage = false) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
    if (useMoEngage) {
      //moengage.track_event(name, props);
    }
  },
  identify: (userEmail, userId) => {
    if (env_check) {
      mixpanel.identify(userEmail);
    }

    if (userId) {
      //moengage.add_unique_user_id(userId);
    }
  },
  addProfileInformation: (clientInformation, registerOnce = false) => {
    if (env_check) {
      if (registerOnce) {
        mixpanel.people.set_once(clientInformation);
      } else {
        mixpanel.people.set(clientInformation);
      }
    }
  },
  moengageAddUserAttribute: attributes => {
    /*
    Object.keys(attributes).forEach(key => {
      moengage.add_user_attribute(key, attributes[key]);
    });*/
  },
  registerMoEngageUser: userInformation => {
    /*
    moengage.add_first_name(userInformation.firstName);
    moengage.add_last_name(userInformation.lastName);
    moengage.add_email(userInformation.email);
    moengage.add_unique_user_id(userInformation.userId);
    moengage.add_user_attribute('userId', userInformation.userId);
    moengage.add_user_attribute('companyId', userInformation.companyId);
    moengage.add_user_attribute('role', userInformation.role);
    moengage.add_user_attribute('accountType', userInformation.accountType);
    moengage.add_user_attribute('region', process.env.REGION);
    moengage.add_user_attribute(
      'rootDomain',
      regionRootMap[process.env.REGION] || regionRootMap['default']
    );*/
  },
  logInMoEngageUser: userId => {
    //moengage.add_unique_user_id(userId);
  },
  // addExperiemnts allows for registering new experiments without overriding any existing experiments.
  addExperiments: experiments => {
    let existingExperiments = mixpanel.get_property('experiments') ?? [];
    existingExperiments = Array.from(new Set([...existingExperiments, ...experiments]));
    mixpanel.register({ experiments: existingExperiments });
  },
  logoutOfTracking: () => {
    //moengage.destroy_session();
    mixpanel.reset();
  }
};

export let Analytics = actions;
