import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  skipButton: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#afaeae',
    width: '100%',
    maxWidth: '350px'
  },
  nextButton: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#fff',
    width: '100%',
    margin: '8px 0',
    maxWidth: '350px'
  },
  infoBlock: {
    marginBottom: '12px',
    alignSelf: 'start'
  },
  step: {
    fontSize: '10px',
    fontWeight: '600',
    color: '#000',
    margin: '8px 0'
  },
  bottomButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  }
});

const StepButtons = ({ next, skip, isUpdatingProfile, handleSkip, stepsLength, activeStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.bottomButtons}>
      <div className={classes.step}>{`Step ${activeStep + 1} of ${stepsLength}`}</div>
      <Button
        type="submit"
        disabled={isUpdatingProfile}
        className={classes.nextButton}
        variant="contained"
        color="primary">
        {next}
      </Button>
      <Button disabled={isUpdatingProfile} className={classes.skipButton} onClick={handleSkip}>
        {skip}
      </Button>
    </div>
  );
};

export default StepButtons;
