import Axios from 'axios';
import { date2str } from '../utils/helpers';
import { ApiError } from './error';

const url = '/api/v1/booking';

export function fetchBookingsForReport(
  dateRange,
  selectedTours,
  dateMode = 'bookingDate',
  statusMode = 'all'
) {
  return Axios.post(`${url}/report`, {
    dateRange: { start: date2str(dateRange.start), end: date2str(dateRange.end) },
    tourIds: selectedTours,
    dateMode,
    statusMode
  }).then(res => res.data.data);
}

export function cancelBooking(id, cancelBookingRequest) {
  return Axios.post(`${url}/${id}/cancel`, cancelBookingRequest)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function deleteBooking(id) {
  return Axios.delete(`${url}/${id}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function refundBooking(id, refundBookingRequest) {
  return Axios.post(`${url}/${id}/refund`, refundBookingRequest)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function checkInBooking(id) {
  return Axios.post(`${url}/check-in/${id}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function updateBookingNotes(id, bookingNote) {
  return Axios.post(`${url}/edit-note/${id}`, { bookingNote })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function fetchBookingDetails(id) {
  return Axios.get(`${url}/${id}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function resendEmail(bookingId, companyId) {
  return Axios.post(`${url}/resend-email/${bookingId}?companyId=${companyId}`).catch(
    ApiError.ofAndThrow
  );
}

export function editBooking(bookingDto) {
  return Axios.put(`${url}/${bookingDto.bookingId}/edit`, bookingDto)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function moveBooking(bookingDto) {
  return Axios.put(`${url}/${bookingDto.bookingId}/move`, bookingDto)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function initPayment({ bookingId, ...dto }) {
  return Axios.post(`${url}/${bookingId}/init_payment`, dto)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function getTotalWithFees({ amount, currency, paymentType }) {
  return Axios.get(
    `${url}/total-with-fees?amount=${amount}&currency=${currency}&paymentType=${paymentType}`
  )
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}
