import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { currencySign, formatMoney } from '../../utils/helpers';
import messages from './messages';

const styles = theme => ({
  coloredInput: {
    color: theme.palette.primary.main
  },
  wasPrice: {
    textDecoration: 'line-through',
    opacity: '0.6',
    marginLeft: '5px'
  },
  participantInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    marginTop: '20px',
    minWidth: '250px',
    marginBottom: '8px'
  },
  participantName: {
    fontSize: '15px',
    lineHeight: '1',
    fontWeight: '700',
    margin: '5px 0'
  },
  select: {
    userSelect: 'none',
    minWidth: '124px',
    maxHeight: '30px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  participantDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  ageRange: {
    fontSize: '14px'
  },
  participantInput: {
    textAlign: 'center',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    borderRadius: '0.25rem',
    background: 'none',
    border: '0px',
    width: '36px',
    height: '32px',
    padding: '0px',
    margin: '0px 2px',
    fontSize: '15px',
    fontWeight: '600'
  },
  inputIcon: {
    fontSize: '20px',
    cursor: 'pointer',
    background: theme.palette.primary.main || 'rgb(0, 114, 206)',
    borderRadius: '4px',
    width: '32px',
    height: '32px',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(255, 255, 255)'
  },
  disabledIcon: {
    opacity: '0.2'
  }
});

export const getParticipantPrice = (p, season, participantsAmount) => {
  if (!season?.pricing?.isEnabledTieredPricing) {
    return p.price;
  }

  const activeTier =
    season.pricing.tiers
      .filter(t => t.minAmount <= participantsAmount)
      .sort((a, b) => b.minAmount - a.minAmount)[0] || season.pricing.tiers[0];

  return activeTier?.tierPrices?.find(tp => tp.participantId == p.participantId)?.price || p.price;
};

function ChooseParticipants({
  season,
  selectedParticipants,
  currency,
  classes,
  discountPercent,
  isSaleDate,
  participantsAmount,

  handleClose,
  setParticipants,
  isManualBooking
}) {
  const intl = useIntl();

  const incrementTotal = (p, i) => {
    const newValue = getSelectedParticipantsAmount(p, i) + 1;
    addParticipant(p.title.toLowerCase(), newValue);
  };

  const decrementTotal = (p, i) => {
    const newValue = getSelectedParticipantsAmount(p, i) - 1;
    addParticipant(p.title.toLowerCase(), newValue);
  };

  if (!season)
    return (
      <div style={{ height: '100px', padding: '20px' }}>
        {intl.formatMessage(messages.selectDateFirst)}
      </div>
    );

  const participantNote = season.pricing.participantNote ? (
    <div style={{ maxWidth: '300px' }}>
      <br />
      {season.pricing.participantNote}
      <br />
    </div>
  ) : null;

  const addParticipant = (participantName, value) => {
    setParticipants({ ...selectedParticipants, [participantName]: value });
  };
  const [inFocus, setInFocus] = useState();

  const getSelectedParticipantsAmount = (p, i) => {
    const participantName = p.title.toLowerCase();
    if (
      inFocus === i &&
      (!selectedParticipants[participantName] || selectedParticipants[participantName] === '0')
    ) {
      return '';
    } else {
      return selectedParticipants[participantName] ?? 0;
    }
  };

  const getParticipantPriceWithDiscount = price => {
    const discountAmount =
      discountPercent && isSaleDate ? Math.round((price * discountPercent) / 100) : 0;
    return price - discountAmount;
  };

  const getCurrentPrice = (price, currency) => {
    return ` ${formatMoney({
      amount: getParticipantPriceWithDiscount(price),
      currency: currency
    })}`;
  };

  const getWasPrice = (price, currency) => {
    return `${formatMoney({ amount: price, currency: currency })}`;
  };

  return (
    <div style={{ padding: '20px', paddingTop: '8px' }}>
      {season.pricing.participants.map((p, i) => {
        const participantPrice = getParticipantPrice(p, season, participantsAmount);

        return (
          <div key={i}>
            <div className={classes.participantInputContainer}>
              <div className={classes.participantDetails}>
                <div className={classes.participantName}>{p.title}</div>
                <div className={classes.ageRange}>
                  {intl.formatMessage(messages.ages, p.ageRange)}
                </div>
              </div>
              <div className={classes.select}>
                <span
                  className={`${classes.inputIcon} ${getSelectedParticipantsAmount(p, i) === 0 &&
                    classes.disabledIcon}`}
                  onClick={function() {
                    if (getSelectedParticipantsAmount(p, i)) {
                      decrementTotal(p, i);
                    }
                  }}
                  onKeyDown={function() {
                    if (getSelectedParticipantsAmount(p, i)) {
                      decrementTotal(p, i);
                    }
                  }}
                  role="button"
                  tabIndex={0}>
                  -
                </span>
                <input
                  className={classes.participantInput}
                  type="tel"
                  pattern="[0-9]*"
                  disabled
                  tabIndex="-1"
                  value={getSelectedParticipantsAmount(p, i)}
                />
                <span
                  className={`${classes.inputIcon} ${!isManualBooking &&
                    getSelectedParticipantsAmount(p, i) ===
                      season.pricing.maximumParticipantsPerBooking &&
                    classes.disabledIcon}`}
                  onClick={function() {
                    if (
                      getSelectedParticipantsAmount(p, i) === 0 ||
                      getSelectedParticipantsAmount(p, i) < season.pricing.capacity ||
                      isManualBooking
                    ) {
                      incrementTotal(p, i);
                    }
                  }}
                  onKeyDown={function() {
                    if (
                      getSelectedParticipantsAmount(p, i) === 0 ||
                      getSelectedParticipantsAmount(p, i) < season.pricing.capacity ||
                      isManualBooking
                    ) {
                      incrementTotal(p, i);
                    }
                  }}
                  role="button"
                  tabIndex={0}>
                  +
                </span>
              </div>
            </div>
            <div>
              {intl.formatMessage(messages.cost)}: {currencySign(currency)}
              {currency.toUpperCase()}
              {discountPercent && isSaleDate && participantPrice > 0 && (
                <span className={classes.wasPrice}>{getWasPrice(participantPrice, currency)}</span>
              )}
              {getCurrentPrice(participantPrice, currency)}
            </div>
          </div>
        );
      })}
      {participantNote}
      <br />
      <Button onClick={handleClose} style={{ width: '100%' }} variant="contained" color="primary">
        {intl.formatMessage(messages.done)}
      </Button>
    </div>
  );
}

/*class ChooseParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSeason: props.selectedSeason,
      participants: props.participants,
      selectedParticipants: props.selectedParticipants,

      setParticipants: props.setParticipants,
      currency: props.currency,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      selectedSeason: props.selectedSeason,
      selectedDate: this.props.date,
      currency: props.currency
    });
  }

  AddParticipant(participantType) {
    return function (e) {
      var stateUpdate = { participants: this.state.participants };
      stateUpdate['participants'][participantType] = e.target.value;
      this.setState(stateUpdate);
      this.state.setParticipants(this.state.participants);
    }.bind(this);
  }

  renderNoteIfPresent() {
    const noteText = this.state.selectedSeason.pricing.participantNote;
    const isString = typeof this.state.selectedSeason.pricing.participantNote == 'string';
    if (isString && noteText.length > 0) {
      return;
    } else {
      return null;
    }
  }

  render() {
    if (!this.state.selectedSeason) {
      return <div style={{ height: '100px', padding: '20px' }}>Please select a date first</div>;
    }
    const participantNote = this.renderNoteIfPresent();

    return (
      <div style={{ padding: '20px', paddingTop: '8px' }}>
        {' '}
        {this.state.selectedSeason.pricing.participants.map(p => (
          <div>
            <TextField
              style={{ marginTop: '14px' }}
              id="standard-number"
              label={p.title}
              placeholder={'Ages ' + p.ageRange.start + ' to ' + p.ageRange.end}
              InputProps={{ inputProps: { min: 0 } }}
              type="number"
              variant="outlined"
              value={this.state.participants[p.title]}
              onChange={this.AddParticipant(p.title).bind(this)}
              InputLabelProps={{
                shrink: true,
                className: this.props.classes.coloredInput
              }}
            />
            <div>
              Cost: {currencySign(this.state.currency)}
              {formatMoney({ amount: p.price, currency: this.state.currency })}
            </div>
          </div>
        ))}
        {participantNote}
        <br />
        <Button
          onClick={this.state.handleClose}
          style={{ width: '100%' }}
          variant="contained"
          color="primary"
        >
          Done
        </Button>
      </div>
    );
  }
}*/

export default withStyles(styles)(ChooseParticipants);
