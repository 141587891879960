import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ProfileOverview from './ProfileOverView';
import messages from './messages';

const useStyles = makeStyles({
  profiles: {
    borderTop: 'solid 1px #d9d9d9',
    borderBottom: 'solid 1px #d9d9d9',
    padding: '20px',
    height: '350px',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '8px',
      height: '0px',
      paddingRight: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e7e7e7',
      width: '8px',
      borderRadius: '4px'
    }
  },
  selectUserText: {
    color: '#000',
    fontSize: '20px'
  },
  selectUserInfo: {
    fontSize: '13px',
    color: '#a6a6a6'
  },
  assignCardInfo: {
    fontSize: '13px',
    color: '#a6a6a6'
  },
  search: {
    width: '100%',
    height: '50px',
    borderRadius: '4px',
    fontSize: '14px',
    marginTop: '16px',
    marginBottom: '16px'
  },
  checkbox: {
    margin: '16px 0',
    '& .MuiFormControlLabel-label': {
      fontSize: '13px',
      fontWeight: 600,
      color: '#000'
    }
  },
  searchButton: {
    padding: 0,

    '& .MuiSvgIcon-root': {
      fontSize: '32px',
      color: '#d4d1d1'
    }
  },
  emptyData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
});

const AssignCardToProfile = ({ users, setSelectedUser, selectedUser }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showNoActiveCards, setShowNoActiveCards] = useState(false);

  const classes = useStyles();
  const intl = useIntl();

  const handleCheckboxChange = event => {
    setSelectedUser(null);
    setShowNoActiveCards(event.target.checked);
  };
  const handleSearch = event => {
    setSelectedUser(null);
    setSearchTerm(event.target.value);
  };

  const handleSelectUser = userId => {
    setSelectedUser(userId);
  };

  const filteredUsers = users.filter(({ firstName, lastName, email, assignedCardsAmount }) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const isNoActiveCards = assignedCardsAmount === 0;

    return (
      (firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
        lastName.toLowerCase().includes(lowerCaseSearchTerm) ||
        email.toLowerCase().includes(lowerCaseSearchTerm)) &&
      (showNoActiveCards ? isNoActiveCards : true)
    );
  });

  return (
    <div>
      <h2 className={classes.selectUserText}>{intl.formatMessage(messages.selectUser)}</h2>
      <div className={classes.selectUserInfo}>{intl.formatMessage(messages.selectUserInfo)}</div>
      <TextField
        type="text"
        label={intl.formatMessage(messages.searchLabel)}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        variant="outlined"
        value={searchTerm}
        className={classes.search}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.searchButton}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <div className={classes.assignCardInfo}>{intl.formatMessage(messages.assignCardInfo)}</div>

      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox checked={showNoActiveCards} onChange={handleCheckboxChange} color="primary" />
        }
        label={intl.formatMessage(messages.checkboxLabel)}
      />
      <div className={classes.profiles}>
        {filteredUsers.length ? (
          filteredUsers.map((user, index) => (
            <ProfileOverview
              key={index}
              user={user}
              selectedUser={selectedUser}
              handleSelectUser={handleSelectUser}
            />
          ))
        ) : (
          <div className={classes.emptyData}>
            {intl.formatMessage(messages.emptyDataSourceMessage)}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignCardToProfile;
