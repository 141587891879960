import { defineMessages } from "react-intl";

const scope = 'common.fees'

const messages = defineMessages({
    inside: {
        id: `${scope}.inside_transaction_fee`,
        defaultMessage: `Transaction fee`
    },
    outside: {
        id: `${scope}.outside_transaction_fee`,
        defaultMessage: `Transaction fee`
    },
    default: {
        id: `${scope}.default_fee`,
        defaultMessage: `Transaction fee`
    }
});

export function getFeeTextRepresentation(intl, feeType) {
    switch (feeType) {
        case 'inside':
            return intl.formatMessage(messages.inside);
        case 'outside':
            return intl.formatMessage(messages.outside);
        default:
            return intl.formatMessage(messages.default);
    }
}

export default messages;
