import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/wire/';

export const updateProfileWire = profile => {
  return axios
    .post(`${url}wire-details/`, profile)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const getUserWireDetails = userId => {
  return axios
    .get(`${url}wire-details/${userId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const userWireDetailsExist = userId => {
  return axios
    .get(`${url}wire-details-exist/${userId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
