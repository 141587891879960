import { DatePicker } from '@material-ui/pickers';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { placeholders } from '../../../utils/common_translations/date';
import messages from '../messages';

function BasicDatePicker(props) {
  const intl = useIntl();
  return (
    <Fragment>
      <DatePicker
        autoOk={props.autoOk || false}
        inputVariant={props.inputVariant || 'standard'}
        format={props.format || 'MMMM do - yyyy'}
        label={<FormattedMessage {...placeholders.startDate} />}
        value={props.selectedStartDate}
        onChange={props.handleStartDateChange}
        animateYearScrolling
        okLabel={React.createElement('span', null, intl.formatMessage(messages.calendar.ok))}
        cancelLabel={React.createElement(
          'span',
          null,
          intl.formatMessage(messages.calendar.cancel)
        )}
      />
      &nbsp;&nbsp;&nbsp;
      <DatePicker
        autoOk={props.autoOk || false}
        inputVariant={props.inputVariant || 'standard'}
        format={props.format || 'MMMM do - yyyy'}
        label={<FormattedMessage {...placeholders.endDate} />}
        value={props.selectedEndDate}
        onChange={props.handleEndDateChange}
        animateYearScrolling
        okLabel={React.createElement('span', null, intl.formatMessage(messages.calendar.ok))}
        cancelLabel={React.createElement(
          'span',
          null,
          intl.formatMessage(messages.calendar.cancel)
        )}
      />
    </Fragment>
  );
}

export default BasicDatePicker;
