import { all, call, delay, put, takeLatest } from '@redux-saga/core/effects';
import { assignCardToUser, fetchUsers } from '../../api/taptotip';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* assignCardToUserWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));

    yield all([call(assignCardToUser, payload), delay(1000)]);

    yield put(actions.assignCardToUserSuccess());
    yield put(setSnackbarStatus(snackbarStatus.successfulAssignedTipCard));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchUsersWorker() {
  try {
    const users = yield call(fetchUsers);

    yield put(actions.fetchUsersSuccess(users));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err)));
  }
}

export default function* tapToTipAssignCardWatcher() {
  yield takeLatest(types.ASSIGN_CARD_TO_USER, assignCardToUserWorker);
  yield takeLatest(types.FETCH_USERS, fetchUsersWorker);
}
