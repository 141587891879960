/*
 *
 * LanguageProvider actions
 *
 */

import {
  CHANGE_BOOKING_WIDGET_LOCALE,
  CHANGE_LOCALE,
  CHANGE_SESSION_LOCALE,
  CHANGE_TIPPING_LOCALE
} from './constants';

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale
  };
}

export function changeBookingWidgetLocale(languageLocale) {
  return {
    type: CHANGE_BOOKING_WIDGET_LOCALE,
    locale: languageLocale
  };
}

export function changeSessionLocale(language) {
  return { type: CHANGE_SESSION_LOCALE, locale: language };
}

export function changeTippingLocale(languageLocale) {
  return {
    type: CHANGE_TIPPING_LOCALE,
    locale: languageLocale
  };
}
