import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/subscriptions';

export const getSubscriptionDetails = checkoutSessionId => {
  return axios
    .get(`${url}/${checkoutSessionId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
