/*
 * TopNav Messages
 *
 * This contains all the text for the TopNav component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.TopNav';

export default defineMessages({
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  manifest: {
    id: `${scope}.manifest`,
    defaultMessage: 'Manifest'
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Payments'
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings'
  },
  bookings: {
    id: `${scope}.bookings`,
    defaultMessage: 'Bookings'
  },
  channelManager: {
    id: `${scope}.channel_manager`,
    defaultMessage: 'Channel Manager'
  },
  promotions: {
    id: `${scope}.promotions`,
    defaultMessage: 'Promotions'
  },
  tipdirect: {
    id: `${scope}.tipdirect`,
    defaultMessage: 'Tip Direct'
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile'
  },
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews'
  },
  signOut: {
    id: `${scope}.sign_out`,
    defaultMessage: 'Sign Out'
  },
  recommendToAFriend: {
    id: `${scope}.recommend_to_a_friend`,
    defaultMessage: 'Recommend To A Friend'
  }
});
