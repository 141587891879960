import { Analytics } from '@analytics';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getUserWireDetails, updateProfileWire, userWireDetailsExist } from '../../../api/wire';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  textField: {
    marginBottom: '14px',
    width: '100%'
  },
  root: {
    padding: '14px'
  }
});

export function WireDialogContent(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, userId } = props;

  const [formLoaded, setFormLoaded] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [addressLine4, setAddressLine4] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [bankAccountName, setBankAccountName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [taxNumber, setTaxNumber] = useState('');

  const handleSelectChange = event => {
    setCustomerType(event.target.value);
  };

  useEffect(() => {
    const fetchExistingWireDetails = async () => {
      const data = await getUserWireDetails(userId);
      setFormLoaded(true);

      if (data) {
        setName(data.userWireDetails.name);
        setEmail(data.userWireDetails.email);
        setAddressLine1(data.userWireDetails.addressLine1);
        setAddressLine2(data.userWireDetails.addressLine2);
        setAddressLine3(data.userWireDetails.addressLine3);
        setAddressLine4(data.userWireDetails.addressLine4);
        setCity(data.userWireDetails.locality);
        setCountry(data.userWireDetails.country);
        setBankAccountName(data.userWireDetails.bankname);
        setBankAccountNumber(data.userWireDetails.iban);
        setCustomerType(data.userWireDetails.customerType);
        setTaxNumber(data.userWireDetails.taxNumber);
      }
    };

    // call the function
    fetchExistingWireDetails().catch(console.error);
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = value => {
    onClose(value);
  };
  if (!formLoaded) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Your Banking Details</DialogTitle>
      <form
        className={classes.root}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();

          let profileUpdate = {
            userId: userId,
            userWireDetails: {
              name: name,
              email: email,
              addressLine1: addressLine1,
              addressLine2: addressLine2,
              addressLine3: addressLine3,
              addressLine4: addressLine4,
              locality: city,
              country: country,
              bankname: bankAccountName,
              iban: bankAccountNumber,
              customerType: customerType,
              taxNumber: taxNumber
            }
          };
          updateProfileWire(profileUpdate);

          Analytics.moengageAddUserAttribute({
            hasSetupRemittance: true,
            remittanceType: 'wire'
          });

          Analytics.track('remittance setup', { type: 'wire' }, true);

          props.onSubmit && props.onSubmit();
          handleClose();
        }}>
        <TextField
          className={classes.textField}
          id="ContactName"
          label="Contact Name"
          variant="outlined"
          onChange={e => setName(e.target.value)}
          name="name"
          type="name"
          autocomplete="name"
          defaultValue={name}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="EmailAddress"
          label="Email Address"
          onChange={e => setEmail(e.target.value)}
          variant="outlined"
          name="email"
          type="email"
          autocomplete="email"
          defaultValue={email}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="POAddressLine1"
          label="Postal Address Line 1"
          onChange={e => setAddressLine1(e.target.value)}
          variant="outlined"
          id="address-line1"
          name="address-line1"
          defaultValue={addressLine1}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="POAddressLine2"
          label="Postal Address Line 2"
          onChange={e => setAddressLine2(e.target.value)}
          variant="outlined"
          name="address-line2"
          type="address-line2"
          defaultValue={addressLine2}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="POCity"
          label="City"
          onChange={e => setCity(e.target.value)}
          variant="outlined"
          id="locality"
          name="locality"
          defaultValue={city}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="POAddressLine3"
          label="County / State / Province / Territory"
          onChange={e => setAddressLine3(e.target.value)}
          variant="outlined"
          name="address-line3"
          type="address-line3"
          defaultValue={addressLine3}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="POCountry"
          label="Country"
          onChange={e => setCountry(e.target.value)}
          name="country"
          type="country"
          variant="outlined"
          defaultValue={country}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="POAddressLine4"
          label="	Postcode / Zip Code"
          onChange={e => setAddressLine4(e.target.value)}
          name="address-line4"
          type="address-line4"
          variant="outlined"
          defaultValue={addressLine4}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="BankAccountName"
          label="BIC / Sort Code / SWIFT Code"
          onChange={e => setBankAccountName(e.target.value)}
          name="bankname"
          type="bankname"
          variant="outlined"
          defaultValue={bankAccountName}
          onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        />
        <TextField
          className={classes.textField}
          id="BankAccountNumber(IBAN)"
          label="IBAN / Account Number"
          onChange={e => setBankAccountNumber(e.target.value)}
          name="iban"
          type="iban"
          defaultValue={bankAccountNumber}
          variant="outlined"
        />

        <br />
        <br />
        <Button variant="outlined" color="primary" type="submit" value="Submit">
          Submit
        </Button>
      </form>
    </Dialog>
  );
}

WireDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default function WireDetailsDialog({ userId }) {
  const [open, setOpen] = React.useState(false);
  const [hasWireDetails, setHasWireDetails] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchExistingWireDetails = async () => {
      const data = await userWireDetailsExist(userId);
      setHasWireDetails(data);
    };

    fetchExistingWireDetails().catch(console.error);
  }, []);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {hasWireDetails
          ? 'Update banking details for Wire Transfer'
          : 'Add banking details for Wire Transfer'}
      </Button>
      <WireDialogContent userId={userId} open={open} onClose={handleClose} />
    </div>
  );
}
