import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useBreakdownRowStyles = makeStyles({
  row: {
    marginBottom: '4px',
    display: 'flex'
  },
  title: {
    textAlign: 'left',
    width: '70%'
  },
  price: {
    textAlign: 'right',
    width: '30%'
  }
});

export function BreakdownRow({ title, price, minus }) {
  const classes = useBreakdownRowStyles();

  return (
    <div className={classes.row}>
      <div className={classes.title}>{title}</div>
      {price && (
        <div className={classes.price}>
          {minus ? ' − ' : ''}
          {price}
        </div>
      )}
    </div>
  );
}
