import { createAction } from "../../utils/helpers";
import * as actionTypes from './types';

export const fetchTours = createAction(actionTypes.FETCH_TOURS);
export const fetchIntegratedTours = createAction(actionTypes.FETCH_INTEGRATED_TOURS);
export const addToursToIntegratedTours = createAction(actionTypes.ADD_TOURS_TO_INTEGRATED_TOURS);
export const updateIntegratedTour = createAction(actionTypes.UPDATE_INTEGRATED_TOUR);
export const deleteIntegratedTour = createAction(actionTypes.DELETE_INTEGRATED_TOUR);
export const cleanValidationErrors = createAction(actionTypes.CLEAN_VALIDATION_ERRORS);
export const setIsModalOpen = createAction(actionTypes.SET_IS_MODAL_OPEN);

export const fetchToursSuccess = createAction(actionTypes.FETCH_TOURS_SUCCESS);
export const fetchIntegratedToursSuccess = createAction(actionTypes.FETCH_INTEGRATED_TOURS_SUCCESS);
export const addToursToIntegratedTourSuccess = createAction(actionTypes.ADD_TOURS_TO_INTEGRATED_TOURS_SUCCESS);
export const updateIntegratedTourSuccess = createAction(actionTypes.UPDATE_INTEGRATED_TOUR_SUCCESS);
export const deleteIntegratedTourSuccess = createAction(actionTypes.DELETE_INTEGRATED_TOUR_SUCCESS);
export const toursValidationFailed = createAction(actionTypes.TOURS_VALIDATION_FAILED);