import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../utils/constants';
import { currencySign, formatMoney } from '../../utils/helpers';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  coloredInput: {
    color: theme.palette.primary.main
  },
  nativeSelect: {
    display: 'block',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none'
    }
  },
  desktopSelect: {
    display: 'none',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'block'
    }
  },
  formControl: {
    width: '100%',
    marginTop: '16px'
  }
}));

export default function CurrencySelector({ selectCurrency, selectedCurrency, currencies, total }) {
  const classes = useStyles();
  const intl = useIntl();

  function getOptionBody(currency) {
    return currency === selectedCurrency
      ? `${currencySign(currency)} ${formatMoney({
          amount: total,
          currency: selectedCurrency
        })}`
      : currency.toUpperCase() + ' ' + currencySign(currency);
  }

  return (
    <>
      <div className={classes.nativeSelect}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="currency-select-outlined-label" className={classes.coloredInput}>
            {intl.formatMessage(messages.currency)}
          </InputLabel>
          <Select
            native={true}
            inputProps={{
              name: 'currency',
              id: 'outlined-age-native-simple'
            }}
            labelId="currency-select-outlined-label"
            id="select-currency-outlined"
            value={selectedCurrency}
            onChange={ev => selectCurrency(ev.target.value)}
            label={intl.formatMessage(messages.currency)}>
            {currencies.map((currency, i) => (
              <option key={i} value={currency}>
                {getOptionBody(currency)}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className={classes.desktopSelect}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="currency-Select-label" className={classes.coloredInput}>
            {intl.formatMessage(messages.currency)}
          </InputLabel>
          <Select
            label={intl.formatMessage(messages.currency)}
            labelId="currency-Select-label"
            id="currency-Select"
            value={selectedCurrency}
            onChange={ev => selectCurrency(ev.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}>
            {currencies.map((currency, i) => (
              <MenuItem key={i} value={currency}>
                {getOptionBody(currency)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}
