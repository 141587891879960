import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AddGuidesTable';

export default defineMessages({
  guides: {
    id: `${scope}.guides`,
    defaultMessage: 'Guides'
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email'
  }
});
