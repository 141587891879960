import { defineMessages } from 'react-intl';

export const scope = 'app.components.ChannelManagerModalTable';

export default defineMessages({
  tour: {
    id: `${scope}.tour`,
    defaultMessage: 'Tour'
  },
  tourId: {
    id: `${scope}.tour_id `,
    defaultMessage: 'Tour ID '
  },
  availableChannels: {
    id: `${scope}.available_channels`,
    defaultMessage: 'Available Channels '
  },
  selectAll: {
    id: `${scope}.select_all`,
    defaultMessage: 'Select all '
  },
  cutOffTimesError: {
    id: `${scope}.cut_off_times_error`,
    defaultMessage: 'Please add cut off times to all of your tour seasons'
  }
});
