export const mapTours = (activeTours, tours) => {
  return activeTours?.reduce((prev, curr) => {
    const tour = tours?.find(tour => curr._id === tour._id);

    if (tour) return [...prev, { ...tour, checked: true }];

    return [...prev, { ...curr, checked: false }];
  }, []);
};

export const filterCheckedTours = allTours => {
  return allTours.reduce((prev, curr) => {
    if (curr.checked) return [...prev, curr._id];

    return prev;
  }, []);
};
