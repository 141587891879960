import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import localizationMessages from '../../Resources/ResourcesTable/messages';
import { StyleBreakpoints } from '../../../utils/constants';

const useStyles = makeStyles({
  tableWithPadding: {
    width: '100%',
    maxWidth: '800px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.17)',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        position: 'static'
      },
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  }
});

const AddGuidesTable = ({ guides, updateCheckedGuides }) => {
  const intl = useIntl();
  const classes = useStyles();

  const columns = [
    {
      title: intl.formatMessage(messages.guides),
      field: 'name',
      cellStyle: {
        fontWeight: 'bold'
      },
      width: '40%',
      render: rowData => `${rowData.firstName || ''} ${rowData.lastName || ''}`
    },
    {
      title: intl.formatMessage(messages.email),
      field: 'email',
      cellStyle: {
        fontWeight: 'bold'
      },
      width: '40%'
    },
    {
      title: '',
      field: 'checked',
      render: rowData => {
        return (
          <Checkbox
            size="small"
            color="primary"
            checked={rowData.checked}
            onChange={e => updateCheckedGuides(rowData.userId, e.target.checked)}
          />
        );
      },
      width: '20%',
      cellStyle: {
        textAlign: 'right'
      }
    }
  ];

  const onRowClick = (_, rowData) => {
    updateCheckedGuides(rowData.userId, !rowData.checked);
  };

  return (
    <MaterialTable
      columns={columns}
      data={guides}
      onRowClick={onRowClick}
      options={{
        pageSize: 5,
        headerStyle: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          top: '0'
        },
        cellStyle: {
          whiteSpace: 'nowrap'
        },
        maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
      }}
      components={{
        // eslint-disable-next-line react/display-name
        Container: props => <div className={classes.tableWithPadding} {...props} />
      }}
      localization={{
        pagination: {
          labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
          labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
          firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
          firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
          previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
          previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
          nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
          nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
          lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
          lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
        },
        toolbar: {
          searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
          searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
        },
        body: {
          emptyDataSourceMessage: intl.formatMessage(localizationMessages.emptyDataSourceMessage)
        }
      }}
    />
  );
};

export default AddGuidesTable;
