import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { RevolutCurrencies } from '../../../utils/constants';
import { countries, currencies } from '../../../utils/helpers';
import TooltipWrapper from '../../Common/TooltipWrapper';
import messages from '../../TapToTip/Profile/messages';

const useStyles = makeStyles({
  validationErrorMessage: {
    color: 'red'
  },
  textField: {
    marginTop: 20,
    width: '100%',
    minWidth: '250px',
    '& label.Mui-focused': {
      fontWeight: 600,
      color: '#6b6b6b'
    }
  }
});

const TippingInformation = ({ profile, errors, touched, values }) => {
  const intl = useIntl();
  const classes = useStyles();
  const isRevolutCurrencyEnabled = Boolean(profile?.revolut?.currency);

  const Fields = {
    Currency: 'currency',
    DefaultPaymentAmount: 'defaultPaymentAmount',
    Country: 'country'
  };

  const getFieldContent = (form, field) => {
    switch (field) {
      case Fields.Currency: {
        return (
          <TooltipWrapper
            shouldShowTooltip={isRevolutCurrencyEnabled}
            title={intl.formatMessage(messages.currencyTooltip)}>
            <TextField
              id="standard-select-currency"
              disabled={isRevolutCurrencyEnabled}
              select
              label={intl.formatMessage(messages.paymentLabel)}
              variant="outlined"
              className={classes.textField}
              value={profile?.revolut?.currency || values.currency}
              onChange={e => form.setFieldValue('currency', e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}>
              {Object.entries({
                ...currencies,
                ...(isRevolutCurrencyEnabled && { ...RevolutCurrencies })
              }).map(([key, symbol]) => (
                <MenuItem key={key} value={key}>
                  {symbol}
                </MenuItem>
              ))}
            </TextField>
          </TooltipWrapper>
        );
      }

      case Fields.DefaultPaymentAmount:
        return (
          <TextField
            className={classes.textField}
            label={intl.formatMessage(messages.defaultPaymentAmountLabel)}
            variant="outlined"
            placeholder="10"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={values.defaultPaymentAmount.toString()}
            onChange={e => {
              const parsedNumber = parseInt(e.target.value);
              form.setFieldValue(
                'defaultPaymentAmount',
                Number.isNaN(parsedNumber) ? 0 : parsedNumber
              );
            }}
          />
        );
      case Fields.Country:
        return (
          <TextField
            id="standard-select-country"
            select
            label={intl.formatMessage(messages.countryLabel)}
            variant="outlined"
            className={classes.textField}
            value={values.country}
            onChange={e => form.setFieldValue('country', e.target.value)}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}>
            {Object.entries(countries).map(([key, symbol]) => (
              <MenuItem key={key} value={key}>
                {symbol}
              </MenuItem>
            ))}
          </TextField>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {Object.values(Fields).map(field => (
        <div key={field}>
          <Field name={field}>
            {({ form }) => {
              const fieldContent = getFieldContent(form, field);
              return fieldContent;
            }}
          </Field>
          {errors[field] && touched[field] && (
            <div className={classes.validationErrorMessage}>{errors[field]}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TippingInformation;
