import { Button, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import React from 'react';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%'
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden',
    height: '45px',
    boxShadow: '0 2px 2px 0 rgb(0 0 0 / 31%)',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  title: {
    fontWeight: 'bold',
    flexShrink: 0
  },
  buttonInput: {
    flex: '0 0 28px',
    height: '45px',
    padding: '6px 0',
    minWidth: '0px',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '4px',
    boxShadow: '0 2px 2px 0 rgb(0 0 0 / 31%)',
    backgroundColor: '#ffffff',
    color: '#6a6a6a',
    fontSize: '20px',
    fontWeight: 'normal',
    justifyContent: 'center',
    margin: `0 ${theme.spacing()}px`,
    cursor: 'pointer',
    opacity: 1
  },
  inputElements: {
    display: 'flex',
    gap: '5px'
  },
  arrow: {
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: 0
  },
  menuItem: {
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main
    },
    '&.Mui-selected:hover': {
      color: '#767777'
    }
  }
}));

function StepButton({ items, setSelectedItems, selectedItems, type, getValue }) {
  const classes = useStyles();

  const displayIcon =
    type === 'left' ? (
      <span className={classes.arrow}>&#8249;</span>
    ) : (
      <span className={classes.arrow}>&#8250;</span>
    );
  const indexOffset = type === 'left' ? -1 : 1;

  const handleClick = () => {
    const index =
      selectedItems.length < 1
        ? -1
        : items.findIndex(x => (getValue ? getValue(x) : x._id) === R.last(selectedItems));
    const newIndex = index + indexOffset;
    const itemIndex = newIndex < 0 ? items.length - 1 : newIndex % items.length;

    return setSelectedItems([getValue ? getValue(items[itemIndex]) : items[itemIndex]._id]);
  };

  return (
    <Button className={classes.buttonInput} onClick={handleClick}>
      {displayIcon}
    </Button>
  );
}

export default function MultiplePicker({
  items,
  selectedItems,
  setSelectedItems,
  getTitle,
  getValue,
  defaultOptionLabel
}) {
  const classes = useStyles();
  const value = selectedItems.length === 0 ? [null] : selectedItems;

  const handleChange = event => {
    const values = event.target.value;
    const validValues = values.filter(Boolean);

    if (!values.length) setSelectedItems([]);

    values.forEach(value =>
      value === null ? setSelectedItems([]) : setSelectedItems(validValues)
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputElements}>
        <StepButton
          items={items}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          getValue={getValue}
          type="left"
        />
        <Select
          multiple
          className={classes.input}
          value={value}
          onChange={handleChange}
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}>
          <MenuItem value={null}>{defaultOptionLabel}</MenuItem>
          {items.map((item, i) => (
            <MenuItem
              className={classes.menuItem}
              value={getValue ? getValue(item) : item._id}
              key={i}>
              {getTitle ? getTitle(item) : item.displayName || item.title}
            </MenuItem>
          ))}
        </Select>

        <StepButton
          items={items}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          getValue={getValue}
          type="right"
        />
      </div>
    </div>
  );
}
