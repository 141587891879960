import { SET_SNACKBAR_STATUS, snackbarStatus } from './constants';

export const initialState = {
  type: snackbarStatus.hidden,
  message: '',
  title: ''
};

export function StatusBarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SNACKBAR_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
