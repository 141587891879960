import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles({
  tableWithPadding: {
    maxWidth: '800px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.17)',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        position: 'static'
      },
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  }
});

const AddToursTable = ({ tours, updateCheckedTours }) => {
  const intl = useIntl();
  const classes = useStyles();

  const columns = [
    {
      title: intl.formatMessage(messages.tourTitle),
      field: 'title',
      cellStyle: {
        fontWeight: 'bold'
      },
      width: '70%'
    },
    {
      title: '',
      field: 'checked',
      render: rowData => {
        return (
          <Checkbox
            size="small"
            color="primary"
            checked={rowData.checked}
            onChange={e => updateCheckedTours(rowData._id, e.target.checked)}
          />
        );
      },
      width: '30%',
      cellStyle: {
        textAlign: 'right'
      }
    }
  ];

  const onRowClick = (_, rowData) => {
    updateCheckedTours(rowData._id, !rowData.checked);
  };

  return (
    <MaterialTable
      columns={columns}
      data={tours}
      onRowClick={onRowClick}
      options={{
        tableLayout: 'fixed',
        paging: false,
        showTitle: false,
        search: false,
        toolbar: false,
        padding: 'dense'
      }}
      components={{
        // eslint-disable-next-line react/display-name
        Container: props => <div className={classes.tableWithPadding} {...props} />
      }}
    />
  );
};

export default AddToursTable;
