import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../../utils/constants';
import messages from '../messages';
import InviteGuidesIcon from './../../../assets/images/onboarding/InviteGuides.svg';
import OrderCardsIcon from './../../../assets/images/onboarding/OrderCards.svg';
import SetupReviewsIcon from './../../../assets/images/onboarding/ReviewLinks.svg';
import WelcomeIcon from './../../../assets/images/onboarding/Step1_Hand.svg';
import AdditionalInfoIcon from './../../../assets/images/onboarding/Step2_Tick.svg';
import MoneyIcon from './../../../assets/images/onboarding/Step3_MoneyBag.svg';
import CongratsIcon from './../../../assets/images/onboarding/Step4_Congrats.svg';
import TippingLinkIcon from './../../../assets/images/onboarding/TippingLink.svg';
import GetPaidIcon from './../../../assets/images/onboarding/Step3_MoneyBag.svg';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 'auto',
    minHeight: '80px',
    padding: '20px 22px 13px 21px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    width: '35px',
    marginRight: '-5px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  altIconStyle: {
    width: '22px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#000'
  },
  boldText: {
    fontWeight: '600'
  },
  textContainer: {
    marginLeft: '10px'
  },
  altCardLayout: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '45px',
    paddingRight: '45px',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      paddingLeft: '25px',
      paddingRight: '25px'
    }
  },
  altBoldTextLayout: {
    fontSize: '14px',
    display: 'block'
  }
}));

const TextIconInformationBox = ({ card, noBackgroundCircle = false, columnLayout = false }) => {
  const classes = useStyles();
  const intl = useIntl();

  const InfoBoxIcon = {
    Welcome: WelcomeIcon,
    AdditionalInfo: AdditionalInfoIcon,
    NearlyThere: MoneyIcon,
    Congratulations: CongratsIcon,
    PersonalTipping: TippingLinkIcon,
    InviteGuides: InviteGuidesIcon,
    SetupReviews: SetupReviewsIcon,
    OrderCards: OrderCardsIcon,
    GetPaid: GetPaidIcon
  };

  const InfoBoxBoldText = {
    Welcome: intl.formatMessage(messages.welcomeBoldText),
    AdditionalInfo: intl.formatMessage(messages.additionalInfoBoldText),
    NearlyThere: intl.formatMessage(messages.nearlyThereBoldText),
    Congratulations: intl.formatMessage(messages.congratulationsBoldText),
    PersonalTipping: intl.formatMessage(messages.personalTippingBoldText),
    InviteGuides: intl.formatMessage(messages.inviteGuidesBoldText),
    SetupReviews: intl.formatMessage(messages.setupReviewsBoldText),
    OrderCards: intl.formatMessage(messages.orderCardsBoldText),
    GetPaid: `How do I get paid?`
  };
  const InfoBoxDescription = {
    Welcome: ` - ${intl.formatMessage(messages.welcomeDescription)}`,
    AdditionalInfo: ` - ${intl.formatMessage(messages.additionalInfoDescription)}`,
    NearlyThere: ` - ${intl.formatMessage(messages.nearlyThereDescription)}`,
    Congratulations: `${intl.formatMessage(messages.congratulationsDescription)}`,
    PersonalTipping: ` - ${intl.formatMessage(messages.personalTippingDescription)}`,
    InviteGuides: ` - ${intl.formatMessage(messages.inviteGuidesDescription)}`,
    SetupReviews: ` - ${intl.formatMessage(messages.setupReviewsDescription)}`,
    OrderCards: ` - ${intl.formatMessage(messages.orderCardsDescription)}`,
    GetPaid:
      ' - Once you receive a tip we will send you an email with a link to claim it. Once you choose your payout method the rest of your tips will be sent automatically.'
  };

  return (
    <div className={`${classes.container} ${columnLayout ? classes.altCardLayout : ''}`}>
      <div>
        <img
          src={InfoBoxIcon[card]}
          className={noBackgroundCircle ? classes.altIconStyle : classes.icon}
          alt=""
        />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.text}>
          <span className={`${classes.boldText} ${columnLayout ? classes.altBoldTextLayout : ''}`}>
            {InfoBoxBoldText[card]}
          </span>
          {InfoBoxDescription[card]}
        </div>
      </div>
    </div>
  );
};

export default TextIconInformationBox;
