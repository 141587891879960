import { Button, Container, Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import ChannelManagerTable from '../../components/ChannelManager/ChannelManagerTable';
import ModalTable from '../../components/ChannelManager/Common/ModalTable';
import CustomizableModal from '../../components/Common/CustomizableModal';
import TopNav from '../../components/Nav/TopNav';
import { convertArrayToObject } from '../../utils/helpers';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import { INITIAL_INTEGRATIONS_DATA } from './constants/initialIntegrationsData';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const ChannelManagerPage = ({
  fetchIntegratedTours,
  fetchTours,
  tours = [],
  integratedTours = [],
  updateIntegratedTour,
  deleteSelectedIntegratedTour,
  addToursToIntegratedTours,
  cleanValidationErrors,
  validationErrors,
  isModalOpen,
  setIsModalOpen
}) => {
  useInjectReducer({ key: 'channelManager', reducer });
  useInjectSaga({ key: 'channelManager', saga });

  useEffect(() => {
    fetchTours();
    fetchIntegratedTours();
  }, []);

  const intl = useIntl();

  const toursById = useMemo(() => convertArrayToObject(tours, '_id'), [tours]);
  const integratedToursById = useMemo(() => convertArrayToObject(integratedTours, 'tourId'), [
    integratedTours
  ]);

  const [mode, setMode] = React.useState();
  const [dataToSave, setDataToSave] = React.useState([]);
  const [dataToRender, setDataToRender] = React.useState([]);

  const [toursForAdding, setToursForAdding] = React.useState([]);

  useEffect(() => {
    setToursForAdding(
      tours
        .filter(tour => tour.tourState == 'published')
        .filter(({ _id }) => !Object.prototype.hasOwnProperty.call(integratedToursById, _id))
        .map(({ _id }) => ({
          tourId: _id,
          integrations: [...INITIAL_INTEGRATIONS_DATA]
        }))
    );
  }, [tours, integratedTours]);

  const handleClose = () => {
    cleanValidationErrors();
    setIsModalOpen(false);
    setDataToSave([]);
  };

  const handleSave = () => {
    switch (mode) {
      case 'edit':
        updateIntegratedTour(...dataToSave);
        break;
      case 'delete':
        deleteSelectedIntegratedTour(...dataToSave);
        break;
      case 'add':
        addToursToIntegratedTours(dataToSave);
        break;
    }
    cleanValidationErrors();
    setDataToSave([]);
  };

  const deleteIntegratedTour = integratedTour => {
    setMode('delete');
    setDataToRender([integratedTour]);
    setDataToSave([integratedTour]);
    setIsModalOpen(true);
  };

  const editIntegratedTour = integratedTour => {
    setMode('edit');
    setDataToRender([integratedTour]);
    setDataToSave([integratedTour]);
    setIsModalOpen(true);
  };

  const addNewTours = () => {
    setMode('add');
    setDataToRender(toursForAdding);
    setIsModalOpen(true);
  };

  return (
    <>
      <TopNav />
      <Container maxWidth="xl" style={{ padding: '0 24px' }}>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={10} />
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={addNewTours}>
              {`${intl.formatMessage(messages.addTours)} +`}
            </Button>
          </Grid>
        </Grid>

        <ChannelManagerTable
          integratedTours={integratedTours}
          toursById={toursById}
          deleteIntegratedTour={deleteIntegratedTour}
          editIntegratedTour={editIntegratedTour}
        />

        {isModalOpen && (
          <CustomizableModal
            isOpen={isModalOpen}
            onClose={handleClose}
            withDividers={true}
            bodyPadding={false}
            title={intl.formatMessage(messages[mode + 'Title'])}
            desc={intl.formatMessage(messages[mode + 'Desc'])}
            body={
              <ModalTable
                mode={mode}
                toursToDisplay={dataToRender}
                setDataToSave={setDataToSave}
                toursById={toursById}
                integratedToursById={integratedToursById}
                validationErrors={validationErrors}
              />
            }
            handleFooterCloseButton={handleClose}
            primaryActionButtonData={{
              isDisabled: false,
              onClick: handleSave,
              content:
                mode == 'delete'
                  ? intl.formatMessage(messages.delete)
                  : intl.formatMessage(messages.save)
            }}
          />
        )}
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  tours: state.channelManager?.tours,
  integratedTours: state.channelManager?.integratedTours,
  validationErrors: state.channelManager?.validationErrors,
  isModalOpen: state.channelManager?.isModalOpen
});

const mapDispatchToProps = dispatch => ({
  fetchIntegratedTours: () => dispatch(actions.fetchIntegratedTours()),
  fetchTours: () => dispatch(actions.fetchTours()),
  updateIntegratedTour: payload => dispatch(actions.updateIntegratedTour(payload)),
  deleteSelectedIntegratedTour: payload => dispatch(actions.deleteIntegratedTour(payload)),
  addToursToIntegratedTours: payload => dispatch(actions.addToursToIntegratedTours(payload)),
  cleanValidationErrors: () => dispatch(actions.cleanValidationErrors()),
  setIsModalOpen: payload => dispatch(actions.setIsModalOpen(payload))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelManagerPage);
