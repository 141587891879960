import { defineMessages } from 'react-intl';

export const scope = 'app.components.commonModal';

export default defineMessages({
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete'
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back'
  }
});
