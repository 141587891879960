import { defineMessages } from 'react-intl';

export const scope = `app.containers.BookingPage`;

export default defineMessages({
  whyBookDirect: {
    id: `${scope}.why_book_direct`,
    defaultMessage: 'Why Book Direct'
  },
  securityGuaranteed: {
    id: `${scope}.security_guarantied`,
    defaultMessage: 'Security Guaranteed'
  },
  lowestPriceGuarantied: {
    id: `${scope}.lowest_price_guarantied`,
    defaultMessage: 'Save More by Booking Direct'
  },
  immediateConfirmation: {
    id: `${scope}.immediate_confirmartion`,
    defaultMessage: 'Immediate Confirmation'
  }
});
