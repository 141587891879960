import { defineMessages } from 'react-intl';

export const scope = 'app.components.common.DateRangePicker';

export default defineMessages({
  from: {
    id: `${scope}.from`,
    defaultMessage: 'From'
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'To'
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'Ok'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  }
});
