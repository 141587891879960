import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import MiniLogo from '../../../assets/images/miniLogo.svg';
import { strToAbsoluteDate } from '../../../utils/helpers';
import CustomizableModal from '../../Common/CustomizableModal';
import RemoveTourFromPromotionModal from '../RemoveTourModal';
import messages from './messages';
import NamePromotion from './NamePromotion';
import PromotionsDetails from './PromotionsDetails';
import SelectToursForPromotion from './SelectToursForPromotion';

export const MODAL_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_TOUR: 'DELETE_TOUR'
};

const STEP = {
  ADD_NAME: 0,
  SELECT_TOURS: 1,
  TOUR_DETAILS: 2,
  DELETE: 3,
  DELETE_TOUR: 'DELETE_TOUR'
};

function getInitialPromoData(hasPromoCode) {
  return {
    title: '',
    discount: null,
    promoEndDate: null,
    promoStartDate: null,
    tourIds: [],
    travelByEndDate: null,
    travelByStartDate: null,
    ...(hasPromoCode && { promoCode: '' })
  };
}

function parsePromotionToState(promo) {
  return {
    ...promo,
    promoEndDate: new Date(strToAbsoluteDate(promo.promoEndDate)),
    promoStartDate: new Date(strToAbsoluteDate(promo.promoStartDate)),
    travelByEndDate: new Date(strToAbsoluteDate(promo.travelByEndDate)),
    travelByStartDate: new Date(strToAbsoluteDate(promo.travelByStartDate))
  };
}

const useStyles = makeStyles(() => ({
  deletePromoTitle: {
    margin: '0',
    width: '30vw',
    maxWidth: '100%',
    fontWeight: '700'
  },
  miniLogo: {
    margin: '0 -10px 0 10px'
  }
}));

function PromotionModal({ tours, modalData, setModalData, operationStatus }) {
  const intl = useIntl();
  const classes = useStyles();

  const [step, setStep] = useState();
  const [isNextStepDisabled, setIsNextStepDisabled] = useState();
  const [activePromoData, setActivePromoData] = useState();

  useEffect(() => {
    if (!modalData) return;

    switch (modalData.mode) {
      case MODAL_MODE.CREATE: {
        setStep(STEP.ADD_NAME);
        setIsNextStepDisabled(true);
        setActivePromoData(getInitialPromoData(modalData.hasPromoCode));
        break;
      }
      case MODAL_MODE.UPDATE: {
        setStep(STEP.ADD_NAME);
        setIsNextStepDisabled(false);
        setActivePromoData(parsePromotionToState(modalData.data));
        break;
      }
      case MODAL_MODE.DELETE: {
        setStep(STEP.DELETE);
        setIsNextStepDisabled(false);
        setActivePromoData(modalData.data);
        break;
      }
      case MODAL_MODE.DELETE_TOUR: {
        setStep(STEP.DELETE_TOUR);
        setIsNextStepDisabled(false);
        setActivePromoData(modalData.data);
        break;
      }
      default: {
        setIsNextStepDisabled(true);
      }
    }
  }, [modalData]);

  useEffect(() => {
    if (!operationStatus?.error && modalData?.isOpen) handleClose();
  }, [operationStatus]);

  const handleChange = name => eventOrValue => {
    const value = Object.prototype.hasOwnProperty.call(eventOrValue, 'target')
      ? eventOrValue.target.value
      : eventOrValue;

    setActivePromoData(activePromoData => ({
      ...activePromoData,
      [name]: value
    }));

    if (eventOrValue.key == 'Enter' && value) {
      handleNextStep();
    }
  };

  function handleClose() {
    setModalData({
      ...modalData,
      isOpen: false
    });
  }

  function handleNextStep() {
    setIsNextStepDisabled(modalData?.mode == MODAL_MODE.CREATE);
    setStep(step + 1);
  }

  function handleStepBack() {
    setIsNextStepDisabled(false);
    setStep(step - 1);
  }

  function getModalTitle() {
    switch (step) {
      case STEP.ADD_NAME:
        return intl.formatMessage(messages.namePromotionTitle);
      case STEP.SELECT_TOURS:
        return intl.formatMessage(messages.selectToursForPromo);
      case STEP.TOUR_DETAILS:
        return intl.formatMessage(messages.setPromotionDetails);
      case STEP.DELETE:
        return intl.formatMessage(messages.deletePromo);
      case STEP.DELETE_TOUR:
        return intl.formatMessage(messages.deleteTourModalTitle);
      default:
        return null;
    }
  }

  function getModalDesc() {
    switch (step) {
      case STEP.ADD_NAME:
        return intl.formatMessage(messages.internalPurposes);
      default:
        return null;
    }
  }

  function getModalBody() {
    switch (step) {
      case STEP.ADD_NAME:
        return (
          <NamePromotion
            title={activePromoData.title}
            handleChange={handleChange('title')}
            setIsNextStepDisabled={setIsNextStepDisabled}
          />
        );
      case STEP.SELECT_TOURS:
        return (
          <SelectToursForPromotion
            tours={tours}
            selectedToursIds={activePromoData.tourIds}
            handleChange={handleChange('tourIds')}
            setIsNextStepDisabled={setIsNextStepDisabled}
          />
        );
      case STEP.TOUR_DETAILS:
        return (
          <PromotionsDetails
            activePromoData={activePromoData}
            handleChange={handleChange}
            hasPromoCode={modalData.hasPromoCode}
            setIsNextStepDisabled={setIsNextStepDisabled}
          />
        );
      case STEP.DELETE:
        return <p className={classes.deletePromoTitle}>{modalData.data?.title}</p>;
      case STEP.DELETE_TOUR:
        return (
          <RemoveTourFromPromotionModal
            promotionId={modalData.data?.promotionId}
            tourId={modalData.data?.tourId}
            tourName={modalData.data?.tourName}
            removeTourFromPromo={modalData.handler}
          />
        );

      default:
        return null;
    }
  }

  function getButtonContent() {
    const isLastStep = step == STEP.TOUR_DETAILS;
    const isDeleteMode = modalData.mode === MODAL_MODE.DELETE;
    const isUpdateMode = modalData.mode === MODAL_MODE.UPDATE;

    if (isDeleteMode) {
      return <span>{intl.formatMessage(messages.delete)}</span>;
    }
    if (isLastStep) {
      if (isUpdateMode) {
        return (
          <span>
            {intl.formatMessage(messages.update)}{' '}
            <img src={MiniLogo} className={classes.miniLogo} alt="" />
          </span>
        );
      } else {
        return (
          <span>
            {intl.formatMessage(messages.schedule)}{' '}
            <img src={MiniLogo} className={classes.miniLogo} alt="" />
          </span>
        );
      }
    } else {
      return <span>{intl.formatMessage(messages.next)}</span>;
    }
  }

  function getHandlerForModalBtn() {
    if (step == STEP.TOUR_DETAILS || step == STEP.DELETE) {
      return () => {
        modalData.handler(activePromoData);
      };
    } else {
      return () => handleNextStep();
    }
  }

  if (!modalData) {
    return null;
  }

  return (
    <>
      {modalData.isOpen && (
        <CustomizableModal
          isOpen={modalData.isOpen}
          onClose={handleClose}
          withFooter={modalData.mode !== MODAL_MODE.DELETE_TOUR}
          bodyPadding={step !== STEP.SELECT_TOURS}
          handleHeaderBackButton={step == STEP.TOUR_DETAILS ? handleStepBack : null}
          handleHeaderCloseButton={handleClose}
          title={getModalTitle()}
          body={getModalBody()}
          desc={getModalDesc()}
          handleFooterCloseButton={modalData.mode === MODAL_MODE.DELETE ? handleClose : null}
          primaryActionButtonData={{
            content: getButtonContent(),
            isDisabled: isNextStepDisabled,
            onClick: getHandlerForModalBtn()
          }}
        />
      )}
    </>
  );
}

export default PromotionModal;
