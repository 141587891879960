import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Close as CloseIcon } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { Analytics } from '@analytics';
import { DialogTitle, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import RevolutIcon from '../../../assets/images/payment/RevolutIcon.svg';
import SafeAndSecure from '../../../assets/images/payment/SafeAndSecure.svg';
import { StyleBreakpoints } from '../../../utils/constants';
import messages from './messages';

import { AddCounterparty, userHasRevolut } from '../../../api/payout';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%'
  },
  root: {
    padding: '14px',
    width: '100%',
    display: 'block',
    height: 'auto'
  },
  dialogContainer: { width: '100%', maxWidth: '709px', margin: '0 auto' },
  headerIcon: {
    height: '85px',
    width: 'auto'
  },
  safeAndSecureMessaging: {
    width: 'auto',
    height: '48px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      marginTop: '10px'
    }
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '27px 26px 0 39px',
    padding: '12px 12px 0 12px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      margin: '27px 12px 0 18px',
      padding: '0'
    }
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '0',
    marginTop: '19px',
    color: '#000000'
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: '600',
    marginTop: '7px',
    color: '#555555'
  },
  dialogPaper: {
    maxWidth: '100vw',
    margin: '32px auto',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      margin: '0 auto',
      height: '100vh',
      maxHeight: 'unset'
    }
  },
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '45px 26px 0 39px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      margin: '25px 12px 0 18px',
      flexDirection: 'column-reverse',
      justifyContent: 'center'
    }
  },
  formBody: {
    margin: '0 65px 0 58px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      margin: '0 10px 0 10px'
    }
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '39px'
  },
  cancelButton: {
    color: '#a6a6a6',
    fontSize: '12px',
    marginRight: '15px',
    fontWeight: 'normal',
    height: '100%'
  },
  submitButton: {
    width: '156px',
    height: '39px'
  },
  sectionHeading: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#000000'
  },
  headingContainer: {
    marginBottom: '13px',
    marginTop: '10px',
    marginLeft: '12px'
  },
  phoneContainer: {
    fontFamily: theme.typography.fontFamily,
    '& .special-label': {
      fontSize: '12px',
      left: '124px',
      color: 'rgb(118, 119, 119)',
      [theme.breakpoints.down(StyleBreakpoints.xs)]: {
        left: '76px'
      }
    }
  },
  phoneInput: {
    paddingLeft: '14px !important',
    maxHeight: '56px',
    width: 'calc(100% - 114px) !important',
    marginLeft: '114px',
    color: 'rgb(118, 119, 119)',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      marginLeft: '66px',
      width: 'calc(100% - 66px) !important'
    }
  },
  phoneButton: {
    height: '56px',
    '& .selected-flag': {
      borderRadius: '5.5px',
      width: '104px',
      backgroundColor: '#edeff2',
      [theme.breakpoints.down(StyleBreakpoints.xs)]: {
        width: '52px'
      }
    },
    '& .arrow': {
      marginLeft: '40px',
      [theme.breakpoints.down(StyleBreakpoints.xs)]: {
        marginLeft: 'unset'
      }
    }
  },
  footerButtonBlock: {
    margin: '0 0 0 auto',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      marginBottom: '20px'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'none'
    }
  },
  formContainer: {
    overflowY: 'auto'
  }
}));

export const CustomerType = {
  Business: 'business',
  Personal: 'personal'
};

export default function RevolutWireDialogContent({ userId, isOpen, onClose, onSubmit = null }) {
  const classes = useStyles();
  const intl = useIntl();

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');

  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [bankCountry, setBankCountry] = useState('');
  const [iban, setIban] = useState('');
  const [bic, setBIC] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [currency, setCurrency] = useState('');
  const [customerType, setCustomerType] = React.useState(CustomerType.Personal);

  const nonIbanCountries = ['GB', 'US'];

  const handleSelectChange = event => {
    setCustomerType(event.target.value);
  };

  const handleSelectCurrencyChange = event => {
    setCurrency(event.target.value);
  };

  const handleSelectCountryChange = event => {
    setCountry(event.target.value);
  };
  const handleSelectBankCountryChange = event => {
    setBankCountry(event.target.value);
  };

  useEffect(() => {
    const hasRevolut = async () => {
      try {
        const data = await userHasRevolut(userId);
        if (data.exists) {
          setShowSuccess(true);
          setShowForm(true);
        }
      } catch (e) {
        setShowSuccess(false);
        setShowForm(true);
      }
    };
    // call the function
    hasRevolut().catch(console.error);
  }, []);

  const mapCounterParty = () => {
    const counterparty = {
      individual_name: {
        first_name: firstName,
        last_name: lastName
      },
      bank_country: bankCountry,
      currency: currency,
      phone: `+${phone
        .split(' ')
        .join('')
        .split('+')
        .join('')}`,
      address: {
        street_line1: addressLine1,
        street_line2: addressLine2,
        region,
        postcode: postCode,
        city,
        country
      }
    };
    if (bankCountry === 'US') {
      counterparty.account_no = accountNumber;
      counterparty.routing_number = routingNumber;
    } else if (bankCountry === 'GB') {
      counterparty.account_no = accountNumber;
      counterparty.sort_code = sortCode;
    } else {
      counterparty.iban = iban;
      counterparty.bic = bic;
    }

    return counterparty;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await AddCounterparty({ userId, counterparty: mapCounterParty() });
      setShowError(false);
      Analytics.moengageAddUserAttribute({
        hasSetupRemittance: true,
        remittanceType: 'revolut'
      });

      Analytics.track('remittance setup', { type: 'revolut' }, true);

      onSubmit && onSubmit();
      onClose();
    } catch (e) {
      console.log('Caught error sending counterparty');
      console.log(e);
      setShowError(true);
    }
  };

  const countiesEntry = {
    AT: intl.formatMessage(messages.austria),
    BE: intl.formatMessage(messages.belgium),
    BG: intl.formatMessage(messages.bulgaria),
    HR: intl.formatMessage(messages.croatia),
    CY: intl.formatMessage(messages.cyprus),
    CZ: intl.formatMessage(messages.czech),
    DK: intl.formatMessage(messages.denmark),
    EE: intl.formatMessage(messages.estonia),
    FI: intl.formatMessage(messages.finland),
    FR: intl.formatMessage(messages.france),
    DE: intl.formatMessage(messages.germany),
    GR: intl.formatMessage(messages.greece),
    HU: intl.formatMessage(messages.hungary),
    IS: intl.formatMessage(messages.iceland),
    IE: intl.formatMessage(messages.ireland),
    IT: intl.formatMessage(messages.italy),
    LV: intl.formatMessage(messages.latvia),
    LI: intl.formatMessage(messages.liechtenstein),
    LT: intl.formatMessage(messages.lithuania),
    LU: intl.formatMessage(messages.luxembourg),
    MT: intl.formatMessage(messages.malta),
    NL: intl.formatMessage(messages.netherlands),
    NO: intl.formatMessage(messages.norway),
    PL: intl.formatMessage(messages.poland),
    PT: intl.formatMessage(messages.portugal),
    RO: intl.formatMessage(messages.romania),
    SK: intl.formatMessage(messages.slovakia),
    SI: intl.formatMessage(messages.slovenia),
    ES: intl.formatMessage(messages.spain),
    SE: intl.formatMessage(messages.sweden),
    GB: intl.formatMessage(messages.uk),
    US: intl.formatMessage(messages.us)
  };

  const currencyEntry = {
    EUR: `${intl.formatMessage(messages.euroCurrency)} (€)`,
    DKK: `${intl.formatMessage(messages.danishKrone)} (DKK)`,
    ISK: `${intl.formatMessage(messages.icelandicKrona)} (ISK)`,
    NOK: `${intl.formatMessage(messages.norwegianKrone)} (NOK)`,
    PLN: `${intl.formatMessage(messages.polishZloty)} (PLN)`,
    RON: `${intl.formatMessage(messages.romanianLeu)} (RON)`,
    SEK: `${intl.formatMessage(messages.swedishKrona)} (SEK)`,
    GBP: `${intl.formatMessage(messages.gbp)} (GBP)`,
    USD: `${intl.formatMessage(messages.usd)} (USD)`
  };

  return (
    <Dialog
      classes={{
        container: classes.dialogContainer,
        paperScrollPaper: classes.dialogPaper
      }}
      onClose={() => onClose()}
      aria-labelledby="simple-dialog-title"
      open={isOpen}>
      <DialogTitle
        classes={{
          root: classes.header
        }}>
        <IconButton className={classes.headerCloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={classes.formContainer}>
        <form className={classes.root} noValidate onSubmit={handleSubmit}>
          <Grid className={classes.body} container spacing={3}>
            <Grid className={classes.headerRow} xs={12} md={12}>
              <div className={classes.titleBlock}>
                <h1 className={classes.title}>{intl.formatMessage(messages.revolut)}</h1>
                <div className={classes.subTitle}>
                  {intl.formatMessage(messages.yourRevolutDetails)}
                </div>
              </div>
              <img className={classes.headerIcon} src={RevolutIcon} alt="Cvc icon" />
            </Grid>

            <Grid container className={classes.formBody} spacing={3}>
              <Grid className={classes.headingContainer} xs={12} md={12}>
                <div className={classes.sectionHeading}>
                  {intl.formatMessage(messages.accountHolder)}
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    id="standard-select-currency"
                    labelId="demo-simple-select-label"
                    select
                    label={intl.formatMessage(messages.accountType)}
                    variant="outlined"
                    placeholder={intl.formatMessage(messages.accountType)}
                    className={classes.textField}
                    value={customerType}
                    onChange={handleSelectChange}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}>
                    <MenuItem disabled value={CustomerType.Business}>
                      {intl.formatMessage(messages.business)}
                    </MenuItem>
                    <MenuItem value={CustomerType.Personal}>
                      {intl.formatMessage(messages.personal)}
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.firstName)}
                  className={classes.textField}
                  id="ContactName"
                  label={intl.formatMessage(messages.firstName)}
                  variant="outlined"
                  onChange={e => setFirstName(e.target.value)}
                  name="firstName"
                  type="firstName"
                  autocomplete="firstName"
                  defaultValue={firstName}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.lastName)}
                  className={classes.textField}
                  id="ContactName"
                  label={intl.formatMessage(messages.lastName)}
                  variant="outlined"
                  onChange={e => setLastName(e.target.value)}
                  name="lastName"
                  type="lastName"
                  autocomplete="lastName"
                  defaultValue={lastName}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <PhoneInput
                  country="ie"
                  value="phone"
                  containerClass={classes.phoneContainer}
                  inputClass={classes.phoneInput}
                  buttonClass={classes.phoneButton}
                  dropdownClass={classes.phoneDropdown}
                  searchClass={classes.searchClass}
                  specialLabel={intl.formatMessage(messages.phone)}
                  onChange={e => {
                    setPhoneNumber(e);
                  }}
                  classes={{ containerClass: classes.container }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.address_1)}
                  className={classes.textField}
                  id="AddressLine1"
                  label={intl.formatMessage(messages.address_1)}
                  onChange={e => setAddressLine1(e.target.value)}
                  variant="outlined"
                  name="address-line1"
                  defaultValue={addressLine1}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.address_2)}
                  className={classes.textField}
                  id="AddressLine2"
                  label={intl.formatMessage(messages.address_2)}
                  onChange={e => setAddressLine2(e.target.value)}
                  variant="outlined"
                  name="address-line2"
                  type="address-line2"
                  defaultValue={addressLine2}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.city)}
                  className={classes.textField}
                  id="City"
                  label={intl.formatMessage(messages.city)}
                  onChange={e => setCity(e.target.value)}
                  variant="outlined"
                  name="locality"
                  defaultValue={city}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.stateProvince)}
                  className={classes.textField}
                  id="Region"
                  label={intl.formatMessage(messages.stateProvince)}
                  onChange={e => setRegion(e.target.value)}
                  variant="outlined"
                  name="region"
                  defaultValue={city}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  placeholder={intl.formatMessage(messages.zipCode)}
                  className={classes.textField}
                  id="PostCode"
                  label={intl.formatMessage(messages.zipCode)}
                  onChange={e => setPostCode(e.target.value)}
                  name="address-line4"
                  type="address-line4"
                  variant="outlined"
                  defaultValue={postCode}
                  onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    id="standard-select-currency"
                    labelId="demo-simple-select-label"
                    select
                    placeholder={intl.formatMessage(messages.country)}
                    label={intl.formatMessage(messages.country)}
                    variant="outlined"
                    className={classes.textField}
                    value={country}
                    onChange={handleSelectCountryChange}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}>
                    {Object.entries(countiesEntry).map(([code, countryLabel]) => {
                      return (
                        <MenuItem key={code} value={code}>
                          {countryLabel}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Divider variant="middle" />
              <Grid className={classes.headingContainer} xs={12} md={12}>
                <div className={classes.sectionHeading}>
                  {intl.formatMessage(messages.bankDetails)}
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    id="standard-select-currency"
                    labelId="demo-simple-select-label"
                    select
                    placeholder={intl.formatMessage(messages.bankCountry)}
                    label={intl.formatMessage(messages.bankCountry)}
                    variant="outlined"
                    className={classes.textField}
                    value={bankCountry}
                    onChange={handleSelectBankCountryChange}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}>
                    {Object.entries(countiesEntry).map(([code, countryLabel]) => {
                      return (
                        <MenuItem key={code} value={code}>
                          {countryLabel}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    id="standard-select-currency"
                    labelId="demo-simple-select-label"
                    select
                    variant="outlined"
                    className={classes.textField}
                    value={currency}
                    placeholder={intl.formatMessage(messages.accountCurrency)}
                    label={intl.formatMessage(messages.accountCurrency)}
                    onChange={handleSelectCurrencyChange}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }
                    }}>
                    {Object.entries(currencyEntry).map(([code, countryLabel]) => {
                      return (
                        <MenuItem key={code} value={code}>
                          {countryLabel}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              {nonIbanCountries.indexOf(bankCountry) <= -1 ? (
                <>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      className={classes.textField}
                      id="iban(IBAN)"
                      placeholder={intl.formatMessage(messages.IBAN)}
                      label={intl.formatMessage(messages.IBAN)}
                      onChange={e => setIban(e.target.value)}
                      name="iban"
                      type="iban"
                      defaultValue={iban}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      className={classes.textField}
                      id="BIC"
                      placeholder={intl.formatMessage(messages.BIC)}
                      label={intl.formatMessage(messages.BIC)}
                      onChange={e => setBIC(e.target.value)}
                      name="bic"
                      type="bic"
                      defaultValue={bic}
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : null}
              {bankCountry == 'US' ? (
                <>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      className={classes.textField}
                      id="accountnumber"
                      placeholder={intl.formatMessage(messages.accountNumber)}
                      label={intl.formatMessage(messages.accountNumber)}
                      onChange={e => setAccountNumber(e.target.value)}
                      name="accountnumber"
                      type="accountnumber"
                      defaultValue={accountNumber}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      className={classes.textField}
                      id="routingnumber"
                      placeholder={intl.formatMessage(messages.routingNumber)}
                      label={intl.formatMessage(messages.routingNumber)}
                      onChange={e => setRoutingNumber(e.target.value)}
                      name="routingnumber"
                      type="routingnumber"
                      defaultValue={routingNumber}
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : null}
              {bankCountry == 'GB' ? (
                <>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      className={classes.textField}
                      id="accountnumber"
                      placeholder={intl.formatMessage(messages.accountNumber)}
                      label={intl.formatMessage(messages.accountNumber)}
                      onChange={e => setAccountNumber(e.target.value)}
                      name="accountnumber"
                      type="accountnumber"
                      defaultValue={accountNumber}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      className={classes.textField}
                      id="sortcode"
                      placeholder={intl.formatMessage(messages.sortCode)}
                      label={intl.formatMessage(messages.sortCode)}
                      onChange={e => setSortCode(e.target.value)}
                      name="sortcode"
                      type="sortcode"
                      defaultValue={sortCode}
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : null}
              <br />
              <br />
              {showError ? (
                <Grid item xs={12}>
                  <Alert severity="error">{intl.formatMessage(messages.revolutError)}</Alert>
                </Grid>
              ) : null}
            </Grid>
            <Grid className={classes.footerRow} item xs={12} md={12}>
              <img className={classes.safeAndSecureMessaging} src={SafeAndSecure} alt="Cvc icon" />
              <div className={classes.footerButtonBlock}>
                <Button
                  className={classes.cancelButton}
                  variant="text"
                  color="secondary"
                  onClick={() => onClose()}>
                  {intl.formatMessage(messages.cancel)}
                </Button>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  type="submit"
                  value="Submit">
                  {intl.formatMessage(messages.submit)}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Dialog>
  );
}

RevolutWireDialogContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
