import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AddToursModal';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Add Tour[s] to Profile'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Select your tours'
  },
  descriptionWithUser: {
    id: `${scope}.descriptionWithUser`,
    defaultMessage: 'Select the tours for this profile'
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  }
});
