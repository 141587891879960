/*
 * AdditionalExtrasPurchase Messages
 *
 * This contains all the text for the AdditionalExtrasPurchase component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AdditionalExtrasPurchase';

export default defineMessages({
  skip: {
    id: `${scope}.skip`,
    defaultMessage: 'Skip',
  },
  completePurchase: {
    id: `${scope}.complete_purchase`,
    defaultMessage: 'Complete Purchase',
  },
  selectExtras: {
    id: `${scope}.select_extras`,
    defaultMessage: 'Select Extras',
  },
  additionalExtras: {
    id: `${scope}.additional_extras`,
    defaultMessage: 'Additional Extras',
  },
});
