export const SubscriptionPostCheckoutStatus = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  ACTIVE: 'active'
};

export const subscriptionErrorTypes = {
  subscription_failed: 'subscription_failed',
  subscribed_user_exists: 'subscribed_user_exists'
};
