import { Button, Container, Grid } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import TopNav from '../../components/Nav/TopNav';
import PromotionModal, { MODAL_MODE } from '../../components/Promotions/PromotionModal';
import ToursPromotionTable from '../../components/Promotions/ToursPromotionTable';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from '../Promotions/actions';
import reducer from '../Promotions/reducer';
import saga from '../Promotions/saga';
import messages from './messages';

const PromotionToursPage = ({
  fetchPromotionTours,
  fetchPromotionById,
  deleteTourFromPromotion,
  updatePromotion,
  operationStatus,
  toursFromPromotion = [],
  promotion,
  modalData,
  updateModalData,
  fetchTours,
  tours = [],
  locale
}) => {
  useInjectReducer({ key: 'promotions', reducer });
  useInjectSaga({ key: 'promotions', saga });

  const { promotionId } = useParams();
  const intl = useIntl();
  const history = useHistory();

  function handleUpdatePromo(promo) {
    updateModalData({
      data: promo,
      mode: MODAL_MODE.UPDATE,
      isOpen: true,
      hasPromoCode: Boolean(promo.promoCode),
      handler: updatePromotion
    });
  }

  function handleDeleteTourFromPromo(tourId, tourName) {
    updateModalData({
      data: {
        promotionId,
        tourId,
        tourName
      },
      mode: MODAL_MODE.DELETE_TOUR,
      isOpen: true,
      handler: deleteTourFromPromotion
    });
  }

  useEffect(() => {
    fetchPromotionTours(promotionId);
    fetchPromotionById(promotionId);
    fetchTours();
  }, []);

  return (
    <>
      <TopNav />
      <Container maxWidth="xl" style={{ padding: '0 24px' }}>
        <Grid container spacing={1} style={{ marginBottom: '20px' }}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              style={{ border: 'none', marginRight: '20px' }}
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push('/promotions')}>
              {intl.formatMessage(messages.backToPromotions)}
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdatePromo(promotion)}>
              {intl.formatMessage(messages.editPromotion)}
            </Button>
          </Grid>
          <PromotionModal
            modalData={modalData}
            setModalData={updateModalData}
            tours={tours}
            operationStatus={operationStatus}
          />
        </Grid>
        <ToursPromotionTable
          promotionId={promotionId}
          promotionTitle={promotion?.title}
          tours={toursFromPromotion}
          handleDeleteTourFromPromo={handleDeleteTourFromPromo}
          locale={locale}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  toursFromPromotion: state.promotions?.toursFromPromotion,
  operationStatus: state.promotions?.status,
  promotion: state.promotions?.promotion,
  modalData: state.promotions?.modalData,
  tours: state.promotions?.tours,
  locale: state.language?.locale
});

const mapDispatchToProps = dispatch => ({
  fetchPromotionTours: payload => dispatch(actions.fetchPromotionTours(payload)),
  fetchPromotionById: payload => dispatch(actions.fetchPromotionById(payload)),
  deleteTourFromPromotion: payload => dispatch(actions.deleteTourFromPromotion(payload)),
  updateModalData: payload => dispatch(actions.updateModalData(payload)),
  updatePromotion: payload => dispatch(actions.updatePromotion(payload)),
  fetchTours: () => dispatch(actions.fetchTours())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionToursPage);
