import { createAction } from '../../utils/helpers';
import * as actionTypes from './types';

export const fetchPromotions = createAction(actionTypes.FETCH_PROMOTIONS);
export const createPromotion = createAction(actionTypes.CREATE_PROMOTION);
export const updatePromotion = createAction(actionTypes.UPDATE_PROMOTION);
export const deletePromotion = createAction(actionTypes.DELETE_PROMOTION);
export const fetchTours = createAction(actionTypes.FETCH_TOURS);
export const updateModalData = createAction(actionTypes.UPDATE_MODAL_DATA);
export const fetchPromotionTours = createAction(actionTypes.FETCH_PROMOTION_TOURS);
export const deleteTourFromPromotion = createAction(actionTypes.DELETE_TOUR_FROM_PROMOTION);
export const fetchPromotionById = createAction(actionTypes.FETCH_PROMOTION_BY_ID);
export const facedError = createAction(actionTypes.ACTION_ERROR);

export const fetchPromotionsSuccess = createAction(actionTypes.FETCH_PROMOTIONS_SUCCESS);
export const updatePromotionSuccess = createAction(actionTypes.UPDATE_PROMOTION_SUCCESS);
export const createPromotionSuccess = createAction(actionTypes.CREATE_PROMOTION_SUCCESS);
export const deletePromotionSuccess = createAction(actionTypes.DELETE_PROMOTION_SUCCESS);
export const fetchToursSuccess = createAction(actionTypes.FETCH_TOURS_SUCCESS);
export const fetchPromotionToursSuccess = createAction(actionTypes.FETCH_PROMOTION_TOURS_SUCCESS);
export const deleteTourFromPromotionSuccess = createAction(
  actionTypes.DELETE_TOUR_FROM_PROMOTION_SUCCESS
);
export const fetchPromotionByIdSuccess = createAction(actionTypes.FETCH_PROMOTION_BY_ID_SUCCESS);
