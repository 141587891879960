import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.InviteMenuButton';

export default defineMessages({
  deleteInvite: {
    id: `${scope}.deleteInvite`,
    defaultMessage: 'Delete Invite'
  },
  resendInvite: {
    id: `${scope}.resendInvite`,
    defaultMessage: 'Resend Invite'
  }
});
