import axios from 'axios';
import { ApiError } from './error';

export function fetchPayments(startDate, endDate, selectedTour) {
  return axios.get('/api/v1/payment', { params: { startDate, endDate, selectedTour } });
}

export function fetchThesePayments(formatting = 'short') {
  return axios.get(`/api/v1/payments`);
}

export function markAdditionalPaymentAsFailed(intent) {
  return axios
    .post(`/api/v1/webhook/stripe`, {
      type: 'payment_intent.payment_failed',
      data: {
        object: intent
      }
    })
    .catch(ApiError.ofAndThrow);
}
