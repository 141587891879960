import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { checkInBooking } from '../../api';
import { setSnackbarStatus } from '../StatusBar/actions';
import { snackbarStatus } from '../StatusBar';
import { ApiError } from '../../api/error';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }
});

function CheckInPage({ setSnackbarStatus }) {
  const { bookingId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    checkInBooking(bookingId)
      .then(({ id, date }) => {        
          history.replace(`/manifest?bookingId=${id}&bookingDate=${date.date}`);
        }).catch(err => {
          setTimeout(() => {
            const error = ApiError.fromApiError(err);
            const message = Object.values(error.message);
            setHasError(true);
            setSnackbarStatus(snackbarStatus.error, 'Error', message);
          }, 1500);
      });
  }, []);

  return (
    <div className={classes.container}>{!hasError && <CircularProgress size={'100px'} />}</div>
  );
}

const mapDispatchToProps = dispatch => ({
  setSnackbarStatus: R.compose(
    dispatch,
    setSnackbarStatus
  )
});

export default connect(
  null,
  mapDispatchToProps
)(CheckInPage);
