import { Analytics } from '@analytics';
import { Button, CircularProgress, Grid, Popover, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Alert } from '@material-ui/lab';
import { parse, startOfDay } from 'date-fns';
import queryString from 'query-string';
import * as R from 'ramda';
import { filter, find, fromPairs, head, pick, pickBy, pipe, toPairs } from 'ramda';
import React, { Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import CheckMark from '../../assets/images/booking_widget/checkMarkGreen.svg';
import AdditionalExtrasPurchase from '../../components/AdditionalExtrasPurchase';
import BookingNotes from '../../components/BookingReports/ManualBookingModal/BookingNotes';
import BookingDaySelect from '../../components/BookingWidget/BookingDaySelect';
import BookingStartTimePicker, {
  getSelectedDateInMs,
  getTimeslotInMs,
  isTimeslotDisabled
} from '../../components/BookingWidget/BookingStartTimePicker';
import TourSummaryCard from '../../components/Common/TourSummaryCard';
import ImageHeader from '../../components/Common/TourSummaryCard/ImageHeader';
import IntermediateTourPrice from '../../components/Common/TourSummaryCard/SummaryOfCharges/IntermediateTourPrice';
import FullTourSummary from '../../components/Common/TourSummaryCard/TourSummaryInfo/FullTourSummary';
import ShortTourSummary from '../../components/Common/TourSummaryCard/TourSummaryInfo/ShortTourSummary';
import WhyTripAdmit from '../../components/Common/TourSummaryCard/WhyTripAdmitPanel';
import LanguagePicker from '../../components/LanguagePicker';
import PoweredBy from '../../components/PoweredBy';
import CompanyThemeProviderFromCustomizationSettings from '../../themes/CompanyThemeProvider';
import { StyleBreakpoints } from '../../utils/constants';
import {
  currencySign,
  date2day,
  formatMoney,
  getFormattedOffsetTime,
  getParticipantsTotalCapacityEffect,
  noop,
  parseSeasonDates,
  seasonByDate
} from '../../utils/helpers';
import { changeBookingWidgetLocale } from '../LanguageProvider/actions';
import { scheduleAvailabilityTypes } from '../TourEditor/reducer/schedule';
import ChooseCurrency from './chooseCurrency';
import ChooseParticipants, { getParticipantPrice } from './chooseParticipants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  coloredInput: {
    color: theme.palette.primary.main
  },
  secondSubmitBlock: {
    width: '100%',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none'
    }
  },
  customColor: {
    color: theme.palette.primary.main
  },
  customFont: {
    fontFamily: theme.typography.fontFamily
  },
  modalContainer: {
    maxWidth: '1220px',
    width: '100%',
    paddingTop: '0',
    margin: '0 auto'
  },
  leftSideContainer: {
    paddingTop: '0!important',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      paddingTop: '24px!important'
    }
  },
  firstHeading: {
    color: '#424242',
    margin: '4px 0',
    fontSize: '30px',
    lineHeight: '38px',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      fontSize: '39px',
      lineHeight: '56px'
    }
  },
  sectionDesc: {
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '19px',
    lineHeight: '24px',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      fontSize: '22px',
      lineHeight: '31px'
    }
  },
  inputsBlock: {
    display: 'grid',
    gridGap: '10px',
    margin: '10px 0px 16px',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      margin: '18px 0px'
    }
  },
  participantsBtn: {
    color: theme.palette.primary.main,
    marginTop: '16px',
    cursor: 'pointer',
    width: '100%'
  },
  leftSideWrapper: {
    width: '100%'
  },
  checkoutButton: {
    marginTop: '12px',
    marginBottom: '12px',
    height: '54px',
    width: '100%',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      marginTop: '16px',
      marginBottom: '16px'
    }
  },
  errorBlock: {
    marginTop: '12px',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      marginTop: '16px'
    }
  },
  bookDirectText: {
    position: 'relative',
    margin: '0 auto',
    fontSize: '16px'
  },
  bookDirectCheckMark: {
    height: '25px',
    marginLeft: '10px'
  }
}));

export function toDateString(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function getParticipantsTotalCapacityAmount(participants, activeSeason) {
  const fullParticipants = Object.entries(participants).map(([id, count]) => ({
    ...activeSeason.pricing.participants.find(p => p.title.toLowerCase() === id.toLowerCase()),
    count
  }));
  return getParticipantsTotalCapacityEffect(fullParticipants);
}

function getParticipantsCount(participants) {
  return Object.values(participants)?.reduce((sum, value) => Number(sum) + Number(value), 0);
}

function calculateTotal(activeSeason, activeSale, participants) {
  if (!activeSeason || !participants) {
    return '';
  }
  const pricing = activeSeason.pricing;

  const totalWithoutDiscount = Object.entries(participants)
    .map(
      ([id, count]) =>
        count *
        getParticipantPrice(
          pricing.participants.find(pId => pId.title.toLowerCase() === id.toLowerCase()),
          activeSeason,
          getParticipantsCount(participants)
        )
    )
    .reduce((acc, cur) => acc + cur, 0);

  const discountAmount = activeSale
    ? Math.round((totalWithoutDiscount * activeSale.discount) / 100)
    : 0;
  return totalWithoutDiscount - discountAmount;
}

export function getActiveSale(selectedDate, tourTicketingDetails) {
  if (!selectedDate || !tourTicketingDetails.currentSales?.length) return null;

  let activeSale = null;

  tourTicketingDetails.currentSales.forEach(sale => {
    const startDate = new Date(sale.travelByStartDate);
    const endDate = new Date(sale.travelByEndDate);

    const isDateOnSale =
      selectedDate.getTime() + getFormattedOffsetTime(selectedDate.getTimezoneOffset()) >=
        startDate &&
      selectedDate.getTime() + getFormattedOffsetTime(selectedDate.getTimezoneOffset()) <= endDate;

    if (isDateOnSale) {
      activeSale = sale;
    }
  });

  return activeSale;
}

export function getTakenPlaces(selectedSeason, bookedAmountPerDate, selectedDate, selectedTime) {
  if (selectedSeason?.tag === scheduleAvailabilityTypes.interval) {
    return (
      bookedAmountPerDate[getSelectedDateInMs(selectedDate)]?.[
        getTimeslotInMs(selectedDate, selectedTime)
      ]?.count || 0
    );
  } else {
    return bookedAmountPerDate[getSelectedDateInMs(selectedDate)]?.count || 0;
  }
}

class BookingWidget extends React.Component {
  //Get tour ID from query params
  //Pass along price and details to stripe checkout

  constructor(props) {
    super(props);
    const qp = this.props.manualBookingData
      ? {
          tourId: this.props.manualBookingData.tourId,
          showFreePlacesCount: this.props.manualBookingData.showFreePlacesCount,
          language: this.props.manualBookingData.language
        }
      : queryString.parse(this.props.location.search);
    const { displayDateAsSelectBox, hideCalendar } = qp;
    const date = parse(qp.date, 'dd/MM/yyyy', new Date());
    const isValidDate = !isNaN(date.getTime());
    this.state = {
      qp,
      language: qp.language || 'en',
      participantsAreDepoists: qp.participantsAreDepoists ? true : false,
      displayDateAsSelectBox,
      hideCalendar: isValidDate && hideCalendar,
      participants: {},
      extras: [],
      selectedDate: isValidDate ? date : null,
      selectedTime: null,
      isPaymentInProgress: false,
      shouldRenderExtras: false,
      buttonText: Math.random() >= 0.5 ? messages.checkAvailability : messages.checkout,
      bookDirect: Math.random() >= 0.5 ? true : false,
      ...(this.props.manualBookingData?.searchAvailabilityData ?? {}),
      shouldIgnoreCutoffTimes: !!this.props.manualBookingData,
      canOverbook: !!this.props.manualBookingData
    };
    changeBookingWidgetLocale(qp.language);

    this.analytics = Analytics;
  }

  activeSeason() {
    return seasonByDate(this.state.item.seasons, this.state.selectedDate);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.manualBookingData?.searchAvailabilityData.isStepBack) {
      return {
        ...state,
        shouldRenderExtras: props.manualBookingData.searchAvailabilityData.isStepBack
          ? props.manualBookingData.shouldRenderExtras
          : state.shouldRenderExtras
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.manualBookingData?.setSearchAvailabilityData) {
      const maybeUpdatedData = pick(
        [
          'language',
          'participants',
          'selectedDate',
          'selectedTime',
          'extras',
          'shouldRenderExtras'
        ],
        this.state
      );

      const isStateUpdated = !R.equals(
        this.props.manualBookingData.searchAvailabilityData,
        maybeUpdatedData
      );

      if (isStateUpdated) {
        this.props.manualBookingData.setSearchAvailabilityData(maybeUpdatedData);
      }
    }
  }

  async componentDidMount() {
    this.setState({ isLoaded: false });
    try {
      const saleSettings = await fetch(`/api/v1/tour/${this.state.qp.tourId}/sale-options`)
        .then(res => res.json())
        .then(res => res.data);

      let currency = this.state.qp.currency;
      if (!currency || !saleSettings.supportedCurrencies.includes(currency)) {
        currency = saleSettings.defaultCurrency ?? saleSettings.supportedCurrencies?.[0] ?? 'eur';
      }

      const newState = {
        currency: currency,
        supportedCurrencies: saleSettings.supportedCurrencies,
        customization: { ...saleSettings.customization, companyId: saleSettings.companyId },
        timezone: saleSettings.timezone
      };

      //Set up Google Tag Manager if code is set
      if (typeof saleSettings.customization.gtmCode === 'string') {
        const tagManagerArgs = {
          gtmId: saleSettings.customization.gtmCode
        };
        TagManager.initialize(tagManagerArgs);
      }

      this.setState(newState);
      await this.fetchTourDetails(newState.currency, this.state.language);
      const experiments = [];

      // We only want to set default values if there is an active season.
      if (this.activeSeason()) {
        // Set a default participant
        const firstPart = this.activeSeason().pricing.participants[0];
        const lowerCaseTitle = firstPart.title.toLowerCase();
        this.setParticipants({ [lowerCaseTitle]: 1 });
      }

      experiments.push('booking-widget-checkout-button-v1', 'booking-widget-book-direct-text-v1');
      this.analytics.register({
        'company id': saleSettings.companyId,
        'tour id': this.state.qp.tourId,
        'tour name': this.state.item.tourDetails.title,
        'booking widget button text': this.state.buttonText.defaultMessage,
        'book direct text': this.state.bookDirect
      });

      this.analytics.addExperiments(experiments);

      this.analytics.track('booking widget viewed');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({ isLoaded: true, error });
    }
    //Overwrite the height style of the body as it's interfering with rendering on client sites
    document.getElementsByTagName('BODY')[0].style.height = 'unset';
  }

  async fetchTourDetails(currency, language) {
    this.setState({ isLoaded: false });

    try {
      const { datesWithAmountOfParticipants: bookedAmountPerDate, ...tour } = await fetch(
        `/api/v1/tour/${this.state.qp.tourId}/details/ticketing?${
          language ? `language=${language}` : ''
        }&currency=${currency}`
      )
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            throw data.error;
          }
          return data.data;
        });

      tour.seasons = parseSeasonDates(tour.seasons);
      const newSeason = seasonByDate(tour.seasons, this.state.selectedDate);
      const participants = pipe(
        toPairs,
        filter(
          pipe(
            head,
            participantKey =>
              find(
                x => x.title.toLowerCase() === participantKey.toLowerCase(),
                newSeason.pricing.participants
              )
          )
        ),
        fromPairs
      )(this.state.participants);
      const bookingLanguage = language ?? tour.primaryLanguage;

      this.setState({
        isLoaded: true,
        item: tour,
        selectedSeason: newSeason,
        participants: participants,
        currency: currency,
        language: bookingLanguage,
        bookedAmountPerDate: bookedAmountPerDate,
        error: null,
        activeSale: getActiveSale(this.state.selectedDate, tour)
      });
      this.props.changeLanguage(bookingLanguage);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({ isLoaded: true, error });
    }
  }

  setSelectedDate(d) {
    const currentSelectedTimeslot = this.state.selectedTime;
    const newSeason = seasonByDate(this.state.item.seasons, d);

    //if new season have same date as previously selected - selectedTime is not null, else set it to null
    let selectedTime = null;

    if (newSeason) {
      const newSchedule = newSeason.schedule[date2day(d)];
      if (newSchedule && newSchedule.timeslots) {
        selectedTime = newSchedule.timeslots.find(
          t =>
            t.hours === currentSelectedTimeslot?.hours &&
            t.minutes === currentSelectedTimeslot?.minutes
        );
      }
    }

    const participants = newSeason
      ? pick(
          newSeason.pricing.participants.map(participant => participant.title.toLowerCase()),
          this.state.participants
        )
      : {};

    const activeSale = getActiveSale(d, this.state.item);

    this.setState(
      {
        selectedDate: d,
        selectedTime: isTimeslotDisabled({
          timeslot: selectedTime,
          season: newSeason,
          bookedAmountPerDate: this.state.bookedAmountPerDate,
          selectedDate: d,
          timezoneName: this.state.timezone?.timezoneName,
          shouldIgnoreCutoffTimes: this.state.shouldIgnoreCutoffTimes,
          canOverbook: this.state.canOverbook,
          participantsAmount: getParticipantsTotalCapacityAmount(
            this.state.participants,
            this.activeSeason()
          )
        })
          ? null
          : selectedTime,
        participants,
        selectedSeason: newSeason,
        activeSale
      },
      this.checkAvailabilityAndToggleAlert
    );

    this.analytics.register({
      'is sale date': Boolean(activeSale)
    });

    this.analytics.track('date selected', {
      date: d?.toISOString()
    });
  }

  handlePurchase() {
    if (this.checkIfAvailabilityIsEnough()) {
      if (this.state.selectedSeason.extras.length) {
        const extrasSavedDuringManualBooking = this.props.manualBookingData?.searchAvailabilityData
          .extras;
        const extrasWithCorrectCount = this.state.selectedSeason.extras.map(e => ({
          ...e,
          count: e.isMandatory ? getParticipantsCount(this.state.participants) : 0
        }));
        this.setState({
          shouldRenderExtras: true,
          extras: extrasSavedDuringManualBooking?.length
            ? extrasSavedDuringManualBooking
            : extrasWithCorrectCount,
          error: null
        });
      } else {
        this.completePurchase();
      }
    } else {
      this.displayAvailabilityIssueAlert();
    }
  }

  handleSetBookingNote(bookingNote) {
    this.setState({ bookingNote });
  }

  completePurchase({ isExtrasSkipped } = { isExtrasSkipped: false }) {
    this.notifyIframeEnlargeModal();
    const date = pickBy(Boolean, {
      date: toDateString(startOfDay(this.state.selectedDate)),
      timeslot: this.state.selectedTime
    });

    const activeSeason = this.activeSeason();
    const participants = Object.keys(this.state.participants).map(participantKey => {
      const participants = activeSeason.pricing.participants;
      const participant = participants.find(p => p.title.toLowerCase() === participantKey);
      return {
        participantId: participant?.participantId,
        count: parseInt(this.state.participants[participantKey] ?? 0),
        capacityEffect: participant?.capacityEffect
      };
    });

    const extras = this.toExtrasDto(this.state.extras);

    this.setState({ isPaymentInProgress: true }, () => {
      fetch(`/api/v1/booking/tour/${this.state.item.tourId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          participants: participants,
          date: date,
          currency: this.state.currency,
          language: this.state.language,
          extras: isExtrasSkipped ? [] : extras,
          isManualBooking: !!this.props.manualBookingData,
          ...(!!this.props.manualBookingData && { bookingNote: this.state.bookingNote })
        })
      })
        .then(response => response.json())
        .then(response => (response.error ? Promise.reject(response) : response))
        .then(data => {
          this.setState({ isPaymentInProgress: false });
          if (this.props.manualBookingData) {
            this.props.manualBookingData.setBookingId(data.data.id);
            this.props.manualBookingData.handleNextStep();
          } else {
            window.location.href =
              '/bookingproto?id=' + data.data.id + '&language=' + this.state.language;
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error:', error);
          if (error.error.message === 'not_enough_capacity') {
            const placesLeft = error.error.details.match(/\d+/);
            const localizedError = {
              details: (
                <span>
                  <FormattedMessage {...messages.participantsAmountErrorFirstPart} /> {placesLeft}{' '}
                  <FormattedMessage {...messages.participantsAmountErrorSecondPart} />
                </span>
              )
            };
            this.setState({ error: localizedError, isPaymentInProgress: false });
          } else {
            this.setState({ error: error.error, isPaymentInProgress: false });
          }
        });
    });
  }

  handleCurrencyChange(currency) {
    this.fetchTourDetails(currency, this.state.language);
    this.analytics.track('currency selected', {
      currency
    });
  }

  handleSelectedTime(st) {
    if (this.state.selectedTime) {
      this.analytics.track('time selected', {
        time: st
      });
    }

    this.setState(
      {
        selectedTime: st
      },
      this.checkAvailabilityAndToggleAlert
    );
  }

  toExtrasDto(extras) {
    return extras
      .filter(e => e.status == 'active' && e.count > 0)
      .map(e => ({
        extraId: e._id,
        count: e.count,
        price: e.price
      }));
  }

  handleExtraCountChange(id, amount) {
    const updatedExtras = this.state.extras.map(e => {
      if (e._id == id) {
        e.count = Number(amount);
      }
      return e;
    });

    this.setState({
      extras: updatedExtras
    });
  }

  setAnchorEl(el) {
    this.setState({ anchorEl: el });
  }

  checkLimitParticipants(participants) {
    const maxCapacity = this.state.selectedSeason?.pricing?.capacity || 0;
    const result = Object.entries(participants)?.reduce((acc, [name, count]) => {
      if (count > 0) {
        acc.push([name, Math.min(count, maxCapacity)]);
      }

      return acc;
    }, []);
    return Object.fromEntries(result);
  }

  setParticipants(participants) {
    const limitedParticipants = this.checkLimitParticipants(participants);
    this.setState(
      {
        participants: this.state.canOverbook ? participants : limitedParticipants
      },
      this.checkAvailabilityAndToggleAlert
    );
  }

  chooseParticipantsLabelText() {
    if (this.state.participantsAreDepoists) {
      return <FormattedMessage {...messages.chooseParticipantsDeposit} />;
    }
    return <FormattedMessage {...messages.chooseParticipants} />;
  }

  chooseParticipantsButtonText() {
    const participants = this.state.participants;

    if (Object.keys(participants).length === 0) {
      // return <FormattedMessage {...messages.chooseParticipants} />;
      return '';
    }
    return Object.entries(participants).reduce((acc, [participantName, count]) => {
      if (count > 0)
        return `${acc}${acc.length > 0 ? ', ' : ''}${capitalize(participantName)} x ${count}`;
      return acc;
    }, '');
  }

  canPurchase() {
    const hasParticipants = Object.values(this.state.participants).some(
      participantCount => participantCount > 0
    );

    const isEnoughAvailability = this.checkIfAvailabilityIsEnough();

    const activeSeason = this.activeSeason();
    return (
      isEnoughAvailability &&
      hasParticipants &&
      (this.state.selectedTime ||
        (activeSeason.tag === 'allDay' || activeSeason.tag === 'privateTour'))
    );
  }

  checkIfAvailabilityIsEnough() {
    const takenPlaces = getTakenPlaces(
      this.state.selectedSeason,
      this.state.bookedAmountPerDate,
      this.state.selectedDate,
      this.state.selectedTime
    );
    return (
      this.state.selectedSeason?.pricing.capacity - takenPlaces >=
        getParticipantsTotalCapacityAmount(this.state.participants, this.activeSeason()) ||
      this.state.canOverbook
    );
  }

  displayAvailabilityIssueAlert() {
    const firstPart = this.state.canOverbook
      ? messages.overbookWarningFirstPart
      : messages.participantsAmountErrorFirstPart;

    const secondPart = this.state.canOverbook
      ? messages.overbookWarningSecondPart
      : messages.participantsAmountErrorSecondPart;

    const takenPlaces = getTakenPlaces(
      this.state.selectedSeason,
      this.state.bookedAmountPerDate,
      this.state.selectedDate,
      this.state.selectedTime
    );

    const error = {
      details: (
        <span>
          <FormattedMessage {...firstPart} />{' '}
          {this.state.selectedSeason?.pricing.capacity - takenPlaces || 0}{' '}
          <FormattedMessage {...secondPart} />
        </span>
      )
    };
    this.setState({ error: error });
  }

  hideAvailabilityIssueAlert() {
    this.setState({ error: null });
  }

  checkAvailabilityAndToggleAlert() {
    const takenPlaces = getTakenPlaces(
      this.state.selectedSeason,
      this.state.bookedAmountPerDate,
      this.state.selectedDate,
      this.state.selectedTime
    );

    const isEnoughAvailability =
      this.state.selectedSeason?.pricing.capacity - takenPlaces >=
      getParticipantsTotalCapacityAmount(this.state.participants, this.activeSeason());

    if (isEnoughAvailability) {
      this.hideAvailabilityIssueAlert();
    } else {
      this.displayAvailabilityIssueAlert();
    }
  }

  total() {
    return calculateTotal(this.activeSeason(), this.state.activeSale, this.state.participants);
  }

  activeDateOperationalHours() {
    const activeSeason = this.activeSeason();
    const day = activeSeason.schedule[date2day(this.state.selectedDate)];
    if (activeSeason.tag !== 'allDay') {
      return null;
    }
    return R.pick(['start', 'end'], day);
  }

  participantPriceBreakdown() {
    const activeSeason = this.activeSeason();
    if (!activeSeason || !this.state.participants) {
      return [];
    }
    const participants = this.state.participants;
    const pricing = activeSeason.pricing;

    return Object.entries(participants).map(([title, count]) => ({
      title,
      count,
      price: getParticipantPrice(
        pricing.participants.find(pId => pId.title.toLowerCase() === title.toLowerCase()),
        activeSeason,
        getParticipantsCount(participants)
      )
    }));
  }

  extraPriceBreakdown() {
    const activeSeason = this.activeSeason();
    if (!activeSeason || !this.state.extras) {
      return [];
    }

    const extras = this.state.extras;

    return extras
      .map(({ title, count, price }) => ({
        title,
        count,
        price
      }))
      .filter(e => e.count);
  }

  notifyIframeEnlargeModal() {
    window.parent.postMessage(
      JSON.stringify({
        msgType: 'enlargeModal',
        tourId: this.state.qp.tourId
      }),
      //TODO: Maybe we should add target domain to the settings section?
      '*'
    );
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <CompanyThemeProviderFromCustomizationSettings {...this.state.customization}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '40vw',
              width: '60vw',
              margin: '0 auto'
            }}>
            <CircularProgress color="primary" size={45} />
          </div>
        </CompanyThemeProviderFromCustomizationSettings>
      );
    }

    if (this.state.item.tourState != 'published') {
      return (
        <div
          style={{
            backgroundColor: 'rgb(245, 247, 250)',
            overflowY: 'hidden',
            height: '275px',
            fontSize: '18px'
          }}>
          <br />
          <center>
            <FormattedMessage {...messages.tourNotAvailable} />
          </center>
        </div>
      );
    }

    if (this.state.shouldRenderExtras) {
      return (
        <CompanyThemeProviderFromCustomizationSettings {...this.state.customization}>
          <AdditionalExtrasPurchase
            extras={this.state.extras}
            currency={this.state.currency}
            participantsAmount={getParticipantsCount(this.state.participants)}
            handleCompletePurchase={this.completePurchase.bind(this)}
            handleExtraCountChange={this.handleExtraCountChange.bind(this)}
            error={this.state.error}
            renderRightHandSide={() => (
              <TourSummaryCard
                renderCardInfo={() => (
                  <FullTourSummary
                    title={this.state.item.tourDetails.title}
                    shortDescription={this.state.item.tourDetails.description}
                    duration={this.state.item.tourDetails.duration}
                    startingTime={this.state.selectedTime}
                    date={this.state.selectedDate}
                    operationalHours={this.activeDateOperationalHours()}
                  />
                )}
                renderPricingBlock={() => (
                  <IntermediateTourPrice
                    currency={this.state.currency}
                    participants={this.participantPriceBreakdown()}
                    extras={this.extraPriceBreakdown()}
                    discountPercent={this.state.activeSale?.discount}
                    isSaleDate={Boolean(this.state.activeSale)}
                  />
                )}
                renderCardTop={() => (
                  <ImageHeader
                    showImageRow={true}
                    initialImage={this.state.item.tourDetails.primaryImage}
                    images={this.state.item.tourDetails.images}
                  />
                )}
                renderLowerBlock={() => <WhyTripAdmit />}
              />
            )}
          />
        </CompanyThemeProviderFromCustomizationSettings>
      );
    }

    const MainBody = RenderMainBody;

    return (
      <CompanyThemeProviderFromCustomizationSettings {...this.state.customization}>
        <MainBody that={this} />
      </CompanyThemeProviderFromCustomizationSettings>
    );
  }
}

//TODO: unfoobar this bs ASAP
function RenderMainBody({ that }) {
  const classes = useStyles();
  const setAnchorEl = that.setAnchorEl.bind(that);
  const anchorEl = that.state.anchorEl;
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.modalContainer}>
      <Grid container spacing={6} style={{ width: '100%', margin: '0' }}>
        <Grid className={classes.leftSideContainer} item xs={12} md={7}>
          <div className={classes.leftSideWrapper}>
            <h1 className={`${classes.firstHeading} ${classes.customFont}`}>
              <FormattedMessage {...messages.bookNow} />
            </h1>

            <span className={`${classes.sectionDesc} ${classes.customFont}`}>
              <FormattedMessage {...messages.selectDateTimeCurrencyParticipants} />
            </span>

            <div className={classes.inputsBlock}>
              {!that.state.hideCalendar && (
                <div>
                  <BookingDaySelect
                    tour={that.state.item}
                    setSelectedDate={that.setSelectedDate.bind(that)}
                    selectedDate={that.state.selectedDate}
                    displayDateAsSelectBox={that.state.displayDateAsSelectBox}
                    shouldShowFreePlaces={that.state.qp.showFreePlacesCount}
                    bookedAmountPerDate={that.state.bookedAmountPerDate}
                    timezoneName={that.state.timezone?.timezoneName}
                    shouldIgnoreCutoffTimes={that.state.shouldIgnoreCutoffTimes}
                  />
                </div>
              )}

              <TextField
                multiline={true}
                label={that.chooseParticipantsLabelText()}
                value={that.chooseParticipantsButtonText()}
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: Object.keys(that.state.participants).length !== 0,
                  className: classes.coloredInput
                }}
                className={classes.participantsBtn}
                aria-describedby={id}
                variant="outlined"
                color="primary"
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}>
                <ChooseParticipants
                  currency={that.state.currency}
                  season={that.activeSeason()}
                  isSaleDate={Boolean(that.state.activeSale)}
                  discountPercent={that.state.activeSale?.discount}
                  selectedParticipants={that.state.participants}
                  participantsAmount={getParticipantsCount(that.state.participants)}
                  setParticipants={that.setParticipants.bind(that)}
                  handleClose={() => {
                    that.analytics.track('participants selected', {
                      participants: that.state.participants
                    });
                    handleClose();
                  }}
                  isManualBooking={!!that.props.manualBookingData}
                />
              </Popover>
              <BookingStartTimePicker
                season={that.activeSeason()}
                selectedDate={that.state.selectedDate}
                selectedTime={that.state.selectedTime}
                timezoneName={that.state.timezone?.timezoneName}
                handleTimeslotChange={that.handleSelectedTime.bind(that)}
                bookedAmountPerDate={that.state.bookedAmountPerDate}
                shouldShowFreePlaces={that.state.qp.showFreePlacesCount}
                participantsAmount={getParticipantsTotalCapacityAmount(
                  that.state.participants,
                  that.activeSeason()
                )}
                shouldSelectFirstAvailableTime={true}
                shouldIgnoreCutoffTimes={that.state.shouldIgnoreCutoffTimes}
                canOverbook={that.state.canOverbook}
              />
              <ChooseCurrency
                currencies={that.state.supportedCurrencies}
                selectCurrency={that.handleCurrencyChange.bind(that)}
                selectedCurrency={that.state.currency}
                total={that.total()}
              />

              {that.state.error && (
                <div className={classes.errorBlock}>
                  <Alert severity="error">{that.state.error.details}</Alert>
                </div>
              )}

              {that.props.manualBookingData && (
                <BookingNotes
                  title={<FormattedMessage {...messages.bookingNotes} />}
                  notes={that.state.bookingNote}
                  saveNotes={that.handleSetBookingNote.bind(that)}
                />
              )}

              <Button
                className={classes.checkoutButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (that.state.isPaymentInProgress) {
                    noop();
                  } else {
                    that.handlePurchase.bind(that)();
                    that.analytics.track('check availability clicked', {
                      participants: that.state.participants,
                      'total price': that.total(),
                      'start time': that.state.selectedTime,
                      currency: that.state.currency,
                      date: that.state.selectedDate?.toISOString(),
                      'total participants': getParticipantsCount(that.state.participants)
                    });
                  }
                }}
                disabled={!that.canPurchase()}>
                {that.state.isPaymentInProgress ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Fragment>
                    <FormattedMessage {...that.state.buttonText} />{' '}
                    {that.total() > 0
                      ? `${formatMoney({
                          amount: that.total(),
                          currency: that.state.currency
                        })} - ${currencySign(that.state.currency)}`
                      : ''}
                  </Fragment>
                )}
              </Button>

              {that.state.bookDirect && (
                <div className={classes.bookDirectText}>
                  <FormattedMessage {...messages.bookDirect} />
                  <img className={classes.bookDirectCheckMark} src={CheckMark} alt="" />
                </div>
              )}

              {!that.props.manualBookingData && (
                <div style={{ margin: '12px 0px' }}>
                  <LanguagePicker
                    selectedLanguage={that.state.language}
                    setSelectedLanguage={language =>
                      that.fetchTourDetails(that.state.currency, language)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TourSummaryCard
            renderCardInfo={() => (
              <ShortTourSummary
                title={that.state.item.tourDetails.title}
                shortDescription={that.state.item.tourDetails.description}
              />
            )}
            renderCardTop={() => (
              <ImageHeader
                showImageRow={true}
                initialImage={that.state.item.tourDetails.primaryImage}
                images={that.state.item.tourDetails.images}
              />
            )}
            renderLowerBlock={() => <WhyTripAdmit />}
          />
        </Grid>
        <Grid item className={classes.secondSubmitBlock}>
          <Button
            style={{ marginBottom: '16px', height: '54px', width: '100%' }}
            variant="contained"
            color="primary"
            onClick={that.state.isPaymentInProgress ? noop : that.handlePurchase.bind(that)}
            disabled={!that.canPurchase()}>
            {that.state.isPaymentInProgress ? (
              <CircularProgress color="secondary" />
            ) : (
              <Fragment>
                <FormattedMessage {...that.state.buttonText} />{' '}
                {that.total() > 0
                  ? `${formatMoney({
                      amount: that.total(),
                      currency: that.state.currency
                    })} - ${currencySign(that.state.currency)}`
                  : ''}
              </Fragment>
            )}
          </Button>
        </Grid>
      </Grid>
      <PoweredBy />
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: R.compose(
      dispatch,
      changeBookingWidgetLocale
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingWidget);
