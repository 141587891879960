import { defineMessages } from 'react-intl';

export const scope = `app.containers.OnboardingCompletionPage`;

export default defineMessages({
  orderCards: {
    id: `${scope}.orderCards`,
    defaultMessage: 'Order Cards'
  },
  setupReviews: {
    id: `${scope}.setupReviews`,
    defaultMessage: 'Setup Reviews'
  },
  inviteGuides: {
    id: `${scope}.inviteGuides`,
    defaultMessage: 'Invite Guides'
  },
  viewTippingPage: {
    id: `${scope}.viewTippingPage`,
    defaultMessage: 'View Tipping Page'
  },
  copiedToClipboard: {
    id: `${scope}.copied_to_clipboard`,
    defaultMessage: 'Copied to clipboard'
  }
});
