export const FETCH_TOURS = 'FETCH_TOURS';
export const FETCH_INTEGRATED_TOURS = 'FETCH_INTEGRATED_TOURS';
export const ADD_TOURS_TO_INTEGRATED_TOURS = 'ADD_TOURS_TO_INTEGRATED_TOURS';
export const UPDATE_INTEGRATED_TOUR = 'UPDATE_INTEGRATED_TOUR';
export const DELETE_INTEGRATED_TOUR = 'DELETE_INTEGRATED_TOUR';
export const CLEAN_VALIDATION_ERRORS = 'CLEAN_VALIDATION_ERRORS';
export const SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN';

export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';
export const FETCH_INTEGRATED_TOURS_SUCCESS = 'FETCH_INTEGRATED_TOURS_SUCCESS';
export const ADD_TOURS_TO_INTEGRATED_TOURS_SUCCESS = 'ADD_TOURS_TO_INTEGRATED_TOURS_SUCCESS';
export const UPDATE_INTEGRATED_TOUR_SUCCESS = 'UPDATE_INTEGRATED_TOUR_SUCCESS';
export const DELETE_INTEGRATED_TOUR_SUCCESS = 'DELETE_INTEGRATED_TOUR_SUCCESS';
export const TOURS_VALIDATION_FAILED = 'TOURS_VALIDATION_FAILED';
