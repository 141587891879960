import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AddToursTable';

export default defineMessages({
  tourTitle: {
    id: `${scope}.tour_title`,
    defaultMessage: 'Tours on Profile'
  }
});
