import { defineMessages } from 'react-intl';

const scope = `app.containers.ResetPasswordPage`;

export default defineMessages({
  defaultInfoText: {
    id: `${scope}.defaultInfoText`,
    defaultMessage:
      'Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric & 1 Special Character(@$!%*?&#-).'
  },
  passwordsDoNotMatch: {
    id: `${scope}.passwordsDoNotMatch`,
    defaultMessage: 'Your passwords do not match, please try again'
  },
  passwordDoesNotMatchGuide: {
    id: `${scope}.passwordDoesNotMatchGuide`,
    defaultMessage:
      'Password does not match guidelines - Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric & 1 Special Character(@$!%*?&#-).'
  },
  resetPasswordTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Reset Password'
  },
  tokenNotFound: {
    id: `${scope}.tokenNotFound`,
    defaultMessage: 'Token is not found'
  },
  newPasswordLabel: {
    id: `${scope}.newPasswordLabel`,
    defaultMessage: 'New Password'
  },
  repeatPasswordLabel: {
    id: `${scope}.repeatPasswordLabel`,
    defaultMessage: 'Repeat Password'
  }
});
