import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.LinkInformation';

export default defineMessages({
  downloadImage: {
    id: `${scope}.download_image`,
    defaultMessage: 'Download Image'
  },
  copiedToClipboard: {
    id: `${scope}.copied_to_clipboard`,
    defaultMessage: 'Copied to clipboard'
  },
  orderCards: {
    id: `${scope}.orderCards`,
    defaultMessage: 'Order Cards'
  },
  orderCardsDesc: {
    id: `${scope}.orderCardsDesc`,
    defaultMessage: 'Need additional Tip Direct cards? Order them through our website.'
  },
  personalCodeTitle: {
    id: `${scope}.personalCodeTitle`,
    defaultMessage: 'Personal QR Code'
  },
  personalCodeDesc: {
    id: `${scope}.personalCodeDesc`,
    defaultMessage:
      'Using your QR code you can still accept digital tips if you forget or lose your Tip Direct card.'
  },
  personalLinkTitle: {
    id: `${scope}.personalLinkTitle`,
    defaultMessage: 'Personal Tipping Link'
  },
  personalLinkDesc: {
    id: `${scope}.personalLinkDesc`,
    defaultMessage:
      'Share your unique link with customers via text or email to provide other options for them to leave tips.'
  }
});
