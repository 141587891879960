/*
 * AvailabilityBuilder Messages
 *
 * This contains all the text for the AvailabilityBuilder component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.AvailabilityBuilder';

export default {
  scheduleAllDayBuilder: defineMessages({
    unavailable: {
      id: `${scope}.schedule_all_day_builder.unavailable`,
      defaultMessage: 'unavailable'
    }
  }),
  placeholders: defineMessages({
    selectSeason: {
      id: `${scope}.placeholders.select_season`,
      defaultMessage: 'Select season to edit or create a new one'
    },
    enterSeasonDetails: {
      id: `${scope}.placeholders.enter_season_details`,
      defaultMessage: 'Enter season details'
    },
    createNewSeason: {
      id: `${scope}.placeholders.create_new_season`,
      defaultMessage: 'Create New Season'
    },
    duplicateSeason: {
      id: `${scope}.placeholders.duplicate_season`,
      defaultMessage: 'Duplicate Season'
    }
  }),
  steps: defineMessages({
    setTitle: {
      id: `${scope}.steps.set_title`,
      defaultMessage: 'Set Title'
    },
    monthsAvailable: {
      id: `${scope}.steps.months_available`,
      defaultMessage: 'Months Available'
    },
    daysUnavailable: {
      id: `${scope}.steps.days_unavailable`,
      defaultMessage: 'Days Unavailable'
    },
    seasonType: {
      id: `${scope}.steps.season_type`,
      defaultMessage: 'Schedule type'
    },
    schedulePreset: {
      id: `${scope}.steps.schedule_preset`,
      defaultMessage: 'Configure Schedule Preset'
    },
    tourSchedule: {
      id: `${scope}.tour_schedule.start_date`,
      defaultMessage: 'Configure Tour Schedule'
    },
    cutoffTimes: {
      id: `${scope}.steps.cutoff_times`,
      defaultMessage: 'Tour Cut Off Time [Optional]'
    }
  }),
  titleInput: defineMessages({
    label: {
      id: `${scope}.title.label`,
      defaultMessage: 'Title'
    },
    placeholder: {
      id: `${scope}.title.placeholder`,
      defaultMessage: 'example: High Season'
    }
  }),
  scheduleBuilder: {
    placeholders: defineMessages({
      header: {
        id: `${scope}.schedule_builder.placeholders.header`,
        defaultMessage: 'Apply a preset to timeslots'
      },
      applyPreset: {
        id: `${scope}.schedule_builder.placeholders.applyPreset`,
        defaultMessage: 'Apply preset'
      }
    }),
    everyHour: defineMessages({
      start: {
        id: `${scope}.schedule_builder.hourly.start`,
        defaultMessage: 'This tour should start, every hour at'
      },
      pastHour: {
        id: `${scope}.schedule_builder.hourly.past_hour`,
        defaultMessage: 'past hour'
      }
    }),
    everyX: defineMessages({
      start: {
        id: `${scope}.schedule_builder.everyX.start`,
        defaultMessage: 'This tour should start every'
      }
    }),
    manual: defineMessages({
      start: {
        id: `${scope}.schedule_builder.manual`,
        defaultMessage: 'I want to manually configure tour starting times'
      }
    })
  },
  scheduleType: defineMessages({
    interval: {
      id: `${scope}.schedule_builder.interval`,
      defaultMessage: 'Starting Times'
    },
    allDay: {
      id: `${scope}.schedule_builder.allDay`,
      defaultMessage: 'All day tour (Opening and closing time)'
    },
    privateTour: {
      id: `${scope}.schedule_builder.privateTour`,
      defaultMessage: "Private tour (Once a tour is booked it's taken off sale for that date)"
    }
  }),
  startingTimes: defineMessages({
    addNewTimeslot: {
      id: `${scope}.starting_times.add_new_timeslot`,
      defaultMessage: '+ Add new timeslot'
    }
  }),
  calendar: defineMessages({
    ok: {
      id: `${scope}.ok`,
      defaultMessage: 'Ok'
    },
    cancel: {
      id: `${scope}.cancel`,
      defaultMessage: 'Cancel'
    },
    endDate: {
      id: `${scope}.endDate`,
      defaultMessage: 'End Date'
    },
    excludedDays: {
      id: `${scope}.excludedDays`,
      defaultMessage: 'Excluded Days'
    }
  })
};
