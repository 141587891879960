import { defineMessages } from 'react-intl';

export const scope = `app.containers.TicketPage`;

export default defineMessages({
  dayPlural: {
    id: `${scope}.day_plural`,
    defaultMessage: 'days'
  },
  daySingular: {
    id: `${scope}.day_singular`,
    defaultMessage: 'day'
  },
  hourPlural: {
    id: `${scope}.hour_plural`,
    defaultMessage: 'hours'
  },
  hourSingular: {
    id: `${scope}.hour_single`,
    defaultMessage: 'hour'
  },
  minutePlural: {
    id: `${scope}.minute_plural`,
    defaultMessage: 'minutes'
  },
  minuteSingular: {
    id: `${scope}.minute_singular`,
    defaultMessage: 'minute'
  },
  bookedBy: {
    id: `${scope}.booked_by`,
    defaultMessage: 'Booked by'
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date & Time'
  },
  startingTime: {
    id: `${scope}.starting_time`,
    defaultMessage: 'Starting Time'
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duration'
  },
  bookingreference: {
    id: `${scope}.bookingreference`,
    defaultMessage: 'Booking Reference'
  },
  accessability: {
    id: `${scope}.accessability`,
    defaultMessage: 'Accessibillity'
  },
  accessabilityYes: {
    id: `${scope}.accessability_yes`,
    defaultMessage: 'Yes'
  },
  accessabilityNo: {
    id: `${scope}.accessability_no`,
    defaultMessage: 'No'
  },
  cancellationPolicy: {
    id: `${scope}.cancellation_policy`,
    defaultMessage: 'Cancellation Policy'
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'Language'
  },
  operationalHours: {
    id: `${scope}.operational_hours`,
    defaultMessage: 'Operational Hours'
  },
  meetingPoint: {
    id: `${scope}.meeting_point`,
    defaultMessage: 'Meeting point'
  },
  experienceDetails: {
    id: `${scope}.experience_details`,
    defaultMessage: 'Experience details'
  },
  highlights: {
    id: `${scope}.highlights`,
    defaultMessage: 'Highlights'
  },
  includes: {
    id: `${scope}.includes`,
    defaultMessage: 'Includes'
  },
  excludes: {
    id: `${scope}.excludes`,
    defaultMessage: 'Excludes'
  },
  importantInformation: {
    id: `${scope}.important_information`,
    defaultMessage: 'Important infrotmation'
  },
  needHelp: {
    id: `${scope}.need_help`,
    defaultMessage: 'Need help?'
  },
  helpInfo: {
    id: `${scope}.help_info`,
    defaultMessage:
      'For all queries about this activity, or, if you wish to modify your booking please contact the activity provider.'
  },
  activityProviderName: {
    id: `${scope}.activity_provider_name`,
    defaultMessage: 'Activity Provider'
  },
  activityProviderPhone: {
    id: `${scope}.activity_provider_phone`,
    defaultMessage: 'Phone'
  },
  activityProviderEmail: {
    id: `${scope}.activity_provider_email`,
    defaultMessage: 'Email'
  },
  supplierRequestedInfromation: {
    id: `${scope}.supplier_requested_information`,
    defaultMessage: 'Requested information'
  },
  participants: {
    id: `${scope}.participants`,
    defaultMessage: 'Participants'
  },
  additionalExtras: {
    id: `${scope}.additional_extras`,
    defaultMessage: 'Additional Extras'
  },
  paymentOnArrival: {
    id: `${scope}.paymentOnArrival`,
    defaultMessage: 'Payment due on arrival'
  }
});
