import { defineMessages } from 'react-intl';

export const scope = 'app.components.TourAggregate';

export default defineMessages({
  showMore: {
    id: `${scope}.showMore`,
    defaultMessage: 'Show More'
  }
});
