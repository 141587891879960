import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import NavLink from '../NavLink';

const useStyles = makeStyles({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    borderBottom: 'solid 1px #ececec',
    paddingTop: '16px',
    paddingBottom: '12px'
  },
  title: {
    marginLeft: 24,
    color: 'black',
    fontWeight: 'bold'
  },
  menuItemImg: {
    width: '56px',
    height: '56px'
  }
});

export function MenuItem({ imgSrc, title, link }) {
  const classes = useStyles();

  return (
    <div className={classes.menuItem}>
      <NavLink style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }} link={link}>
        {imgSrc && <img className={classes.menuItemImg} src={imgSrc} alt={title} />}
        <span className={classes.title}>{title}</span>
      </NavLink>
    </div>
  );
}

export default MenuItem;
