import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info as InfoIcon } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { StyleBreakpoints } from '../../../utils/constants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: 8,
    width: '100%',
    minWidth: '250px',
    '& label.Mui-focused': {
      fontWeight: 600,
      color: '#6b6b6b'
    }
  },
  validationErrorMessage: {
    color: 'red'
  },
  saveButton: {
    backgroundColor: '#6997ff',
    color: '#fff',
    marginBottom: 16,
    padding: '8px 24px',
    '&:hover:not($disabled):not($focused):not($error)': {
      backgroundColor: '#87acff'
    }
  },
  fieldLabel: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left'
  },
  infoIcon: {
    marginLeft: '5px',
    color: theme.palette.primary.main,
    width: '20px',
    height: '20px'
  },
  inputContainer: {
    marginTop: '10px'
  },
  step: {
    marginTop: '20px'
  },
  buttonContainer: {
    marginTop: '20px'
  },
  stepLabel: {
    fontSize: '14px',
    fontWeight: '500'
  },
  businessStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '25px 15px',
    margin: '18px 20px 18px auto',
    borderRadius: '11px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      minWidth: '347px'
    }
  }
}));

const businessStatusSchema = yup.object({
  businessStatus: yup.string().required(),
  taxNumber: yup.string()
});

const BusinessStatusField = ({ mergedProfile, handleSubmit, validateSchema }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.businessStatusContainer}>
      <Formik
        initialValues={mergedProfile}
        validateOnChange={false}
        validate={validateSchema(businessStatusSchema)}
        validateOnBlur={false}
        onSubmit={handleSubmit}>
        {props => (
          <Form>
            <div>
              <div
                className={classes.step}
                key={intl.formatMessage(messages.businessStatus)}
                active={true}>
                <div className={classes.fieldLabel}>
                  <div className={classes.stepLabel}>
                    {intl.formatMessage(messages.businessStatus)}
                  </div>
                  <Tooltip
                    enterTouchDelay={0}
                    placement="bottom-end"
                    title={intl.formatMessage(messages.businessStatusTooltip)}>
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
                <div>
                  <Typography>
                    <Field name="businessStatus">
                      {({ field, form, meta }) => (
                        <Typography className={classes.inputContainer}>
                          <RadioGroup
                            aria-labelledby="businessStatus"
                            name="businessStatus"
                            {...field}>
                            <FormControlLabel
                              value="business"
                              control={<Radio />}
                              label={intl.formatMessage(messages.business)}
                            />
                            <FormControlLabel
                              value="soleTrader"
                              control={<Radio />}
                              label={intl.formatMessage(messages.soleTrader)}
                            />
                            <FormControlLabel
                              value="individual"
                              control={<Radio />}
                              label={intl.formatMessage(messages.individual)}
                            />
                          </RadioGroup>
                        </Typography>
                      )}
                    </Field>
                    {props.errors.businessStatus && (
                      <div className={classes.validationErrorMessage}>
                        {props.errors.businessStatus}
                      </div>
                    )}
                  </Typography>
                </div>
              </div>
              <div
                className={classes.step}
                key={intl.formatMessage(messages.taxNumber)}
                active={true}>
                <div className={classes.fieldLabel}>
                  <div className={classes.stepLabel}>{intl.formatMessage(messages.taxNumber)}</div>
                  <Tooltip
                    enterTouchDelay={0}
                    placement="bottom-end"
                    title={intl.formatMessage(messages.taxNumberTooltip)}>
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
                <div>
                  <Typography>
                    <Field name="taxNumber">
                      {({ field, form, meta }) => (
                        <Typography className={classes.inputContainer}>
                          <TextField
                            className={classes.textField}
                            label={intl.formatMessage(messages.taxNumberLabel)}
                            variant="outlined"
                            type="text"
                            name="taxNumber"
                            {...field}
                          />
                        </Typography>
                      )}
                    </Field>
                    {props.errors.taxNumber && (
                      <div className={classes.validationErrorMessage}>{props.errors.taxNumber}</div>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button type="submit" className={classes.saveButton}>
                {intl.formatMessage(messages.saveChanges)}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BusinessStatusField;
