export const TransactionStatusType = {
  pending: 'Pending',
  success: 'Success',
  failed: 'Failed'
};

export const TransactionStatusBackgroundColor = {
  pending: '#555555',
  success: '#d9ffd7',
  failed: '#ffccd8'
};

export const TransactionStatusForeColor = {
  pending: '#eaeaea',
  success: '#12573e',
  failed: '#fe424d'
};
