import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Fragment } from 'react';

const useStylesImageHeader = makeStyles(() => ({
    cardImage: {
        paddingTop: '56.25%',
        height: 0
    }
}));

const useImageRowsStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        '&::hover': {
            overflow: 'scroll-y'
        },
        marginTop: '15px'
    },
    image: {
        width: '80px',
        height: '80px',
        objectFit: 'cover',
        borderRadius: '3px',
        margin: '0 15px',
        cursor: 'pointer'
    }
}));

export function OtherImages({ images, selectImage }) {
    const classes = useImageRowsStyles();
    return <div className={classes.container}>
        {
            images.map(image => <img
                key={image}
                src={image}
                className={classes.image}
                onClick={() => selectImage(image)}
            />)
        }
    </div>
}

export default function ImageHeader({
    images,
    initialImage,
    showImageRow
}) {

    const classes = useStylesImageHeader();

    const [selectedImage, setSelectedImage] = React.useState(initialImage);
    const [notSelectedImages, setNotSelectedImages] = React.useState(
        Array.isArray(images) ? images.filter(x => x !== selectedImage) : []
    );

    const handleImageSelect = newImage => {
        const newNotSelected = notSelectedImages.filter(x => x !== newImage).concat(selectedImage);
        setSelectedImage(newImage);
        setNotSelectedImages(newNotSelected);
    }

    return (
        <Fragment>
            <CardMedia
                className={classes.cardImage}
                image={selectedImage}
            />
            {
                (
                    showImageRow &&
                    notSelectedImages.length > 0 &&
                    <OtherImages images={notSelectedImages} selectImage={handleImageSelect} />
                )
            }
        </Fragment>
    )
}