import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/tip-direct-tours';

export const fetchActiveTours = () => {
  return axios
    .get(url)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const addTapToTipTour = payload => {
  return axios
    .post(url, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const editTapToTipTour = ({ id, ...payload }) => {
  return axios
    .put(`${url}/${id}`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
