import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
    separator: {
        margin: '15px 0 20px 0',
        border: 'solid 1px #d7d7d7',
        background: '#d7d7d7',
    },
    card: {
        height: 'fit-content',
        width: '100%',
        borderRadius: '10px'
    }
}));


export default function TourSummaryCard({
    rootClass,
    renderCardTop,
    renderCardInfo,
    renderPricingBlock,
    renderLowerBlock
}) {
    const classes = useStyles();

    return (
        <Card className={`${rootClass ?? ''} ${classes.card}`.trimStart()}>
            {renderCardTop()}
            <CardContent>
                {renderCardInfo()}
                {typeof renderPricingBlock === 'function' &&
                    <Fragment>
                        <hr className={classes.separator} />
                        {renderPricingBlock()}
                    </Fragment>
                }
                {typeof renderLowerBlock === 'function' &&
                    <Fragment>
                        <hr className={classes.separator} />
                        {renderLowerBlock()}
                    </Fragment>
                }
            </CardContent>
        </Card>
    );
}