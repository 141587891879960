import { call, put, takeLatest } from '@redux-saga/core/effects';
import { fetchToursAggregate, fetchTourSaleOptionsByCompanyId } from '../../../api';
import * as actions from './actions';
import * as types from './types';

function* fetchToursAggregateWorker({ payload: { companyId, page, perPage, language } }) {
  try {
    yield put(
      actions.fetchToursAggregateInit({
        resetList: page === 0
      })
    );

    const { tours, metadata } = yield call(fetchToursAggregate, {
      companyId,
      page,
      perPage,
      language
    });

    yield put(
      actions.fetchToursAggregateSuccess({
        canLoadMore: metadata.total - (page + 1) * perPage > 0,
        page,
        data: tours
      })
    );

    const result = yield call(fetchTourSaleOptionsByCompanyId, companyId);
    yield put(actions.fetchSaleOptionsSuccess(result ));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    yield put(
      actions.fetchToursAggregateSuccess({
        canLoadMore: false,
        page: 0,
        data: []
      })
    );
  }
}

export default function* channelManagerWatcher() {
  yield takeLatest(types.FETCH_TOURS_AGGREGATE, fetchToursAggregateWorker);
}
