import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import messages from './messages';
import { CheckCircleRounded as CheckCircleRoundedIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { Fragment } from 'react';
import tourRecap from '../../../../assets/images/booking_widget/poweredByStripe/TourRecap_TrustSignals.svg';

const useStyles = makeStyles(theme => ({
  checkMark: {
    color: theme.palette.primary.main
  },
  item: {
    padding: 'unset'
  },
  title: {
    fontWeight: 'bolder',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px'
  },
  tourRecap: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  }
}));

export default function WhyTripAdmit() {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.title}>{intl.formatMessage(messages.whyBookDirect)}</div>
      <List component="nav">
        <ListItem className={classes.item}>
          <ListItemIcon>
            <CheckCircleRoundedIcon className={classes.checkMark} />
          </ListItemIcon>
          <ListItemText primary={intl.formatMessage(messages.securityGuaranteed)} />
        </ListItem>
        <ListItem className={classes.item}>
          <ListItemIcon>
            <CheckCircleRoundedIcon className={classes.checkMark} />
          </ListItemIcon>
          <ListItemText primary={intl.formatMessage(messages.immediateConfirmation)} />
        </ListItem>
        <ListItem className={classes.item}>
          <ListItemIcon>
            <CheckCircleRoundedIcon className={classes.checkMark} />
          </ListItemIcon>
          <ListItemText primary={intl.formatMessage(messages.lowestPriceGuarantied)} />
        </ListItem>
      </List>
      <List className={classes.tourRecap}>
        <img src={tourRecap} width="100%" alt="Tour Recap Trust Signals" />
      </List>
    </Fragment>
  );
}
