import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/channel-manager/product-references';

export const fetchIntegratedTours = () => {
  return axios
    .get(url)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const addIntegratedTours = newIntegratedTours => {
  return axios
    .post(url, newIntegratedTours)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const updateIntegratedTour = (updatedIntegratedTour) => {
  return axios
    .put(url, updatedIntegratedTour)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const deleteIntegratedTour = integratedTourId => {
  axios
    .delete(`${url}/${integratedTourId}`)
};