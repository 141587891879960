import { Analytics } from '@analytics';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles({
  manageButton: {
    marginRight: 5
  }
});

const InviteMenuButton = ({ inviteId, revokeInvite, sendInvite, inviteeEmail }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleOpenDropDown = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.manageButton}
        style={{ backgroundColor: '#fff' }}
        color="#fff"
        variant="contained"
        onClick={handleOpenDropDown}>
        <MoreHorizIcon />
      </Button>
      <Menu
        style={{ marginTop: '50px' }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropDown}>
        <MenuItem
          onClick={() =>
            sendInvite({ emails: [inviteeEmail], isResend: true, isRecommendationEmail: false })
          }>
          <FormattedMessage {...messages.resendInvite} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            Analytics.track('revoke invite');
            revokeInvite(inviteId);
          }}>
          <FormattedMessage {...messages.deleteInvite} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default InviteMenuButton;
