const prefix = 'tour_editor';

export const Constants = {
    editor: {
        INIT_TOUR_EDITOR_SAGA: `${prefix}/editor/INIT_TOUR_EDITOR_SAGA`,

        PERFORM_UNSAFE_OPERATION: `${prefix}/editor/PERFORM_UNSAFE_OPERATION`,
        CONFIRM_UNSAFE_OPERATION: `${prefix}/editor/CONFIRM_UNSAFE_ACTION`,
        CANCEL_UNSAFE_OPERATION: `${prefix}/editor/CANCEL_UNSAFE_ACTION`,
        
        PUBLISH_TOUR: `${prefix}/editor/PUBLISH_TOUR`,
        UNPUBLISH_TOUR: `${prefix}/editor/UNPUBLISH_TOUR`,

        SET_STEP: `${prefix}/editor/SET_STEP`,
        SET_HAS_UNSAVED_CHANGES: `${prefix}/editor/SET_HAS_UNSAVED_STATE`,
        LOAD_TOUR_INIT: `${prefix}/editor/INIT_TOUR_LOADING`,
        LOAD_TOUR_SUCCESS: `${prefix}/editor/LOAD_TOUR_SUCCESS`,
        LOAD_TOUR_ERROR: `${prefix}/editor/LOAD_TOUR_ERROR`,
        SHOW_USAVED_CONFIRMATION: `${prefix}/editor/SHOW_UNSAVED_CONFIRMATION`,
        SET_TOUR_PUBLISHED_STATUS: `${prefix}/tour/SET_TOUR_PUBLISHED_STATUS`,
    },
    tour: {
        INIT_DETAILS_EDITOR: `${prefix}/tour/INIT_DETAILS_EDITOR`,
        SEND_UPDATE_DETAILS_DEBOUNCED: `${prefix}/tour/SEND_UPDATE_DETAILS_DEBOUNCED`,
        SEND_UPDATE_DETAILS_IMMEDIATE: `${prefix}/tour/SEND_UPDATE_DETAILS_IMMEDIATE`,
        SET_TOUR_IMAGES: `${prefix}/tour/SET_TOUR_IMAGES`,
        SET_TOUR_DETAILS: `${prefix}/tour/SET_TOUR_DETAILS`,
        UPLOAD_TOUR_IMAGES: `${prefix}/tour/UPLOAD_TOUR_IMAGES`
    },
    schedule: {
        SET_TOUR_SEASONS: `${prefix}/schedule/SET_SCHEDULE`,
        SET_ACTIVE_SEASON: `${prefix}/schedule/SET_ACTIVE_SEASON`,
        CREATE_NEW_SEASON: `${prefix}/schedule/CREATE_NEW_SEASON`,
        SET_STEP: `${prefix}/schedule/SET_STEP`,
        UPDATE_ACTIVE_SEASON: `${prefix}/schedule/UPDATE_ACTIVE_SEASON`,
        APPLY_SCHEDULE_PRESET: `${prefix}/schedule/APPLY_SCHEDULE_PRESET`,
        UPDATE_SCHEDULE_PRESET_STATE: `${prefix}/schedule/UPDATE_SCHEDULE_PRESET_STATE`,
        SAVE_SEASON: `${prefix}/schedule/SAVE_SEASON`,
        RESET_ACTIVE_SCHEDULE: `${prefix}/schedule/RESET_ACTIVE_SCHEDULE`
    },
    pricings: {
        SET_PRICINGS: `${prefix}/pricing/SET_PRICINGS`,
        SET_IS_ACTIVE_PRICING_PRIVATE: `${prefix}/pricing/SET_IS_ACTIVE_PRICING_PRIVATE`,
        SET_PRICING_STEP: `${prefix}/pricing/SET_PRICING_STEP`,
        SET_ACTIVE_PRICING: `${prefix}/pricing/SET_ACTIVE_PRICING`,
        UPDATE_ACTIVE_PRICING: `${prefix}/pricing/UPDATE_ACTIVE_PRICING`,
        SAVE_PRICING: `${prefix}/pricing/SAVE_PRICING`
    },
}

export default Constants;