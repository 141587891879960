import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { lensPath, pipe, set } from 'ramda';
import React from 'react';
import { StyleBreakpoints } from '../utils/constants';

const customizeMuiTheme = ({ primaryColorHex, secondaryColorHex, font }) => {
  let customTheme = {};
  if (primaryColorHex) {
    customTheme = pipe(
      set(lensPath(['palette', 'primary']), { main: primaryColorHex }),
      set(lensPath(['palette', 'info']), { main: primaryColorHex })
    )(customTheme);
  }

  if (secondaryColorHex) {
    customTheme = set(lensPath(['palette', 'secondary']), { main: secondaryColorHex }, customTheme);
  }

  if (font) {
    const fontFamily = font;
    customTheme = set(lensPath(['typography', 'fontFamily']), fontFamily, customTheme);
  } else {
    const montserratFont = 'Montserrat, Arial';
    customTheme = set(lensPath(['typography', 'fontFamily']), montserratFont, customTheme);
  }

  const newTheme = createMuiTheme({
    ...customTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: +StyleBreakpoints.md,
        lg: +StyleBreakpoints.lg,
        xl: 1536
      }
    }
  });

  return newTheme;
};

const useStyles = makeStyles(theme => ({
  themeProvider: {
    fontFamily: theme.typography.fontFamily
  }
}));

function ThemeApplier({ children }) {
  const classes = useStyles();
  return <div className={classes.themeProvider}>{children}</div>;
}

export default function CompanyThemeProviderFromCustomizationSettings({
  primaryColorHex,
  secondaryColorHex,
  fontUrl,
  companyId,
  children
}) {
  const [font, setFont] = React.useState(null);

  React.useEffect(() => {
    if (!fontUrl) {
      return;
    }
    try {
      const fontFace = `Custom-company-font-${companyId}`;
      const font = new FontFace(fontFace, `url(${fontUrl})`, {
        style: 'normal',
        weight: 400
      });
      font
        .load()
        .then(loadedFace => {
          document.fonts.add(loadedFace);
          setFont(fontFace);
        })
        .catch(err => console.error('Failed to load custom font: ' + err.message));
    } catch (err) {
      console.error('Failed to load custom font: ' + err.message);
    }
  }, [fontUrl]);

  const injectedTheme = React.useMemo(
    () => customizeMuiTheme({ primaryColorHex, secondaryColorHex, font }),
    [primaryColorHex, secondaryColorHex, font]
  );

  return (
    <ThemeProvider theme={injectedTheme}>
      <ThemeApplier>{children}</ThemeApplier>
    </ThemeProvider>
  );
}
