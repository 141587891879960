import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/promotion';

export const fetchAvailablePromotions = () => {
  return axios
    .get(url)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const createPromotion = promoDto => {
  return axios
    .post(url, promoDto)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const updatePromotion = (promoDto, promoId) => {
  return axios
    .put(`${url}/${promoId}`, promoDto)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const deletePromotion = promoId => {
  return axios
    .post(`${url}/${promoId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchPromotionTours = promotionId => {
  return axios
    .get(`${url}/tours-info?promotionId=${promotionId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const deleteTourFromPromo = (promotionId, tourId) => {
  return axios
    .post(`${url}/${promotionId}/tour/${tourId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchPromotionById = promotionId => {
  return axios
    .get(`${url}/${promotionId}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
