import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import AlertIcon from '../../assets/images/Alert.svg';
import AttentionIcon from '../../assets/images/Attention_Required.svg';
import SuccessIcon from '../../assets/images/Success.svg';

export const ResetPasswordInfoStatus = {
  DEFAULT: 'DEFAULT',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
};

export const ResetPasswordInfoIcon = {
  DEFAULT: AttentionIcon,
  ERROR: AlertIcon,
  SUCCESS: SuccessIcon
};

const ResetPasswordInfoBackgroundColor = {
  DEFAULT: '#f8f8f8',
  ERROR: '#fdecea',
  SUCCESS: '#caffbf'
};

export const ResetPasswordInfoFontColor = {
  DEFAULT: '#000',
  ERROR: '#611a15',
  SUCCESS: '#247321'
};

const useStyles = makeStyles({
  container: ({ status }) => ({
    display: 'grid',
    gridTemplateColumns: '20% 80%',
    backgroundColor: `${ResetPasswordInfoBackgroundColor[status]}`,
    fontWeight: 500,
    borderRadius: '2px'
  }),
  text: ({ status }) => ({
    color: ResetPasswordInfoFontColor[status],
    fontSize: '12px'
  }),
  imgBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '25px'
  }
});

const ResetPasswordInfo = ({ text, status }) => {
  const classes = useStyles({ status });

  return (
    <div className={classes.container}>
      <div className={classes.imgBlock}>
        <img className={classes.image} src={ResetPasswordInfoIcon[status]} alt={'Info Icon'} />
      </div>

      <p className={classes.text}>{text}</p>
    </div>
  );
};

export default ResetPasswordInfo;
