import { Analytics } from '@analytics';
import { isEmpty } from 'ramda';
import React from 'react';
import { Redirect, Route } from 'react-router';
import { SessionStorageKeys, SignupType } from '../../utils/constants';

function checkOnboardingFlow(user, path) {
  const hasIncompleteOnboardingSteps =
    user.profile.onboarding?.tipping == false || user.profile.onboarding?.profile == false;
  const interestedInTapToTip = user.profile.interestedIn === SignupType.TapToTip;
  const isNotOnboardingPage = path !== '/onboarding';
  //Note: Never interrupt with the user payout flow
  const isNotPayoutPage = path !== '/payoutlink' && path !== '/payoutlink-confirm';
  const skipAllSteps = sessionStorage.getItem(SessionStorageKeys.SkipAllSteps);

  if (
    hasIncompleteOnboardingSteps &&
    interestedInTapToTip &&
    isNotOnboardingPage &&
    isNotPayoutPage &&
    !skipAllSteps
  ) {
    return <Redirect to="/onboarding" />;
  }

  return null;
}

function checkRegion(user, props) {
  if (user.profile.interestedIn === SignupType.TapToTip) {
    logoutAndChangeRegion(props.path);
    return false;
  }

  return true;
}

function logoutAndChangeRegion(path) {
  fetch('/api/v1/user/logout', { method: 'POST' })
    .then(resp => resp.json())
    .then(function() {
      Analytics.logoutOfTracking();
      sessionStorage.removeItem(SessionStorageKeys.SkipAllSteps);

      window.location.href = `https://eu.tip.direct${path}`;
    });
}

export function PrivateRoute({ user, ...props }) {
  if (isEmpty(user)) {
    return <Redirect to="/signin" />;
  }

  if (!checkRegion(user, props)) return null;

  const onboardingRedirect = checkOnboardingFlow(user, props.path);

  if (onboardingRedirect) return onboardingRedirect;

  return <Route {...props} />;
}

export function TSUserRoute({ user, ...props }) {
  if (isEmpty(user)) {
    return <Redirect to="/tipdirect" />;
  }

  if (!checkRegion(user, props)) return null;

  const onboardingRedirect = checkOnboardingFlow(user, props.path);

  if (onboardingRedirect) return onboardingRedirect;

  return <Route {...props} />;
}
