import { Analytics } from '@analytics';
import { StyleBreakpoints } from '@constants';
import { Button, MenuItem, Paper, Tooltip } from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ButtonWithMenu from '../../Common/ButtonWithMenu';
import CustomizableModal from '../../Common/CustomizableModal';
import localizationMessages from '../../Resources/ResourcesTable/messages';
import CreateTourModal from '../CreateTourModal';
import ManageGuidesModal from '../ManageGuidesModal';
import { StatusBackgroundColor, StatusForeColor, StatusType } from './constants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '4px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#fff',
    border: 'none',
    cursor: 'pointer',
    color: '#000'
  },
  icon: {
    width: '24px',
    height: '24px'
  },
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    },
    width: '100%'
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
    width: '80%',
    maxHeight: '90vh',
    flexWrap: 'wrap',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%'
    }
  },
  table: {
    width: '100%'
  },
  topItems: {
    margin: '0 0 20px auto',
    display: 'flex',
    gap: '15px'
  },
  status: {
    textAlign: 'center',
    width: '86px',
    padding: '4px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '4px'
  }
}));

function mapTourForEditing(tour) {
  const lang = tour.translations.primaryLanguage;
  return {
    _id: tour._id,
    primaryLanguage: lang,
    title: tour.translations.translations[lang].data.title,
    description: tour.translations.translations[lang].data.longDescription,
    reviewSites: tour.reviewSites
  };
}

const TourStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: StatusBackgroundColor[status],
        color: StatusForeColor[status]
      }}>
      {StatusType[status]}
    </div>
  );
};

const ToursTable = ({
  tours,
  fetchTours,
  addTapToTipTour,
  editTapToTipTour,
  guides,
  fetchGuides,
  multipleAssign,
  deleteTour
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isAddTourModalOpen, setIsAddTourModalOpen] = useState(false);
  const [isManageGuidesOpen, setIsManageGuidesOpen] = useState(false);

  const [tourDataToEdit, setTourDataToEdit] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tourToDelete, setTourToDelete] = useState({});
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleTourRemove = tours => {
    deleteTour(tours._id);
    Analytics.track('delete tour clicked');
    handleModalClose();
  };

  useEffect(() => {
    if (!tours?.length) {
      fetchTours();
    }
    if (!guides?.length) {
      fetchGuides();
    }
  }, []);

  function openEditTourModal(data) {
    setTourDataToEdit(mapTourForEditing(data));
    setIsAddTourModalOpen(true);
  }

  function openAddTourModal() {
    setTourDataToEdit(null);
    setIsAddTourModalOpen(true);
    Analytics.track('create TTT tour opened');
  }

  const getMenuBody = rowData => [
    <MenuItem
      key={rowData._id}
      onClick={() => {
        openEditTourModal(rowData);
        Analytics.track('edit TTT tour opened');
      }}>
      {intl.formatMessage(messages.editTour)}
    </MenuItem>,
    <MenuItem
      key={rowData._id}
      onClick={() => {
        setIsDeleteModalOpen(true);
        setTourToDelete(rowData);
        Analytics.track('delete TTT tour opened');
      }}>
      {intl.formatMessage(messages.deleteTour)}
    </MenuItem>
  ];

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      customSort: (a, b) => {
        return (a.reviewSites?.length > 0) - (b.reviewSites?.length > 0);
      },
      render: rowData => (
        <TourStatus status={rowData.numberTourSites ? 'active' : 'incomplete'} classes={classes} />
      )
    },
    {
      field: 'title',
      title: intl.formatMessage(messages.tour)
    },
    {
      field: 'numberTourSites',
      title: intl.formatMessage(messages.reviewLinks),
      defaultSort: 'desc',
      render: rowData => {
        return <div className={classes.status}>{rowData.numberTourSites}</div>;
      }
    },
    {
      title: intl.formatMessage(messages.manage),
      field: 'manage',
      sorting: false,
      headerStyle: {
        paddingRight: '25px',
        textAlign: 'right'
      },
      cellStyle: {
        textAlign: 'right'
      },
      render: rowData => <ButtonWithMenu menuBody={getMenuBody(rowData)} />
    }
  ];

  const toursWithTitles = tours?.map(tour => {
    return {
      ...tour,
      title: tour.translations.translations[tour.translations.primaryLanguage].data.title,
      numberTourSites: tour.reviewSites
        ? tour.reviewSites.filter(site => site.url && site.url.length).length
        : 0
    };
  });

  return (
    <div className={classes.container}>
      <div className={classes.topItems}>
        <Tooltip
          title={
            !isFeatureForCompanyEnabled('PostTipReviewCollection')
              ? 'Upgrade your subscription to the Pro or Pro Plus plans in Settings to start collecting reviews'
              : 'Create new tours to collect reviews for'
          }>
          <span>
            <Button
              variant="contained"
              disabled={!isFeatureForCompanyEnabled('PostTipReviewCollection')}
              color="primary"
              endIcon={<AddCircleIcon />}
              onClick={openAddTourModal}>
              {intl.formatMessage(messages.createTour)}
            </Button>
          </span>
        </Tooltip>
      </div>
      <MaterialTable
        className={classes.table}
        title={intl.formatMessage(messages.tours)}
        columns={columns}
        data={toursWithTitles}
        components={{
          Container: props => <Paper className={classes.striped} {...props} />
        }}
        options={{
          pageSize: 10,
          tableLayout: 'auto',
          emptyRowsWhenPaging: false,
          headerStyle: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            top: '0'
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            width: 'fit-content'
          },
          maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
        }}
        localization={{
          pagination: {
            labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
            labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
            firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
            firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
            previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
            previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
            nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
            nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
            lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
            lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
          },
          toolbar: {
            searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
            searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
          },
          body: {
            emptyDataSourceMessage: intl.formatMessage(localizationMessages.emptyDataSourceMessage)
          }
        }}
      />

      <CreateTourModal
        isOpen={isAddTourModalOpen}
        setIsOpen={setIsAddTourModalOpen}
        addTapToTipTour={addTapToTipTour}
        setIsManageGuidesOpen={setIsManageGuidesOpen}
        updateTapToTipTour={editTapToTipTour}
        tourDetails={tourDataToEdit}
      />
      <ManageGuidesModal
        isOpen={isManageGuidesOpen}
        setIsOpen={setIsManageGuidesOpen}
        setIsAddTourModalOpen={setIsAddTourModalOpen}
        guides={guides}
        tourDetails={tourDataToEdit}
        multipleAssign={multipleAssign}
      />
      <CustomizableModal
        isOpen={isDeleteModalOpen}
        onClose={handleModalClose}
        handleHeaderCloseButton={handleModalClose}
        title={intl.formatMessage(messages.confirmAction)}
        body={
          <p>
            {intl.formatMessage(messages.removeTourConfirm)}: <b>{tourToDelete.title}</b>
          </p>
        }
        handleFooterCloseButton={handleModalClose}
        primaryActionButtonData={{
          content: intl.formatMessage(messages.confirm),
          onClick: () => handleTourRemove(tourToDelete),
          isDisabled: false
        }}
      />
    </div>
  );
};

export default ToursTable;
