/*
 * ChannelManagerTable Messages
*/
import { defineMessages } from 'react-intl';

export const scope = 'app.components.ChannelManagerTable';

export default defineMessages({
  channelManager: {
    id: `${scope}.channel_manager`,
    defaultMessage: 'Channel Manager'
  },
  CMTours: {
    id: `${scope}.cm_tours`,
    defaultMessage: 'Channel Manager Tours'
  },
  activeChannels: {
    id: `${scope}.active_channels`,
    defaultMessage: 'Active Channels'
  },
  labelRowsSelect: {
    id: `${scope}.labelRowsSelect`,
    defaultMessage: 'rows'
  },
  labelRowsPerPage: {
    id: `${scope}.labelRowsPerPage`,
    defaultMessage: 'Rows per page'
  },
  firstAriaLabel: {
    id: `${scope}.firstAriaLabel`,
    defaultMessage: 'First Page'
  },
  firstTooltip: {
    id: `${scope}.firstTooltip`,
    defaultMessage: 'First Page'
  },
  previousAriaLabel: {
    id: `${scope}.previousAriaLabel`,
    defaultMessage: 'Previous Page'
  },
  previousTooltip: {
    id: `${scope}.previousTooltip`,
    defaultMessage: 'Previous Page'
  },
  nextAriaLabel: {
    id: `${scope}.nextAriaLabel`,
    defaultMessage: 'Next Page'
  },
  nextTooltip: {
    id: `${scope}.nextTooltip`,
    defaultMessage: 'Next Page'
  },
  lastAriaLabel: {
    id: `${scope}.lastAriaLabel`,
    defaultMessage: 'Last Page'
  },
  lastTooltip: {
    id: `${scope}.lastTooltip`,
    defaultMessage: 'Last Page'
  },
  searchTooltip: {
    id: `${scope}.searchTooltip`,
    defaultMessage: 'Search'
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search'
  },
  emptyDataSourceMessage: {
    id: `${scope}.emptyDataSourceMessage`,
    defaultMessage: 'No records to display'
  }
});
