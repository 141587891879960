import { Analytics } from '@analytics';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { SessionStorageKeys } from '../../../../utils/constants';

const useStyles = makeStyles({
  menuStyle: {
    marginTop: '40px'
  }
});

export default function SettingsMenu(props) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleOpenDropDown = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    fetch('/api/v1/user/logout', { method: 'POST' })
      .then(resp => resp.json())
      .then(function() {
        localStorage.removeItem('locale');
        Analytics.logoutOfTracking();
        window.location.href = '/signin';
      });
    setAnchorEl(null);
    sessionStorage.removeItem(SessionStorageKeys.SkipAllSteps);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenDropDown}>
        {props.children}
      </Button>
      <Menu
        className={classes.menuStyle}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleSignOut}>{intl.formatMessage(messages.signOut)}</MenuItem>
      </Menu>
    </>
  );
}
