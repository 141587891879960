import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  resetPasswordToken: null
};

const fetchResetPasswordTokenSuccess = (state, resetPasswordToken) => ({
  ...state,
  resetPasswordToken
});

export default createReducer(
  {
    [actionTypes.FETCH_RESET_TOKEN_SUCCESS]: fetchResetPasswordTokenSuccess
  },
  initialState
);
