import { defineMessages } from 'react-intl';

const scope = `app.containers.SubscriptionPostCheckout`;

export default defineMessages({
  waitingText: {
    id: `${scope}.waitingText`,
    defaultMessage: 'Just a moment while we prepare your account...'
  },
  subscribed_user_exists: {
    id: `${scope}.subscribed_user_exists`,
    defaultMessage: 'You are already subscribed to our service.'
  },
  subscription_failed: {
    id: `${scope}.subscription_failed`,
    defaultMessage:
      'An issue occurred while setting up your account. A member of staff has been notified and is working on a resolution. They will contact you as soon as possible.'
  }
});
