import { all, put, call, takeLatest, delay } from '@redux-saga/core/effects';
import { fetchAvailableTours } from '../../api';
import {
  addIntegratedTours,
  deleteIntegratedTour,
  fetchIntegratedTours,
  updateIntegratedTour
} from '../../api/channelManager';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

const mockData = [
  {
    tourId: '6070033099109723d796c5ac',
    integrations: [
      {
        channelId: 'idgig123',
        channelType: 'gyg',
        integrationStatus: 'active'
      },
      {
        channelId: 'idreserve_with_google123',
        channelType: 'reserve_with_google',
        integrationStatus: 'active'
      },
      {
        channelId: 'idgroupon123',
        channelType: 'groupon',
        integrationStatus: 'active'
      },
      {
        channelId: 'idexpedia123',
        channelType: 'expedia',
        integrationStatus: 'active'
      }
    ]
  },
  {
    tourId: '6070050699109723d796c5af',
    integrations: [
      {
        channelId: 'idgig123',
        channelType: 'gyg',
        integrationStatus: 'active'
      },
      {
        channelId: 'idgroupon123',
        channelType: 'groupon',
        integrationStatus: 'pending_update'
      },
      {
        channelId: 'idreserve_with_google123',
        channelType: 'reserve_with_google',
        integrationStatus: 'pending_update'
      },
      {
        channelId: 'idexpedia123',
        channelType: 'expedia',
        integrationStatus: 'active'
      }
    ]
  },
  {
    tourId: '6070060a99109723d796c5b4',
    integrations: [
      {
        channelId: 'idgig123',
        channelType: 'gyg',
        integrationStatus: 'not_active'
      },
      {
        channelId: 'idreserve_with_google123',
        channelType: 'reserve_with_google',
        integrationStatus: 'active'
      },
      {
        channelId: 'idgroupon123',
        channelType: 'groupon',
        integrationStatus: 'active'
      },
      {
        channelId: 'idexpedia123',
        channelType: 'expedia',
        integrationStatus: 'not_active'
      }
    ]
  },
  {
    tourId: '607d876bf5058a2ee8571543',
    integrations: [
      {
        channelId: 'idgig123',
        channelType: 'gyg',
        integrationStatus: 'active'
      },
      {
        channelId: 'idreserve_with_google123',
        channelType: 'reserve_with_google',
        integrationStatus: 'pending_update'
      },
      {
        channelId: 'idgroupon123',
        channelType: 'groupon',
        integrationStatus: 'not_active'
      },
      {
        channelId: 'idexpedia123',
        channelType: 'expedia',
        integrationStatus: 'active'
      }
    ]
  },
  {
    tourId: '607eed38620d0a6cd750393f',
    integrations: [
      {
        channelId: 'idreserve_with_google123',
        channelType: 'reserve_with_google',
        integrationStatus: 'active'
      },
      {
        channelId: 'idgroupon123',
        channelType: 'groupon',
        integrationStatus: 'active'
      },
      {
        channelId: 'idexpedia123',
        channelType: 'expedia',
        integrationStatus: 'not_active'
      },
      {
        channelId: 'idgig123',
        channelType: 'gyg',
        integrationStatus: 'pending_update'
      }
    ]
  }
];

function* fetchToursWorker() {
  try {
    const toursResponse = yield call(fetchAvailableTours);
    yield put(actions.fetchToursSuccess(toursResponse.data.data.tours));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error)));
  }
}

function* fetchIntegratedToursWorker() {
  try {
    const integratedTours = yield call(fetchIntegratedTours);
    yield put(actions.fetchIntegratedToursSuccess(integratedTours));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error)));
  }
}

function* updateIntegratedTourWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    const updatedTour = yield call(updateIntegratedTour, payload);
    yield put(actions.updateIntegratedTourSuccess(updatedTour));
    yield put(actions.setIsModalOpen(false));
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error)));
  }
}

function* deleteIntegratedTourWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    yield call(deleteIntegratedTour, payload.tourId);
    yield put(actions.deleteIntegratedTourSuccess(payload.tourId));
    yield put(actions.setIsModalOpen(false));
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error)));
  }
}

function* addTourToIntegratedWorker({ payload }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    const response = yield call(addIntegratedTours, payload);
    yield put(actions.addToursToIntegratedTourSuccess(response));
    yield put(actions.setIsModalOpen(false));
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (error) {
    yield put(actions.toursValidationFailed(error.details));
    yield put(setSnackbarStatus(snackbarStatus.hidden));
  }
}

export default function* channelManagerWatcher() {
  yield takeLatest(types.FETCH_INTEGRATED_TOURS, fetchIntegratedToursWorker);
  yield takeLatest(types.FETCH_TOURS, fetchToursWorker);
  yield takeLatest(types.UPDATE_INTEGRATED_TOUR, updateIntegratedTourWorker);
  yield takeLatest(types.DELETE_INTEGRATED_TOUR, deleteIntegratedTourWorker);
  yield takeLatest(types.ADD_TOURS_TO_INTEGRATED_TOURS, addTourToIntegratedWorker);
}
