import { defineMessages } from 'react-intl';

export const scope = 'app.components.TourAggregateSecurePopup';

export default defineMessages({
  secureMessage: {
    id: `${scope}.secureMessage`,
    defaultMessage: 'All data and information is transferred securely using end to end encryption.'
  },
  secure: {
    id: `${scope}.secure`,
    defaultMessage: 'Secure'
  }
});
