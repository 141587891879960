import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.Profile';

export default defineMessages({
  revolut: {
    id: `${scope}.revolut`,
    defaultMessage: 'Revolut'
  },
  yourRevolutDetails: {
    id: `${scope}.yourRevolutDetails`,
    defaultMessage: 'Your Revolut account details'
  },
  accountHolder: {
    id: `${scope}.accountHolder`,
    defaultMessage: 'Account Holder'
  },
  business: {
    id: `${scope}.business`,
    defaultMessage: 'Business'
  },
  personal: {
    id: `${scope}.personal`,
    defaultMessage: 'Personal'
  },
  austria: {
    id: `${scope}.austria`,
    defaultMessage: 'Austria'
  },
  belgium: {
    id: `${scope}.belgium`,
    defaultMessage: 'Belgium'
  },
  bulgaria: {
    id: `${scope}.bulgaria`,
    defaultMessage: 'Bulgaria'
  },
  croatia: {
    id: `${scope}.croatia`,
    defaultMessage: 'Croatia'
  },
  cyprus: {
    id: `${scope}.cyprus`,
    defaultMessage: 'Cyprus'
  },
  czech: {
    id: `${scope}.czech`,
    defaultMessage: 'Czech Republic'
  },
  denmark: {
    id: `${scope}.denmark`,
    defaultMessage: 'Denmark'
  },
  estonia: {
    id: `${scope}.estonia`,
    defaultMessage: 'Estonia'
  },
  finland: {
    id: `${scope}.finland`,
    defaultMessage: 'Finland'
  },
  france: {
    id: `${scope}.france`,
    defaultMessage: 'France'
  },
  germany: {
    id: `${scope}.germany`,
    defaultMessage: 'Germany'
  },
  greece: {
    id: `${scope}.greece`,
    defaultMessage: 'Greece'
  },
  hungary: {
    id: `${scope}.hungary`,
    defaultMessage: 'Hungary'
  },
  iceland: {
    id: `${scope}.iceland`,
    defaultMessage: 'Iceland'
  },
  ireland: {
    id: `${scope}.ireland`,
    defaultMessage: 'Ireland'
  },
  italy: {
    id: `${scope}.italy`,
    defaultMessage: 'Italy'
  },
  latvia: {
    id: `${scope}.latvia`,
    defaultMessage: 'Latvia'
  },
  liechtenstein: {
    id: `${scope}.liechtenstein`,
    defaultMessage: 'Liechtenstein'
  },
  lithuania: {
    id: `${scope}.lithuania`,
    defaultMessage: 'Lithuania'
  },
  luxembourg: {
    id: `${scope}.luxembourg`,
    defaultMessage: 'Luxembourg'
  },
  malta: {
    id: `${scope}.malta`,
    defaultMessage: 'Malta'
  },
  netherlands: {
    id: `${scope}.netherlands`,
    defaultMessage: 'Netherlands'
  },
  norway: {
    id: `${scope}.norway`,
    defaultMessage: 'Norway'
  },
  poland: {
    id: `${scope}.poland`,
    defaultMessage: 'Poland'
  },
  portugal: {
    id: `${scope}.portugal`,
    defaultMessage: 'Portugal'
  },
  romania: {
    id: `${scope}.romania`,
    defaultMessage: 'Romania'
  },
  slovakia: {
    id: `${scope}.slovakia`,
    defaultMessage: 'Slovakia'
  },
  slovenia: {
    id: `${scope}.slovenia`,
    defaultMessage: 'Slovenia'
  },
  spain: {
    id: `${scope}.spain`,
    defaultMessage: 'Spain'
  },
  sweden: {
    id: `${scope}.sweden`,
    defaultMessage: 'Sweden'
  },
  uk: {
    id: `${scope}.uk`,
    defaultMessage: 'United Kingdom'
  },
  us: {
    id: `${scope}.us`,
    defaultMessage: 'United States'
  },
  bankDetails: {
    id: `${scope}.bankDetails`,
    defaultMessage: 'Bank Details'
  },
  euroCurrency: {
    id: `${scope}.euroCurrency`,
    defaultMessage: 'Euro'
  },
  danishKrone: {
    id: `${scope}.danishKrone`,
    defaultMessage: 'Danish Krone'
  },
  icelandicKrona: {
    id: `${scope}.icelandicKrona`,
    defaultMessage: 'Icelandic Króna'
  },
  norwegianKrone: {
    id: `${scope}.norwegianKrone`,
    defaultMessage: 'Norwegian Krone'
  },
  polishZloty: {
    id: `${scope}.polishZloty`,
    defaultMessage: 'Polish Złoty'
  },
  romanianLeu: {
    id: `${scope}.romanianLeu`,
    defaultMessage: 'Romanian Leu'
  },
  swedishKrona: {
    id: `${scope}.swedishKrona`,
    defaultMessage: 'Swedish Krona'
  },
  gbp: {
    id: `${scope}.gbp`,
    defaultMessage: 'British Pound'
  },
  usd: {
    id: `${scope}.usd`,
    defaultMessage: 'United States Dollar'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit'
  },
  revolutError: {
    id: `${scope}.revolutError`,
    defaultMessage:
      'An error occured when sending your information to Revolut, please check the information above and try again.'
  },
  accountType: {
    id: `${scope}.accountType`,
    defaultMessage: 'Account Type'
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name'
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name'
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone Number'
  },
  address_1: {
    id: `${scope}.address_1`,
    defaultMessage: 'Address 1'
  },
  address_2: {
    id: `${scope}.address_2`,
    defaultMessage: 'Address 2'
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City'
  },
  stateProvince: {
    id: `${scope}.stateProvince`,
    defaultMessage: 'State / Province'
  },
  zipCode: {
    id: `${scope}.zipCode`,
    defaultMessage: 'Zip Code / Post Code'
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country'
  },
  bankCountry: {
    id: `${scope}.bankCountry`,
    defaultMessage: 'Bank Country'
  },
  accountCurrency: {
    id: `${scope}.accountCurrency`,
    defaultMessage: 'Account Currency'
  },
  IBAN: {
    id: `${scope}.IBAN`,
    defaultMessage: 'Bank Account Number (IBAN)'
  },
  BIC: {
    id: `${scope}.BIC`,
    defaultMessage: 'BIC'
  },
  accountNumber: {
    id: `${scope}.accountNumber`,
    defaultMessage: 'Account Number'
  },
  routingNumber: {
    id: `${scope}.routingNumber`,
    defaultMessage: 'Routing Number'
  },
  sortCode: {
    id: `${scope}.sortCode`,
    defaultMessage: 'Sort Code'
  }
});
