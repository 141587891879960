import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/paypal';

export const loginPayPalAccount = () => {
  return axios
    .get(`${url}/login`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const paypalLoginCallback = code => {
  return axios
    .get(`${url}/callback?code=${code}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
