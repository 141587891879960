import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  counterBlock: {
    height: '30px',
    width: 'auto'
  },
  counterDisplay: {
    width: 'auto',
    color: '#d1d1d1',
    fontSize: '21px',
    fontWeight: 'normal',
    border: '1.5px solid #d1d1d1',
    lineHeight: '10px',
    wordBreak: 'keep-all'
  },
  increment: {
    width: '30px',
    color: theme.palette.primary.main,
    fontSize: '27px',
    border: '1.5px solid #d1d1d1',
    lineHeight: '10px'
  },
  decrement: {
    width: '30px',
    color: theme.palette.primary.main,
    fontSize: '27px',
    border: '1.5px solid #d1d1d1',
    lineHeight: '10px'
  }
}));

export default function Counter({
  id,
  count,
  isMandatory,
  participantsAmount,
  handleExtraCountChange
}) {
  const classes = useStyles();

  const handleIncrement = () => {
    handleExtraCountChange(id, +count + 1);
  };

  const handleDecrement = () => {
    if (isMandatory) {
      if (count > participantsAmount) handleExtraCountChange(id, +count - 1);
    } else if (count > 0) {
      handleExtraCountChange(id, +count - 1);
    }
  };

  return (
    <ButtonGroup
      className={classes.counterBlock}
      size="small"
      aria-label="small outlined button group"
    >
      <Button className={classes.decrement} onClick={handleDecrement}>
        -
      </Button>
      <Button className={classes.counterDisplay} disabled>
        {+count}
      </Button>
      <Button className={classes.increment} onClick={handleIncrement}>
        +
      </Button>
    </ButtonGroup>
  );
}
