import {
  InputAdornment,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as React, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { date2strSlash } from '../../../../utils/helpers';
import DateRangePickerComponent from '../../../AvailabilityBuilder/DateRangePickerComponent';
import messages from '../messages';

const useStyles = makeStyles(() => ({
  root: {
    width: '70vw',
    maxWidth: '870px',
    '& > .MuiStepper-root': {
      padding: 0
    }
  },
  desc: {
    margin: '4px 0 20px 32px',
    color: '#b8b8b8',
    fontSize: '11px',
    maxWidth: '500px'
  },
  summaryRow: {
    fontSize: '12px',
    color: '#555555',
    margin: '3px 0'
  },
  textInput: {
    width: '100%',
    maxWidth: '250px'
  }
}));

export default function PromotionsDetails({
  activePromoData,
  hasPromoCode,
  setIsNextStepDisabled,
  handleChange
}) {
  const classes = useStyles();
  const intl = useIntl();

  const {
    promoStartDate,
    promoEndDate,
    travelByStartDate,
    travelByEndDate,
    discount,
    promoCode
  } = activePromoData;

  const stepsData = [
    [intl.formatMessage(messages.promoDatesTitle), intl.formatMessage(messages.promoDatesDesc)],
    [intl.formatMessage(messages.travelByTitle), intl.formatMessage(messages.travelByDesc)],
    [intl.formatMessage(messages.discountTitle), intl.formatMessage(messages.discountDesc)],
    [intl.formatMessage(messages.reviewTitle), intl.formatMessage(messages.reviewDesc)]
  ];

  if (hasPromoCode)
    stepsData.splice(3, 0, [
      intl.formatMessage(messages.promoCodeTitle),
      intl.formatMessage(messages.promoCodeDesc)
    ]);

  useEffect(() => {
    setIsNextStepDisabled(Object.values(activePromoData).some(value => !value));
  }, [activePromoData]);

  const [step, setStep] = useState(0);

  function getReviewAndSaveBlock() {
    return (
      <>
        <p className={classes.summaryRow}>
          <b>{intl.formatMessage(messages.promoDatesSummary)}: </b>
          {promoStartDate && (
            <span>{`${date2strSlash(promoStartDate)} - ${date2strSlash(promoEndDate)}`}</span>
          )}
        </p>
        <p className={classes.summaryRow}>
          <b>{intl.formatMessage(messages.travelDatesSummary)}: </b>
          {travelByStartDate && (
            <span>{`${date2strSlash(travelByStartDate)} - ${date2strSlash(travelByEndDate)}`}</span>
          )}
        </p>
        <p className={classes.summaryRow}>
          <b>{intl.formatMessage(messages.discountSummary)}: </b>
          {discount && <span>{`${discount}%`}</span>}
        </p>
        {hasPromoCode && (
          <p className={classes.summaryRow}>
            <b>{intl.formatMessage(messages.promoCodeSummary)}: </b>
            <span>{promoCode}</span>
          </p>
        )}
      </>
    );
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <DateRangePickerComponent
            autoOk={true}
            inputVariant={'outlined'}
            format={'dd/MM/yyyy'}
            selectedStartDate={promoStartDate}
            selectedEndDate={promoEndDate}
            handleStartDateChange={date => {
              const end = new Date(promoEndDate);
              handleChange('promoStartDate')(date);
              if (date > end) {
                handleChange('promoEndDate')(new Date(new Date(date).setDate(date.getDate() + 1)));
              }
            }}
            handleEndDateChange={handleChange('promoEndDate')}
          />
        );
      case 1:
        return (
          <DateRangePickerComponent
            autoOk={true}
            inputVariant={'outlined'}
            format={'dd/MM/yyyy'}
            selectedStartDate={travelByStartDate}
            selectedEndDate={travelByEndDate}
            handleStartDateChange={date => {
              const end = new Date(travelByEndDate);
              handleChange('travelByStartDate')(date);
              if (date > end) {
                handleChange('travelByEndDate')(
                  new Date(new Date(date).setDate(date.getDate() + 1))
                );
              }
            }}
            handleEndDateChange={handleChange('travelByEndDate')}
          />
        );
      case 2:
        return (
          <div>
            <TextField
              id="outlined-basic"
              label={intl.formatMessage(messages.percentage)}
              type="number"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              value={discount || ''}
              onChange={e => {
                if (e.target.value < 0 || e.target.value > 100) {
                  return;
                }
                handleChange('discount')(e);
              }}
              className={classes.textInput}
            />
          </div>
        );
      case 3:
        if (!hasPromoCode) return getReviewAndSaveBlock();

        return (
          <div>
            <TextField
              id="outlined-basic"
              label={intl.formatMessage(messages.promoCodeSummary)}
              variant="outlined"
              value={promoCode}
              onChange={e => handleChange('promoCode')(e.target.value.trim())}
              className={classes.textInput}
            />
          </div>
        );

      case 4:
        return getReviewAndSaveBlock();
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={step} orientation="vertical">
        {stepsData.map(([label, desc], index) => (
          <Step key={label} active={true}>
            <StepButton onClick={() => setStep(index)}>
              <StepLabel>{label}</StepLabel>
            </StepButton>
            <p className={classes.desc}>{desc}</p>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
