import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Analytics } from '@analytics';

const useStyles = makeStyles({
  title: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 12,
    marginTop: 8
  },
  notesField: {
    width: '100%'
  }
});

const BookingNotes = ({ title, notes, saveNotes, extraTrackingDetails }) => {
  const classes = useStyles();
  const [hasNoteFieldChanged, setHasNoteFieldChanged] = useState(false);
  const MAX_NOTES_LENGTH = 500;

  const handleNoteChanged = e => {
    const value = e.target.value;

    if (value.length > MAX_NOTES_LENGTH) return;

    saveNotes(value);
    setHasNoteFieldChanged(true);
  };

  const handleClickedFiledAnalytics = () => {
    Analytics.track('notes field clicked', {
      location: 'booking modal'
    });
  };

  const handleLostFieldFocusAnalytics = () => {
    Analytics.track('notes field changed', {
      location: 'booking modal',
      hasTextChanged: hasNoteFieldChanged
    });

    setHasNoteFieldChanged(false);
  };

  return (
    <div>
      {title && <div className={classes.title}>{title}</div>}
      <TextField
        className={classes.notesField}
        multiline
        variant="outlined"
        value={notes ?? ''}
        onChange={handleNoteChanged}
        onBlur={handleLostFieldFocusAnalytics}
        onFocus={handleClickedFiledAnalytics}
        onClick={e => {
          Analytics.track('booking note edited', {
            ...extraTrackingDetails,
            'note length': notes.length,
            'edited note length': e.target.value.length
          });
        }}
      />
    </div>
  );
};

export default BookingNotes;
