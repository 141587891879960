import { defineMessages } from 'react-intl';

export const scope = 'app.containers.BookingWidget';

export default defineMessages({
  tourNotAvailable: {
    id: `${scope}.tour_not_available`,
    defaultMessage: "We're sorry this tour is no longer available"
  },
  purchase: {
    id: `${scope}.purchase`,
    defaultMessage: 'Purchase'
  },
  checkAvailability: {
    id: `${scope}.check_availability`,
    defaultMessage: 'Check Availability'
  },
  checkout: {
    id: `${scope}.checkout`,
    defaultMessage: 'Checkout'
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency'
  },
  selectDateFirst: {
    id: `${scope}.select_date_first`,
    defaultMessage: 'Please select a date first'
  },
  ages: {
    id: `${scope}.age_range`,
    defaultMessage: 'Ages {start} to {end}'
  },
  cost: {
    id: `${scope}.cost`,
    defaultMessage: 'Cost'
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done'
  },
  startingTimes: {
    id: `${scope}.starting_times`,
    defaultMessage: 'Time'
  },
  chooseParticipants: {
    id: `${scope}.choose_participants`,
    defaultMessage: 'Participants'
  },
  chooseParticipantsDeposit: {
    id: `${scope}.choose_participants_deposit`,
    defaultMessage: 'Deposit'
  },
  bookNow: {
    id: `${scope}.book_now`,
    defaultMessage: 'Book Now'
  },
  selectDateTimeCurrencyParticipants: {
    id: `${scope}.select_date_time_currency_participants`,
    defaultMessage: 'Select: Date, Participants, Starting Time and Currency'
  },
  participantsAmountErrorFirstPart: {
    id: `${scope}.participants_amount_error_first`,
    defaultMessage: 'There are too few spaces available. There are '
  },
  participantsAmountErrorSecondPart: {
    id: `${scope}.participants_amount_error_second`,
    defaultMessage: ' spaces available for this time and date'
  },
  overbookWarningFirstPart: {
    id: `${scope}.overbookWarningFirstPart`,
    defaultMessage: 'There are only '
  },
  overbookWarningSecondPart: {
    id: `${scope}.overbookWarningSecondPart`,
    defaultMessage:
      ' spaces available for this time and date. Continuing will overbook your activity.'
  },
  placesLeft: {
    id: `${scope}.places_left`,
    defaultMessage: ' places left'
  },
  bookingNotes: {
    id: `${scope}.booking_notes`,
    defaultMessage: 'Notes [Optional]'
  },
  bookDirect: {
    id: `${scope}.book_direct`,
    defaultMessage: 'Lowest price by booking direct'
  }
});
