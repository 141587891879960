import { defineMessages } from 'react-intl';

export const scope = 'app.components.TourAggregateTourCard';

export default defineMessages({
  bookNow: {
    id: `${scope}.bookNow`,
    defaultMessage: 'Book Now'
  },
  fromOnly: {
    id: `${scope}.fromOnly`,
    defaultMessage: 'From Only'
  },
  per: {
    id: `${scope}.per`,
    defaultMessage: 'per'
  },
  was: {
    id: `${scope}.was`,
    defaultMessage: 'Was'
  }
});
