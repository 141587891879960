import { defineMessages } from 'react-intl';

export const scope = 'app.components.Onboarding';

export default defineMessages({
  welcomeDescription: {
    id: `${scope}.welcomeDescription`,
    defaultMessage:
      'Let us help you get setup. To get started, enter some profile information about yourself below. '
  },
  welcomeBoldText: {
    id: `${scope}.welcomeBoldText`,
    defaultMessage: 'Welcome'
  },
  additionalInfoDescription: {
    id: `${scope}.additionalInfoDescription`,
    defaultMessage:
      'Now we just need to know how much you’d like to get paid, in what currency and what country you are in.'
  },
  additionalInfoBoldText: {
    id: `${scope}.additionalInfoBoldText`,
    defaultMessage: 'Additional Info'
  },
  nearlyThereDescription: {
    id: `${scope}.nearlyThereDescription`,
    defaultMessage:
      'Now we just need you to tell us how to pay you, select on of our supported payment methods below'
  },
  nearlyThereBoldText: {
    id: `${scope}.nearlyThereBoldText`,
    defaultMessage: 'Nearly There!'
  },
  congratulationsDescription: {
    id: `${scope}.congratulationsDescription`,
    defaultMessage:
      'You’re all setup and ready to start taking digital tips. Explore addtional Tip Direct features below'
  },
  congratulationsBoldText: {
    id: `${scope}.congratulationsBoldText`,
    defaultMessage: 'Congratulations'
  },
  personalTippingDescription: {
    id: `${scope}.personalTippingDescription`,
    defaultMessage:
      'Share your unique link with customers via text or email to provide other options for them to leave tips.'
  },
  personalTippingBoldText: {
    id: `${scope}.personalTippingBoldText`,
    defaultMessage: 'Personal Tipping Link'
  },
  inviteGuidesDescription: {
    id: `${scope}.inviteGuidesDescription`,
    defaultMessage: 'Would you like to invite guides to start using Tip Direct? you can do so here.'
  },
  inviteGuidesBoldText: {
    id: `${scope}.inviteGuidesBoldText`,
    defaultMessage: 'Invite Guides'
  },
  setupReviewsDescription: {
    id: `${scope}.setupReviewsDescription`,
    defaultMessage:
      'We make it easy for you to receive reviews on tours, make sure yours are linked.'
  },
  setupReviewsBoldText: {
    id: `${scope}.setupReviewsBoldText`,
    defaultMessage: 'Setup Reviews'
  },
  orderCardsDescription: {
    id: `${scope}.orderCardsDescription`,
    defaultMessage: 'Need Tip Direct cards? Order them through our website.'
  },
  orderCardsBoldText: {
    id: `${scope}.orderCardsBoldText`,
    defaultMessage: 'Order Cards'
  }
});
