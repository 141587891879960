import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '70vw',
    maxWidth: '500px'
  },
  tourName: {
    fontSize: '13.4px',
    fontWeight: 'bold',
    color: '#6b6b6b',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  deleteButton: {
    padding: '10px 0',
    fontSize: '12px'
  }
}));

export default function RemoveTourFromPromotionModal({
  promotionId,
  tourId,
  tourName,
  removeTourFromPromo
}) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.tourName}>{tourName}</div>
      <Button
        color="primary"
        onClick={() => removeTourFromPromo({ promotionId, tourId })}
        className={classes.deleteButton}>
        {intl.formatMessage(messages.modalDeleteMessage)}
      </Button>
    </div>
  );
}
