import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { getFeeTextRepresentation } from '../../../../utils/common_translations/fees';
import { currencySign, formatMoney } from '../../../../utils/helpers';
import { BreakdownRow } from './common';
import messages from './messages';

const useStyles = makeStyles({
  header: {
    fontWeight: 'bolder',
    fontSize: '16px',
    marginBottom: '8px'
  },
  total: {
    fontWeight: 'bolder',
    fontSize: '16px',
    marginTop: '16px'
  },
  participant: {
    fontSize: '12px'
  },
  fee: {
    fontSize: '12px',
    color: 'grey'
  },
  wrapper: {
    fontWeight: 'bold'
  },
  discountBlock: {
    fontSize: '12px',
    color: '#ff4e4e',
    marginTop: '8px'
  },
  manualAdjustmentMessage: {
    fontSize: '16px'
  }
});

const formatPrice = ({ amount, currency }) =>
  `${currencySign(currency)} ${formatMoney({ amount, currency })}`;

function BreakdownItem({
  classes,
  intl,
  currency,
  item,
  isPriceManuallyAdjusted,
  manualTotalFeesAmount
}) {
  switch (item.type) {
    case 'participant':
    case 'additional_extra':
      return (
        <div className={classes.participant}>
          <BreakdownRow
            price={isPriceManuallyAdjusted ? null : formatPrice({ amount: item.total, currency })}
            title={`${item.count} ${item.title}`}
          />
        </div>
      );
    case 'fee':
      return (
        <div className={classes.fee}>
          <BreakdownRow
            price={formatPrice({
              amount: isPriceManuallyAdjusted ? manualTotalFeesAmount : item.price,
              currency
            })}
            title={getFeeTextRepresentation(intl, item.feeType)}
          />
        </div>
      );
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown participant type: ' + item.type);
      return null;
  }
}

export default function FullPriceBreakdown({
  breakdown,
  isLoaded,
  isPriceManuallyAdjusted,
  finalTotal,
  manualTotalFeesAmount
}) {
  const classes = useStyles();
  const intl = useIntl();

  if (!isLoaded) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  function getDiscountRowTitle() {
    const discount = intl.formatMessage(messages.discount);
    const sale = intl.formatMessage(messages.sale);
    const code = intl.formatMessage(messages.code);
    if (breakdown.discount.promoCode) {
      return `${discount} (${code} ${breakdown.discount.promoCode})`;
    }
    return `${discount} (${sale} ${breakdown.discount.discountPercent}%)`;
  }

  return (
    <div>
      <div className={classes.header}>{intl.formatMessage(messages.priceBreakdown)}</div>
      <div className={classes.wrapper}>
        {breakdown.elements.map((item, i) => (
          <BreakdownItem
            key={i}
            classes={classes}
            intl={intl}
            currency={breakdown.currency}
            item={item}
            isPriceManuallyAdjusted={isPriceManuallyAdjusted}
            manualTotalFeesAmount={manualTotalFeesAmount}
          />
        ))}

        {Boolean(breakdown.discount) && (
          <div className={classes.discountBlock}>
            <BreakdownRow
              price={formatPrice({
                amount: breakdown.discount.discountAmount,
                currency: breakdown.currency
              })}
              title={getDiscountRowTitle()}
              minus={true}
            />
          </div>
        )}
      </div>
      {isPriceManuallyAdjusted && (
        <p className={classes.manualAdjustmentMessage}>
          {intl.formatMessage(messages.hasBeenManualAdjusted)}
        </p>
      )}
      <div className={classes.total}>
        <BreakdownRow
          price={formatPrice({
            amount: finalTotal,
            currency: breakdown.currency
          })}
          title={intl.formatMessage(messages.tripTotal)}
        />
      </div>
    </div>
  );
}
