import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: white;
  }

  body {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Montserrat', Helvetica, Arial, sans-serif;
  }

  body::-webkit-scrollbar {
    width: 10px;
    cursor: pointer;
    background-color: #f6f3f3;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #b8b7b7;
    width: 8px;
    border-radius: 4px;
  }

  #app {
    background-color: white;
    min-width: 100%;
    height: 100%;
  }

  p,
  label {
    font-family: Montserrat, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }
`;

export default GlobalStyle;
