import { Analytics } from '@analytics';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Globe from '../../../assets/images/tour_aggregate/Globe.svg';
import LanguagePicker from '../../../components/LanguagePicker';
import PoweredBy from '../../../components/PoweredBy';
import SecurePopup from '../../../components/TourAggregate/SecurePopup';
import TourAggregateCard from '../../../components/TourAggregate/TourCard';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { changeBookingWidgetLocale } from '../../LanguageProvider/actions';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(() => ({
  wrapper: props => ({
    padding: `${props.isSmallScreen ? '5px 30px 30px' : '5px 20px 20px'}`,
    maxWidth: '900px',
    margin: 'auto'
  }),
  secureBtnContainer: props => ({
    marginBottom: '20px',
    width: '100%',
    textAlign: `${props.windowWidth < 450 ? 'center' : 'left'}`,
    display: 'flex',
    justifyContent: `${props.windowWidth < 450 ? 'center' : 'space-between'}`,
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
    rowGap: '10px',
    columnGap: '30px'
  }),
  toursContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    position: 'relative',
    margin: '0 -9px 0 -9px'
  },
  commonTourStyles: props => ({
    boxSizing: 'border-box',
    padding: `${props.isSmallScreen ? '11px 9px' : '9px'}`,
    minHeight: '250px'
  }),
  twoToursLayout: {
    flex: '0 1 100%'
  },
  threeToursLayout: {
    flex: '0 1 50%',
    '&:first-child': {
      flex: '0 1 100%'
    }
  },
  fourToursLayout: {
    flex: '0 1 50%'
  },
  manyToursLayout: {
    flex: '0 1 33.3%',
    '&:nth-child(5n+1)': {
      flex: '0 1 50%'
    },
    '&:nth-child(5n+2)': {
      flex: '0 1 50%'
    }
  },
  mediumScreenToursLayout: {
    flex: '0 1 50% !important'
  },
  smallScreenToursLayout: {
    flex: '0 1 100% !important'
  },
  showMoreBtn: {
    backgroundColor: '#ff3f15',
    color: '#fff',
    fontSize: '14px',
    height: '36px',
    width: '100%',
    marginTop: '15px',
    '&:hover': {
      backgroundColor: '#c93413'
    }
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: '150px'
  },
  languagePickerContainer: {
    height: '30px',
    margin: '25px 0 0'
  },
  clientLogo: {
    maxHeight: '100px',
    maxWidth: '250px'
  }
}));

function getProperClassName(tourAmount) {
  switch (tourAmount) {
    case 2:
      return 'twoToursLayout';
    case 3:
      return 'threeToursLayout';
    case 4:
      return 'fourToursLayout';
    default:
      return 'manyToursLayout';
  }
}

async function tryInitGtm(tourId) {
  const saleSettings = await fetch(`/api/v1/tour/${tourId}/sale-options?formatting=extended`)
    .then(res => res.json())
    .then(res => res.data);

  //Set up Google Tag Manager if code is set
  if (typeof saleSettings.customization.gtmCode === 'string') {
    const tagManagerArgs = {
      gtmId: saleSettings.customization.gtmCode
    };
    TagManager.initialize(tagManagerArgs);
  }
}

const TourAggregate = ({
  fetchToursAggregate,
  tours,
  page,
  canLoadMore,
  isLoaded,
  saleOptions,
  changeBookingWidgetLocale
}) => {
  useInjectReducer({ key: 'tourAggregate', reducer });
  useInjectSaga({ key: 'tourAggregate', saga });

  const intl = useIntl();
  const params = new URLSearchParams(location.search);
  const companyId = params.get('companyId');
  const lang = params.get('language') || 'en';
  const currency = params.get('currency');
  const [triedToCreateGtm, setTriedToCreateGtm] = useState(false);

  // If there are no tours it is the first time the widget
  // has loaded so track a view.
  if (!tours) {
    Analytics.track('tour aggregate viewed', {
      'company id': companyId
    });
  }

  //try init gtm
  if (tours?.length > 0 && !triedToCreateGtm) {
    setTriedToCreateGtm(true);
    tryInitGtm(tours[0]._id);
  }

  const [selectedLanguage, setSelectedLanguage] = useState(lang);

  const [windowWidth, setWindowWidth] = useState(0);

  const smallScreenBreakpoint = tours?.length == 2 ? 450 : 550;
  const mediumScreenBreakpoint = 800;

  const isSmallScreen = windowWidth < smallScreenBreakpoint && tours?.length != 2;
  const isMediumScreen = windowWidth < mediumScreenBreakpoint && tours?.length > 4;

  const classes = useStyles({ isSmallScreen, windowWidth });

  let adaptiveStyles = `
    ${isSmallScreen && classes.smallScreenToursLayout} 
    ${isMediumScreen && classes.mediumScreenToursLayout}`;

  const tourClassBasedOnToursAmount = getProperClassName(tours?.length);

  const buttonWidth = isSmallScreen ? 'full' : 'small';

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  useEffect(() => {
    getToursData({
      companyId,
      language: selectedLanguage,
      page: 0,
      perPage: 5
    });
  }, []);

  useEffect(() => {
    if (window === window.parent) {
      const script = document.createElement('script');

      script.src = window.location.origin + '/js/bookNow.js';
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  function getToursData({ companyId, language, page, perPage }) {
    fetchToursAggregate({
      companyId,
      language,
      page,
      perPage
    });
    changeBookingWidgetLocale(language);
  }

  function handleTourBookNowClick(tour, index) {
    Analytics.track('tour selected', {
      'display area': 'tour aggregate widget',
      'tour index': index,
      'tour id': tour._id,
      'company id': companyId
    });

    window.parent.postMessage(
      JSON.stringify({
        msgType: 'openIframe',
        tourId: tour._id,
        lang: selectedLanguage
      }),
      '*'
    );
  }

  if (!tours) {
    return null;
  }

  if (!isLoaded && !tours?.length) {
    return (
      <div className={classes.preloader}>
        <CircularProgress color={'#c93413'} size={45} />
      </div>
    );
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.secureBtnContainer}>
          <SecurePopup pointerPosition={isSmallScreen ? 'center' : 'left'} />
          {saleOptions?.customization?.emailImage && (
            <img
              className={classes.clientLogo}
              src={saleOptions?.customization?.emailImage}
              alt="Logo"
            />
          )}
        </div>

        <div className={classes.toursContainer}>
          {tours.map((tour, index) => {
            return (
              <div
                key={tour._id}
                className={`
                  ${classes.commonTourStyles}
                  ${classes[tourClassBasedOnToursAmount]} 
                  ${adaptiveStyles}
                `}>
                <TourAggregateCard
                  tourId={tour._id}
                  title={tour.title}
                  pictureUrl={tour.pictureUrl}
                  price={tour.price}
                  wasPrice={tour.wasPrice}
                  discount={tour.discount}
                  currency={currency}
                  participantTitle={tour.data.participantTitle}
                  onClick={() => handleTourBookNowClick(tour, index)}
                  buttonWidth={buttonWidth}
                  isSmallScreen={isSmallScreen}
                  selectedLanguage={selectedLanguage}
                />
              </div>
            );
          })}
        </div>

        {canLoadMore && (
          <Button
            className={classes.showMoreBtn}
            onClick={() =>
              getToursData({
                companyId,
                language: selectedLanguage,
                page: page + 1,
                perPage: 5
              })
            }>
            {isLoaded ? (
              intl.formatMessage(messages.showMore)
            ) : (
              <CircularProgress color={'white'} size={25} />
            )}
          </Button>
        )}

        <div className={classes.languagePickerContainer}>
          <LanguagePicker
            icon={Globe}
            color={'#ff3f15'}
            fontSize={'small'}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={newLanguage => {
              setSelectedLanguage(newLanguage);
              getToursData({
                companyId,
                language: newLanguage,
                page: 0,
                perPage: 5
              });
            }}
          />
        </div>
        <PoweredBy />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  tours: state.tourAggregate?.tours,
  canLoadMore: state.tourAggregate?.canLoadMore,
  isLoaded: state.tourAggregate?.isLoaded,
  page: state.tourAggregate?.page,
  saleOptions: state.tourAggregate?.saleOptions
});

const mapDispatchToProps = dispatch => ({
  fetchToursAggregate: payload => dispatch(actions.fetchToursAggregate(payload)),
  changeBookingWidgetLocale: payload => dispatch(changeBookingWidgetLocale(payload))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TourAggregate);
