import { Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import Information from '../../../assets/images/tour_aggregate/Information.svg';
import Lock from '../../../assets/images/tour_aggregate/Lock.svg';
import messages from './messages';

const useStyles = makeStyles(() => ({
  secureInfo: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#000',
    padding: '12px 14px'
  },
  secureBtn: {
    padding: '7px 8px 8px 13px',
    borderRadius: '9px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.11)',
    backgroundColor: '#f6f6f6',
    color: '#a7a7a7',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#eeeeee',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)'
    }
  },
  popover: props => ({
    borderRadius: '6px',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 31%)',
    overflow: 'visible',
    '&:after': {
      content: '""',
      width: '15px',
      height: '15px',
      top: '-5px',
      left: `${props.pointerPosition == 'center' ? 'calc(50% - 57px)' : '16px'}`,
      position: 'absolute',
      transform: 'rotate(45deg)',
      backgroundColor: '#fff'
    }
  })
}));

export default function SecurePopup({ pointerPosition }) {
  const intl = useIntl();
  const classes = useStyles({ pointerPosition });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        className={classes.secureBtn}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}>
        <img src={Information} alt="" style={{ paddingRight: '10px' }} />
        {intl.formatMessage(messages.secure)}
        <img src={Lock} alt="" style={{ paddingLeft: '30px' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.popover
        }}>
        <div className={classes.secureInfo}>{intl.formatMessage(messages.secureMessage)}</div>
      </Popover>
    </div>
  );
}
