import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './messages';
import tableMessages from '../PromotionsTable/messages';
import { Paper, Grid } from '@material-ui/core';
import SalesPromotionPlaceholder from '../../../assets/images/sale&promotion_placeholder.svg';
import ToursPromotionSummaryButton from '../ToursPromotionSummaryButton';
import PromoPricePopUp from '../PromoPricePopUp';
import Cell from './cell';
import { useCurrency } from '../../../utils/hooks';
import { currencySign, formatMoney } from '../../../utils/helpers';

const useStyles = makeStyles({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  emptyViewText: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    color: '#8c8c8c',
    fontWeight: 'bold',
    padding: '15px 0px 15px 0px'
  },
  emptyViewGrid: {
    marginTop: '200px',
    marginBottom: '200px'
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: 0
  },
  fromPrice: {
    color: '#b0b0b0',
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'line-through'
  },
  discountAndPromoPrice: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  manageBtn: {
    textAlign: 'center'
  }
});

export default function ToursPromotionTable({
  promotionTitle,
  tours,
  handleDeleteTourFromPromo,
  locale
}) {
  const intl = useIntl();
  const classes = useStyles();
  const optionsBool = tours ? true : false;
  const currency = useCurrency();

  const columns = [
    {
      title: promotionTitle,
      field: 'title',
      headerStyle: {
        color: '#6997ff',
        textAlign: 'left'
      },
      type: 'string',
      render: value => <Cell className={classes.title}>{value.title}</Cell>
    },
    {
      title: intl.formatMessage(messages.from),
      field: 'from',
      width: '15%',
      type: 'numeric',
      render: value => (
        <Cell className={classes.fromPrice}>
          {currencySign(currency) + formatMoney({ amount: value.from, currency: currency })}
        </Cell>
      )
    },
    {
      title: intl.formatMessage(messages.discount),
      field: 'discount',
      width: '15%',
      type: 'numeric',
      render: value => <Cell className={classes.discountAndPromoPrice}>{`${value.discount}%`}</Cell>
    },
    {
      title: intl.formatMessage(messages.promotionPrices),
      field: 'promoPrices',
      width: '15%',
      type: 'numeric',
      render: tour => (
        <Cell className={classes.discountAndPromoPrice}>
          <PromoPricePopUp promoPrices={tour.promoPrices} currency={currency} locale={locale} />
        </Cell>
      )
    },
    {
      title: intl.formatMessage(messages.manage),
      field: 'Manage',
      width: '15%',
      type: 'numeric',
      render: tour => (
        <Cell className={classes.manageBtn}>
          <ToursPromotionSummaryButton
            tourId={tour._id}
            title={tour.title}
            handleDeleteTourFromPromo={handleDeleteTourFromPromo}
          />
        </Cell>
      )
    }
  ];

  const emptyBody = (
    <Fragment>
      <Grid container className={classes.emptyViewGrid}>
        <Grid item xs={12}>
          <img width={'150px'} alt="Sales Promotion Placeholder" src={SalesPromotionPlaceholder} />
        </Grid>
        <Grid item xs={12} className={classes.emptyViewText}>
          {intl.formatMessage(messages.noToursInPromotion)}
        </Grid>
      </Grid>
    </Fragment>
  );

  return (
    <MaterialTable
      title={intl.formatMessage(messages.tableTitle)}
      columns={columns}
      data={tours}
      components={{
        Container: props => <Paper className={classes.striped} {...props} />
      }}
      options={{
        pageSizeOptions: [10, 20, 30],
        pageSize: 10,
        paging: optionsBool,
        search: optionsBool,
        showTitle: false,
        headerStyle: {
          fontWeight: 'bold',
          textAlign: 'center',
          whiteSpace: 'nowrap'
        }
      }}
      localization={{
        pagination: {
          labelRowsSelect: intl.formatMessage(tableMessages.labelRowsSelect),
          labelRowsPerPage: intl.formatMessage(tableMessages.labelRowsPerPage),
          firstAriaLabel: intl.formatMessage(tableMessages.firstAriaLabel),
          firstTooltip: intl.formatMessage(tableMessages.firstTooltip),
          previousAriaLabel: intl.formatMessage(tableMessages.previousAriaLabel),
          previousTooltip: intl.formatMessage(tableMessages.previousTooltip),
          nextAriaLabel: intl.formatMessage(tableMessages.nextAriaLabel),
          nextTooltip: intl.formatMessage(tableMessages.nextTooltip),
          lastAriaLabel: intl.formatMessage(tableMessages.lastAriaLabel),
          lastTooltip: intl.formatMessage(tableMessages.lastTooltip)
        },
        toolbar: {
          searchTooltip: intl.formatMessage(tableMessages.searchTooltip),
          searchPlaceholder: intl.formatMessage(tableMessages.searchPlaceholder)
        },
        body: {
          emptyDataSourceMessage: emptyBody
        }
      }}
    />
  );
}

ToursPromotionTable.propTypes = {
  promotionTitle: PropTypes.string,
  tours: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      from: PropTypes.number,
      discount: PropTypes.number,
      promoPrices: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
          participantTitle: PropTypes.object
        })
      )
    })
  ).isRequired
};
