import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  isResetEmailSent: false
};

const resetPasswordSuccess = state => ({
  ...state,
  isResetEmailSent: true
});

export default createReducer(
  {
    [actionTypes.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess
  },
  initialState
);
