import { defineMessages } from 'react-intl';

export const scope = 'app.components.ChannelManagerPage';

export default defineMessages({
  addTours: {
    id: `${scope}.addTours`,
    defaultMessage: 'Add Tours'
  },
  editTitle: {
    id: `${scope}.editTitle`,
    defaultMessage: 'Edit Tour'
  },
  editDesc: {
    id: `${scope}.editDesc`,
    defaultMessage: 'Make changes to this tour on channel manager'
  },
  addTitle: {
    id: `${scope}.addTitle`,
    defaultMessage: 'Add Tours'
  },
  addDesc: {
    id: `${scope}.addDesc`,
    defaultMessage: 'Add tours to the channel manager'
  },
  deleteTitle: {
    id: `${scope}.deleteTitle`,
    defaultMessage: 'Are you sure?'
  },
  deleteDesc: {
    id: `${scope}.deleteDesc`,
    defaultMessage: 'Press delete to remove this tour from the following channels:'
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete'
  }
});
