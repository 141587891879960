import { defineMessages } from "react-intl";


export const scope = `app.containers.OrderConfirmationPage`;

export default defineMessages({
    dayPlural: {
        id: `${scope}.day_plural`,
        defaultMessage: 'days'
    },
    daySingular: {
        id: `${scope}.day_singular`,
        defaultMessage: 'day'
    },
    hourPlural: {
        id: `${scope}.hour_plural`,
        defaultMessage: 'hours'
    },
    hourSingular: {
        id: `${scope}.hour_single`,
        defaultMessage: 'hour'
    },
    minutePlural: {
        id: `${scope}.minute_plural`,
        defaultMessage: 'minutes'
    },
    minuteSingular: {
        id: `${scope}.minute_singular`,
        defaultMessage: 'minute'
    },
    orderConfirmed: {
        id: `${scope}.order_confirmed`,
        defaultMessage: 'Order Confirmed'
    },
    thankYou: {
        id: `${scope}.thank_you`,
        defaultMessage: 'Thank You'
    },
    detailsSentTo: {
        id: `${scope}.details_sent_to`,
        defaultMessage: 'Booking details have been sent to your email'
    },
    bookingReference: {
        id: `${scope}.booking_reference`,
        defaultMessage: 'Booking Reference'
    },
    orderDetails: {
        id: `${scope}.order_details`,
        defaultMessage: 'Order Details'
    },
    date: {
        id: `${scope}.date`,
        defaultMessage: 'Date'
    },
    startingTime: {
        id: `${scope}.starting_time`,
        defaultMessage: 'Starting Time'
    },
    duration: {
        id: `${scope}.duration`,
        defaultMessage: 'Duration'
    },
    viewTickets: {
        id: `${scope}.view_tickets`,
        defaultMessage: 'View Ticket'
    },
    cancellationPolicy: {
        id: `${scope}.cancellation_policy`,
        defaultMessage: 'Cancellation Policy'
    },
    importantInformation: {
        id: `${scope}.important_information`,
        defaultMessage: 'Important Information'
    },
    summaryOfCharges: {
        id: `${scope}.summary_of_charges`,
        defaultMessage: 'Summary Of Charges'
    },
    numberOfGuests: {
        id: `${scope}.number_of_guests`,
        defaultMessage: 'Number Of Guests'
    },
    totalCost: {
        id: `${scope}.total_cost`,
        defaultMessage: 'Total Cost'
    },
    paymentDate: {
        id: `${scope}.payment_date`,
        defaultMessage: 'Payment Date'
    },
    paymentType: {
        id: `${scope}.paymnet_type`,
        defaultMessage: 'Payment Type'
    },
    paymentTypeCard: {
        id: `${scope}.payment_type_card`,
        defaultMessage: 'Card'
    },
    ccNumber: {
        id: `${scope}.cc_number`,
        defaultMessage: 'CC Number'
    },
    guestDetails: {
        id: `${scope}.guest_details`,
        defaultMessage: 'Guest Details'
    },
    leadGuest: {
        id: `${scope}.lead_guest`,
        defaultMessage: 'Lead Guest'
    },
    name: {
        id: `${scope}.name`,
        defaultMessage: 'Name'
    },
    participants: {
        id: `${scope}.participants`,
        defaultMessage: 'Participants'
    },
    participantsEach: {
        id: `${scope}.participants_each`,
        defaultMessage: 'each'
    },
    operationalHours: {
        id: `${scope}.operational_hours`,
        defaultMessage: 'Operational Hours'
    },
    included: {
        id: `${scope}.included`,
        defaultMessage: 'Included'
    },
    notIncluded: {
        id: `${scope}.not_included`,
        defaultMessage: 'Not Included'
    },
    meetingPoint: {
        id: `${scope}.meeting_point`,
        defaultMessage: 'Meeting Point'
    },
    importantDetails: {
        id: `${scope}.important_details`,
        defaultMessage: 'Important Details'
    },
    additionalExtras: {
        id: `${scope}.additional_extras`,
        defaultMessage: 'Additional Extras'
    }
});