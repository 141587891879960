import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/reset-password';

export const resetPassword = email => {
  return axios
    .post(url, { email })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchResetPasswordToken = token => {
  return axios
    .get(`${url}/${token}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const updatePassword = (token, password) => {
  return axios
    .put(`${url}/${token}`, { password })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
