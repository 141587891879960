import React from 'react';

var img = require('../../assets/images/PoweredBy.svg');

class PoweredBy extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <img
          src={img}
          style={{ width: '210px', margin: 'auto', marginTop: '24px' }}
          alt="Powered by TripAdmit"
        />
      </div>
    );
  }
}

export default PoweredBy;
