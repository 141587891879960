import { Analytics } from '@analytics';
import { Button, Chip, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCSVReader } from 'react-papaparse';
import { useHistory } from 'react-router';
import { StyleBreakpoints } from '../../../utils/constants';
import CustomizableModal from '../../Common/CustomizableModal';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  modalBody: {
    width: '80vw',
    maxWidth: '600px'
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      flexWrap: 'wrap'
    }
  },
  title: {
    fontSize: '18px',
    color: '#555555',
    margin: '20px 0 0'
  },
  desc: {
    margin: '5px 0 30px'
  },
  adornment: {
    flexWrap: 'wrap',
    gap: '5px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: ' 0 1 auto',
    maxHeight: 'unset',
    height: 'auto',
    marginBottom: '14px'
  },
  error: {
    color: 'red'
  },
  companyName: {
    fontSize: '18px',
    padding: '0 0 2px 2px'
  },
  setNameButton: {
    order: -1,
    position: 'absolute',
    left: 0,

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginRight: 'auto',
      position: 'static'
    }
  },
  zone: {
    width: '100%',
    border: 'dashed',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    borderColor: '#C8C8C8',
    backgroundColor: '#F0F0F0',
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '20px'
  },
  zoneHover: {
    borderColor: '#686868'
  }
}));

const InviteGuideModal = ({
  isOpen,
  setIsOpen,
  sendInvitesToGuides,
  companyName,
  isRecommendationEmail = false
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { CSVReader } = useCSVReader();

  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [zoneHover, setZoneHover] = useState(false);

  function handleClose() {
    setIsOpen(false);
    setEmails([]);
    setValue('');
    setError(null);
  }

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', 'Space'].includes(evt.key)) {
      evt.preventDefault();

      validateEmails();
    }
  };

  const validateEmails = () => {
    if (value.trim() && isValid(value.trim())) {
      setEmails([...emails, value.trim()]);
      setValue('');
    }
  };

  const handleChange = evt => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = item => {
    setEmails(emails.filter(i => i !== item));
  };

  const handlePaste = evt => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const newEmails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (newEmails) {
      const toBeAdded = newEmails.filter(newEmail => !isInList(newEmail));
      setEmails([...emails, ...toBeAdded]);
    }
  };

  const isValid = email => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);
      return false;
    }

    return true;
  };

  const isInList = email => {
    return emails.includes(email);
  };

  const isEmail = email => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  };

  const handleSetNameClick = () => {
    history.push(`/settings/account/`);
  };

  const isCompanyNameValid = companyName?.length > 2;

  const companyNameOrDefaultString = isCompanyNameValid
    ? companyName
    : intl.formatMessage(messages.nameNotSet);

  const addEmails = data => {
    let emailIndex;
    let isolatedEmails = [];

    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (isEmail(data[i][j])) {
          emailIndex = j;
          break;
        }
      }
    }

    if (emailIndex > -1) {
      data.map(row => {
        isEmail(row[emailIndex]) && isolatedEmails.push(row[emailIndex]);
      });
      error && setError(null);
    } else {
      setError('Valid email address not present in first two lines of file');
    }

    isolatedEmails.length > 0 && setEmails([...emails, ...isolatedEmails]);
  };

  const getModalBody = () => {
    return (
      <div className={classes.modalBody}>
        <h3 className={classes.title}>
          {isRecommendationEmail ? (
            intl.formatMessage(messages.recommendTitle)
          ) : (
            <>
              {intl.formatMessage(messages.inviteGuide)}:{' '}
              <Button
                color="primary"
                className={classes.companyName}
                onClick={isCompanyNameValid ? null : handleSetNameClick}>
                {companyNameOrDefaultString}
              </Button>
            </>
          )}
        </h3>
        <p className={classes.desc}>
          {intl.formatMessage(
            isRecommendationEmail ? messages.recommendEnterAnEmail : messages.enterAnEmail
          )}
        </p>
        <TextField
          multiline
          className={classes.input}
          label={intl.formatMessage(messages.emailAddress)}
          placeholder={intl.formatMessage(messages.placeholder)}
          variant="outlined"
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={validateEmails}
          onPaste={handlePaste}
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.adornment} position="top">
                {emails.map(item => (
                  <Chip key={item} label={item} onDelete={() => handleDelete(item)} />
                ))}
              </InputAdornment>
            )
          }}
        />
        <CSVReader
          onUploadAccepted={results => {
            addEmails(results.data);
            setZoneHover(false);
          }}
          onDragOver={event => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={event => {
            event.preventDefault();
            setZoneHover(false);
          }}>
          {({ getRootProps }) => (
            <>
              <div
                {...getRootProps()}
                className={`${classes.zone} ${zoneHover && classes.zoneHover}`}>
                {intl.formatMessage(messages.dragAndDrop)}
              </div>
            </>
          )}
        </CSVReader>
        {error ? <p className={classes.error}>{error}</p> : <p />}
      </div>
    );
  };

  const getCustomButtons = () => {
    return isCompanyNameValid
      ? []
      : [
          <Button
            key="1"
            className={classes.setNameButton}
            onClick={handleSetNameClick}
            color="primary">
            {intl.formatMessage(messages.setName)}
          </Button>
        ];
  };

  return (
    <CustomizableModal
      isOpen={isOpen}
      onClose={handleClose}
      withHeader={false}
      body={getModalBody()}
      handleFooterCloseButton={handleClose}
      primaryActionButtonData={{
        isDisabled: !emails.length,
        onClick: () => {
          sendInvitesToGuides({ emails, isResend: false, isRecommendationEmail });
          Analytics.track(
            isRecommendationEmail ? 'recommend to a friend clicked' : 'invite guides clicked',
            { number_of_emails: emails.length }
          );
          handleClose();
        },
        content: intl.formatMessage(messages.sendInvite)
      }}
      customButtons={getCustomButtons()}
    />
  );
};

export default InviteGuideModal;
