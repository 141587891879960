//saga
export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const SIGNOUT = 'SIGNOUT';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';

//reducer
export const SET_LANDING_URL = 'SET_LANDING_URL';
export const INIT_USER_LOADING = 'INIT_USER_LOADING';
export const SET_CURRENT_USER = 'SET_USER';
export const SET_TARGET_LOCATION = 'SET_TARGET_LCOATION';
export const INIT_USER_SIGNIN_FAILED = 'INIT_USER_SIGNIN_FAILED';
export const INIT_USER_SIGNUP_FAILED = 'INIT_USER_SIGNUP_FAILED';
