import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

const DefaultButton = withStyles({
  root: {
    color: '#555555',
    textTransform: 'capitalize',
    fontWeight: 500
  }
})(Button);

const ActiveButton = withStyles({
  root: {
    color: 'black',
    textTransform: 'capitalize',
    fontWeight: 500
  }
})(Button);

const NavLink = ({ link, children, style }) => {
  const history = useHistory();

  if (link === window.location.pathname) {
    return (
      <ActiveButton style={style} aria-controls="simple-menu" aria-haspopup="true">
        {children}
      </ActiveButton>
    );
  } else {
    return (
      <DefaultButton
        style={style}
        onClick={() => history.push(link)}
        aria-controls="simple-menu"
        aria-haspopup="true">
        {children}
      </DefaultButton>
    );
  }
};

export default NavLink;
