import { defineMessages } from 'react-intl';
const scope = 'app.components.PromotionModal';

export default defineMessages({
  namePromotionTitle: {
    id: `${scope}.name_promotion`,
    defaultMessage: 'Name Your Promotion'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name'
  },
  internalPurposes: {
    id: `${scope}.internalPurposes`,
    defaultMessage:
      'For internal purposes only, you can edit the name of a promotion after it is setup.'
  },
  selectAll: {
    id: `${scope}.selectAll`,
    defaultMessage: 'Select All'
  },
  priceFrom: {
    id: `${scope}.priceFrom`,
    defaultMessage: 'Price from'
  },
  selectToursForPromo: {
    id: `${scope}.selectToursForPromo`,
    defaultMessage: 'Select the tours you would like to add to your promotion'
  },

  setPromotionDetails: {
    id: `${scope}.setPromotionDetails`,
    defaultMessage: 'Set Promotion Details'
  },
  deletePromo: {
    id: `${scope}.deletePromo`,
    defaultMessage: 'Delete Promotion'
  },
  schedule: {
    id: `${scope}.schedule`,
    defaultMessage: 'Schedule'
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next'
  },
  deletePromotion: {
    id: `${scope}.deletePromotion`,
    defaultMessage: 'Delete Promotion'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update'
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create'
  },

  promoDatesTitle: {
    id: `${scope}.promoDatesTitle`,
    defaultMessage: 'Start and End Dates'
  },
  promoDatesDesc: {
    id: `${scope}.promoDatesDesc`,
    defaultMessage:
      'Enter the starting and ending dates for your promotion, a promotion will begin at 00:00 of your start date and end at 00:00 of your end date.'
  },
  travelByTitle: {
    id: `${scope}.travelByTitle`,
    defaultMessage: 'Travel By Date Range'
  },
  travelByDesc: {
    id: `${scope}.travelByDesc`,
    defaultMessage:
      'Enter a date range that users can book this promotion in. Dates outside of this time range will not be bookable in this promotion.'
  },
  discountTitle: {
    id: `${scope}.discountTitle`,
    defaultMessage: 'Percentage Discount'
  },
  discountDesc: {
    id: `${scope}.discountDesc`,
    defaultMessage:
      'Enter the percentage discount you would like your tours to be discounted by for the promotion running time.'
  },
  promoCodeTitle: {
    id: `${scope}.promoCodeTitle`,
    defaultMessage: 'Promotion Code'
  },
  promoCodeDesc: {
    id: `${scope}.promoCodeDesc`,
    defaultMessage: 'Customers enter this code at the checkout'
  },
  reviewTitle: {
    id: `${scope}.reviewTitle`,
    defaultMessage: 'Review & Save'
  },
  reviewDesc: {
    id: `${scope}.reviewDesc`,
    defaultMessage:
      'This promotion will run from the provided dates with the percentage discount applied to all ticket categories.'
  },
  promoDatesSummary: {
    id: `${scope}.promoDatesSummary`,
    defaultMessage: 'Promotion Dates'
  },
  travelDatesSummary: {
    id: `${scope}.travelDatesSummary`,
    defaultMessage: 'Travel by Dates'
  },
  discountSummary: {
    id: `${scope}.discountSummary`,
    defaultMessage: 'Promotion Percentage'
  },
  promoCodeSummary: {
    id: `${scope}.promoCodeSummary`,
    defaultMessage: 'Promo Code'
  },
  percentage: {
    id: `${scope}.percentage`,
    defaultMessage: 'Percentage'
  },
  deleteTourModalTitle: {
    id: `${scope}.deleteTourModalTitle`,
    defaultMessage: 'Actions'
  }
});
