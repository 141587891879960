import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../utils/constants';
import PoweredBy from '../PoweredBy';
import ExtraItem from './ExtraItem';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    maxWidth: '1220px',
    width: '100%',
    paddingTop: '0px',
    margin: '0 auto'
  },
  leftSideContainer: {
    marginBottom: '20px',
    paddingTop: '0!important',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      paddingTop: '24px!important'
    }
  },
  rightSideContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  firstHeading: {
    fontSize: '39px',
    color: '#424242',
    margin: '4px 0',
    [theme.breakpoints.down(StyleBreakpoints.lg)]: {
      fontSize: '30px',
      lineHeight: '38px'
    }
  },
  sectionDesc: {
    fontSize: '22px',
    color: '#000',
    fontWeight: 'bold',
    [theme.breakpoints.down(StyleBreakpoints.lg)]: {
      fontSize: '19px',
      lineHeight: '24px'
    }
  },
  extrasControlsBlock: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginTop: '5px'
  },
  borderedButton: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    height: '32px',
    width: '141px',
    fontSize: '12px',
    alignSelf: 'flex-end'
  },
  completeButton: {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    width: '100%',
    height: '52px',
    borderRadius: '3px',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginBottom: '16px'
  },
  extrasList: {
    margin: '50px 0 20px'
  },
  secondSubmitBlock: {
    width: '100%',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none'
    }
  }
}));

//TODO: Split into independent component during booking widget logic refactoring
export default function AdditionalExtrasPurchase({
  participantsAmount,
  currency,
  extras,
  handleCompletePurchase,
  handleExtraCountChange,
  error,
  renderRightHandSide
}) {
  const classes = useStyles();
  const intl = useIntl();

  const isDisabled = extras.some(e => e.isMandatory);
  const canBePurchased = extras.every(e =>
    e.isMandatory == true ? e.count >= participantsAmount : true
  );

  const handleSkip = () => handleCompletePurchase({ isExtrasSkipped: true });

  return (
    <div className={classes.modalContainer}>
      <Grid container spacing={6} style={{ width: '100%', margin: '0' }}>
        <Grid item xs={12} md={7} className={classes.leftSideContainer}>
          <h1 className={classes.firstHeading}>{intl.formatMessage(messages.additionalExtras)}</h1>

          <span className={classes.sectionDesc}>{intl.formatMessage(messages.selectExtras)}</span>

          <div className={classes.extrasControlsBlock}>
            <Button
              variant="outlined"
              className={classes.borderedButton}
              disabled={isDisabled}
              onClick={handleSkip}>
              {intl.formatMessage(messages.skip)}
            </Button>

            <div className={classes.extrasList}>
              {extras.map(extra => (
                <ExtraItem
                  key={extra._id}
                  id={extra._id}
                  participantsAmount={participantsAmount}
                  currency={currency}
                  title={extra.title}
                  description={extra.description}
                  image={extra.image}
                  price={extra.price}
                  count={extra.count}
                  isMandatory={extra.isMandatory}
                  handleExtraCountChange={handleExtraCountChange}
                />
              ))}
            </div>

            <Button
              variant="contained"
              color="primary"
              className={classes.completeButton}
              onClick={() => handleCompletePurchase()}
              disabled={!canBePurchased}>
              {intl.formatMessage(messages.completePurchase)}
            </Button>
            {error && <Alert severity="error">{error.details}</Alert>}
          </div>
        </Grid>
        <Grid item xs={12} md={5} className={classes.rightSideContainer}>
          {renderRightHandSide()}
        </Grid>
        <Grid item className={classes.secondSubmitBlock}>
          <Button
            variant="contained"
            color="primary"
            className={classes.completeButton}
            onClick={handleCompletePurchase}
            disabled={!canBePurchased}>
            {intl.formatMessage(messages.completePurchase)}
          </Button>
        </Grid>
      </Grid>
      <PoweredBy />
    </div>
  );
}
