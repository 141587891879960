import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  isAssigned: false,
  users: []
};

const assignCardToUserSuccess = state => ({
  ...state,
  isAssigned: true
});

const fetchUsersSuccess = (state, users) => ({
  ...state,
  users
});

export default createReducer(
  {
    [actionTypes.ASSIGN_CARD_TO_USER_SUCCESS]: assignCardToUserSuccess,
    [actionTypes.FETCH_USERS_SUCCESS]: fetchUsersSuccess
  },
  initialState
);
