import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.Profile.Tours';

export default defineMessages({
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  yourTours: {
    id: `${scope}.your_tours`,
    defaultMessage: 'Your Tours'
  },
  toursSubHeader: {
    id: `${scope}.sub_header`,
    defaultMessage: 'Select your tours so customers can leave a review'
  },
  toursEmptyStateMessage: {
    id: `${scope}.tours_empty_state`,
    defaultMessage: 'Selected tours will appear here'
  },
  selectTours: {
    id: `${scope}.select_tours`,
    defaultMessage: 'Select Tours'
  }
});
