import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ChannelsSummary from '../ChannelsSummary';
import messages from './messages';

const useStyles = makeStyles({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  activeChannelIndicator: {
    display: 'inline-block',
    marginLeft: '8px',
    position: 'relative',
    top: '1px',
    width: '14px',
    height: '14px',
    backgroundColor: '#b4ec51',
    border: '1px solid #fff',
    borderRadius: '50%',
    boxShadow: '0 2px 4px #ccc'
  }
});

const ChannelManagerTable = ({
  integratedTours,
  toursById,
  deleteIntegratedTour,
  editIntegratedTour
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const columns = [
    {
      title: intl.formatMessage(messages.CMTours),
      field: 'title',
      cellStyle: {
        fontWeight: 'bold'
      }
    },
    {
      title: (
        <span>
          {intl.formatMessage(messages.activeChannels)}
          <span className={classes.activeChannelIndicator} />
        </span>
      ),
      headerStyle: { textAlign: 'right', paddingRight: '5vw' },
      cellStyle: { textAlign: 'right' },
      field: 'channelsInfo',
      render: integratedTours?.length
        ? dataForTable => (
            <ChannelsSummary
              tourId={dataForTable.tourId}
              integratedTour={dataForTable.integratedTour}
              channelsData={dataForTable.channelsData}
              deleteIntegratedTour={deleteIntegratedTour}
              editIntegratedTour={editIntegratedTour}
            />
          )
        : null
    }
  ];

  const dataForTable = useMemo(() => {
    if (integratedTours?.length) {
      return integratedTours.map(integratedTour => ({
        integratedTour: integratedTour,
        tourId: integratedTour.tourId,
        channelsData: integratedTour.integrations,
        title: toursById[integratedTour.tourId]?.displayName
      }));
    }
    return [];
  }, [integratedTours, toursById]);

  return (
    <MaterialTable
      title={intl.formatMessage(messages.channelManager)}
      columns={columns}
      data={dataForTable}
      options={{
        pageSizeOptions: [10, 20, 30],
        pageSize: 10
      }}
      components={{
        Container: props => <Paper className={classes.striped} {...props} />
      }}
      localization={{
        pagination: {
          labelRowsSelect: intl.formatMessage(messages.labelRowsSelect),
          labelRowsPerPage: intl.formatMessage(messages.labelRowsPerPage),
          firstAriaLabel: intl.formatMessage(messages.firstAriaLabel),
          firstTooltip: intl.formatMessage(messages.firstTooltip),
          previousAriaLabel: intl.formatMessage(messages.previousAriaLabel),
          previousTooltip: intl.formatMessage(messages.previousTooltip),
          nextAriaLabel: intl.formatMessage(messages.nextAriaLabel),
          nextTooltip: intl.formatMessage(messages.nextTooltip),
          lastAriaLabel: intl.formatMessage(messages.lastAriaLabel),
          lastTooltip: intl.formatMessage(messages.lastTooltip)
        },
        toolbar: {
          searchTooltip: intl.formatMessage(messages.searchTooltip),
          searchPlaceholder: intl.formatMessage(messages.searchPlaceholder)
        },
        body: {
          emptyDataSourceMessage: intl.formatMessage(messages.emptyDataSourceMessage)
        }
      }}
    />
  );
};

export default ChannelManagerTable;
