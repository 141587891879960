import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { currencySign, formatMoney } from '../../../../utils/helpers';
import { BreakdownRow } from './common';
import messages from './messages';

const useStyles = makeStyles({
  header: {
    fontWeight: 'bolder',
    fontSize: '16px',
    marginBottom: '8px'
  },
  total: {
    fontWeight: 'bolder',
    fontSize: '16px',
    marginTop: '12px'
  },

  element: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  discountBlock: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#ff4e4e',
    marginTop: '8px'
  }
});

function formatPrice({ amount, currency }) {
  return `${currencySign(currency)} ${formatMoney({ amount, currency })}`;
}

export default function IntermediateTourPrice({
  currency,
  participants,
  extras,
  discountPercent,
  isSaleDate
}) {
  const classes = useStyles();
  const intl = useIntl();

  const totalParticipants = participants.reduce((acc, { price, count }) => acc + price * count, 0);
  const totalExtras = extras.reduce((acc, { price, count }) => acc + price * count, 0);

  const discountAmount =
    discountPercent && isSaleDate ? Math.round((totalParticipants * discountPercent) / 100) : 0;

  const totalParticipantsWithDiscount = totalParticipants - discountAmount;

  const total = totalParticipantsWithDiscount + totalExtras;

  return (
    <div>
      <div className={classes.header}>{intl.formatMessage(messages.summaryOfCharges)}</div>
      <div>
        {participants.map(participant => (
          <div key={participant} className={classes.element}>
            <BreakdownRow
              price={formatPrice({
                amount: participant.price * participant.count,
                currency
              })}
              title={`${participant.count} ${participant.title}`}
            />
          </div>
        ))}
        {extras.map(extra => (
          <div key={extra} className={classes.element}>
            <BreakdownRow
              price={formatPrice({
                amount: extra.price * extra.count,
                currency
              })}
              title={`${extra.count} ${extra.title}`}
            />
          </div>
        ))}
        {discountAmount > 0 ? (
          <div className={classes.discountBlock}>
            <BreakdownRow
              price={formatPrice({
                amount: discountAmount,
                currency
              })}
              title={`${intl.formatMessage(messages.discount)} (${intl.formatMessage(
                messages.sale
              )} ${discountPercent}%)`}
              minus={true}
            />
          </div>
        ) : null}
      </div>

      <div className={classes.total}>
        <BreakdownRow
          price={formatPrice({ amount: total, currency })}
          title={intl.formatMessage(messages.tripTotal)}
        />
      </div>
    </div>
  );
}
