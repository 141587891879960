import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

const useStyles = makeStyles({
  cell: {
    marginLeft: 28, whiteSpace: 'nowrap'
  }
})

const Cell = ({ children, className }) => {
  const classes = useStyles();
  return <div className={[className, classes.cell].join(' ')}>{children}</div>;
};

export default Cell;
