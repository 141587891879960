import { call, delay, put, takeLatest } from '@redux-saga/core/effects';
import { fetchResetPasswordToken, updatePassword } from '../../api/resetPassword';
import * as userActions from '../../store/user/actionCreators';
import { SIGNIN } from '../../store/user/actions';
import { signinSaga } from '../../store/user/saga';
import { extractStatusBarError, snackbarStatus } from '../StatusBar';
import { setSnackbarStatus } from '../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* fetchResetPasswordTokenWorker({ payload }) {
  try {
    const resetPasswordToken = yield call(fetchResetPasswordToken, payload);

    yield put(actions.fetchResetTokenSuccess(resetPasswordToken));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err)));
  }
}

function* updatePasswordWorker({ payload: { token, password, history } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));

    const updatedUser = yield call(updatePassword, token, password);

    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(userActions.signin(updatedUser.email, password, '/', history));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err)));
  }
}

export default function* resetPasswordWatcher() {
  yield takeLatest(types.FETCH_RESET_TOKEN, fetchResetPasswordTokenWorker);
  yield takeLatest(types.UPDATE_PASSWORD, updatePasswordWorker);
  yield takeLatest(SIGNIN, signinSaga);
}
