import { MODAL_MODE } from '../../components/Promotions/PromotionModal';
import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  modalData: {
    data: null,
    mode: MODAL_MODE.CREATE,
    isOpen: false,
    hasPromoCode: false,
    handler: null
  },
  toursFromPromotion: [],
  promotions: [],
  tours: [],
  status: {
    error: null
  }
};

const fetchPromotions = (state, promotions) => ({
  ...state,
  status: {
    error: null
  },
  promotions: [...promotions]
});

const fetchTours = (state, tours) => ({
  ...state,
  tours: [...tours.filter(tour => tour.tourState == 'published')]
});

const updateModalData = (state, payload) => ({
  ...state,
  modalData: payload
});

const fetchToursFromPromotion = (state, payload) => ({
  ...state,
  status: {
    error: null
  },
  toursFromPromotion: payload
});

const fetchPromotionById = (state, payload) => ({
  ...state,
  status: {
    error: null
  },
  promotion: payload
});

const facedError = (state, error) => ({
  ...state,
  status: {
    error: error
  }
});

export default createReducer(
  {
    [actionTypes.FETCH_TOURS_SUCCESS]: fetchTours,
    [actionTypes.FETCH_PROMOTIONS_SUCCESS]: fetchPromotions,
    [actionTypes.UPDATE_MODAL_DATA]: updateModalData,
    [actionTypes.FETCH_PROMOTION_TOURS_SUCCESS]: fetchToursFromPromotion,
    [actionTypes.FETCH_PROMOTION_BY_ID_SUCCESS]: fetchPromotionById,
    [actionTypes.ACTION_ERROR]: facedError
  },
  initialState
);
