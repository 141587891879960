import { defineMessages } from 'react-intl';

const scope = 'app.component.AdditionalExtras.AdditionalExtrasSummary';

export default defineMessages({
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View'
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete'
  },
  convertToDraft: {
    id: `${scope}.convertToDraft`,
    defaultMessage: 'Convert To Draft'
  }
})