import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../messages';

const NamePromotion = function({ title, handleChange, setIsNextStepDisabled }) {
  const intl = useIntl();

  function handleNameChange(e) {
    setIsNextStepDisabled(!e.target.value.trim());
    handleChange(e);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              handleNameChange(ev);
              ev.preventDefault();
            }
          }}
          id="tour-display-name"
          name="title"
          value={title}
          onChange={handleNameChange}
          label={intl.formatMessage(messages.name)}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default NamePromotion;
