import { createReducer } from '../../utils/helpers';
import * as actionTypes from './types';

const initialState = {
  tours: [],
  integratedTours: [],
  isModalOpen: false,
  validationErrors: null
};

const updateById = (integratedTours, updatedTour) => {
  return integratedTours.map(tour => {
    if (tour.tourId == updatedTour.tourId) {
      return updatedTour;
    }
    return tour;
  });
};

const sortIntegratedToursChannelsByName = integrations => {
  return integrations.sort((a, b) => {
    if (a.channelType < b.channelType) return -1;
    if (a.channelType > b.channelType) return 1;
    return 0;
  });
};
const fetchTours = (state, tours) => ({
  ...state,
  tours: [...tours]
});

const fetchIntegratedTours = (state, integratedTours = []) => ({
  ...state,
  integratedTours: [
    ...integratedTours.map(tour => ({
      ...tour,
      integrations: sortIntegratedToursChannelsByName(tour.integrations)
    }))
  ]
});

const addToursToIntegratedTour = (state, newIntegratedTours) => ({
  ...state,
  integratedTours: [...newIntegratedTours, ...state.integratedTours]
});

const updateIntegratedTour = (state, updatedIntegratedTour) => ({
  ...state,
  integratedTours: updateById(state.integratedTours, updatedIntegratedTour)
});

const deleteIntegratedTour = (state, tourId) => ({
  ...state,
  integratedTours: state.integratedTours.filter(integratedTour => integratedTour.tourId != tourId)
});

const addValidationFailureInfo = (state, validationErrors) => ({
  ...state,
  validationErrors: [...validationErrors]
});

const cleanValidationErrors = state => ({
  ...state,
  validationErrors: []
});

const setIsModalOpen = (state, isModalOpen) => ({
  ...state,
  isModalOpen
});

export default createReducer(
  {
    [actionTypes.FETCH_TOURS_SUCCESS]: fetchTours,
    [actionTypes.FETCH_INTEGRATED_TOURS_SUCCESS]: fetchIntegratedTours,
    [actionTypes.ADD_TOURS_TO_INTEGRATED_TOURS_SUCCESS]: addToursToIntegratedTour,
    [actionTypes.UPDATE_INTEGRATED_TOUR_SUCCESS]: updateIntegratedTour,
    [actionTypes.DELETE_INTEGRATED_TOUR_SUCCESS]: deleteIntegratedTour,
    [actionTypes.TOURS_VALIDATION_FAILED]: addValidationFailureInfo,
    [actionTypes.CLEAN_VALIDATION_ERRORS]: cleanValidationErrors,
    [actionTypes.SET_IS_MODAL_OPEN]: setIsModalOpen
  },
  initialState
);
