import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Onboarding';

export default defineMessages({
  profileDescription: {
    id: `${scope}.profile_description`,
    defaultMessage: 'Your profile information'
  },
  tippingInfoDescription: {
    id: `${scope}.tipping_info_description`,
    defaultMessage: 'Let’s set some defaults for how you will get paid'
  },
  payoutDescription: {
    id: `${scope}.payout_description`,
    defaultMessage: 'Select a payout method and add your details'
  },
  profileTitle: {
    id: `${scope}.profile_title`,
    defaultMessage: 'Profile'
  },
  tippingInfoTitle: {
    id: `${scope}.tipping_info_title`,
    defaultMessage: 'Tipping Information'
  },
  payoutTitle: {
    id: `${scope}.payout_title`,
    defaultMessage: 'Payout'
  },
  onlySelectPaymentMethod: {
    id: `${scope}.only_select_payment_method`,
    defaultMessage: 'Only select a payment method if you are collecting tips yourself.'
  }
});
