import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/tour';

export function createTourDraft(draftDetails) {
  return axios.post(`${url}/draft`, draftDetails);
}

export function fetchTourDetails(tourId) {
  return axios.get(`${url}/${tourId}`).then(res => res.data.data);
}

export function fetchAvailableTours(status = null, formatting = 'short', forManualBooking = false) {
  const tourStatus = status ? `&status=${status}` : '';
  return axios.get(
    `${url}?formatting=${formatting}&forManualBooking=${forManualBooking}${tourStatus}`
  );
}

export function updateDraftTour(tourId, newDetails) {
  return axios
    .put(`${url}/draft/${tourId}`, newDetails)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function uploadTourImage(tourId, images) {
  const formData = new FormData();
  images.forEach(image => formData.append('images', image));

  return axios
    .post(`${url}/${tourId}/image`, formData)
    .then(res => res.data.data.url)
    .catch(ApiError.ofAndThrow);
}

export function createSchedule(tourId, schedule) {
  return axios
    .post(`${url}/draft/${tourId}/schedule`, schedule)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function updateSchedule(tourId, schedule) {
  return axios
    .put(`${url}/draft/${tourId}/schedule`, schedule)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function updateSeasonPricing(tourId, pricing) {
  return axios
    .post(`${url}/draft/${tourId}/pricing`, pricing)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function cloneTour(tourId, cloneDto) {
  return axios.post(`${url}/${tourId}/clone`, cloneDto);
}

export function publishTour(tourId) {
  return axios.post(`${url}/${tourId}/publish`).catch(ApiError.ofAndThrow);
}

export function unpublishTour(tourId) {
  return axios.post(`${url}/${tourId}/unpublish`).catch(ApiError.ofAndThrow);
}

export function fetchTourTicketingDetails(tourId, language, currency) {
  return axios
    .get(`${url}/${tourId}/details/ticketing?language=${language}&currency=${currency}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function fetchTourSaleOptions(tourId, formatting = 'short') {
  return axios
    .get(`${url}/${tourId}/sale-options?formatting=${formatting}`)
    .then(res => res.data.data);
}

export function fetchTourSaleOptionsByCompanyId(companyId, formatting = 'short') {
  return axios
    .get(`${url}/${companyId}/sale-options-by-company-id?formatting=${formatting}`)
    .then(res => res.data.data);
}

export function fetchCompanyToursTicketingDetails({ language, currency }) {
  return axios
    .get(`${url}/details/company-tours?language=${language}&currency=${currency}`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function toggleDisplayOnWidget(tourId, displayOnTourAggregator) {
  return axios
    .put(`${url}/${tourId}/toggle-display`, { displayOnTourAggregator })
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export function deleteTour(tourId) {
  return axios
    .delete(`/api/v1/tour/${tourId}/delete`)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}
