import { defineMessages } from "react-intl";

export const scope = `app.containers.BookingPage`;

export default defineMessages({
    dayPlural: {
        id: `${scope}.day_plural`,
        defaultMessage: 'days'
    },
    daySingular: {
        id: `${scope}.day_singular`,
        defaultMessage: 'day'
    },
    hourPlural: {
        id: `${scope}.hour_plural`,
        defaultMessage: 'hours'
    },
    hourSingular: {
        id: `${scope}.hour_single`,
        defaultMessage: 'hour'
    },
    minutePlural: {
        id: `${scope}.minute_plural`,
        defaultMessage: 'minutes'
    },
    minuteSingular: {
        id: `${scope}.minute_singular`,
        defaultMessage: 'minute'
    },
});