import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ChannelsSummary from '../../ChannelsSummary';
import messages from './messages';

const useStyles = makeStyles({
  tableWithPadding: {
    maxWidth: '1400px',
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        paddingLeft: '50px',
        position: 'static'
      }
    }
  },
  errorMessage: {
    padding: '10px',
    color: 'red'
  }
});

const ModalTable = ({
  mode,
  toursToDisplay,
  toursById,
  setDataToSave,
  integratedToursById,
  validationErrors
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const translatedErrors = {
    '@TA-TS/integration/cut-off-times': intl.formatMessage(messages.cutOffTimesError)
  };

  useEffect(() => {
    const formDataForTable = () => {
      if (toursToDisplay?.length) {
        return toursToDisplay.map(tour => ({
          tour: tour,
          tourId: tour.tourId,
          channelsData: tour.integrations,
          title: toursById[tour.tourId]?.displayName,
          isSelected: false
        }));
      }
      return [];
    };
    setTableData([...formDataForTable()]);
  }, [toursToDisplay, toursById]);

  useEffect(() => {
    if (validationErrors?.length) {
      const updatedData = tableData.map(td => {
        if (validationErrors.some(e => e.tourId == td.tourId && e.errors)) {
          return {
            ...td,
            tableData: {
              ...td.tableData,
              showDetailPanel: renderErrorMessages
            }
          };
        }
        return { ...td };
      });
      setTableData(updatedData);
      setDataToSave(getDataForSavingFromTableData(updatedData));
    }
  }, [validationErrors]);

  function renderErrorMessages(rowData) {
    return (
      <div className={classes.errorMessage}>
        {validationErrors
          .filter(e => e.tourId == rowData.tourId)[0]
          .errors.map(errorMessage => (
            <p style={{ margin: '2px' }} key={errorMessage}>
              {displayError(errorMessage)}
            </p>
          ))}
      </div>
    );
  }

  function displayError(errorMessage) {
    return translatedErrors[errorMessage] || errorMessage;
  }

  const getDataForSavingFromTableData = tableData => {
    if (mode == 'add') {
      return tableData
        .filter(td => td.tableData.checked)
        .map(td => ({
          tourId: td.tourId,
          integrations: td.channelsData
        }));
    } else {
      return tableData.map(td => ({
        tourId: td.tourId,
        integrations: td.channelsData
      }));
    }
  };

  const getChannelsSummaryByMode = mode => {
    if (!toursToDisplay?.length) {
      return null;
    }
    switch (mode) {
      case 'delete': {
        return dataForTable => (
          <ChannelsSummary tourId={dataForTable.tourId} channelsData={dataForTable.channelsData} />
        );
      }
      case 'edit': {
        return dataForTable => (
          <ChannelsSummary
            tourId={dataForTable.tourId}
            channelsData={dataForTable.channelsData}
            handleStatusChange={handleStatusChange}
          />
        );
      }
      default: {
        return dataForTable => (
          <ChannelsSummary
            tourId={dataForTable.tourId}
            channelsData={dataForTable.channelsData}
            handleStatusChange={handleStatusChange}
            isSelected={dataForTable.isSelected}
          />
        );
      }
    }
  };

  const handleStatusChange = (tourId, cannelId, value) => {
    const channelPrevData = integratedToursById[tourId]?.integrations?.find(
      c => c.channelId == cannelId
    );

    const updatedData = tableData.map(tour => {
      if (tour.tourId == tourId) {
        return {
          ...tour,
          channelsData: tour.channelsData.map(i => {
            if (i.channelId == cannelId) {
              return {
                ...i,
                integrationStatus: value
                  ? channelPrevData?.integrationStatus == 'active'
                    ? 'active'
                    : 'pending_update'
                  : 'not_active'
              };
            }
            return i;
          })
        };
      }
      return tour;
    });

    setTableData(updatedData);
    setDataToSave(getDataForSavingFromTableData(updatedData));
  };

  const handleSelection = rows => {
    const updatedData = tableData.map(tour => {
      const isUnselected =
        !rows.find(row => row.tourId == tour.tourId) &&
        selectedRows.find(row => row.tourId == tour.tourId);

      const isSelectedFirstTime =
        rows.find(row => row.tourId == tour.tourId) &&
        !selectedRows.find(row => row.tourId == tour.tourId);

      if (isSelectedFirstTime) {
        tour.isSelected = true;
      }

      if (isUnselected) {
        tour.isSelected = false;
      }

      if (isSelectedFirstTime || isUnselected) {
        return {
          ...tour,
          channelsData: tour.channelsData.map(channel => ({
            ...channel,
            integrationStatus: isUnselected
              ? 'not_active'
              : isSelectedFirstTime
              ? 'pending_update'
              : channel.integrationStatus
          }))
        };
      } else {
        return tour;
      }
    });

    setTableData([...updatedData]);
    setSelectedRows(rows);
    setDataToSave(getDataForSavingFromTableData(updatedData));
  };

  const columns = [
    {
      title:
        mode == 'add' ? intl.formatMessage(messages.selectAll) : intl.formatMessage(messages.tour),
      field: 'title',
      cellStyle: {
        fontWeight: 'bold'
      }
    },
    {
      title: intl.formatMessage(messages.tourId),
      field: 'tourId',
      cellStyle: {
        fontWeight: 'bold'
      }
    },
    {
      title: intl.formatMessage(messages.availableChannels),
      field: 'channels',
      cellStyle: {
        paddingTop: '10px',
        paddingBottom: mode == 'delete' ? '10px' : '0'
      },
      render: getChannelsSummaryByMode(mode)
    }
  ];

  return (
    <>
      <MaterialTable
        columns={columns}
        data={tableData}
        options={{
          tableLayout: 'fixed',
          paging: false,
          showTitle: false,
          search: false,
          toolbar: false,
          selection: mode == 'add'
        }}
        onSelectionChange={handleSelection}
        components={{
          Container: props => <div className={classes.tableWithPadding} {...props} />
        }}
      />
    </>
  );
};

export default ModalTable;
