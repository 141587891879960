import { push } from 'connected-react-router';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { getSubscriptionDetails } from '../../api/subscription';
import { subscriptionFailed } from './actions';
import { SubscriptionPostCheckoutStatus, subscriptionErrorTypes } from './constants';
import { START_POLLING } from './types';

const POLL_INTERVAL = 3000; // Adjust as needed
const MAX_FETCH_ATTEMPTS = 10; // Adjust as needed

function* pollStatusWorker({ payload }) {
  // Initial delay before polling
  yield delay(POLL_INTERVAL);

  if (!payload) {
    yield put(subscriptionFailed());
    return;
  }

  let fetchAttempts = 0;

  // Poll for the subscription details until max attempts
  while (fetchAttempts < MAX_FETCH_ATTEMPTS) {
    try {
      const { subscriptionDetails, hasSignedUpBefore, companyName, email } = yield call(
        getSubscriptionDetails,
        payload
      );

      if (hasSignedUpBefore) {
        // If the current user is signed in we redirect them to settings.
        const user = yield select(state => state.user.user);
        if (user) {
          yield put(push('/settings'));
          return;
        }

        yield put(subscriptionFailed(subscriptionErrorTypes.subscribed_user_exists));
        return;
      }

      if (
        subscriptionDetails.status === SubscriptionPostCheckoutStatus.SUCCEEDED ||
        subscriptionDetails.status === SubscriptionPostCheckoutStatus.ACTIVE
      ) {
        yield put(push('/signup', { companyName, email, checkoutId: payload }));
        return;
      } else if (
        !subscriptionDetails.status ||
        subscriptionDetails.status === SubscriptionPostCheckoutStatus.FAILED
      ) {
        yield put(subscriptionFailed());
        return;
      }

      // Wait for the next polling interval
      yield delay(POLL_INTERVAL);
      fetchAttempts += 1;
    } catch (error) {
      yield put(subscriptionFailed());
      return;
    }
  }

  yield put(subscriptionFailed());
}

function* subscriptionPostCheckoutWatcher() {
  yield takeLatest(START_POLLING, pollStatusWorker);
}

export default subscriptionPostCheckoutWatcher;
