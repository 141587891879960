/* eslint-disable no-console */
import { Button, CircularProgress } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import queryString from 'query-string';
import * as R from 'ramda';
import React from 'react';
import {
  bwButtonTrans,
  fromOnlyTrans,
  perPersonTrans,
  perTrans
} from '../../../utils/common_translations/widget';
import {
  changeColorBrightness,
  currencies,
  moneyFromIntegerToDecimal
} from '../../../utils/helpers';

function getContrastYIQ(hexcolor = '') {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

function getShadeIndex(initialColor) {
  const fontColor = getContrastYIQ(initialColor);
  if (fontColor == 'black') return -0.9;
  return 0.7;
}

const classes = {
  bookNowSectionWrapper: {
    backgroundColor: '#010e1d',
    position: 'sticky',
    top: '-2px',
    zIndex: '999'
  },
  bookNowSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '78px',
    padding: '0 25px',
    color: '#fff',
    width: '100%',
    maxWidth: '1340px',
    margin: '0 auto',
    fontSize: '14px'
  },

  bookNowLeftBlock: {
    textAlign: 'left'
  },
  price: {
    fontSize: '16px',
    marginRight: '8px',
    display: 'inline-block',
    fontWeight: '700'
  },

  from: {
    display: 'block',
    fontSize: '11px',
    marginBottom: '5px',
    fontWeight: '700'
  },
  perUnit: {
    fontSize: '11px',
    marginRight: '8px',
    fontWeight: '700'
  }
};

export default class BookNowButton extends React.Component {
  constructor(props) {
    super(props);
    const qp = queryString.parse(this.props.location.search);
    this.state = {
      qp,
      language: queryString.parse(this.props.location.search).language || 'en',
      firstText: queryString.parse(this.props.location.search).firstText,
      secondText: queryString.parse(this.props.location.search).secondText,
      hidePriceBlock: queryString.parse(this.props.location.search).hidePriceBlock,
      btntext: queryString.parse(this.props.location.search).btntext || null
    };
  }

  getBookNowString() {
    if (this.state.btntext) {
      return this.state.btntext;
    }
    if (bwButtonTrans[this.state.language]) {
      return bwButtonTrans[this.state.language];
    }
    return bwButtonTrans['en'];
  }

  getFromOnlyString() {
    if (this.state.qp.firstText) {
      return this.state.qp.firstText;
    }
    if (fromOnlyTrans[this.state.language]) {
      return fromOnlyTrans[this.state.language];
    }
    return fromOnlyTrans['en'];
  }

  getPerPersonString() {
    if (this.state.qp.secondText) {
      return this.state.qp.secondText;
    }
    if (this.state.participantType) {
      return perTrans[this.state.language] + this.state.participantType;
    }
    if (perPersonTrans[this.state.language]) {
      return perPersonTrans[this.state.language];
    }
    return perPersonTrans['en'];
  }

  handleBookNowClick() {
    if (this.state.aggregateTours.length == 1) {
      this.notifyIframeOpenModal();
    } else if (this.state.aggregateTours.length > 1) {
      this.notifyIframeOpenAggregateTourModal();
    }
  }

  notifyIframeOpenModal() {
    window.parent.postMessage(
      JSON.stringify({
        msgType: 'openIframe',
        tourId: this.state.aggregateTours[0]._id,
        lang: this.state.language
      }),
      '*'
    );
  }

  notifyIframeOpenAggregateTourModal() {
    window.parent.postMessage(
      JSON.stringify({
        msgType: 'openAggregateModal',
        companyId: this.state.qp.companyId,
        lang: this.state.language,
        toursAmount: this.state.aggregateTours.length,
        currency: this.state.currency
      }),
      '*'
    );
  }

  async fetchTourAggregateDetails(currency, language) {
    this.setState({ isLoaded: false });
    try {
      const aggregateTours = await fetch(
        `/api/v1/tour-order?companyId=${
          this.state.qp.companyId
        }&language=${language}&currency=${currency}&page=${0}&perPage=${50}`
      )
        .then(res => res.json())
        .then(data => data.data);

      const {
        price,
        data: { participantType }
      } = aggregateTours.tours?.sort((a, b) => a.price - b.price)[0];

      this.setState({
        isLoaded: true,
        aggregateTours: aggregateTours.tours,
        currency: currency,
        minimalPrice: price,
        participantType: participantType
      });
    } catch (error) {
      console.error(error);
      this.setState({ isLoaded: true, error });
    }
  }

  async componentDidMount() {
    this.setState({ isLoaded: false });
    if (this.state.qp.companyId) {
      try {
        const saleSettings = await fetch(
          `/api/v1/tour/${this.state.qp.companyId}/sale-options-by-company-id`
        )
          .then(res => res.json())
          .then(res => res.data);
        const initCurrency = this.state.qp.currency;
        const newState = {
          currency:
            initCurrency && saleSettings.supportedCurrencies.includes(initCurrency)
              ? initCurrency
              : saleSettings.defaultCurrency,
          supportedCurrencies: saleSettings.supportedCurrencies,
          customization: saleSettings.customization,
          companyId: saleSettings.companyId
        };
        if (saleSettings.customization.fontUrl) {
          this.loadCustomFont(saleSettings.customization.fontUrl, saleSettings.companyId);
        }
        this.setState(newState);
        await this.fetchTourAggregateDetails(newState.currency, this.state.language);
      } catch (error) {
        console.error(error);
        this.setState({ isLoaded: true, error });
      }
    }

    window.addEventListener('message', this.handleOpeningElementClick.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleOpeningElementClick.bind(this));
  }

  handleOpeningElementClick(event) {
    try {
      const message = event.data;
      const taMsg = typeof message === 'string' ? JSON.parse(message) : null;
      if (taMsg?.msgType == 'openingElementClicked') {
        this.handleBookNowClick();
      }
    } catch (e) {
      console.log(e);
    }
  }

  loadCustomFont(fontUrl, companyId) {
    try {
      const fontFace = `Custom-company-font-${companyId}`;
      const font = new FontFace(fontFace, `url(${fontUrl})`, {
        style: 'normal',
        weight: 400
      });
      font.load().then(loadedFace => {
        document.fonts.add(loadedFace);
        this.setState({ customFont: fontFace });
      });
    } catch (err) {
      console.error('Failed to load custom font: ' + err.message);
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      );
    }

    if (!this.state.aggregateTours?.length) {
      return null;
    }

    let minimalPrice = moneyFromIntegerToDecimal({
      amount: this.state.minimalPrice,
      currency: this.state.currency
    });

    let currencySymbol = currencies[this.state.currency];

    const {
      primaryColorHex = '#010e1d',
      secondaryColorHex = '#6997ff',
      buttonColorHex = '#ffffff'
    } = this.state.customization;

    const mainBackgroundColor = changeColorBrightness(
      getShadeIndex(secondaryColorHex),
      secondaryColorHex
    );
    const mainTextColor = getContrastYIQ(mainBackgroundColor);

    const font = this.state.customFont;

    document.body.style.backgroundColor = 'transparent';

    return (
      <ThemeProvider
        theme={theme => {
          const newTheme = { ...theme };
          if (primaryColorHex) {
            newTheme.palette.primary = { main: primaryColorHex };
            newTheme.palette.info = { main: primaryColorHex };
          }

          if (buttonColorHex) {
            newTheme.raisedButton = {
              textColor: buttonColorHex,
              primaryTextColor: buttonColorHex
            };
          }
          if (secondaryColorHex) {
            newTheme.palette.secondary = { main: secondaryColorHex };
          }
          if (font) {
            const fontFamily = [
              font,
              theme.typography.fontFamily
                .split(',')
                .map(R.trim)
                .filter(x => x !== font)
            ].join(',');

            newTheme.typography = {
              fontFamily,
              ...R.pick(
                [
                  'htmlFontSize',
                  'fontSize',
                  'fontWeightLight',
                  'fontWeightRegular',
                  'fontWeightMedium',
                  'fontWeightBold'
                ],
                theme.typography
              )
            };
          }

          return createMuiTheme(newTheme);
        }}>
        <div
          style={{
            ...classes.bookNowSectionWrapper,
            backgroundColor: mainBackgroundColor
          }}>
          <div style={classes.bookNowSection}>
            <div
              style={{
                ...classes.bookNowLeftBlock,
                color: mainTextColor
              }}>
              {this.state.hidePriceBlock !== 'true' && (
                <>
                  <span style={classes.from}>{this.getFromOnlyString()}</span>
                  <span style={classes.price}>
                    {minimalPrice}
                    {currencySymbol}
                  </span>
                  <span style={classes.perUnit}>
                    {this.getPerPersonString().replace(/\s/g, '\u00A0')}
                  </span>
                </>
              )}
            </div>
            <div style={classes.bookNowRightBlock}>
              <Button
                style={{
                  borderRadius: '7px',
                  minHight: '32px',
                  minWidth: '140px',
                  fontWeight: 'bold',
                  lineHeight: '1.3',
                  padding: '7px 17px',
                  color: buttonColorHex ? buttonColorHex : getContrastYIQ(secondaryColorHex)
                }}
                variant="contained"
                color="secondary"
                onClick={this.handleBookNowClick.bind(this)}>
                {this.getBookNowString()}
              </Button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
