export const TextIconBoxType = {
  Welcome: 'Welcome',
  AdditionalInfo: 'AdditionalInfo',
  NearlyThere: 'NearlyThere',
  Congratulations: 'Congratulations',
  PersonalTipping: 'PersonalTipping',
  InviteGuides: 'InviteGuides',
  SetupReviews: 'SetupReviews',
  OrderCards: 'OrderCards',
  GetPaid: 'GetPaid'
};
