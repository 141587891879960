import { Analytics } from '@analytics';
import { Button, Paper } from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../../utils/constants';
import localizationMessages from '../../Resources/ResourcesTable/messages';
import AgentProfileSummaryButton from '../AgentProfileSummaryButton';
import InviteGuideModal from '../InviteGuideModal';
import InviteMenuButton from '../InviteMenuButton';
import { StatusBackgroundColor, StatusForeColor, StatusType, UserType } from './constants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
    width: '80%',
    maxHeight: '90vh',
    flexWrap: 'wrap',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%'
    }
  },
  table: {
    width: '100%'
  },
  status: {
    textAlign: 'center',
    width: '66px',
    padding: '4px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '4px'
  },
  topItems: {
    margin: '0 0 20px auto',
    display: 'flex',
    gap: '15px'
  }
}));

const AgentStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: StatusBackgroundColor[status],
        color: StatusForeColor[status]
      }}>
      {StatusType[status]}
    </div>
  );
};

const AgentsProfilesTable = ({
  agentsProfiles,
  sendInvitesToGuides,
  revokeInvite,
  fetchAgentsProfiles,
  addToursToProfile,
  activeTours,
  userId,
  companyName,
  isReviewCollectionEnabled
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  useEffect(() => {
    if (!agentsProfiles?.length) {
      fetchAgentsProfiles();
    }
  }, []);

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      render: rowData => <AgentStatus status={rowData.status} classes={classes} />
    },
    {
      field: 'type',
      title: intl.formatMessage(messages.type),
      render: rowData => UserType[rowData.type]
    },
    {
      field: 'name',
      title: intl.formatMessage(messages.name),
      render: rowData => `${rowData.firstName || ''} ${rowData.lastName || ''}`
    },
    {
      field: 'email',
      title: intl.formatMessage(messages.email)
    },
    {
      field: 'numberOfCards',
      title: intl.formatMessage(messages.numberOfCards),
      render: rowData => rowData.assignedCardsAmount,
      customSort: (a, b) => a.assignedCardsAmount - b.assignedCardsAmount
    },
    {
      title: intl.formatMessage(messages.manage),
      field: 'manage',
      sorting: false,
      headerStyle: {
        paddingRight: '25px',
        textAlign: 'right'
      },
      cellStyle: {
        textAlign: 'right'
      },
      render: rowData => {
        if (rowData.type === 'user' && isReviewCollectionEnabled) {
          return (
            <AgentProfileSummaryButton
              activeTours={activeTours}
              profile={rowData}
              addToursToProfile={addToursToProfile}
              userId={userId}
            />
          );
        }

        if (rowData.type === 'invite') {
          return (
            <InviteMenuButton
              revokeInvite={revokeInvite}
              inviteId={rowData.inviteId}
              sendInvite={sendInvitesToGuides}
              inviteeEmail={rowData.email}
            />
          );
        }
      }
    }
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.topItems}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddCircleIcon />}
            onClick={() => {
              Analytics.track('invite guides open');
              setIsInviteModalOpen(true);
            }}>
            {intl.formatMessage(messages.inviteGuides)}
          </Button>
        </div>

        <MaterialTable
          style={{ width: '100%' }}
          title={intl.formatMessage(messages.users)}
          columns={columns}
          data={agentsProfiles}
          components={{
            // eslint-disable-next-line react/display-name
            Container: props => <Paper className={classes.striped} {...props} />
          }}
          options={{
            pageSize: 10,
            tableLayout: 'auto',
            emptyRowsWhenPaging: false,
            headerStyle: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              top: '0'
            },
            cellStyle: {
              whiteSpace: 'nowrap',
              width: 'fit-content'
            },
            maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
          }}
          localization={{
            pagination: {
              labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
              labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
              firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
              firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
              previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
              previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
              nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
              nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
              lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
              lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
            },
            toolbar: {
              searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
              searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
            },
            body: {
              emptyDataSourceMessage: intl.formatMessage(
                localizationMessages.emptyDataSourceMessage
              )
            }
          }}
        />

        <InviteGuideModal
          isOpen={isInviteModalOpen}
          setIsOpen={setIsInviteModalOpen}
          sendInvitesToGuides={sendInvitesToGuides}
          companyName={companyName}
        />
      </div>
    </>
  );
};

export default AgentsProfilesTable;
