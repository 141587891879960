import axios from 'axios';
import { ApiError } from './error';

const url = '/api/v1/additional-extra';

export const createAdditionalExtra = (additionalExtraDetails) => {
  return axios.post(url, additionalExtraDetails).then(res => res.data.data).catch(ApiError.ofAndThrow);
}

export const draftAdditionalExtra = (additionalExtraId, draftDetails) => {
  return axios.post(`${url}/${additionalExtraId}/draft`, draftDetails).catch(ApiError.ofAndThrow);
}

export const publishAdditionalExtra = (additionalExtraId, publishDetails) => {
  return axios.post(`${url}/${additionalExtraId}/publish`, publishDetails).catch(ApiError.ofAndThrow);
}

export const deleteAdditionalExtra = (additionalExtraId) => {
  return axios.post(`${url}/${additionalExtraId}/delete`).catch(ApiError.ofAndThrow);
}

export const updateAdditionalExtra = (additionalExtraId, additionalExtraDetails) => {
  return axios
    .put(`${url}/${additionalExtraId}`, additionalExtraDetails)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

export const uploadAdditionalExtraImage = (image) => {
  const formData = new FormData();
  formData.append('image', image);

  return axios.post(`${url}/upload-image`, formData)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
}

// TODO: uploadAdditionalExtraImage(additionalExtraId, image)