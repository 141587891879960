import React, { useEffect, useState } from 'react';
import { TTTPaymentPlatforms } from '../constants';
import { userWireDetailsExist } from '../../api/wire';
import { userHasRevolut } from '../../api/payout';

const useActivePaymentPlatform = (userId, connectStripeResponse) => {
  const [activePaymentPlatform, setActivePaymentPlatform] = useState(null);

  useEffect(() => {
    const hasStripeConnect = () => {
      const stripeConnected = ['active', 'active_with_problems'].includes(
        connectStripeResponse?.paymentIntegrationStatus
      );
      stripeConnected && setActivePaymentPlatform(TTTPaymentPlatforms.stripe);
    };

    const hasWireTransfer = async () => {
      const wireTransferConnected = await userWireDetailsExist(userId);
      wireTransferConnected && setActivePaymentPlatform(TTTPaymentPlatforms.wire);
    };

    const hasRevolut = async () => {
      try {
        const revolutUser = await userHasRevolut(userId);
        if (revolutUser.exists) {
          setActivePaymentPlatform(TTTPaymentPlatforms.revolut);
        }
      } catch (e) {
        //noop
      }
    };
    // call the function
    hasStripeConnect();
    hasWireTransfer();
    hasRevolut();
  }, [connectStripeResponse]);

  return activePaymentPlatform;
};

export default useActivePaymentPlatform;
