export const INITIAL_INTEGRATIONS_DATA = [
  {
    channelId: '1f253266-de9b-4275-8dc1-587f9df45d02',
    channelType: 'gyg',
    integrationStatus: 'active'
  },
  {
    channelId: 'e0f062db-6d39-4ea3-99d1-1af477f109ea',
    channelType: 'viator',
    integrationStatus: 'active'
  },
  {
    integrationStatus: 'active',
    channelId: 'f856a86d-3536-493b-a77e-7ac20ad7d3f2',
    channelType: 'civitatis'
  },
  {
    integrationStatus: 'active',
    channelId: 'a487a56c-a01a-4fd3-9397-725d1dfa2980',
    channelType: 'klook'
  },
  {
    integrationStatus: 'active',
    channelId: 'a0aa7bb9-7bea-4e2b-8ebc-23c53dc1337d',
    channelType: 'musement'
  },
  {
    integrationStatus: 'active',
    channelId: '5cb7c4bf-20bc-415b-8bf2-5b139186e2dc',
    channelType: 'tiqets'
  }
];
