import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { perPersonTrans } from '../../../utils/common_translations/widget';
import { currencies, formatMoney } from '../../../utils/helpers';
import SaleIcon from './../../../assets/images/tour_aggregate/sale.svg';
import messages from './messages';

const useStyles = makeStyles(() => ({
  cardBlock: {
    borderRadius: '4px',
    padding: '25px 12px 12px',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 4px #ccc',
    flexWrap: `wrap`,
    position: 'relative'
  },
  textBlock: {
    flex: '0 1 60%'
  },
  btnBlock: props => ({
    flex: `${props.buttonWidth == 'full' ? '0 1 100%' : '0 1 40%'}`,
    textAlign: 'right',
    marginLeft: 'auto'
  }),
  mobileBtnBlock: {
    flex: '0 1 100%'
  },
  title: {
    fontSize: '14px',
    color: '#fff',
    letterSpacing: '-0.04px',
    fontWeight: '700',
    margin: '0'
  },
  priceDesc: props => ({
    fontSize: '9px',
    letterSpacing: '-0.03px',
    textTransform: 'lowercase',
    color: '#fff',
    fontWeight: '700',
    margin: `${props.isSmallScreen ? '5px 0 18px' : '5px 0 5px'}`
  }),
  btn: props => ({
    backgroundColor: '#ff3f15',
    color: '#fff',
    fontSize: '11px',
    whiteSpace: 'nowrap',
    minHeight: '21px',
    height: 'fit-content',
    padding: '5px 15px',
    lineHeight: '14px',
    width: `${props.buttonWidth == 'full' ? '100%' : 'auto'}`,
    '&:hover': {
      backgroundColor: '#c93413'
    }
  }),
  wasPrice: {
    fontSize: '10px',
    letterSpacing: '-0.03px',
    color: '#979797',
    fontWeight: '700',
    textDecoration: 'line-through',
    marginBottom: '4px'
  },
  discountBlock: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    fontSize: '11px',
    fontWeight: '700',
    color: '#fff',
    padding: '5px',
    borderRadius: '4px',
    border: 'solid 1px #ff3f15',
    backgroundColor: 'rgba(255, 63, 21, 0.35)',
    display: 'flex',
    alignItems: 'center'
  },
  saleIcon: {
    width: '18px',
    marginLeft: '5px'
  }
}));

export default function TourAggregateCard({
  tourId,
  pictureUrl,
  title,
  price,
  wasPrice,
  discount,
  currency,
  participantTitle,
  buttonWidth,
  isSmallScreen,
  onClick,
  selectedLanguage
}) {
  const classes = useStyles({ buttonWidth, isSmallScreen });
  const intl = useIntl();

  const formattedPrice = formatMoney({
    amount: price,
    currency
  });
  const formattedWasPrice = formatMoney({
    amount: wasPrice,
    currency
  });
  const currencySymbol = currencies[currency];
  const perPersonMessage = participantTitle
    ? `${intl.formatMessage(messages.per)} ${participantTitle}`
    : perPersonTrans[selectedLanguage];

  return (
    <div
      className={classes.cardBlock}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), #000), url(${pictureUrl})`,
        backgroundSize: 'cover'
      }}>
      {discount && (
        <div className={classes.discountBlock}>
          <span>-{discount}%</span>
          <img src={SaleIcon} alt="" className={classes.saleIcon} />
        </div>
      )}
      <div className={classes.textBlock}>
        {wasPrice && (
          <p className={classes.wasPrice}>
            {`${intl.formatMessage(messages.was)} ${formattedWasPrice}${currencySymbol}`}
          </p>
        )}
        <p className={classes.title}>{title}</p>
        <p className={classes.priceDesc}>
          {`${intl.formatMessage(
            messages.fromOnly
          )} ${formattedPrice}${currencySymbol} ${perPersonMessage}`}
        </p>
      </div>
      <div className={classes.btnBlock}>
        <Button className={classes.btn} variant="contained" onClick={() => onClick(tourId)}>
          {intl.formatMessage(messages.bookNow)}
        </Button>
      </div>
    </div>
  );
}
