import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AgentProfileSummaryButton';

export default defineMessages({
  addToursToProfile: {
    id: `${scope}.add_tour_to_profile`,
    defaultMessage: 'Add Tours to Profile'
  }
});
