import gyg from '../../../../assets/images/channel_manager/GetYourGuide.svg';
import reserve_with_google from '../../../../assets/images/channel_manager/ReserveWithGoogle.svg';
import expedia from '../../../../assets/images/channel_manager/Expedia.svg';
import viator from '../../../../assets/images/channel_manager/Viator.svg';
import klook from '../../../../assets/images/channel_manager/klook.svg';
import musement from '../../../../assets/images/channel_manager/musement.svg';
import civitatis from '../../../../assets/images/channel_manager/civitatis.svg';
import tiqets from '../../../../assets/images/channel_manager/tiqets.svg';

import gygGrey from '../../../../assets/images/channel_manager/GetYourGuideGrey.svg';
import reserve_with_googleGrey from '../../../../assets/images/channel_manager/ReserveWithGoogleGrey.svg';
import expediaGrey from '../../../../assets/images/channel_manager/ExpediaGrey.svg';
import viatorGrey from '../../../../assets/images/channel_manager/ViatorGrey.svg';
import klookGrey from '../../../../assets/images/channel_manager/klookGrey.svg';
import musementGrey from '../../../../assets/images/channel_manager/musementGrey.svg';
import civitatisGrey from '../../../../assets/images/channel_manager/civitatisGrey.svg';
import tiqetsGrey from '../../../../assets/images/channel_manager/tiqetsGrey.svg';

export default {
  gyg,
  expedia,
  reserve_with_google,
  viator,
  tiqets,
  klook,
  musement,
  civitatis,

  gygGrey,
  expediaGrey,

  reserve_with_googleGrey,
  viatorGrey,
  tiqetsGrey,
  klookGrey,
  musementGrey,
  civitatisGrey
};
