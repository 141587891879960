import { defineMessages } from 'react-intl';

export const scope = 'app.containers.promotionTours-container';

export default defineMessages({
  editPromotion: {
    id: `${scope}.edit-promotion`,
    defaultMessage: 'Edit Promotion'
  },
  backToPromotions: {
    id: `${scope}.back-to-promotions`,
    defaultMessage: 'Promotions'
  }
});
