import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  title: {
    fontWeight: 'bold',
    flex: '1 0 auto',
    display: 'block',
    marginRight: theme.spacing(2)
  },
  input: {
    fontWeight: 'bold'
  },
  inputElements: {
    display: 'flex',
    gap: '14px'
  }
}));

export default function DateRangePicker({ dateRange: { start, end }, setDateRange }) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputElements}>
        <DatePicker
          label={intl.formatMessage(messages.from)}
          className={classes.input}
          format="dd/MM/yyyy"
          value={start}
          onChange={date => setDateRange({ start: date, end })}
          animateYearScrolling
          okLabel={React.createElement('span', null, intl.formatMessage(messages.ok))}
          cancelLabel={React.createElement('span', null, intl.formatMessage(messages.cancel))}
        />
        <DatePicker
          label={intl.formatMessage(messages.to)}
          format="dd/MM/yyyy"
          className={classes.input}
          value={end}
          onChange={date => setDateRange({ end: date, start })}
          animateYearScrolling
          okLabel={React.createElement('span', null, intl.formatMessage(messages.ok))}
          cancelLabel={React.createElement('span', null, intl.formatMessage(messages.cancel))}
        />
      </div>
    </div>
  );
}
