import { StyleBreakpoints } from '@constants';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EngineeringIcon from '@material-ui/icons/Build';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  waitingText: {
    marginTop: theme.spacing(7)
  },
  alertSvg: {
    height: 40,
    width: 40
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 72,
    height: 72,
    borderRadius: '50%',
    backgroundColor: '#f85a13',
    color: 'white',
  },
  errorMessage: {
    maxWidth: '300px',
    textAlign: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '500px'
    }
  }
}));

const SubscriptionPostCheckout = ({ errorType, error, getSubscriptionDetails }) => {
  useInjectReducer({ key: 'subscriptionPostCheckout', reducer });
  useInjectSaga({ key: 'subscriptionPostCheckout', saga });

  const intl = useIntl();
  const classes = useStyles();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const checkoutSessionId = params.get('CHECKOUT_SESSION_ID');

  useEffect(() => {
    getSubscriptionDetails(checkoutSessionId);
  }, [checkoutSessionId]);

  return error || !checkoutSessionId ? (
    <Box className={classes.wrapper}>
      <div className={classes.iconWrapper}>
        <EngineeringIcon className={classes.alertSvg} src={EngineeringIcon} alt="Alert Icon" />{' '}
      </div>
      <h2 className={classes.errorMessage}>{intl.formatMessage(messages[errorType])}</h2>
    </Box>
  ) : (
    <Box className={classes.wrapper}>
      <CircularProgress />

      <h2 className={classes.waitingText}>{intl.formatMessage(messages.waitingText)}</h2>
    </Box>
  );
};

const mapStateToProps = state => ({
  error: state.subscriptionPostCheckout?.error,
  errorType: state.subscriptionPostCheckout?.errorType
});

const mapDispatchToProps = dispatch => ({
  getSubscriptionDetails: checkoutSessionId => dispatch(actions.startPolling(checkoutSessionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPostCheckout);
