import { Analytics } from '@analytics';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { endOfDay, isEqual, startOfDay } from 'date-fns';
import format from 'date-fns/format';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { scheduleAvailabilityTypes } from '../../../containers/TourEditor/reducer/schedule';
import {
  constructDateStringWithTimezone,
  date2day,
  getCutoffTimeInMs,
  getFormattedOffsetTime,
  seasonByDate,
  strToAbsoluteDate
} from '../../../utils/helpers';
import messages from '../messages';
import SaleIcon from './../../../assets/images/booking_widget/sale-calendar-icon.svg';

const useStyles = makeStyles(theme => ({
  coloredInput: {
    '& label': {
      color: theme.palette.primary.main
    }
  },
  saleIconBlock: {
    position: 'absolute',
    zIndex: 1,
    borderRadius: '50%',
    top: '-4px',
    right: '-4px',
    boxShadow: '0 0 1px 0 rgb(0 0 0 / 13%)',
    backgroundColor: '#fff'
  },
  coloredSelection: {
    background: theme.palette.primary.main || '#6997ff'
  }
}));

/**
 * Returns the first available date, equal to or after baseDate, for the given season.
 * @param {object} season
 * @param {Date} baseDate
 * @param {Function} checkIfDateIsDisabled
 * @returns {(Date|null)}
 */
function getNextAvailableDate(season, baseDate, checkIfDateIsDisabled) {
  let checkFromDate = new Date(Math.max(season.start, baseDate));
  let nextActiveDate = null;

  // Loop through the dates of the season to check if there is any availability
  while (checkFromDate <= season.end) {
    if (checkIfDateIsDisabled(checkFromDate)) {
      checkFromDate.setDate(checkFromDate.getDate() + 1);
    } else {
      nextActiveDate = checkFromDate;
      break;
    }
  }

  return nextActiveDate;
}

/**
 * Returns the closest available date, equal to or after baseDate, from all seasons
 * @param {Array[object]} seasons
 * @param {Date} baseDate
 * @param {Function} checkIfDateIsDisabled
 * @returns {(Date|null)}
 */
function getClosestAvailableDate(seasons, baseDate, checkIfDateIsDisabled) {
  const sortedSeasons = seasons
    .filter(season => season.end >= baseDate)
    .sort((seasonOne, seasonTwo) => {
      if (seasonOne.end < seasonTwo.end) {
        return -1;
      }

      if (seasonOne.end > seasonTwo.end) {
        return 1;
      }

      return 0;
    });

  // If there is no activeSeason coming up return null.
  // No active season means there are no available tours.
  if (sortedSeasons.length <= 0) return null;

  let nextAvailableDate = null;

  for (let x = 0; x < sortedSeasons.length; x++) {
    nextAvailableDate = getNextAvailableDate(sortedSeasons[x], baseDate, checkIfDateIsDisabled);

    // The seasons are sorted earliest first so when an available date
    // is found break.
    if (nextAvailableDate) {
      break;
    }
  }

  return nextAvailableDate;
}

function BookingDaySelect({
  tour,
  selectedDate,
  setSelectedDate,
  displayDateAsSelectBox = false,
  bookedAmountPerDate,
  shouldShowFreePlaces,
  timezoneName,
  shouldIgnoreCutoffTimes = true,
  isDisabled = false,
  extraTrackingDetails = {}
}) {
  const classes = useStyles();
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  const handleMonthChange = async () => {
    // Simulated latency because why not
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 200);
    });
  };

  useEffect(() => {
    if (isNil(selectedDate)) {
      const closestAvailableDate = getClosestAvailableDate(
        tour.seasons,
        startOfDay(new Date()),
        shouldDisableDate
      );
      setSelectedDate(closestAvailableDate);
    }
  }, [selectedDate]);

  const handleCompDateChange = date => {
    if (extraTrackingDetails) {
      Analytics.track('new date selected', {
        ...extraTrackingDetails,
        'selected date': date
      });
    }
    if (shouldDisableDate(selectedDate)) {
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
    }
  };

  const handleSelectPickDate = event => {
    if (extraTrackingDetails) {
      Analytics.track('new date selected', {
        ...extraTrackingDetails,
        'selected date': new Date(event.target.value)
      });
    }
    setSelectedDate(new Date(event.target.value));
  };

  const getCurrentDayCapacity = (seasons, date) => {
    const season = seasonByDate(seasons, date);

    if (season?.tag === scheduleAvailabilityTypes.interval) {
      const timeslotsAmountPerDay = season.schedule[date2day(date)].timeslots.length;
      return season.pricing.capacity * timeslotsAmountPerDay;
    } else if (season?.tag === scheduleAvailabilityTypes.allDay) {
      return season.pricing.capacity;
    }
  };

  const areFreePlacesLeft = date => {
    const capacityForDay = getCurrentDayCapacity(tour.seasons, date);

    if (
      bookedAmountPerDate[date?.getTime() + getFormattedOffsetTime(date?.getTimezoneOffset())]
        ?.count >= capacityForDay ||
      !capacityForDay
    ) {
      return false;
    }

    return true;
  };

  const isDayBlocked = date => {
    return bookedAmountPerDate[date?.getTime() + getFormattedOffsetTime(date?.getTimezoneOffset())]
      ?.isBlocked;
  };

  const isTooLateToMakeBooking = date => {
    const season = seasonByDate(tour.seasons, date);
    let tourStartTimeInMs;

    if (season?.tag === scheduleAvailabilityTypes.interval) {
      const ts = season.schedule[date2day(date)].timeslots;
      const lastTimeSlot = ts[ts.length - 1];
      let dateWithLatestTimeSlot = new Date(date);
      dateWithLatestTimeSlot.setHours(lastTimeSlot.hours);
      dateWithLatestTimeSlot.setMinutes(lastTimeSlot.minutes);
      tourStartTimeInMs = Date.parse(
        constructDateStringWithTimezone(dateWithLatestTimeSlot, timezoneName)
      );
    } else {
      const startTime = season.schedule[date2day(date)].start;
      const dateWithStartTime = new Date(date);
      dateWithStartTime.setHours(startTime?.hours ?? 0);
      dateWithStartTime.setMinutes(startTime?.minutes ?? 0);
      tourStartTimeInMs = Date.parse(
        constructDateStringWithTimezone(dateWithStartTime, timezoneName)
      );
    }

    return (
      Date.now() >
      tourStartTimeInMs - (shouldIgnoreCutoffTimes ? 0 : getCutoffTimeInMs(season.cutoffTimes))
    );
  };

  const getFreePlacesAmount = date => {
    if (date) {
      const capacityForDay = getCurrentDayCapacity(tour.seasons, date);
      return (
        capacityForDay -
        (bookedAmountPerDate[date.getTime() + getFormattedOffsetTime(date.getTimezoneOffset())]
          ?.count || 0)
      );
    }
  };

  //PERFORMANCE
  const shouldDisableDate = date => {
    if (!areFreePlacesLeft(date)) {
      return true;
    }

    if (isDayBlocked(date)) {
      return true;
    }

    if (isTooLateToMakeBooking(date)) {
      return true;
    }

    const maybeSeason = tour.seasons.find(season => season.start <= date && season.end >= date);
    if (!maybeSeason) {
      return true;
    }

    const isExcluded = maybeSeason.exclusions.some(
      exclusion =>
        strToAbsoluteDate(exclusion.start) <= date &&
        date <= endOfDay(strToAbsoluteDate(exclusion.end))
    );

    if (isExcluded) {
      return true;
    }

    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    if (date.getTime() < today.getTime()) {
      return true;
    }

    const dayInSchedule = maybeSeason.schedule[date2day(date)];

    if (dayInSchedule.enabled && dayInSchedule.timeslots && !dayInSchedule.timeslots.length) {
      return true;
    }

    return !dayInSchedule.enabled;
  };

  const datesAvailable = () => {
    let datesInOrder = [];
    //Note: This is a hack for a specific client. Seasons start dates are going to act as running dates for now.
    //When private tours are properly developed, running dates should not be a season
    tour.seasons.forEach(season => {
      datesInOrder.push(season.start);
    });
    return datesInOrder;
  };

  const renderDay = (date, selectedDate, isSameMonth) => {
    if (!isSameMonth) {
      return (
        <div
          style={{
            width: '36px',
            height: '36px',
            margin: '0 2px'
          }}
        />
      );
    }
    const selected = isEqual(date, selectedDate);
    const disabled = shouldDisableDate(date);

    const hasSales = Boolean(tour.currentSales?.length);
    let isOnSale = false;

    if (hasSales) {
      tour.currentSales.forEach(sale => {
        const startDate = new Date(sale.travelByStartDate);
        const endDate = new Date(sale.travelByEndDate);

        isOnSale =
          isOnSale ||
          (date.getTime() + getFormattedOffsetTime(date.getTimezoneOffset()) >= startDate &&
            date.getTime() + getFormattedOffsetTime(date.getTimezoneOffset()) <= endDate);
      });
    }

    let iconsStyle = {
      color: '#767777',
      width: '36px',
      height: '36px',
      margin: '0 2px',
      padding: '0',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      position: 'relative'
    };
    if (selected) {
      iconsStyle = {
        color: 'white',
        width: '36px',
        height: '36px',
        margin: '0 2px',
        padding: '0',
        fontSize: '0.75rem',
        fontWeight: '500'
      };
    }
    if (disabled) {
      iconsStyle = {
        color: '#767777',
        width: '36px',
        height: '36px',
        margin: '0 2px',
        padding: '0',
        fontSize: '0.75rem',
        fontWeight: '300'
      };
    }

    const shouldRenderFreePlacesInfo = !disabled && shouldShowFreePlaces === 'true';

    return (
      <div
        style={{
          position: 'relative'
        }}>
        {isOnSale && !disabled && (
          <div className={classes.saleIconBlock}>
            <img src={SaleIcon} alt="" width="100%" />
          </div>
        )}

        <IconButton
          title={
            shouldRenderFreePlacesInfo
              ? `${getFreePlacesAmount(date)} ${intl.formatMessage(messages.placesLeft)}`
              : ''
          }
          className={[classes.dayInCalendar, selected ? classes.coloredSelection : null]}
          disabled={disabled}
          style={iconsStyle}>
          <span>{date.getDate()}</span>
        </IconButton>
      </div>
    );
  };

  if (displayDateAsSelectBox) {
    return (
      <FormControl variant="outlined" style={{ width: '100%', marginTop: '16px' }}>
        <InputLabel id="startingtime-select-label" style={{ backgroundColor: '#fff' }}>
          {intl.formatMessage(messages.selectDate)}
        </InputLabel>
        <Select
          onChange={handleSelectPickDate}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}>
          {datesAvailable().map(d => {
            return (
              <MenuItem key={d} value={d}>
                {format(new Date(d), 'dd/MM/yyyy')}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <KeyboardDatePicker
        disabled={isDisabled}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        className={classes.coloredInput}
        margin="normal"
        id="date-picker-inline"
        label={intl.formatMessage(messages.selectDate)}
        value={selectedDate}
        renderDay={renderDay}
        autoOk={true}
        open={open}
        minDate={new Date()}
        onClick={() => {
          if (!isDisabled) setOpen(true);
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleCompDateChange}
        onMonthChange={handleMonthChange}
        shouldDisableDate={shouldDisableDate}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        style={{ width: '100%', marginBottom: 'unset' }}
      />
    );
  }
}

const styles = createStyles(theme => ({
  dayWrapper: {
    position: 'relative'
  },
  day: {
    width: 40,
    height: 40,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
    letterSpacing: '0px',
    fontWeight: 'bold'
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  }
}));

export default withStyles(styles)(BookingDaySelect);
