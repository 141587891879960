/*
 * TourTable Messages
 *
 * This contains all the text for the TourTable component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Promotions.RemoveTourModal';

export default defineMessages({
  modalTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Action'
  },
  modalDeleteMessage: {
    id: `${scope}.removeTourFromPromotion`,
    defaultMessage: 'Remove from promotion'
  }
});
