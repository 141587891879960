import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleBreakpoints } from '../../../utils/constants';
import messages from './messages';

import EmptyStar from '../../../assets/images/EmptyStar.svg';
import Star from '../../../assets/images/Star.svg';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '17px 20px 18px 26px',
    borderRadius: '16px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      padding: '17px 20px 18px 20px'
    }
  },
  bar: {
    display: 'flex',
    backgroundColor: '#d8d8d8',
    borderRadius: '5px'
  },
  barContainer: {
    height: '28px',
    width: '100%',
    display: 'flex',
    marginRight: '34px',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      marginRight: '0'
    }
  },
  starRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '19px',
    justifyContent: 'space-between'
  },
  star: {
    width: '20px',
    height: 'auto',
    marginRight: '6px'
  },
  starContainer: {
    marginRight: '25px',
    display: 'flex',
    flexDirection: 'row'
  },
  frequency: {
    fontWeight: 'bold',
    textAlign: 'right',
    color: '#000',
    fontSize: '16px',
    wordBreak: 'normal',
    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      marginLeft: '25px'
    }
  },
  footer: {
    borderTop: '1px solid #e2e2e2;',
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: '-26px',
    width: 'calc(100% + 46px)',
    marginTop: 'auto'
  },
  topBar: {
    paddingBottom: '17px',
    borderBottom: '1px solid #e2e2e2;',
    marginLeft: '-26px',
    width: 'calc(100% + 46px)'
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#000',
    paddingLeft: '26px',
    paddingRight: '20px'
  },
  body: {
    marginTop: '34px'
  }
}));

const TotalStars = ({ reviews }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [relativeFrequencies, setRelativeFrequencies] = useState({});
  const [totalRatings, setTotalRatings] = useState(0);
  const [ratingCounts, setRatingCounts] = useState([0]);
  let ratings = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

  useEffect(() => {
    if (reviews?.length > 0) {
      ratings = reviews
        .map(review => {
          return review.reviewsAmountByRating;
        })
        .reduce((a, b) => {
          for (const [rating, frequency] of Object.entries(b)) {
            if (!a[rating]) {
              a[rating] = 0;
            }
            a[rating] += frequency;
          }
          return a;
        }, {});
    }

    setRatingCounts(Object.values(ratings).reverse());
  }, [reviews]);

  useEffect(() => {
    const mostFrequentRating = Math.max(...ratingCounts);
    let calculatedRelativeFrequencies = {};
    for (let i = 4; i >= 0; i--) {
      calculatedRelativeFrequencies[i] = Math.floor((ratingCounts[i] / mostFrequentRating) * 100);
    }

    setRelativeFrequencies(calculatedRelativeFrequencies);
    setTotalRatings(ratingCounts.reduce((a, b) => a + b));
  }, [ratingCounts]);

  const renderStars = value => {
    let starIcons = [];
    for (let i = 0; i < value; i++) {
      starIcons.push(<img className={classes.star} key={i} src={Star} alt="" />);
    }
    for (let i = value; i < 5; i++) {
      starIcons.push(<img className={classes.star} key={i} src={EmptyStar} alt="" />);
    }
    return <>{starIcons}</>;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.topBar}>
          <div className={classes.boldText}>{intl.formatMessage(messages.starRatings)}</div>
        </div>
        <div className={classes.body}>
          {ratingCounts.map((rating, i) => {
            return (
              <div className={classes.starRow} key={i}>
                <div className={classes.starContainer}>{renderStars(5 - i)}</div>
                <div className={classes.barContainer}>
                  <span
                    className={classes.bar}
                    style={{ width: `${relativeFrequencies[i] || 1}%` }}
                  />
                </div>
                <span className={classes.frequency}>{rating}</span>
              </div>
            );
          })}
        </div>
        <div className={classes.footer}>
          <div className={classes.boldText}>
            {intl.formatMessage(messages.totalStars)}: {totalRatings}
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalStars;
