import axios from 'axios';
import { ApiError } from '../../error';

const url = '/api/v1/tipdirect/reviews';

export const createRating = payload => {
  return axios
    .post(url, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const updateReview = ({ id, ...payload }) => {
  return axios
    .put(`${url}/${id}`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const fetchReviewsStatistic = payload => {
  return axios
    .post(`${url}/statistics`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};

export const sendReviewEmail = payload => {
  return axios
    .post(`${url}/send`, payload)
    .then(res => res.data.data)
    .catch(ApiError.ofAndThrow);
};
