import { Analytics } from '@analytics';
import { AppBar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import TopNav from '../../components/Nav/TopNav';
import AgentsProfilesTable from '../../components/TapToTip/AgentsProfilesTable';
import InviteGuideModal from '../../components/TapToTip/InviteGuideModal';
import PaymentsTable from '../../components/TapToTip/PaymentsTable';
import Profile from '../../components/TapToTip/Profile';
import ReviewsTable from '../../components/TapToTip/ReviewsTable';
import TapToTipLinkInformation from '../../components/TapToTip/TapToTipLinkInformation';
import ToursTable from '../../components/TapToTip/TapToTipToursTable';
import { StyleBreakpoints } from '../../utils/constants';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  tapToTipMenu: {
    display: 'flex',
    flexDirection: 'row',
    height: '89px',
    position: 'relative',
    marginTop: '-20px',
    background: 'white',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '206px',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      height: 'auto',
      paddingTop: '40px',
      boxShadow: 'none',
      marginLeft: '25px',
      borderRight: 'solid 1px #eaeaea'
    }
  },
  tapToTipLink: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#acacac',
    background: 'white',
    border: 'none',
    borderRadius: '0',
    borderBottom: '4px solid transparent',
    margin: 'auto 15px',
    height: '32px',
    textWrap: 'nowrap',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      margin: '0',
      marginRight: '15px',
      justifyContent: 'left',
      fontSize: '16px',
      color: '#000000',
      opacity: '.44',
      border: 'none',
      textAlign: 'left',
      padding: '10px'
    },
    '&:disabled': {
      color: '#000',
      opacity: '.44'
    }
  },
  adminLink: {
    display: 'none',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex'
    }
  },
  activeTapToTipLink: {
    color: '#6997ff',
    borderBottom: '4px solid #6997ff',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      color: '#000000',
      opacity: '1',
      border: 'none'
    }
  },
  contentTitle: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#000'
  },
  recommendButton: {
    borderRadius: '20px',
    color: '#acacac',
    border: '2px solid #acacac',
    marginTop: '20px',
    padding: '8px 16px',
    fontSize: '12px',
    width: 'fit-content',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      display: 'none'
    }
  }
}));

export const TapToTipTabs = {
  PAYMENTS: 'Payments',
  PROFILE: 'Profile',
  USERS: 'Users',
  REVIEWS: 'Reviews',
  TAPTOTIP: 'TipDirect',
  TOURS: 'Tours'
};

function TapToTipPage({
  transactions,
  fetchTransactions,
  sendInvitesToGuides,
  user,
  profile,
  fetchProfile,
  updateProfile,
  isLoading,
  uploadUserProfileImage,
  connectStripeResponse,
  connectStripe,
  agentsProfiles,
  fetchAgentsProfiles,
  revokeInvite,
  activeTours,
  tours,
  fetchActiveTours,
  fetchTours,
  addToursToProfile,
  addTapToTipTour,
  editTapToTipTour,
  reviewsStatistic,
  fetchReviewsStatistic,
  multipleAssign,
  deleteTour,
  paypalLoginUrl,
  paypalAccount,
  getPayPalLoginUrl,
  paypalLoginCallback,
  match
}) {
  useInjectReducer({ key: 'taptotip', reducer });
  useInjectSaga({ key: 'taptotip', saga });

  const classes = useStyles();
  const intl = useIntl();
  const { tab } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  let urlTab = null;

  switch (match.params.tab) {
    case TapToTipTabs.REVIEWS.toLowerCase():
      urlTab = TapToTipTabs.REVIEWS;
      break;
    case TapToTipTabs.PAYMENTS.toLowerCase():
      urlTab = TapToTipTabs.PAYMENTS;
      break;
    case TapToTipTabs.PROFILE.toLowerCase():
      urlTab = TapToTipTabs.PROFILE;
      break;
    case TapToTipTabs.USERS.toLowerCase():
      urlTab = TapToTipTabs.USERS;
      break;
    case TapToTipTabs.TAPTOTIP.toLowerCase():
      urlTab = TapToTipTabs.TAPTOTIP;
      break;
    case TapToTipTabs.TOURS.toLowerCase():
      urlTab = TapToTipTabs.TOURS;
      break;
    default:
      urlTab = null;
  }

  const [shownTab, setShownTab] = useState(
    urlTab || tab || location.state?.tab || TapToTipTabs.PROFILE
  );
  const [isRecommendModalOpen, setIsRecommendModalOpen] = useState(false);
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const isReviewCollectionEnabled = isFeatureForCompanyEnabled('PostTipReviewCollection');

  useEffect(() => {
    if (!profile) {
      fetchProfile(user.userId);
    }

    if (!activeTours?.length) {
      fetchActiveTours();
    }

    if (user?.profile.stripeConnect?.stripeClientId) {
      connectStripe();
    }

    if (!paypalAccount && !profile?.paypal && code) {
      paypalLoginCallback(code);
    }
  }, []);

  const shouldShowTab = role => {
    const isRoleMatching = !role || user.role === role;

    return isRoleMatching;
  };

  useEffect(() => {
    if (location.state?.tab && shownTab !== location.state.tab) {
      const tabInfo = taptotipTabsInfo.find(tab => tab.name === location.state.tab);

      // Check if the tab is available for the user's role and company plan
      tabInfo && shouldShowTab(tabInfo.role, tabInfo.plansAvailability)
        ? setShownTab(location.state.tab)
        : setShownTab(TapToTipTabs.PROFILE);
    }
  }, [location.state]);

  const handleShownTab = taptotipTab => {
    switch (taptotipTab) {
      case TapToTipTabs.PROFILE:
        return (
          <Profile
            profile={profile}
            updateProfile={profile => updateProfile({ userId: user.userId, profile })}
            uploadUserProfileImage={uploadUserProfileImage}
            isLoading={isLoading}
            connectStripeResponse={connectStripeResponse}
            activeTours={activeTours}
            addToursToProfile={addToursToProfile}
            userId={user.userId}
            paypalAccount={paypalAccount}
            paypalLoginUrl={paypalLoginUrl}
            getPayPalLoginUrl={getPayPalLoginUrl}
          />
        );
      case TapToTipTabs.PAYMENTS:
        return (
          <PaymentsTable
            fetchTransactions={fetchTransactions}
            transactions={transactions}
            userRole={user.role}
            disableCompanyTransactionReporting={
              profile?.features?.disableCompanyTransactionReporting ?? false
            }
          />
        );
      case TapToTipTabs.TAPTOTIP:
        return <TapToTipLinkInformation user={user} />;
      case TapToTipTabs.USERS:
        return (
          <AgentsProfilesTable
            fetchAgentsProfiles={fetchAgentsProfiles}
            revokeInvite={revokeInvite}
            agentsProfiles={agentsProfiles}
            sendInvitesToGuides={sendInvitesToGuides}
            activeTours={activeTours}
            addToursToProfile={addToursToProfile}
            userId={user.userId}
            addTapToTipTour={addTapToTipTour}
            editTapToTipTour={editTapToTipTour}
            companyName={user.companyName}
            isReviewCollectionEnabled={isReviewCollectionEnabled}
          />
        );
      case TapToTipTabs.REVIEWS:
        return (
          <ReviewsTable
            fetchAgentsProfiles={fetchAgentsProfiles}
            agentsProfiles={agentsProfiles}
            reviewsStatistic={reviewsStatistic}
            fetchReviewsStatistic={fetchReviewsStatistic}
            userRole={user.role}
          />
        );
      case TapToTipTabs.TOURS:
        return (
          <ToursTable
            tours={tours}
            fetchTours={fetchTours}
            editTapToTipTour={editTapToTipTour}
            addTapToTipTour={addTapToTipTour}
            guides={agentsProfiles}
            fetchGuides={fetchAgentsProfiles}
            multipleAssign={multipleAssign}
            deleteTour={deleteTour}
            isReviewCollectionEnabled={isReviewCollectionEnabled}
          />
        );
    }
  };

  const taptotipTabsInfo = [
    { name: TapToTipTabs.PAYMENTS, label: messages.payments },
    { name: TapToTipTabs.PROFILE, label: messages.profile },
    { name: TapToTipTabs.USERS, label: messages.users, role: 'system_admin' },
    { name: TapToTipTabs.TOURS, label: messages.tours, role: 'system_admin' },
    {
      name: TapToTipTabs.REVIEWS,
      label: messages.reviews,
      role: 'system_admin'
    },
    { name: TapToTipTabs.TAPTOTIP, label: messages.tipDirect }
  ];

  return (
    <div>
      <TopNav sendInvitesToGuides={sendInvitesToGuides} />
      <div className={classes.container}>
        <AppBar className={classes.tapToTipMenu}>
          {taptotipTabsInfo.map(
            ({ name, label, role }, i) =>
              shouldShowTab(role) && (
                <Button
                  key={i}
                  className={`${classes.tapToTipLink} ${shownTab === name &&
                    classes.activeTapToTipLink} ${role === 'system_admin' && classes.adminLink}`}
                  onClick={() => {
                    Analytics.track(`TTT menu clicked`, { page: name });
                    setShownTab(name);
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true">
                  {intl.formatMessage(label)}
                </Button>
              )
          )}

          <Button className={classes.recommendButton} onClick={() => setIsRecommendModalOpen(true)}>
            {intl.formatMessage(messages.recommendToAFriend)}
          </Button>

          <InviteGuideModal
            isOpen={isRecommendModalOpen}
            setIsOpen={setIsRecommendModalOpen}
            sendInvitesToGuides={sendInvitesToGuides}
            companyName={user.companyName}
            isRecommendationEmail
          />
        </AppBar>
        {shouldShowTab(taptotipTabsInfo[shownTab]) && handleShownTab(shownTab)}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  transactions: state.taptotip?.transactions,
  profile: state.taptotip?.profile,
  isLoading: state.taptotip?.isLoading,
  connectStripeResponse: state.taptotip?.connectStripeResponse,
  agentsProfiles: state.taptotip?.agentsProfiles,
  activeTours: state.taptotip?.activeTours,
  user: state.user?.user,
  reviewsStatistic: state.taptotip?.reviewsStatistic,
  tours: state.taptotip?.tours,
  paypalLoginUrl: state.taptotip?.paypalLoginUrl,
  paypalAccount: state.taptotip?.paypalAccount
});

const mapDispatchToProps = dispatch => ({
  fetchTransactions: userId => dispatch(actions.fetchTransactions({ userId })),
  fetchProfile: userId => dispatch(actions.fetchProfile(userId)),
  updateProfile: R.compose(
    dispatch,
    actions.updateProfile
  ),
  uploadUserProfileImage: image => dispatch(actions.uploadUserProfileImage(image)),
  connectStripe: () => dispatch(actions.connectStripe()),
  fetchAgentsProfiles: () => dispatch(actions.fetchAgentsProfiles()),
  sendInvitesToGuides: payload => dispatch(actions.sendInvitesToGuides(payload)),
  fetchActiveTours: () => dispatch(actions.fetchActiveTours()),
  addToursToProfile: (userId, tourIds) => dispatch(actions.addToursToProfile({ userId, tourIds })),
  addTapToTipTour: payload => dispatch(actions.addTapToTipTour(payload)),
  editTapToTipTour: payload => dispatch(actions.editTapToTipTour(payload)),
  fetchReviewsStatistic: payload => dispatch(actions.fetchReviewsStatistic(payload)),
  revokeInvite: payload => dispatch(actions.revokeInvite(payload)),
  fetchTours: () => dispatch(actions.fetchTours()),
  multipleAssign: (tourIds, guideIds) => dispatch(actions.multipleAssign({ tourIds, guideIds })),
  deleteTour: payload => dispatch(actions.deleteTour(payload)),
  getPayPalLoginUrl: () => dispatch(actions.getPayPalLoginUrl()),
  paypalLoginCallback: code => dispatch(actions.paypalLoginCallback(code))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TapToTipPage);
