import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { capitalize } from '@material-ui/core/styles';
import queryString from 'query-string';
import { compose } from 'ramda';
import React, { Fragment } from 'react';
import TagManager from 'react-gtm-module';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import checkMarkGreenIcon from '../../assets/images/booking_widget/checkMarkGreen.svg';
import CloseIcon from '../../assets/images/booking_widget/close.svg';
import { CircularAnimation } from '../../components/CircularAnimation';
import TourSumarryCard from '../../components/Common/TourSummaryCard';
import TourSummaryImageHeader from '../../components/Common/TourSummaryCard/ImageHeader';
import FullPriceBreakdown from '../../components/Common/TourSummaryCard/SummaryOfCharges/FullPriceBreakdown';
import FullTourSummary from '../../components/Common/TourSummaryCard/TourSummaryInfo/FullTourSummary';
import WhyTripAdmit from '../../components/Common/TourSummaryCard/WhyTripAdmitPanel';
import InjectResizable from '../../components/InjectResizable';
import PoweredBy from '../../components/PoweredBy';
import MapContainer from '../../components/Tours/Map';
import CompanyThemeProviderFromCustomizationSettings from '../../themes/CompanyThemeProvider';
import { getFeeTextRepresentation } from '../../utils/common_translations/fees';
import { currencySign, formatMoney, strToAbsoluteDate } from '../../utils/helpers';
import { changeSessionLocale } from '../LanguageProvider/actions';
import messages from './messages';

function renderTimeslot(timeslot) {
  const { hours, minutes } = timeslot;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

var textStyle = {
  marginRight: '14px',
  marginBottom: '14px',
  width: '100%',
  minWidth: '15ch',
  boxShadow: 'rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
  borderRadius: '4px',
  backgroundColor: 'white'
};

function BookingParticipant({ participant, currency }) {
  const intl = useIntl();

  switch (participant.type) {
    case 'participant':
      return (
        <>
          <span style={{ color: '#5ea5ff' }}>
            {capitalize(participant.title)} x {participant.count} - {currencySign(currency)}
            {formatMoney({ amount: participant.perElement, currency: currency })}{' '}
            {intl.formatMessage(messages.participantsEach)}
          </span>
          <br />
        </>
      );
    case 'fee':
      return (
        <>
          <span>
            {`${getFeeTextRepresentation(intl, participant.feeType)} - ${currencySign(
              currency
            )} ${formatMoney({ amount: participant.price, currency: currency })}`}
          </span>
          <br />
        </>
      );
    case 'additional_extra':
      return (
        <>
          <h3 style={{ marginTop: '8px', marginBottom: '8px' }}>
            {intl.formatMessage(messages.additionalExtras)}:
          </h3>
          <span style={{ color: '#5EA5FF' }}>
            {capitalize(participant.title)} x {participant.count} - {currencySign(currency)}
            {formatMoney({ amount: participant.perElement, currency: currency })}{' '}
            {intl.formatMessage(messages.participantsEach)}
          </span>
          <br />
        </>
      );

    default:
      throw new Error(`Unknown participant type: ${participant.type}`);
  }
}
class OrderConfirmationPage extends React.Component {
  //Get tour ID from query params
  //Pass along price and details to stripe checkout

  constructor(props) {
    super(props);
    this.state = {
      qp: queryString.parse(this.props.location.search).id.toUpperCase(),
      season: null,
      enablePayButton: false,
      customizationSettings: {}
    };
  }

  componentDidMount() {
    fetch('/api/v1/booking/' + this.state.qp)
      .then(response => response.json())
      .then(async data => {
        this.props.changeLanguage(data.data.language);
        const saleSettings = await fetch(
          `/api/v1/tour/${data.data.bookingDetails.tourId}/sale-options`
        )
          .then(res => res.json())
          .then(res => res.data);
        const customizationSettings = {
          ...saleSettings.customization,
          companyId: saleSettings.companyId
        };
        //Set up Google Tag Manager if code is set
        if (typeof saleSettings.customization.gtmCode === 'string') {
          const tagManagerArgs = {
            gtmId: saleSettings.customization.gtmCode
          };
          TagManager.initialize(tagManagerArgs);
        }
        this.setState({
          manifest: data.data,
          customizationSettings,
          isLoaded: true
        });
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  markComplete() {
    this.setState({ complete: true });
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }
    var tour = this.state.manifest.tourDetails;
    const manifest = this.state.manifest;
    var t = this.state.manifest.bookingDate.date.timeslot;
    var date = strToAbsoluteDate(this.state.manifest.bookingDate.date.date);

    const orderConfirmedMessage = this.props.intl.formatMessage(messages.orderConfirmed);
    const thankYouMessage = this.props.intl.formatMessage(messages.thankYou);
    const bookingRefMessage = this.props.intl.formatMessage(messages.bookingReference);
    const sendDetailsToMessage = this.props.intl.formatMessage(messages.detailsSentTo);
    const importantDetailsMessage = this.props.intl.formatMessage(messages.importantDetails);
    const includesMessage = this.props.intl.formatMessage(messages.included);
    const excludesMessage = this.props.intl.formatMessage(messages.notIncluded);
    const meetingPointMessage = this.props.intl.formatMessage(messages.meetingPoint);

    const bookingRefValue = this.state.manifest.bookingDetails.id.toUpperCase();
    const userEmail = this.state.manifest.bookingDetails.travelerDetails.email;
    const inclusions = this.state.manifest.tourDetails.inclusions;
    const exclusions = this.state.manifest.tourDetails.exclusions;
    const cancellationPolicy = this.state.manifest.tourDetails.cancelationPolicy;
    const importantInformation = this.state.manifest.tourDetails.importantInformation;
    const lat = this.state.manifest.tourDetails.meetingPoint.lat;
    const long = this.state.manifest.tourDetails.meetingPoint.long;
    const isPriceManuallyAdjusted = manifest.bookingDetails.bookingEditingDetails?.isManualAdjusted;

    const classes = {
      modalContainer: {
        maxWidth: '1220px',
        width: '100%',
        paddingTop: '20px',
        margin: '0 auto'
      },
      poweredByContainer: {
        flex: '1 1 100%'
      },
      firstHeading: {
        fontSize: '39px',
        color: '#424242',
        margin: '20px 0 30px'
      },
      firstHeadingPart: {
        marginBottom: '-12px',
        display: 'block'
      },
      secondHeading: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#333333'
      },
      detailsHeading: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#282828'
      },
      bookRef: {
        fontWeight: 'normal'
      },
      importantText: {
        fontSize: '18px',
        color: '#333333'
      },
      inclusionsList: {
        listStyle: 'none',
        padding: '0px'
      },
      exclusionsList: {
        listStyle: 'none',
        padding: '0px'
      },
      listIcon: {
        width: '20px',
        height: '20px',
        margin: '0 16px'
      },
      listItem: {
        listStyle: 'none',
        margin: '10px 0',
        fontSize: '14px',
        color: '#282828',
        display: 'flex',
        alignItems: 'center'
      },
      importantDetailsBlock: {
        margin: '40px 0 30px'
      },
      closeButton: {
        width: '50px',
        height: '50px',
        color: '#aeaeae',
        fontSize: '25px',
        position: 'absolute',
        top: '11px',
        right: '11px'
      },
      viewTicketsText: {
        color: '#ffffff',
        textTransform: 'capitalize'
      },
      mapBlock: {
        width: '100%'
      }
    };

    return (
      <CompanyThemeProviderFromCustomizationSettings {...this.state.customizationSettings}>
        <div style={classes.modalContainer}>
          <Grid container spacing={6} style={{ width: '100%', margin: '0' }}>
            <InjectResizable />
            <Grid item xs={12} md={7}>
              <CircularAnimation />

              <h1 style={classes.firstHeading}>
                <span style={classes.firstHeadingPart}>{orderConfirmedMessage}</span>
                <span style={classes.firstHeadingPart}>{thankYouMessage}!</span>
              </h1>

              <h2 style={classes.secondHeading}>
                {bookingRefMessage}: <span style={classes.bookRef}>{bookingRefValue}</span>
              </h2>

              <span style={classes.importantText}>
                {sendDetailsToMessage}: <br />
                {userEmail}
              </span>

              {(inclusions.length || exclusions.length) && (
                <div style={classes.importantDetailsBlock}>
                  <h2 style={classes.secondHeading}>{importantDetailsMessage}</h2>

                  <Card elevation={2}>
                    <CardContent>
                      {inclusions.length > 0 ? (
                        <>
                          <span style={classes.detailsHeading}>{includesMessage}:</span>
                          <ul style={classes.inclusionsList}>
                            {inclusions.split('\n').map(inclusion => (
                              <li key={inclusion} style={classes.listItem}>
                                <img style={classes.listIcon} alt="" src={checkMarkGreenIcon} />
                                {inclusion}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : null}
                      {exclusions.length > 0 ? (
                        <>
                          <span style={classes.detailsHeading}>{excludesMessage}:</span>
                          <ul style={classes.exclusionsList}>
                            {exclusions.split('\n').map(exclusion => (
                              <li key={exclusion} style={classes.listItem}>
                                <img style={classes.listIcon} alt="" src={CloseIcon} />
                                {exclusion}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : null}
                    </CardContent>
                  </Card>
                </div>
              )}
              {cancellationPolicy && (
                <div style={classes.importantDetailsBlock}>
                  <h2 style={classes.secondHeading}>
                    {this.props.intl.formatMessage(messages.cancellationPolicy)}
                  </h2>
                  <Card elevation={2}>
                    <CardContent>
                      <span className={classes.listItem}>{cancellationPolicy}</span>
                    </CardContent>
                  </Card>
                </div>
              )}

              {importantInformation && (
                <div style={classes.importantDetailsBlock}>
                  <h2 style={classes.secondHeading}>
                    {this.props.intl.formatMessage(messages.importantInformation)}
                  </h2>
                  <Card elevation={2}>
                    <CardContent>
                      <span className={classes.listItem}>{importantInformation}</span>
                    </CardContent>
                  </Card>
                </div>
              )}

              {Boolean(lat && long) && (
                <div style={classes.mapBlock}>
                  <h2 style={classes.secondHeading}>{meetingPointMessage}</h2>

                  <MapContainer lat={lat} long={long} />
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <TourSumarryCard
                renderCardInfo={() => (
                  <FullTourSummary
                    title={tour.title}
                    shortDescription={tour.description}
                    operationalHours={manifest.bookingDate.operationalHours}
                    startingTime={manifest.bookingDate.date.timeslot}
                    date={date}
                    duration={tour.duration}
                  />
                )}
                renderCardTop={() => <TourSummaryImageHeader initialImage={tour.primaryImage} />}
                renderPricingBlock={() => (
                  <FullPriceBreakdown
                    breakdown={manifest.priceBreakdown}
                    isLoaded={this.state.isLoaded}
                    manualTotalFeesAmount={
                      manifest.bookingDetails.bookingEditingDetails?.total?.auxiliaryFee?.amount
                    }
                    isPriceManuallyAdjusted={isPriceManuallyAdjusted}
                    finalTotal={
                      isPriceManuallyAdjusted
                        ? manifest.bookingDetails.bookingEditingDetails?.total?.total?.amount
                        : manifest.priceBreakdown.total
                    }
                  />
                )}
                renderLowerBlock={() => (
                  <Fragment>
                    <WhyTripAdmit />

                    <Button
                      target="_blank"
                      href={`/api/v1/ticket/${this.state.manifest.bookingDetails.id}`}
                      color="primary"
                      variant="contained"
                      style={{ width: '100%' }}>
                      <span style={classes.viewTicketsText}>
                        {this.props.intl.formatMessage(messages.viewTickets)}
                      </span>
                    </Button>
                  </Fragment>
                )}
              />
            </Grid>
          </Grid>
          <div style={classes.poweredByContainer}>
            <PoweredBy />
          </div>
        </div>
      </CompanyThemeProviderFromCustomizationSettings>
    );
  }

  renderHours(date) {
    if (date.date.timeslot) {
      return (
        <span>
          {this.props.intl.formatMessage(messages.startingTime)}:{' '}
          <span style={{ color: '#5ea5ff' }}>{renderTimeslot(date.date.timeslot)}</span>
        </span>
      );
    }

    const range = date.operationalHours;
    return (
      <span>
        {this.props.intl.formatMessage(messages.operationalHours)}:{' '}
        <span style={{ color: '#5ea5ff' }}>
          {renderTimeslot(range.start)}-{renderTimeslot(range.end)}
        </span>
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: compose(
      dispatch,
      changeSessionLocale
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderConfirmationPage));
