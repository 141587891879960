import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { currencySign, formatMoney } from '../../../utils/helpers';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontSize: 12,
    padding: '8px 20px'
  },
  pricingContainer: {
    width: '100%'
  },
  pricingItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 8,
    marginBottom: 8
  },
  participantTitle: {
    color: '#6997ff',
    fontWeight: 'bold'
  },
  participantPrice: {
    fontWeight: 'bold'
  },
  popover: props => ({
    maxWidth: `${props.windowWidth < 600 ? 'calc(100% - 32px)' : '566px'}`,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
  })
}));

export default function PromoPricePopUp({ promoPrices, currency, locale }) {
  const classes = useStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getExistingLocalizedTitle = promoPrice => {
    return (
      promoPrice.participantTitle[locale]?.data.title ||
      promoPrice.participantTitle['en']?.data.title
    );
  };

  const getMinPrice = () => {
    return Math.min(...promoPrices.map(promoPrice => promoPrice.price));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.container}>
      <div onClick={handleClick}>
        {currencySign(currency) + formatMoney({ amount: getMinPrice(), currency })}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{
          paper: classes.popover
        }}>
        <div className={classes.chips}>
          <div>{intl.formatMessage(messages.participantPrices)}</div>
          <div className={classes.pricingContainer}>
            {promoPrices.length &&
              promoPrices.map(promoPrice => {
                return (
                  <div className={classes.pricingItem}>
                    <div className={classes.participantTitle}>
                      {getExistingLocalizedTitle(promoPrice)}
                    </div>
                    <div className={classes.participantPrice}>
                      {currencySign(currency) + formatMoney({ amount: promoPrice.price, currency })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Popover>
    </div>
  );
}
